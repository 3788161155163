/** Converts FEB23/3JUN to Feb23/3Jun **/

const ConvertExpirationToCamelCase = (expiration = "") => {
    if (expiration == null) return "";
    const char_list = expiration.split('');
    let replace = false;
    for (let i = 0; i < char_list.length; i++) {
        const char = char_list[i];
        if(!replace) {
            if (char.match(/[A-Z]/)) {
                replace = true;
            } else if (char.match(/[a-z]/)) {
                char_list[i] = char.toUpperCase();
            }
        } else if (char.match(/[a-z]/i)) {
            if (replace) {
                char_list[i] = char.toLowerCase();
            }
        } else {
            replace = false;
        }
    }
    return char_list.join('');
}

export default ConvertExpirationToCamelCase
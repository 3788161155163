import { createStore, combineReducers, compose, applyMiddleware } from 'redux';

import reducers from './reducers';
import middleware from './middleware';

export default createStore(
    combineReducers(reducers),
    compose(
        applyMiddleware(...middleware),
        window.devToolsExtension ? window.devToolsExtension() : f => f
    )
);
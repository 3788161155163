import {createCachedSelector} from 're-reselect';
import {
    filter,
} from 'lodash'

const getRooms = state => state.roomsReducer.byId;

const selectRoomsByType = createCachedSelector(
    getRooms,
    (_state_, roomType) => roomType,
    (rooms, roomType) => filter(rooms, (room) => room.type === roomType)
)(
(_state_, roomType) => roomType
)

export default selectRoomsByType;
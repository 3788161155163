import React, {useEffect, useRef} from 'react';
import {connect} from 'react-redux'
import {selectMessagesByRoomId} from '../../../../redux/selectors';

/** Style **/
import './ChatBoard.scss';

/** Components **/
import Message from '../Message';
import Replies from '../Replies';

/** Hooks **/
import usePrevious from '../../../../hooks/usePrevious';

const ChatBoard = (props) => {
    const {
        messages,
        onClick
    } = props;

    const prevMessages = usePrevious(messages);

    const {openThreadsById} = props;

    // Scroll to bottom: https://stackoverflow.com/questions/37620694/how-to-scroll-to-bottom-in-react
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        if ((!prevMessages) || (prevMessages!=null && prevMessages.length > 0 && prevMessages[0] === undefined) || (prevMessages && prevMessages.length !== messages.length)) {
            messagesEndRef.current.scrollIntoView({ behavior: "auto" })
        }
    }

    useEffect(scrollToBottom, [messages])

    return <div onClick = {onClick} className={'ChatBoard'} >
            {messages && messages.map((message, ix) => {
                const messageId = message ? message.id : -1 * ix;
                const isThreadOpen = openThreadsById[messageId];
                return message
                ? <div key = {messageId}>
                    <Message
                        message = {message}
                        isThreadOpen = {isThreadOpen}
                    />
                    {isThreadOpen &&
                        <Replies
                            messageId = {messageId}
                        />
                    }
                </div>
                : <div key = {messageId}>Loading Chat</div>
            })}

        <div className = 'dummyDiv'
             ref={messagesEndRef}
        />
    </div>
}

const mapStateToProps = (state, props) => {
    return {
        openThreadsById: state.aivaReducer.openThreadsById,
        messages: selectMessagesByRoomId(state, props.roomId)
    }
}

export default connect(mapStateToProps, null) (ChatBoard);
import {ConvertExpirationToCamelCase} from './index';
import {REVCON_ID} from './constants';

const _GetOptionString = (order) => {
    const {
        ticker,
        expiration,
        strike,
        ratio,
        structName,
        tied,
        delta,
    } = order;

    return ticker
        + (expiration ? ' ' + ConvertExpirationToCamelCase(expiration): '?')
        + (strike ? ' ' + strike : '?')
        + (ratio ? ' ' + ratio : '')
        + (structName ? ' ' + structName : '?')
        + (tied ? ' ' + 'vs. ' + tied : '')
        + (delta ? ' ' + delta + 'd': '');
}

const _GetRevConString = (order) => {
    const {
        ticker,
        expiration,
        strike,
        structName,
        over
    } = order;

    return ticker
        + (expiration ? ' ' + ConvertExpirationToCamelCase(expiration): '?')
        + (strike ? ' ' + strike : '?')
        + (structName ? ' ' + structName.toLowerCase() : '?')
}

/** MarketSummary is from the mapStateToProps of OrderMenu **/
const GetOrderString = (order, bestBid, bestAsk) => {
    const bid = bestBid.price, ask = bestAsk.price;
    let marketText = '';
    if (bid !=null && ask != null) marketText = ' ' + bid + '/' + ask;
    else if (bid != null) marketText = ' ' + bid + ' bid';
    else if (ask != null) marketText = ' ' + ask + ' off';

    switch(order.structureId) {
        case REVCON_ID:
            return _GetRevConString(order).trim() +  marketText + ' +' + order.over;
        default:
            return _GetOptionString(order).trim() + marketText;
    }
}

export default GetOrderString;
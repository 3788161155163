import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import {setTabFormProps} from '../../../redux/actions/wb';
import {ACTION_TYPE, TAB_DEFAULT_TEMPLATE, RODAL_STYLES, TAB_FILTER_BY, TAB_STATUS} from '../../../helpers/constants';

import Rodal from 'rodal';
import {
    Input,
    Checkbox,
    NumericTextBox
} from '@progress/kendo-react-inputs';
import {Button} from '@progress/kendo-react-buttons';
import {DateRangePicker} from '@progress/kendo-react-dateinputs';
import {
    OrgMultiSelect,
    StructureMultiSelect,
    UnderlyingMultiSelect
} from '../../../utilities/FilterMultiSelect';

/** Same styling as OrderForm.scss **/
import BEMHelper from 'react-bem-helper';
import {usersService} from '../../../feathers';
import {GetWbOrdersQuery} from '../../../helpers';
import {setWbOrdersAndBids} from '../../../redux/actions/wbOrders';
import {ComboBox, DropDownList} from '@progress/kendo-react-dropdowns';
import {filter} from '@progress/kendo-data-query/dist/npm/transducers';
const bem = new BEMHelper('FormTable');

const TabForm = (props) => {
    const {
        user,
        tabFormProps,
        closeTabForm,
        setWbOrdersAndBids
    } = props;

    const {
        id,
        tabs,
        tabKey
    } = user;

    const {
        actionType,
        prevTab
    } = tabFormProps;

    const [tab, setTab] = useState(TAB_DEFAULT_TEMPLATE);

    useEffect(() => {
        /** Generate a unique key if this is a create **/
        if (actionType === ACTION_TYPE.CREATE) {
            setTab({...prevTab, key: (new Date().getTime()).toString()});
        } else {
            setTab({...prevTab});
        }
    }, [tabFormProps]);

    const onSubmit = async (event) => {
        event.preventDefault();

        const {
            filterByDays,
            days,
            filterByRange,
            range
        } = tab;

        /** Check date range is valid **/
        if (filterByDays && (days == null || days < 1 || days > 1000)) {
            window.alert('Business days must be between 1 and 1000.');
            return;
        } else if (filterByRange && (range == null || range.start == null || range.end == null)) {
            window.alert('Please select a valid date range');
            return;
        }

        if (actionType === ACTION_TYPE.CREATE) {
            const user = await usersService.patch(id, {
                    tabs: JSON.stringify([...tabs, tab]),
                    tabKey: tab.key
                });
        } else {
            // Get active tab index, splice it out and replace with updated tab
            const tabIndex = tabs.findIndex(tab => tab.key === tabKey);
            if (tabIndex === -1) return;
            const user = await usersService.patch(id, {
                tabs: JSON.stringify([...tabs.slice(0, tabIndex), tab, ...tabs.slice(tabIndex + 1)]),
                tabKey: tab.key
            });
        }
        setWbOrdersAndBids(GetWbOrdersQuery(tab));
        closeTabForm();
    }

    const onInputChange = (event) => {
        let {
            name,
            value
        } = event.target;

        /** handle Date Picker **/
        if (!name) {
            name = event.target.props.name;
            value = event.value;
        }

        let newTab = {...tab};
        newTab[name] = value;
        setTab(newTab);
    }

    const onCheckboxChange = (event) => {
        const name = event.target.element.name;
        const value = event.value;

        let newTab = {...tab};
        newTab[name] = value;
        if (name === TAB_FILTER_BY.RANGE) {
            delete newTab.days;
            newTab.range = {start: new Date(), end: new Date()}
            newTab[TAB_FILTER_BY.DAYS] = !value;
        } else {
            delete newTab.range;
            newTab.days = 1;
            newTab[TAB_FILTER_BY.RANGE] = !value;
        }

        setTab(newTab);
    }

    let {
        title,
        filterByDays,
        days,
        filterByRange,
        status,
    } = tab;

    let {
        start = null,
        end = null
    } = tab.range ?? {};
    if (start!=null && typeof start === 'string') start = new Date(start);
    if (end!=null && typeof end === 'string') end = new Date(end);

    return <Rodal
        visible={actionType!=null}
        onClose={closeTabForm}
        customStyles={{...RODAL_STYLES}}
        closeOnEsc={true}
    >
        <div {...bem('title')}> {
            actionType === ACTION_TYPE.CREATE
            ? 'Create New Tab'
            : 'Update ' + title + ' tab'} </div>
        <form
            onSubmit={onSubmit}
        >
            <table {...bem()}>
                <tbody>
                    <tr>
                        <td>Title</td>
                        <td>
                            <Input
                                name = 'title'
                                value = {title}
                                autoComplete='off'
                                onChange = {onInputChange}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td> Filter By </td>
                        <td>
                            <Checkbox
                                name = {TAB_FILTER_BY.DAYS}
                                label = 'Biz Days'
                                checked = {filterByDays}
                                onChange = {onCheckboxChange}
                            />
                            &nbsp;&nbsp;&nbsp;
                            <Checkbox
                                name = {TAB_FILTER_BY.RANGE}
                                label = 'Date Range'
                                checked = {filterByRange}
                                onChange = {onCheckboxChange}
                            />
                        </td>
                    </tr>

                <tr>
                    <td>
                        {filterByDays && 'Days'}
                    </td>
                    <td>
                        {filterByDays
                            ? <NumericTextBox
                                name = 'days'
                                value = {days}
                                min = {1}
                                max = {1000}
                                step = {1}
                                validationMessage={'Business days must be between 1 and 100'}
                                rangeOnEnter={true}
                                onChange = {onInputChange}
                            />
                            : <DateRangePicker
                                name = 'range'
                                value = {{start, end}}
                                onChange = {onInputChange}
                            />
                        }
                    </td>
                </tr>

                <tr>
                    <td> Structures </td>
                    <td>
                        <StructureMultiSelect
                            tab = {tab}
                            setTab = {setTab}
                        />
                    </td>
                </tr>

                <tr>
                    <td> Tickers </td>
                    <td>
                        <UnderlyingMultiSelect
                            tab = {tab}
                            setTab = {setTab}
                        />
                    </td>
                </tr>

                <tr>
                    <td> Clients </td>
                    <td>
                        <OrgMultiSelect
                            tab = {tab}
                            setTab = {setTab}
                        />
                    </td>
                </tr>

                <tr>
                    <td> Status </td>
                    <td>
                        <DropDownList
                            name = 'status'
                            onChange = {onInputChange}
                            value = {status}
                            data = {Object.values(TAB_STATUS)}
                        />
                    </td>
                </tr>
                </tbody>
            </table>

            <Button
                primary={true}
                type='submit'
                {...bem('submit_button')}
                icon={'check-circle'}
            >
                Save
            </Button>
        </form>
    </Rodal>
}

const mapState = (state) => {
    return {
        user: state.authReducer.user,
        tabFormProps: state.wbReducer.tabFormProps
    }
}

const mapDispatch = (dispatch) => {
    return {
        closeTabForm: () => dispatch(setTabFormProps()),
        setWbOrdersAndBids: (query) => dispatch(setWbOrdersAndBids(query))
    }
}

export default connect(mapState, mapDispatch)(TabForm);
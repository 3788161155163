import {createCachedSelector} from 're-reselect';

// const getPriceDataByTicker = (state, ticker) => state.symbolsReducer.symbolToPriceData[ticker];
// const getQuoteByTicker = (state, ticker) => state.symbolsReducer.symbolToQuote[ticker];

const getSymbolsReducer = (state) => state.symbolsReducer;
const getIndex = (state, ticker) => state.underlyingsReducer.indicesByTicker[ticker];

/**
 * Retrieves the quote data for a ticker. Appends a ^ if is an index
 */
const selectSummaryByTicker = createCachedSelector(
    (_, rawTicker) => rawTicker,
    getSymbolsReducer,
    getIndex,
    (rawTicker, symbolsReducer, index) => {
        const ticker = index == null ? rawTicker : '^' + rawTicker;

        const priceData = symbolsReducer.symbolToPriceData[ticker];
        const quote = symbolsReducer.symbolToQuote[ticker];
        return {
            ...priceData,
            ...quote
        }
    }
)(
    (_state_, ticker) => ticker ?? '-1'
)

export default selectSummaryByTicker;
import { tradesService } from './index';
import store from '../redux/store';

import {
    handleTradeCreated,
    handleTradeUpdated,
    handleTradeRemoved
} from '../redux/actions';
import {createWbTrade, updateWbTrade} from '../redux/actions/wbTrades';

export default function () {
    tradesService.on('created', obj => {
        store.dispatch(createWbTrade(obj));
        // store.dispatch(handleTradeCreated(obj));
    })

    tradesService.on('updated', obj => {
        store.dispatch(updateWbTrade(obj));
        // store.dispatch(handleTradeUpdated(obj));
    })

    tradesService.on('patched', obj => {
        store.dispatch(updateWbTrade(obj));
        // store.dispatch(handleTradeUpdated(obj));
    })

    tradesService.on('removed', obj => {
        // store.dispatch(handleTradeRemoved(obj));
    })
}
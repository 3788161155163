import {
    CREATE_TICKET
} from '../types';

export default function createTicket (id) {
    return async dispatch => {
        dispatch({
            type: CREATE_TICKET,
            id
        })
    }
}
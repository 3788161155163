
import React from 'react';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';

class FilterComboBox extends React.Component {
    state = {
        data: (this.props.data ? this.props.data.slice() : []),
    };

    filterChange = (event) => {
        this.setState({
            data: this.filterData(event.filter)
        });
    }

    filterData(filter) {
        const data = this.props.data.slice();
        return filterBy(data, filter);
    }

    render() {
        const {value} = this.props;

        /** Custom value is needed to assign a unique ID to custom object **/
        const customValue = (value.id == null
            ? {...value, id: null}
            : value);

        return (
            <ComboBox
                {...this.props}
                value = {customValue}
                data = {this.state.data}
                filterable={true}
                onFilterChange={this.filterChange}
                clearButton={false}
                suggest={true}
            />
        );
    }
}

export default FilterComboBox;
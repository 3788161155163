import {selectWbMarketByOrderId} from '../selectors';
import {
    INTEREST_TYPE,
    BID_PAIR_MAPPINGS
} from '../../helpers/constants';
import {bidsService, tradesService} from '../../feathers';

export const SET_WB_BIDS = 'SET_WB_BIDS';
export const CREATE_WB_BID = 'ADD_WB_BID';
export const UPDATE_WB_BID = 'UPDATE_WB_BID';
export const REMOVE_WB_BID = 'REMOVE_WB_BID';

/**
 * Unlike setWbOrders, setBids does not call the api.
 * this method is called by wbOrders
 * @param bids a list of bid objects from the order associations
 */
export const setWbBids = (bids) => {
    return {
        type: SET_WB_BIDS,
        bids
    }
}

export const createWbBid = (bid) => {
    return {
        type: CREATE_WB_BID,
        bid: {
            ...bid,
            price: typeof bid.price === 'string' ? parseFloat(bid.price) : bid.price,
            size: typeof bid.price === 'string' ? parseFloat(bid.size) : bid.size
        }
    }
}

export const updateWbBid = (bid) => {
    return {
        type: UPDATE_WB_BID,
        bid: {
            ...bid,
            price: typeof bid.price === 'string' ? parseFloat(bid.price) : bid.price,
            size: typeof bid.price === 'string' ? parseFloat(bid.size) : bid.size
        }
    }
}

export const tradeBid = (targetBid) => {
    return async (dispatch, getState) => {
        const {
            id: targetId,
            price,
            direction,
            orderId
        } = targetBid;

        const blockId = targetBid.id;
        let targetSize = targetBid.size, runSize = 0;

        /** Extract order fields which are common for all trades in the same block **/
        const order = getState().wbOrdersReducer.byId[orderId];
        let orderData = {};

        // Left out expiration for now
        [
            'ticker',
            'underlyingId',
            'strike',
            'structName',
            'structureId',
            'ratio',
            'delta',
        ]
            .forEach(field => {
                orderData[field] = order[field];
            });
        orderData.price = price;
        orderData.blockId = blockId;
        orderData.orderId = orderId;

        /** Extract fields which are common for all counter interests trades **/
        const targetPrefix = direction === INTEREST_TYPE.BID ? 'buy' : 'sell';
        const counterPrefix = direction === INTEREST_TYPE.BID ? 'sell' : 'buy';
        let counterData = {};
        BID_PAIR_MAPPINGS.forEach(pair => {
            const from = pair[0], to = pair[1];
            counterData[targetPrefix + to] = targetBid[from];
        });

        const {
            live_asks: asks = [],
            live_bids: bids = []
        } = selectWbMarketByOrderId(getState(), orderId);

        const counterBids = (direction === INTEREST_TYPE.BID
            ? asks
            : bids).filter(interest => (
                interest.price === price
                && interest.voided != true
                && interest.blockId == null
            ));

        if(counterBids.length === 0) {
            window.alert('No matching counterparties found.');
            return;
        }
        if([targetBid,...counterBids].some(({orgCode}) => orgCode == null || orgCode.trim() === '')) {
            window.alert('Organization code cannot be blank.')
            return;
        }

        const _getDataForCurrentCounterBid = (bid) => {
            let data = {};
            BID_PAIR_MAPPINGS.forEach(pair => {
                const from = pair[0], to = pair[1];
                data[counterPrefix + to] = bid[from];
            });
            return data;
        }

        for (let i = 0; i < counterBids.length; i++) {
            const counterBid = counterBids[i];
            const {
                id: counterBidId,
                size
            } = counterBid;

            if (
                i === counterBids.length - 1
                && runSize + size < targetSize
            ) {
                // Create new targetItem with SZ = targetSize - runSize - size
                let newTargetBid = {...targetBid};
                delete newTargetBid.id;
                newTargetBid.size = targetSize - runSize - size;
                bidsService.create(newTargetBid);

                // Update current targetItem SZ = runSize + size
                // Mark current targetItem traded
                bidsService.patch(targetId, {size: runSize + size, blockId});

                // Mark counterItem traded
                bidsService.patch(counterBidId, {blockId});

                // Create trade for counter with SZ = size
                tradesService.create({
                    ...orderData,
                    ...counterData,
                    ..._getDataForCurrentCounterBid(counterBid),
                    size
                });

                break;
            } else if (runSize + size > targetSize) {
                // Create a new counterItem with SZ = runSize + size - targetSize
                let newCounterBid = {...counterBid};
                delete newCounterBid.id;
                newCounterBid.size = runSize + size - targetSize;
                bidsService.create(newCounterBid);

                // Update current counterItem SZ = targetSize - runSize
                // Mark counterItem traded
                bidsService.patch(counterBidId, {size: targetSize - runSize, blockId})

                // Mark current targetItem traded
                bidsService.patch(targetId, {blockId});

                // Create trade for counter with SZ = targetSize - runSize
                tradesService.create({
                    ...orderData,
                    ...counterData,
                    ..._getDataForCurrentCounterBid(counterBid),
                    size: targetSize - runSize
                });

                break;
            } else {
                runSize += size;
                bidsService.patch(counterBidId, {blockId});

                tradesService.create({
                    ...orderData,
                    ...counterData,
                    ..._getDataForCurrentCounterBid(counterBid),
                    size
                });

                if (i === counterBids.length - 1) bidsService.patch(targetId, {blockId})
            }
        }
    }
}
import React from 'react';
import {useDispatch} from 'react-redux';
import {
    toggleDepth,
    setSpreadFormProps
} from '../../../redux/actions/wb';

import './MarketCell.scss';
import BEMHelper from 'react-bem-helper';
import {GetTimeFromDate} from '../../../helpers';
const market_bem = new BEMHelper('MarketCell');
const best_bem = new BEMHelper('Best');

/**
 * Cell with price and size
 * @param interest best bid object to be displayed
 */

const MarketCell = (props) => {
    const {
        orderId,
        direction,
        bestInterest = {},
    } = props;

    /** Dispatch **/
    const dispatch = useDispatch();

    const onDoubleClick = (event) => {
        event.preventDefault();
        dispatch(setSpreadFormProps({
            orderId: orderId,
            offset: {left: event.clientX, top: event.pageY},
            direction,
            bidId: null
        }));
    }

    const onContextMenu = (event) => {
        event.preventDefault();
        event.stopPropagation();
        dispatch(toggleDepth(orderId));
    }

    /** Get best bid or ask (interest) to display **/
    const {
        price,
        size
    } = bestInterest;
    const bem_modifier = price != null ? 'interest' : 'no_interest';

    return <div {...market_bem()}>
        <div
            {...best_bem('', bem_modifier)}
            onContextMenu={onContextMenu}
            onDoubleClick={onDoubleClick}
            title = {''}
        >
            <div {...best_bem('price', bem_modifier)}> {price === 0 ? 'EVEN' : price} </div>
            <div {...best_bem('size', bem_modifier)}> {size} </div>
        </div>
    </div>
}


export default MarketCell;
import {ordersService} from '../../../feathers'
import {SET_ORDERS} from '../types';
import moment from 'moment';

/** Quotestream **/
import {
    setSymbolInfo,
    subscribeToSymbols
} from '../index';

import {
    getEnhancedQuotes
} from '../../../api';

const defaultQuery = {
    updatedAt: {
        $gt: moment().startOf('day')
    }
}

export default function (query = defaultQuery){
    return async (dispatch) => {
        try {
            const orders = await ordersService.find({
                query
            });

            dispatch({
                type: SET_ORDERS,
                orders
            });

            let tickers = [];

            /** Subscribe to quotestream symbols **/
            orders.forEach(order => {
                dispatch(subscribeToSymbols(order));
                tickers.push(order.ticker);
            });

            /** Batch subscribe to tickers **/
            const data = await getEnhancedQuotes(tickers.join(","));
            dispatch(setSymbolInfo(data));
        } catch (e) {
            console.log("Error setting orders");
            console.error(e);
        }
    }
}
import produce from 'immer';
import {combineReducers} from 'redux';

import {
    SET_WB_ORDERS,
    CREATE_WB_ORDER,
    UPDATE_WB_ORDER,
    REMOVE_WB_ORDER,
} from "../actions/wbOrders";
import {GetOrdersById} from '../../helpers/GetByIdWithRelated';
import {CREATE_WB_BID} from '../actions/wbBids';
import {DeleteFromListById} from '../../helpers';
import {CREATE_WB_TRADE} from '../actions/wbTrades';

const byId = produce((draft, action) => {
    switch (action.type) {
        case SET_WB_ORDERS:
            return GetOrdersById(action.orders);
        case CREATE_WB_ORDER:
            action.order.bids = [];
            action.order.trades = [];
            draft[action.order.id] = action.order;
            return;
        case UPDATE_WB_ORDER:
            // Don't replace the previous bids. Let the bidsListener handle updating bids
            draft[action.order.id] = {
                ...action.order,
                bids: draft[action.order.id].bids,
                trades: draft[action.order.id].trades
            };
            return;
        case CREATE_WB_BID:
            if (!(action.bid.orderId in draft)) return;
            draft[action.bid.orderId].bids.push(action.bid.id);
            return;
        case CREATE_WB_TRADE:
            if (!(action.trade.orderId in draft)) return;
            draft[action.trade.orderId].trades.splice(0, 0, action.trade.id);
            return;
        case REMOVE_WB_ORDER:
            delete draft[action.order.id];
            return;
    }
}, {});

const allIds = produce((draft, action) => {
    switch(action.type) {
        case SET_WB_ORDERS:
            return action.orders.map(order => order.id);
        case CREATE_WB_ORDER:
        case UPDATE_WB_ORDER: {
            DeleteFromListById(draft, action.order.id);
            draft.splice(0, 0, action.order.id);
            return;
        }
        case REMOVE_WB_ORDER: {
            DeleteFromListById(draft, action.order.id);
            return;
        }
    }
}, []);


const WbOrdersReducer = combineReducers({
    byId: byId,
    allIds: allIds
});

export default WbOrdersReducer;
import {
    SET_USERS
} from "../types";

import { usersService } from '../../../feathers';

/**
 * Calls Find on Replies and sets Replies reducer state
 * @returns {Function}
 */
const setUsers = () => {
    return async (dispatch) => {
        usersService.find().then(res => {
            dispatch({
                type: SET_USERS,
                users: res
            });
        }).catch(error => {
            console.log('Error retrieving users:');
            console.log(error);
        });
    }
}

export default setUsers;
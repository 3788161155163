export default {
    gridCellBuy: '#33cc33',
    gridCellSell: '#ff704d',
    gridCellVoided: {
        'background-image': 'linear-gradient(123deg, rgba(255,255,255,.1) 25%, rgba(255,255,255,0) 25%, rgba(255,255,255,0) 50%, rgba(255,255,255,.1) 50%, rgba(255,255,255,.1) 75%, rgba(255,255,255,0) 75%, rgba(255,255,255,0) 100%)',
        'background-size': '95.39px 146.89px'
    },

    PUT: '#BB86FC',
    CALL: '#03DAC5',

    // email statuses:
    READY: '#3399ff',
    SENT: '#33ff33',
    SENDING: 'orange',
    NOTREADY: '#c1a57b',
    FAIL: '#ff6666',
}
import React, {useEffect, useState} from 'react';

/** Components **/
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';

/** Redux **/
import {connect} from 'react-redux';
import {selectUsersNotInModalRoom} from '../../../../redux/selectors';

const UsersMulti = (props) => {
    const {
        aivaPortal,
        users,
        onChange,
        value
    } = props;

    const [filteredUsers, setFilteredUsers]= useState([]);

    useEffect(()=>{
        setFilteredUsers(users.slice());
    }, [users])

    const filterChange = (event) => {
        setFilteredUsers(filterBy(users.slice(), event.filter))
    }

    const itemRender = (li, itemProps) => {
        const online_BEM = itemProps.dataItem.isOnline ? 'online' : 'offline';
        const itemChildren = <span>
            {<div style = {{display: 'inline-block'}} className = {'Body__dot Body__dot--' + online_BEM}></div>}
            &nbsp;{li.props.children}
        </span>;

        return React.cloneElement(li, li.props, itemChildren);
    }

    return <div>
        <MultiSelect
            data={filteredUsers}
            filterable={true}
            onFilterChange={filterChange}
            onChange={onChange}
            value={value}
            textField="username"
            dataItemKey="id"
            itemRender={itemRender}
            popupSettings={{
                "animate": false,
                "appendTo": aivaPortal.document ? aivaPortal.document.body : undefined
            }}
        />
    </div>
}

const mapStateToProps = (state) => {
    return {
        aivaPortal: state.aivaReducer.aivaPortal,
        users: selectUsersNotInModalRoom(state)
    };
}


export default connect(mapStateToProps, null) (UsersMulti);
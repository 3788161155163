import createCachedSelector from 're-reselect';

const getOrdersById = (state) => state.wbOrdersReducer.byId;
const getTradesById = (state) => state.wbTradesReducer.byId;
const orderId = (_, orderId) => orderId;

const selectWbOrderById = createCachedSelector(
    getOrdersById,
    getTradesById,
    orderId,
    (ordersById, tradesById, orderId) => {
        const order = ordersById[orderId] ?? {trades: []};
        const tradeObjects = order.trades.map(tradeId => tradesById[tradeId] ?? {});
        return {
            ...order,
            trades: tradeObjects
        };
    }
)((_, orderId) => orderId);

export default selectWbOrderById;
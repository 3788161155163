import React from 'react';
import BEMHelper from 'react-bem-helper';

import './ClientCell.scss';
const classes = new BEMHelper('ClientCell');

const ClientCell = ({orgCode, orgString}) => {
    return <div {...classes()}>
        <div {...classes('name')}>
            {orgCode}
        </div>
        <div {...classes('orgString')}>
            {orgString}
        </div>
    </div>
}

export default ClientCell;
import React, {useCallback} from 'react';
import {connect} from 'react-redux';
import {Tabs, Tab} from 'react-draggable-tab';

import {ACTION_TYPE, TAB_DEFAULT_TEMPLATE, TAB_STATUS} from '../../../../helpers/constants';
import {setWbOrdersAndBids} from '../../../../redux/actions/wbOrders';
import {setTabFormProps} from '../../../../redux/actions/wb';
import {usersService} from '../../../../feathers';
import {GetWbOrdersQuery} from '../../../../helpers';

import BEMHelper from 'react-bem-helper';
import './TabPanel.scss';
import {Button} from '@progress/kendo-react-buttons';
const classes = BEMHelper('TabPanel');

class TabPanel extends React.Component {
    constructor(props) {
        super(props);
    }

    /** Tab keys are STRINGS
     *  Default tab keys are '0', '1', '2'
     *  New Tabs have unique keys generated by date().getTime()
     * **/
    componentDidMount() {
        const DEFAULT_TABS = [
            {
                key: '0',
                title: 'Working',
                days: 1,
                status: TAB_STATUS.WORKING,
                unclosable: true
            },
            {
                key: '1',
                title: 'Not Working',
                days: 1,
                status: TAB_STATUS.NOT_WORKING,
                unclosable: true
            },
            {
                key: '2',
                title: 'Today',
                days: 1,
                status: TAB_STATUS.EVERYTHING,
                unclosable: true
            }
        ]
    }

    /** Tab Methods **/
    handleTabSelect(e, key, currentTabs) {
        // Update user selectedTabKey
        const {
            id,
            tabs
        } = this.props.user;

        if (this.props.wbOrdersLoading) return;

        usersService
            .patch(
                id,
                {tabKey: key}
            )
            .then(() => {
                this.props.setWbOrdersAndBids(
                    GetWbOrdersQuery(
                        tabs.find(tab => tab.key === key)
                    )
                )
            });
    }

    handleTabClose(e, key, currentTabs) {
        const {
            id,
            tabs
        } = this.props.user;

        if (window.confirm('Are you sure you want to delete this tab?')) {
            const newTabs = tabs.filter(tab => tab.key !== key);
            usersService
                .patch(
                    id,
                    {
                        tabKey: newTabs[newTabs.length - 1].key,
                        tabs: JSON.stringify(newTabs)
                    }
                );
        }
    }

    handleTabPositionChange(e, key, currentTabs) {
        const {
            id,
            tabs
        } = this.props.user;

        let currentTabIndex = tabs.map(tab=>tab.key).indexOf(key);
        const currentTab = tabs[currentTabIndex];
        let newTabs = [...tabs];
        newTabs.splice(currentTabIndex, 1);

        let index = null;
        currentTabs.forEach((tab, ix) => {
            if (tab.key === key.toString()) {
                index = ix;
            }
        });
        newTabs.splice(index, 0, currentTab);

        usersService
            .patch(id, {tabs: JSON.stringify(newTabs)})
            .then(res => {
                console.log(JSON.parse(res.tabs));
            })
    }

    handleTabAddButtonClick(e, currentTabs) {
        this.props.setTabFormProps({
            prevTab: TAB_DEFAULT_TEMPLATE,
            actionType: ACTION_TYPE.CREATE
        });
    }

    onEdit = () => {
        const {
            tabs,
            tabKey
        } = this.props.user ?? {};

        this.props.setTabFormProps({
            actionType: ACTION_TYPE.UPDATE,
            prevTab: tabs.find(tab => tab.key === tabKey)
        });
    }

    render () {
        const {
            user,
            orderCount,
        } = this.props;

        const {
            tabs: tab_list = [],
            tabKey,
        } = user;

        const activeTab = tab_list.find(tab => tab.key === tabKey) ?? {key: "0", unclosable: true};

        const tabs = tab_list.map((tab = {}) => {
            const {
                key = "-1",
                title = "Error",
                unclosable = true
            } = tab;
            return <Tab
                key = {key}
                title = {title + (tab === activeTab ? ' (' + orderCount + ')' : '')}
                unclosable = {unclosable}
            >
                <></>
            </Tab>
        });

        return (
            <div {...classes()}>
                <Tabs
                    selectedTab={tabKey}
                    onTabSelect={this.handleTabSelect.bind(this)}
                    onTabClose={this.handleTabClose.bind(this)}
                    onTabAddButtonClick={this.handleTabAddButtonClick.bind(this)}
                    onTabPositionChange={this.handleTabPositionChange.bind(this)}
                    keepSelectedTab={true}
                    tabs={tabs}
                    tabsClassNames = {{
                        tabBarAfter: 'foo'
                    }}
                />
                {activeTab.unclosable!==true && (
                    <div {...classes('button_wrap')}>
                        <Button
                            icon='edit'
                            look='clear'
                            {...classes('button')}
                            onClick = {this.onEdit}
                        />
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        wbOrdersLoading: state.wbReducer.wbState.wbOrdersLoading
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setWbOrdersAndBids: (query) => dispatch(setWbOrdersAndBids(query)),
        setTabFormProps: (tabFormProps) => dispatch(setTabFormProps(tabFormProps))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (TabPanel);
import React from 'react';
import {connect} from 'react-redux';
import MyComboBox from '../MyComboBox';

const OrgComboBox = (props) => {
    return <MyComboBox
        {...props}
        data = {props.organizations}
    />
}

const mapOrgState = (state) => {
    return {
        organizations: Object.values(state.staticReducer.organizationsById)
    }
}

export default connect(mapOrgState)(OrgComboBox);
import { bidsService } from './index';
import store from '../redux/store';

import {
    handleBidCreated,
    handleBidUpdated,
    handleBidRemoved
} from '../redux/actions';

import {
    createWbBid,
    updateWbBid
} from '../redux/actions/wbBids';

export default function () {
    bidsService.on('created', obj => {
        store.dispatch(createWbBid(obj));
        store.dispatch(handleBidCreated(obj));
    })

    bidsService.on('updated', obj => {
        store.dispatch(updateWbBid(obj));
        store.dispatch(handleBidUpdated(obj));
    })

    bidsService.on('patched', obj => {
        store.dispatch(updateWbBid(obj));
        store.dispatch(handleBidUpdated(obj));
    })

    bidsService.on('removed', obj => {
        store.dispatch(handleBidRemoved(obj));
    })
}
import {createCachedSelector} from 're-reselect';

const getRoomById = (state, roomId) => state.roomsReducer.byId[roomId];

const selectRoomById = createCachedSelector(
    getRoomById,
    (room) => room
)(
    (_state_, roomId) => roomId
)

export default selectRoomById;
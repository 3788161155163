import React from 'react';
import {Button, ButtonGroup, Toolbar, ToolbarItem, ToolbarSeparator} from '@progress/kendo-react-buttons';
import {AutoComplete, ComboBox} from '@progress/kendo-react-dropdowns';
import {Input, Checkbox} from "@progress/kendo-react-inputs";
import { Popup } from '@progress/kendo-react-popup'

import _ from 'lodash';
import client from "../../feathers";

import Hotkeys from 'react-hot-keys';

var Scroll = require('react-scroll');
var scroll = Scroll.animateScroll;

const inputMap = {
    "product": "Product",
    "ticker": "Ticker",
    "name": "Underlying Name",
    "cusip": "CUSIP",
    "sector": "Sector",
    "exchange": "Exchange",
}

const dropDownFieldsMap = {
    "product": ["Stock", "ETF", "Index", "Future"],
    "exchange": ["NYSE", "NASDAQ"],
    "sector": ["Energy", "Basic Materials", "Industrials", "Consumer Discretionary", "Consumer Staples", "Healthcare", "Financial", "Technology", "Communications", "Utilities", "Real Estate"],
}


export default class UnderlyingForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orgItemAdded: true,

            orgItemInEdit: {},
            prevOrgItem: {},
            orgItemChanged: false,

            entItems: {},
            prevEntItems: {},
            entIdsInEdit: new Set(),
            entItemChanged: false,

            newEntItems: [],
            entIndexToSelect: -1,

            underData:[] //array of all underlyings
        };
    }

    componentDidMount() {
        this.getOrgList();

        client.service('underlyings').on('created', op => this.orgItemCreated(op));
        client.service('underlyings').on('updated', op => this.orgItemCreated(op));
        client.service('underlyings').on('deleted', op => this.orgItemCreated(op));


    }

    getOrgList = () => {
        console.log("getting org list")
        client.service('underlyings').find().then((reqPage) => {
            this.setState({"underData": reqPage})
        })
    }

    handleOrgSelected = (org_item) => {
        if((!this.state.orgItemChanged && !this.state.entItemChanged) || window.confirm("You have unsaved changes. Discard and continue?")) {

            this.setState({
                orgItemInEdit: {},
                orgComboItemInEdit: {},

                selectedPnObj: {},
            }); //clears the current person item (in this case person)
            if (!org_item) {
                this.setState({
                    orgItemAdded: true,
                })
                return
            }

            let orgId = parseInt(org_item["id"])
            let orgItemInEdit = _.cloneDeep(org_item)

            this.setState({
                orgItemAdded: false,
                orgItemChanged: false,
                orgId,
                orgItemInEdit,
                prevOrgItem: _.cloneDeep(orgItemInEdit),
            })
        }


        }


    handleOrgItemChange = (e) => {

        const orgItemInEdit = this.state.orgItemInEdit;

        if (e.target.name === "finra_member") {
            orgItemInEdit['finra_member'] = !orgItemInEdit['finra_member']
        } else {
            orgItemInEdit[e.target.name] = e.target.value;
        }

        this.setState({
            orgItemInEdit: orgItemInEdit,
            orgItemChanged: true,
        })
    }

    handleAddOrg = () => {

        this.setState({
            orgItemAdded: true,

            orgItemChanged: false,
            orgItemInEdit: {},

            entItemChanged: false,
            entItems: {},
            entIdsInEdit: new Set(),
            newEntItems: [],
        })

    }



    /** Saving changes made to organizations or entities **/
    saveChanges = () => {

        let error = false;

        if(this.state.orgItemAdded){
            client.service('underlyings').create(this.state.orgItemInEdit).then(response=>{
                this.resetAfterSaveChanges()

                this.getOrgList();

                this.setState({orgItemAdded:false}, ()=>{
                    this.handleOrgSelected(response)
                })
            }).catch(e => {
                window.alert(e)
                // this.cancelChanges()
                error = true;
            })

        } else if(this.state.orgItemChanged){
            client.service('underlyings').update(this.state.orgItemInEdit.id, this.state.orgItemInEdit).then(response=>{
                this.resetAfterSaveChanges()
                this.getOrgList();
            }).catch(e => {
                window.alert(e)
                error = true;
            })
        }

    }

    resetAfterSaveChanges = () => {
        this.setState({
            newEntItems: [],
            entIdsInEdit: new Set (),
            orgItemAdded: false,
            entItemChanged: false,
            orgItemChanged: false,
        })

        // this.extractEntities(this.state.orgId);


        this.setState({
            prevOrgItem: _.cloneDeep(this.state.orgItemInEdit),
            prevEntItems: _.cloneDeep(this.state.entItems)
        })

    }

    cancelChanges = () => {
        this.setState({
            orgItemChanged: false,
            orgItemInEdit: _.cloneDeep(this.state.prevOrgItem),

            entItemChanged: false,
            entItems: _.cloneDeep(this.state.prevEntItems),
            entIdsInEdit: new Set(),

            newEntItems: [],
        })
    }

    onKeyDown(keyName, e, handle) {
        console.log("test:onKeyDown", keyName, e, handle)
        this.setState({
            output: `onKeyDown ${keyName}`,
        });
    }
    onKeyUp(keyName, e, handle) {
        console.log("test:onKeyUp", e, handle)
        this.setState({
            output: `onKeyUp ${keyName}`,
        });
    }

    orgItemCreated = (op) => {
        client.service("underlyings").find().then((reqPage) => {
            const reqs = reqPage;
            console.log(reqs)

            this.setState({
                orgData: reqs,
            })
        });
    }

    render() {
        return (
            <Hotkeys
                keyName="shift+a,alt+s"
                onKeyDown={this.onKeyDown.bind(this)}
                onKeyUp={this.onKeyUp.bind(this)}
            >
                <div className={"admin-wrapper"}>
                    <div className={"admin-btn-panel sticky"}>
                        <ComboBox data={this.state.underData}
                                  placeholder={"Underlying"}
                                  allowCustom={false}
                                  suggest={true}
                                  textField={"name"}
                                  dataItemKey={"id"}
                                  onChange={e => {this.handleOrgSelected(e.target.value)}}
                                  popupSettings={{animate:false}}

                            // onOpen = {this.getOrgList}
                            // filter = {this.state.orgItemAdded? "New Organization" : this.state.orgCode}
                        />

                        {(this.state.orgItemChanged || this.state.entItemChanged) ?
                            [
                                <Button icon="cancel" title="Cancel" onClick = {this.cancelChanges}> Cancel </Button>,
                                <Button icon="save" title="Save" onClick = {this.saveChanges}> Save Changes </Button>
                            ]
                            :
                            <Button icon="add" title="Add" onClick = {this.handleAddOrg}> Add Underlying</Button>
                        }
                    </div>
                    {/*<form onSubmit={this.handleSubmit}>*/}

                    {/*<div style={{ marginBottom: '10px' }}*/}
                    {/*     ref={*/}
                    {/*         (x) => {*/}
                    {/*             if(!this.state.firstInputSelected && x) {*/}
                    {/*                 x.querySelector(`[name=${'nlp'}]`).select()*/}
                    {/*                 this.setState({firstInputSelected: true})*/}
                    {/*             }*/}
                    {/*         }*/}
                    {/*     }*/}
                    {/*>*/}
                    <div className={"admin-content"}>
                        <form autoComplete="off">

                            <table className = "admin-table">
                                <tbody className = "dialog-table">
                                {Object.keys(inputMap).map((key, i) => {
                                    let inputComponent;
                                    if (dropDownFieldsMap.hasOwnProperty(key)){
                                        inputComponent = (
                                            <ComboBox
                                                data = {dropDownFieldsMap[key]}
                                                allowCustom={false}
                                                suggest={true}

                                                name = {key}
                                                value={this.state.orgItemInEdit[key] || ''}
                                                onChange={ this.handleOrgItemChange }
                                                popupSettings={{animate:false}}

                                            />
                                        );
                                    } else
                                    {
                                        inputComponent = (
                                            <Input
                                                type="text"
                                                name={key}

                                                value={this.state.orgItemInEdit[key] || ''}
                                                onChange={ this.handleOrgItemChange }
                                                // onKeyPress={this.handleKeyPress}
                                            />
                                        );
                                    }
                                    return(

                                        <tr key={i}>
                                            <td>
                                                {inputMap[key]}
                                            </td>
                                            <td>
                                                {inputComponent}
                                            </td>
                                        </tr>
                                    );
                                })}

                                </tbody>
                            </table>
                        </form>
                    </div>

                </div>
            </Hotkeys>
        );
    }
}
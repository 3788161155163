import React from 'react';

/** Redux **/
import {connect} from 'react-redux';
import {selectBidById} from '../../../../redux/selectors';

/** Style **/
import BEMHelper from 'react-bem-helper';

/** Reuse Order Detail Styling **/
const classes = new BEMHelper('OrderDetail');

const BidDetail = (props) => {
    const bid = props.bid ? props.bid : {};
    const isBid = bid.bid != null;
    const direction = isBid ? 'BID' : 'ASK';
    const price =  isBid ? bid.bid : bid.ask;

    return <div {...classes()}>
        <span {...classes('orgCode')}> {bid.orgCode} </span>
        <span {...classes('price')}> {price} </span>
        <span {...classes('direction')}> {direction} </span>
        <span {...classes('size')}> {bid.size} </span>
    </div>
}

const mapStateToProps = (state, props) => {
    return {
        bid: selectBidById(state, props.bidId)
    }
}

export default connect(mapStateToProps, null) (BidDetail);
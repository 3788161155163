import {createCachedSelector} from 're-reselect';

const getUsersById = (state) => state.usersReducer.byId;
const getRoomsById = (state) => state.roomsReducer.byId;
const getModalRoomId = (state) => state.aivaReducer.modalRoomId;

const selectUsersNotInRoomByRoomId = createCachedSelector(
    getUsersById,
    getRoomsById,
    getModalRoomId,
    (usersById, roomsById, modalRoomId) => {
        const room_users = roomsById[modalRoomId] ? roomsById[modalRoomId].users : [];

        return Object.values(usersById).filter(user => !room_users.includes(user.id))
    }
)(
    (_state_) => 'same_key'
)

export default selectUsersNotInRoomByRoomId;
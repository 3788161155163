import React from 'react';
import {
    GridColumnMenuCheckboxFilter
} from '@progress/kendo-react-grid';

export class ColumnMenuCheckboxFilter extends React.Component {
    render() {
        return (
            <div>
                <GridColumnMenuCheckboxFilter {...this.props} expanded={true}/>
            </div>
        );
    }
}


import React, { Component } from 'react';

/** Components **/
import Login from './Login';

/** Style **/
import './Styles/LoginStyle.css'
import './Styles/App.scss';
// import './Styles/eric-dark-v1.css'
// import "bootstrap/scss/bootstrap.scss";

/** NPM **/
import { CookiesProvider } from 'react-cookie';
import {
    BrowserRouter,
    Route,
    Switch
} from 'react-router-dom'

/** Components **/
import Home from './Home';
import DashBoard from "../Dashboard/DashBoard"
import AdminApp from "../Admin/AdminApp"
import Blotter from "../TradeCapture/Blotter"

/** Feathers Event Handlers **/
import authListener from '../../feathers/authListener'

/** Redux **/
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
    setAuthState,
} from '../../redux/actions';
import SettingsModal from './SettingsModal';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPageLoading: true
        };
    }


    componentDidMount() {
        authListener();
    }

    render() {
        const {
            isLoggedIn,
            user,

            indicesByTicker
        } = this.props;

        // check if user has put computer to sleep
        // let lastTime = (new Date()).getTime();
        //
        // setInterval(function() {
        //     var currentTime = (new Date()).getTime();
        //     if (currentTime > (lastTime + 10000*2)) {  // ignore small delays
        //         // Probably just woke up!
        //         // alert("Temporarily removed refresh because not functioning")
        //         window.location.reload(false);
        //     }
        //     lastTime = currentTime;
        // }, 2000);

        if(isLoggedIn === undefined) {
            return <main className="container text-center">
                <h1>Loading...</h1>
            </main>;
        } else if (user && user.privilege === "DISABLED") {
            return <h1> Account disabled. Please contact your system administrator.</h1>
        } else if (isLoggedIn) {
            /** Underlyings are set in authListener when we are authenticated
             *  We must wait until underlyings are loaded before we can subscribe to symbols
             * **/
            if (user && !user.password_changed) {
                return <div><SettingsModal /></div>
            }

            return <CookiesProvider>
                {Object.keys(indicesByTicker).length === 0 ?
                    <div> Loading... </div>
                    : <BrowserRouter>
                        <Switch>
                            {/*<Route path = '/aiva' component={Aiva} />*/}
                            <Route path = '/dashboard' component={DashBoard} />
                            <Route path = '/admin' component={AdminApp} />
                            <Route path = '/blotter' component={Blotter} />
                            {/*<Route path = '/tickets/:tradeId' children={this.Ticket} />*/}
                            <Route exact path = '/' component={Home} />
                        </Switch>
                    </BrowserRouter>
                }
            </CookiesProvider>;
        }

        return <Login />;
    }
}

function mapStateToProps (state) {
    return {
        isLoggedIn: state.authReducer.isLoggedIn,
        user: state.authReducer.user,
        indicesByTicker: state.underlyingsReducer.indicesByTicker,
    }
}

function mapDispatchToProps (dispatch) {
    return bindActionCreators({
        setAuthState,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps) (App);
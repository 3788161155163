import React from "react";
import ReactDOM from "react-dom";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Ticket from './Ticket';

import {createWindow} from '../../redux/actions';

function copyStyles(source, target) {
    Array.from(source.styleSheets).forEach(styleSheet => {
        // For <style> elements
        let rules;
        try {
            rules = styleSheet.cssRules;
        } catch (err) {
            console.error(err);
        }
        if (rules) {
            const newStyleEl = source.createElement("style");

            // Write the text of each rule into the body of the style element
            Array.from(styleSheet.cssRules).forEach(cssRule => {
                try {
                    const { cssText, type } = cssRule;
                    let returnText = cssText;
                    // Check if the cssRule type is CSSImportRule (3) or CSSFontFaceRule (5) to handle local imports on a about:blank page
                    // '/custom.css' turns to 'http://my-site.com/custom.css'
                    if ([3, 5].includes(type)) {
                        returnText = cssText
                            .split("url(")
                            .map(line => {
                                if (line[1] === "/") {
                                    return `${line.slice(0, 1)}${
                                        window.location.origin
                                    }${line.slice(1)}`;
                                }
                                return line;
                            })
                            .join("url(");
                    }
                    newStyleEl.appendChild(source.createTextNode(returnText));
                } catch (e) {
                    console.log(e)
                }
            });

            target.head.appendChild(newStyleEl);
        } else if (styleSheet.href) {
            // for <link> elements loading CSS from a URL
            const newLinkEl = source.createElement("link");

            newLinkEl.rel = "stylesheet";
            newLinkEl.href = styleSheet.href;
            target.head.appendChild(newLinkEl);
        }
    });
}

class WindowPortal extends React.Component {
    constructor(props) {
        super(props);
        this.containerEl = document.createElement("div"); // STEP 1: create an empty div

        this.state = {
            externalWindow: null,
        }
    }

    componentDidMount() {
        // STEP 3: open a new browser window and store a reference to it
        const width = 500;
        const left = window.screen.width/2 - width/2

        const externalWindow = window.open(
            "",
            "_blank",
            `height=716, width=${width},left=${left}`
        );

        // STEP 4: append the container <div> (that has props.children appended to it) to the body of the new window
        externalWindow.document.body.appendChild(this.containerEl);

        // call the redux function to append the new window object to the store
        this.props.createWindow(externalWindow);

        copyStyles(document, externalWindow.document);


        this.setState({
            externalWindow: externalWindow
        })
    }

    componentWillUnmount() {
    }

    render() {
        // STEP 2: append props.children to the container <div> that isn't mounted anywhere yet
        return ReactDOM.createPortal(<Ticket tradeId = {this.props.id} window = {this.state.externalWindow} />, this.containerEl);
    }
}

function mapDispatchToProps (dispatch) {
    return bindActionCreators({createWindow}, dispatch);
}


export default connect (null, mapDispatchToProps) (WindowPortal);
import produce from 'immer';
import {combineReducers} from 'redux';

import {
    SET_INDICES,
    SET_TICKERS_TO_IDS,
    SET_UNDERLYINGS,
} from '../actions/types';


const underlyingsById = produce((draft, action) => {
    switch (action.type) {
        case SET_UNDERLYINGS:
            return action.underlyings;
        // case CREATE_ORDER:
        //     draft[action.order.id] = action.order;
        //     return;
        // case UPDATE_ORDER:
        //     draft[action.order.id] = action.order;
        //     return;
        // case REMOVE_ORDER:
        //     delete draft[action.order.id];
        //     return;
    }
}, {});

const tickerToId = produce((draft, action) => {
    switch (action.type) {
        case SET_TICKERS_TO_IDS:
            return action.tickersToIds;
    }
}, {})

// const allIds = produce((draft, action) => {
//     switch(action.type) {
//         case SET_UNDERLYINGS:
//             return action.underlyings;
//         // case CREATE_ORDER: {
//         //     draft.splice(InsertId(draft, action.order.id), 0, action.order.id);
//         //     return;
//         // }
//         // case REMOVE_ORDER: {
//         //     let index = draft.findIndex(orderId => orderId === action.order.id);
//         //     if (index !== -1) draft.splice(index, 1);
//         //     return;
//         // }
//     }
// }, []);

const indicesByTicker = produce((draft, action) => {
   switch (action.type) {
       case SET_INDICES: {
           return action.indices;
       }
   }
}, {});

const UnderlyingsReducer = combineReducers({
    underlyingsById,
    tickerToId,
    indicesByTicker
    // byId: byId,
    // allIds: allIds
});

export default UnderlyingsReducer;
import {
    MAP_ORDER_ID_TO_SYMBOLS
} from '../types';

export default function (orderId, symbols) {
    return {
        type: MAP_ORDER_ID_TO_SYMBOLS,
        orderId,
        symbols
    }
}
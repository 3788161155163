import React, {useEffect} from 'react';
import {connect} from 'react-redux';

import {
    setOrderMenuProps,
    setSpreadFormProps,
    setTradeFormProps,
} from '../../redux/actions/wb';

/** Components **/
import OrderRow from './OrderRow/OrderRow';
import OrderForm from './OrderForm/OrderForm';
import Header from './Header/Header';
import SpreadForm from './SpreadForm/SpreadForm';
import LoadingOverlay from 'react-loading-overlay';
import GridLoader from 'react-spinners/GridLoader';
// import PropagateLoader from 'react-spinners/PropagateLoader';
// import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';

import styled from 'styled-components';
import './BlackBoard.scss';
import BEMHelper from 'react-bem-helper';

import OrderMenu from './OrderMenu/OrderMenu';
import TabForm from './TabForm/TabForm';
import TradeForm from './TradeForm/TradeForm';
import SettingsModal from '../Main/SettingsModal';

const classes = new BEMHelper('bb');

const StyledLoader = styled(LoadingOverlay)`
    .MyLoader_overlay {
        height: 100vh;
        position: fixed;
    }
`

const BlackBoard = (props) => {
    const {
        orderIds,
        wbReducer = {},

        closeSpreadForm,
        closeOrderMenu,
        closeTradeForm,
    } = props;

    const {
        openDepths,
        openDetails,
        wbState: {
            wbOrdersLoading,
            settingsModalOpen
        },
        orderMenuProps,
        tradeFormProps,
    } = wbReducer;

    // useEffect(()=>{
    //     structureList.forEach(name => {
    //         structuresService.create({name});
    //     })
    // },[])

    const hideMenus = () => {
        closeSpreadForm();
        closeOrderMenu();
        closeTradeForm();
    }

    return <div
        {...classes()}
        onClick = {hideMenus}
    >
        <Header
            orderCount = {orderIds.length}
        />

        <StyledLoader
            active = {wbOrdersLoading}
            spinner = {<GridLoader color={'#ffffff'}/>}
            fadeSpeed={300}
            classNamePrefix='MyLoader_'
        >
            {orderIds.map(orderId => {
                const isDepthExpanded = orderId in openDepths;
                const isDetailExpanded = orderId in openDetails;
                return <OrderRow
                    key = {orderId}
                    orderId={orderId}
                    isDepthExpanded = {isDepthExpanded}
                    isDetailExpanded = {isDetailExpanded}
                />
            })}
        </StyledLoader>

        <SpreadForm/>

        <OrderForm/>

        <OrderMenu
            orderMenuProps = {orderMenuProps}
        />

        <TabForm />

        <TradeForm
            {...tradeFormProps}
        />

        {
            settingsModalOpen &&
                <SettingsModal/>
        }
    </div>
}


const mapStateToProps = (state) => {
    return {
        orderIds: state.wbOrdersReducer.allIds,
        wbReducer: state.wbReducer,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeSpreadForm: () => dispatch(setSpreadFormProps()),
        closeOrderMenu: () =>  dispatch(setOrderMenuProps()),
        closeTradeForm: () => dispatch(setTradeFormProps()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlackBoard);
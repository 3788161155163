import produce from 'immer';
import {combineReducers} from 'redux';

import {
    SET_WB_BIDS,
    CREATE_WB_BID,
    UPDATE_WB_BID,
    REMOVE_WB_BID,
} from "../actions/wbBids";

import GetByIdWithRelated from '../../helpers/GetByIdWithRelated';

const byId = produce((draft, action) => {
    switch (action.type) {
        case SET_WB_BIDS:
            return GetByIdWithRelated(action.bids);
        case CREATE_WB_BID:
        case UPDATE_WB_BID:
            draft[action.bid.id] = action.bid;
            return;
        case REMOVE_WB_BID:
            delete draft[action.bid.id];
            return;
    }
}, {});

const WbBidsReducer = combineReducers({
    byId: byId,
});

export default WbBidsReducer;
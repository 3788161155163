import { usersService } from './index';
import store from '../redux/store';

import {
    CREATE_USER,
    UPDATE_USER,
    PATCH_AUTH
} from '../redux/actions/types';

export default function () {
    usersService.on('created', obj => {
        store.dispatch({type: CREATE_USER, user: obj});
    })

    usersService.on('updated', obj => {
        store.dispatch({type: UPDATE_USER, user: obj});
    })

    usersService.on('patched', obj => {
        store.dispatch({type: UPDATE_USER, user: obj});

        if(store.getState().authReducer.user.id === obj.id) {
            store.dispatch({type: PATCH_AUTH, user: obj});
        }
    })

    usersService.on('removed', obj => {

    })
}
// returns date that is business days from today

const _isWeekend = (day) => {
    return day === 0 || day === 6;
}

export default function GetDateFromBDays (days) {
    let startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    while(_isWeekend(startDate.getDay())) {
        startDate = new Date(startDate.setDate(startDate.getDate() - 1));
    }

    let noOfDaysToAdd = days - 1, count = 0;
    while (count < noOfDaysToAdd) {
        startDate = new Date(startDate.setDate(startDate.getDate() - 1));
        if (!_isWeekend(startDate.getDay())) {
            count++;
        }
    }

    return startDate;
}
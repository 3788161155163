import client from './index';
import store from '../redux/store';
import {
    handleSetUnderlyings,
    setAuthState,
} from '../redux/actions';
import {
    HANDLE_LOGOUT
} from '../redux/actions/types';

export default function authListener () {
    // Try to authenticate with the JWT stored in localStorage
    client.authenticate()
        .then(({ token }) => {
            console.log('Logged in with JWT')
        })
        .catch(() =>
            console.log('JWT expired')
        );

    // On successful login
    client.on('authenticated', login => {
        store.dispatch(setAuthState(login.user));
        /** Set list of tickers **/
        store.dispatch(handleSetUnderlyings());
    });

    // On logout reset all all local state (which will then show the login screen)
    client.on('logout', () => {
        store.dispatch({type: HANDLE_LOGOUT})
    });
}
import { underlyingsService } from '../../../feathers';
import {setIndices, setUnderlyings} from '../index';
import setTickersToIds from './setTickersToIds';

/**
 * Calls find on all underlyings
 */
const handleSetUnderlyings = () => {
    return async (dispatch) => {
        try {
            const underlyingsRaw = await underlyingsService.find({
                query: {
                    $select: ['id', 'ticker', 'product']
                }
            });

            let underlyingsById = {};
            let tickersToIds = {};
            let indicesByTicker = {};

            underlyingsRaw.forEach(obj => {
                underlyingsById[obj.id] = obj;
                tickersToIds[obj.ticker] = obj.id;
                if(obj.product === 'Index') {
                    indicesByTicker[obj.ticker] = obj;
                }
            });

            dispatch(setUnderlyings(underlyingsById));
            dispatch(setIndices(indicesByTicker));
            dispatch(setTickersToIds(tickersToIds))
        } catch (error) {
            console.error('Error setting underlyings');
            console.error(error)
        }
    }
}

export default handleSetUnderlyings;
import {
    UPDATE_BID,
} from '../types';

export default function handleBidUpdated (obj) {
    return function (dispatch) {
        dispatch({
            type: UPDATE_BID,
            bid: obj
        });
    }
}
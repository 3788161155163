import React from 'react';

/** Redux **/
import {connect} from 'react-redux';
import {
    selectMetadataByTicker,
    selectSummaryByTicker
} from '../../../redux/selectors';

/** Style **/
import './MetadataCell.scss';
import BEMHelper from 'react-bem-helper';
import TickerQuote from '../TickerQuote/TickerQuote';
let classes = new BEMHelper('MetadataCell');

const MetadataCell = (props) => {
    const {
        metadata = {},
        summary = {}
    } = props;

    const {
        longname,
        sector,
    } = metadata;

    return <div {...classes()}>
        {summary.last!=null && <TickerQuote summary = {summary}/>}

        <div>
            <div {...classes('longname')}> {longname} </div>
            <div {...classes('sector')}> {sector} </div>
        </div>
    </div>
}

function mapStateToProps (state, {ticker}) {
    return {
        metadata: selectMetadataByTicker(state, ticker),
        summary: selectSummaryByTicker(state, ticker)
    }
}

export default connect(mapStateToProps, null) (MetadataCell);
import {createCachedSelector} from 're-reselect';

const getInfoByTicker = (state, ticker) => state.symbolsReducer.symbolToInfo[ticker];

/** Get industry, long name and sector metadata for a ticker **/
const selectMetadataByTicker = createCachedSelector(
    getInfoByTicker,
    (info) => {
        let metadata = {
            sector: null,
            industry: null,
            longname: null
        };

        if(info) {
            if (info.classification) {
                metadata.sector = info.classification.sector;
                metadata.industry = info.classification.industry;
            }
            if (info.equityinfo) {
                metadata.longname = info.equityinfo.longname;
            }
        }

        return metadata;
    }
)(
    (_state_, ticker) => ticker ?? '-1'
)

export default selectMetadataByTicker;
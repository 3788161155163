import moment from 'moment';
import store from '../store';
import {ORDERS_QUERY_LIMIT, TAB_ID_FIELDS, TAB_STATUS, TAB_TEXT_FIELDS} from '../../helpers/constants';
import {ordersService} from '../../feathers';
import {setWbBids} from './wbBids';
import {setWbOrdersLoading} from './wb';
import {subscribeToSymbols} from './index';
import {getEnhancedQuotes} from '../../api';
import {setSymbolInfo} from './index';
import {GetWbOrdersQuery} from '../../helpers';
import GetDateFromBDays from '../../helpers/GetDateFromBDays';
import {setWbTrades} from './wbTrades';

/** Types **/
export const SET_WB_ORDERS = 'SET_WB_ORDERS';
export const CREATE_WB_ORDER = 'ADD_WB_ORDER';
export const UPDATE_WB_ORDER = 'UPDATE_WB_ORDER';
export const REMOVE_WB_ORDER = 'REMOVE_WB_ORDER';

/** Actions **/
const defaultQuery = {
    createdAt: {
        $gt: moment().startOf('day') - 24 * 15 * 60 * 60 * 1000,
    },
    $limit: 50
}

/** Gets the active tab from redux store and calls helper to generate query **/
export const _getTabsQuery = (getState) => {
    const {
        tabKey = 0,
        tabs = []
    } = getState().authReducer.user;

    const tab = tabs.find(obj => obj.key === tabKey);

    /** Formulate the query **/
    return GetWbOrdersQuery(tab);
}

/**
 * Critical function which first finds all orders to be displayed in whiteboard
 * Then sets all bids to be displayed in whiteboard
 * Also sets all trades in association
 * Takes in a query object for the orders.find(). Defaults to the current selected tab
 */
export const setWbOrdersAndBids = (query) => {
    return async (dispatch, getState) => {
        try {
            dispatch(setWbOrdersLoading(true));

            /** Get the orders which match the filters
             *  By default we use the user's current tab based on the redux store
             * **/


            const orders = await ordersService.find({
                query: (query ?? _getTabsQuery(getState))
            });

            if (orders.length === ORDERS_QUERY_LIMIT) {
                window.alert(`Maximum query limit of ${ORDERS_QUERY_LIMIT} interests exceeded. Please use a smaller date range or more specific filters.`)
            }

            /** Generate a list of bid objects (pulled through the sequelize association)
             * Update the bids reducer
             * Ditto for related trades
             * **/
            let bids = [];
            let trades = [];
            orders.forEach(order => {
                const bidsList = order.bids ?? [];
                const tradesList = order.trades ?? [];
                bidsList.forEach(bid => {
                    bids.push(bid);
                });

                 tradesList.forEach(trade => {
                    trades.push(trade);
                });
            });
            dispatch(setWbBids(bids));
            dispatch(setWbTrades(trades));

            /** Set the orders after setting the bids **/
            dispatch({
                type: SET_WB_ORDERS,
                orders
            });

            dispatch(setWbOrdersLoading(false));

            let tickers = [];

            /** Subscribe to quotestream symbols **/
            let today = new Date()
            today.setHours(0,0,0,0);
            orders.forEach(order => {
                if (new Date(order.updatedAt) > today) {
                    dispatch(subscribeToSymbols(order));
                    tickers.push(order.ticker);
                }
            });

            /** Batch subscribe to tickers **/
            const data = await getEnhancedQuotes([...new Set(tickers)].join(","));
            dispatch(setSymbolInfo(data));

        } catch (e) {
            console.log("Error setting orders");
            console.error(e);
        }
    }
}


/**
 * Helper method to check if order matches current filters
 * Checks if the order matches the filters for the selected tab in the redux store
 *
 * Probably needs to be refactored, as this is not very efficient
 * @param order a new order that was updated/created
 * @returns true/false
 */

const _checkOrderMatchesFilters = (order, getState) => {
    const {tabKey, tabs} = getState().authReducer.user;
    const tab = tabs.find(tab => tab.key == tabKey) ?? {};

    const {
        status,
        filterByDays,
        days,
        filterByRange,
        range,
    } = tab;

    const updatedAt = new Date(order.updatedAt);
    // Voided
    if (order.isVoided == true) return false;

    // Working status
    if (status === TAB_STATUS.WORKING && order.complete == true) return false;
    if (status === TAB_STATUS.NOT_WORKING && order.complete == false) return false;

    // Date range
    if (filterByRange) {
        const {
            start,
            end
        } = range;

        let endDate = new Date(end);
        endDate.setDate(endDate.getDate() + 1);

        if (!(updatedAt > new Date(start) && updatedAt < endDate)) {return false;}
    } else {
        if (updatedAt < GetDateFromBDays(days)) return false;
    }

    // $or arrays
    let orFieldExists = false, valid = false;
    [...Object.values(TAB_TEXT_FIELDS)].forEach(field => {
        const itemList = tab[field];
        if (itemList != null && Array.isArray(itemList) && itemList.length) {
            orFieldExists = true;
            if(itemList.includes(order[field])) valid = true;
        }
    });

    // for some reason structureId is passed as a string rather than int
    [...Object.values(TAB_ID_FIELDS)].forEach(field => {
        const itemList = tab[field];
        if (itemList != null && Array.isArray(itemList) && itemList.length) {
            orFieldExists = true;
            if(itemList.includes(parseInt(order[field]))) valid = true;
        }
    });

    if (orFieldExists && !valid) return false;
    return true;
}

export const handleWbOrderCreated = order => {
    return async (dispatch, getState) => {
        /** Check if matches filters **/

        if (_checkOrderMatchesFilters(order, getState)) {
            dispatch({
                type: CREATE_WB_ORDER,
                order
            });
        }
    }
}

export const handleWbOrderUpdated = order => {
    return async (dispatch, getState) => {
        // Check if the order is currently contained in the whiteboard.
        const containsOrder = order.id in getState().wbOrdersReducer.byId;
        if (_checkOrderMatchesFilters(order, getState)) {
            if (containsOrder) {
                dispatch({
                    type: UPDATE_WB_ORDER,
                    order
                });
            } else {
                dispatch({
                    type: CREATE_WB_ORDER,
                    order
                });
            }
        } else if (containsOrder) {
            dispatch({
                type: REMOVE_WB_ORDER,
                order
            });
        }
    }
}
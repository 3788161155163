import {
    SET_TRADES,
    SET_TRADES_LOADING
} from "../types";

import { tradesService } from '../../../feathers';
import store from '../../store';

export default function setTradesState () {
    return async (dispatch) => {
        dispatch({
            type: SET_TRADES_LOADING,
            tradesLoading: true,
        })
        const {
            startDate,
            endDate,
        } = store.getState().tcFilterReducer;

        const createdAtFilter = {
            $gt: startDate,
        };
        if (endDate!=null) {createdAtFilter.$lt = endDate}

        tradesService.find({
            query: {
                createdAt: {
                    ...createdAtFilter
                },
                $sort: {
                    createdAt: -1
                }
            }
        }).then(trades => {
            dispatch({
             type: SET_TRADES,
             trades,
            })

            dispatch({
                type: SET_TRADES_LOADING,
                tradesLoading: false,
            });
        }).catch(error => {
           dispatch({
               type: SET_TRADES_LOADING,
               tradesLoading: false,
           });
        });
    }
}
import React from 'react';

/** Redux **/
import {connect} from 'react-redux';
import {
    createMessage,
    handleAivaClick
} from '../../../../redux/actions';
import {bindActionCreators} from 'redux';

/** Components **/
import ChatHeader from '../ChatHeader'
import ChatBoard from '../ChatBoard';
import ChatBox from '../ChatBox';

/** Style **/
import './ChatApp.scss';
import BEMHelper from 'react-bem-helper';
const classes = BEMHelper('ChatApp');

const ChatApp = ({roomId, createMessage, handleAivaClick}) => {
    return <div {...classes()}>
        <ChatHeader
            roomId = {roomId}
        />
        <ChatBoard
            roomId = {roomId}
            onClick = {handleAivaClick}
        />
        <ChatBox
            chatBoxType = {'message'}
            roomId = {roomId}
            createFeathersChat={createMessage}
            onClick = {handleAivaClick}
        />
    </div>
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            createMessage,
            handleAivaClick
        }, dispatch
    )
}

export default connect(null, mapDispatchToProps) (ChatApp);
import store from '../redux/store';

import {ConvertExpirationToCamelCase} from './index';

export default function ExtractNLP (input_str) {
    let str = " " + input_str.toLowerCase();
    let map = {}

    let ticker_r = /[A-Z]{1,5}\s/;
    let ticker = ticker_r.exec(input_str);
    if(!ticker){
        ticker_r = /\s[a-z]{1,5}\s/
        ticker = ticker_r.exec(str)
    }
    // map["ticker"] = ticker;
    let myTicker = ticker
        ? ticker[0].trim().toUpperCase().replace(/\.+$/, "").replace(",", "")
        : undefined;

    let adminUnderlyingObject = null;
    if (myTicker) {
        const underlyingId = store.getState().underlyingsReducer.tickerToId[myTicker];
        if (underlyingId != null) {
            adminUnderlyingObject = {
                ticker: myTicker,
                underlyingId
            }
        } else {
            map['ticker'] = ticker;
        }
    }

    let month_r = /((?:[\d]*(?:jan|feb|mar|apr|may|jun|jul|aug|sep|sept|oct|nov|dec)[\d]*[\/]?)+)/g;
    let month = month_r.exec(str)
    map["expiration"] = month;

    let structure = null;
    const structuresById = store.getState().staticReducer.structuresById;
    for (const [id, {regex, name}] of Object.entries(structuresById)) {
        if (regex!=null && regex!=='' && new RegExp(regex).test(str)) {
            map.structName = name;
            structure = name;
            map.structureId = id;
        }
    }

    let ratio_r = /[\d\.]+x[\d\.]+/
    let ratio = ratio_r.exec(str)
    map["risk_rwd_ratio"] = ratio

    if (month) {
        let month_c = str.slice(month_r.lastIndex)


        let strike_r = /[ /\d\.\-]+\s/g
        let strike = strike_r.exec(month_c)

        map["strike"] = strike

        // let structure_r = /[p|c][a-z]*/
        // let structure = structure_r.exec(month_c)
        // map["structure"] = structure;
        let tied_r = /(?:v|t)[ a-z\.]*([ \/\d\.\-]+)\s/g
        if (structure = "Call" || structure == "Put") {
            tied_r = /(?:v|t)[ a-z\.]*([\d]+\.[\d]*)/g
        }

        let tied = tied_r.exec(month_c)
        if (tied) {
            // console.log('tied')
            // console.log(tied)
            // console.log(tied[tied.length - 1])
            map["tied"] = tied[tied.length - 1]
        } else {
            // console.log('no tied')
            // console.log(tied)
            tied_r = /tie[ a-z]*(\b[\/\d\.\-]+\b)/g
            tied = tied_r.exec(month_c)
            if(tied) map["tied"] = tied[tied.length - 1]
        }

        let delta_r = /[\d\.]+d/
        let delta = delta_r.exec(str)
        if (!delta) {
            delta_r = /\x28[\w ]*\x29/
            delta = delta_r.exec(str)
        }
        if(!delta) {
            delta_r = /([\d\.]+)[ ]*(delta|\^)/gi
            delta = delta_r.exec(str)
            if(delta) delta = delta[1]
        }
        map["delta"] = delta


        let bid;
        let ask;
        let direction;
        let size = "";

        if (new RegExp(/seller|buyer/).test(str) || !new RegExp(/@|bght|bought|buy|sld|sold|sell|bid|ask|trade/).test(str)) {

            let bid_ask_r = /[\.\d]+[ ]*[/][ ]*[\.\d]+/g

            let bid_ask
            if (tied_r.lastIndex) {
                // console.log(month_c.slice(tied_r.lastIndex))
                bid_ask = bid_ask_r.exec(month_c.slice(tied_r.lastIndex))
            } else if (strike_r.lastIndex) {
                // console.log(month_c.slice(strike_r.lastIndex))
                bid_ask = bid_ask_r.exec(month_c.slice(strike_r.lastIndex))
            }
            if (bid_ask) {
                map["bid_ask"] = bid_ask

                let direction_r = /(buy|sell)[a-z]*/g
                let direction = direction_r.exec(str)
                map["direction"] = direction

                let size_r = /[\d\.,]+k/;
                let size;
                if (direction) {
                    size = size_r.exec(str.slice(direction_r.lastIndex, ))
                }
                // else {
                //     size = size_r.exec(bid_ask_c)
                // }
                map["size"] = size
            }
        } else {
            if (new RegExp(/bght|bought|buy|bid/).test(str)) {
                direction = "buyer"
            } else if (new RegExp(/sld|sold|sell|ask/).test(str)) {
                direction = "seller"
            }
            map["direction"] = [direction]

            let size_r = /(sld|sold|sell|bght|bought|buy|trade|bid|off|rev)[a-z\s]*([\d\.xk,]+)\b/ //added |bid|off|rev and \b for revcon bid/off to rev
            size = size_r.exec(str)

            if (!size){
                size_r = /([\dk]+)x\b/ //BIDU April 100 puts vs. 115.65 on an 18^, 2.37 bid 600x (when size ends in "x")
                size = size_r.exec(str)
            }

            let price;
            if (size) {
                map["size"] = [size[size.length - 1]]
            } else {
                //add stuff here to look for a size containing x or k
                // 12/22: if size is by itself (eg 500 @ 11.20 and bid to reverse 3k)
                size_r = /\b(?!\.)([\d\.xk,]+)[\s]+@/  // 05/30/2020 removed negative lookahead (?!) to ensure that the size is not a decimal eg 3.2 @ 1.5
                size = size_r.exec(str)
                if(size) map["size"] = size[size.length-1]

                // Cleanup 12/22: addressing no direction specified but size or price specified
                // eg NVDA Feb  210 Put vs. 215.5 (40d) 500 @ 11.20
                if(size && !direction){
                    direction = "seller"
                    map["direction"] = "seller"
                }
            }

            if (new RegExp(/bid|ask/).test(str)) {
                let price_r = /([\d\.]+)[\s]+(bid|ask)/
                price = price_r.exec(str)

                if(price) {
                    if (direction == "buyer")
                        map["bid"] = [price[1]]
                    else
                        map["ask"] = [price[1]]
                }

            } else {
                let price_r = /\b([\d]+\.[\d]+)[\s]*@[\s]*([\d]+\.[\d]+)\b/ // if there is bid/ask separated by @ eg MCK Mar 200 line 1.20 @ 1.30
                price = price_r.exec(str)
                if (price) {
                    map["bid"] = [price[1]]
                    map["ask"] = [price[2]]
                } else {
                    price_r = /(at|for|paid|@)([\s]*[\d\.]+)/
                    price = price_r.exec(str)
                    if (price) {
                        if (direction == "buyer")
                            map["bid"] = [price[price.length - 1]]
                        else
                            map["ask"] = [price[price.length - 1]]
                    }
                }
            }
        } //end of else

        if(map["structure"]=="Rev Con" && !delta){
            // set delta as BID/ASK TO REV
            if(str.includes("to rev")){
                let delta = "OFF TO REV"
                if(direction == "buyer"){
                    delta = "BID TO REV"
                }
                map["delta"] = delta
            }

            // if no size and size is at the end
            // eg 'XOP Nov 21 r/c .025 bid to reverse 3k'
            // if (!size){
            //     let size_r = /rev[\a-z]*\s([\d\.,]+k)/;
            //     let size = size_r.exec(str)
            //     if(size) map["size"] = size[size.length-1]
            // }
        }

    } //end of if(month)

    for (let key in map) {
        if (map[key] != null && map[key][0] != undefined && map[key][0] != null) {
            let value = map[key]
            if (Array.isArray(value))
                value = map[key][0]
            if (value) {
                value = value.trim().toUpperCase().replace(/\.+$/, "").replace(",", "");
                if (key === "strike" || key === "tied") {
                    value = value.replace(" ", "/")
                    value = value.replace("-", "/")
                } else if (key === 'expiration') {
                    value = ConvertExpirationToCamelCase(value);
                } else if (key === 'delta') {
                    // Replace all non float numbers
                    value = value.replace(/[^\d.-]/g, "")
                }else if (key === "bid_ask") {
                    const bid_ask = value.replace(" ", "").split("/")
                    map["bid"] = parseFloat(bid_ask[0])
                    map["ask"] = parseFloat(bid_ask[1])
                } else if (key === "size") {
                    let kExists = false
                    value = value.replace("X", "")
                    if (value.includes("K")) {
                        value = value.replace("K", "")
                        kExists = true
                    }

                    if (value.includes("X")) {
                        if (kExists) {
                            const ba_size = value.split("X")
                            let bSize = parseFloat(ba_size[0]) * 1000
                            let aSize = parseFloat(ba_size[1]) * 1000
                            map["bsize"] = bSize;
                            map["asize"] = aSize;
                            value = bSize + "x" + aSize
                        }
                    } else if (kExists) {
                        value = parseFloat(value) * 1000
                    }
                }
            }
            map[key] = value
        }
    }

    /** If structure is PUT, assign automatic - to delta if not already present **/
    if (map.structName && (/PUT/).test(map.structName) && map.delta && map.delta[0] !== '-') {
        map.delta = '-' + map.delta;
    }

    /** Finally set the ticker to the admin ticker if it was found **/
    if (adminUnderlyingObject) {
        map.ticker = adminUnderlyingObject.ticker;
        map.underlyingsReducer = adminUnderlyingObject.underlyingId;
    }

    return map;
}
import produce from 'immer';
import {combineReducers} from 'redux';

import {
    MAP_ORDER_ID_TO_SYMBOLS,
    SET_PRICE_DATA,
    BATCH_PRICE_DATA,
    SET_QUOTE,
    SET_IV_GREEKS,
    REMOVE_SYMBOL,
    REMOVE_ORDER_ID_TO_SYMBOLS,
    BATCH_QUOTE_DATA,
    BATCH_IV_GREEKS_DATA, SET_SYMBOL_INFO
} from '../actions/types';

const orderIdToSymbols = produce((draft, action) => {
    switch (action.type) {
        case MAP_ORDER_ID_TO_SYMBOLS:
            draft[action.orderId] = action.symbols
            return;
        case REMOVE_ORDER_ID_TO_SYMBOLS:
            delete draft[action.orderId];
            return;
    }
}, {});

const symbolToPriceData = produce((draft, action) => {
    switch(action.type) {
        case BATCH_PRICE_DATA:
            action.data.forEach(message=>{
                draft[message.symbol] = message;
            });
            return;
        case SET_PRICE_DATA:
            draft[action.symbol] = action.payload;
            return;
        case REMOVE_SYMBOL:
            delete draft[action.symbol];
            return;
    }
}, {});

const symbolToQuote = produce((draft, action) => {
    switch(action.type) {
        case BATCH_QUOTE_DATA:
            action.data.forEach(message=>{
                draft[message.symbol] = message;
            });
            return;
        case SET_QUOTE:
            draft[action.symbol] = action.payload;
            return;
        case REMOVE_SYMBOL:
            delete draft[action.symbol];
            return;
    }
}, {});

const symbolToIVGreeks = produce((draft, action) => {
    switch(action.type) {
        case BATCH_IV_GREEKS_DATA:
            action.data.forEach(message=>{
                draft[message.symbol] = message;
            });
            return;
        case SET_IV_GREEKS:
            draft[action.symbol] = action.payload;
            return;
        case REMOVE_SYMBOL:
            delete draft[action.symbol];
            return;
    }
}, {});

const symbolToInfo = produce((draft, action) => {
    switch (action.type) {
        case SET_SYMBOL_INFO:
            action.data.forEach(quote => {
               draft[quote.symbolstring] = quote;
            });
            return;
    }
}, {});

const SymbolsReducer = combineReducers({
    orderIdToSymbols,
    symbolToPriceData,
    symbolToQuote,
    symbolToIVGreeks,
    symbolToInfo
});

export default SymbolsReducer;
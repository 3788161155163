import {
    SET_REPLIES
} from "../types";

import { repliesService } from '../../../feathers';
import {GetQueryByTopDay} from '../../../helpers';

/**
 * Calls Find on Replies and sets Replies reducer state
 * @returns {Function}
 */
const setRooms = (queryParams = GetQueryByTopDay) => {
    return async (dispatch) => {
        repliesService.find({
            query: {
                ...queryParams
            }
        }).then(res => {
            dispatch({
                type: SET_REPLIES,
                replies: res
            });
        }).catch(error => {
            console.log('Error retrieving replies:');
            console.log(error);
        });
    }
}

export default setRooms;
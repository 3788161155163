import {createSelector} from 'reselect';

const getUsersById = (state) => state.usersReducer.byId;
const getRoomsById = (state) => state.roomsReducer.byId;
const getModalRoomId = (state) => state.aivaReducer.modalRoomId;

const selectUsersInModalRoom = createSelector(
    getUsersById,
    getRoomsById,
    getModalRoomId,
    (usersById, roomsById, modalRoomId) => {
        const room_users = roomsById[modalRoomId] ? roomsById[modalRoomId].users : [];

        return Object.values(usersById)
            .filter(user => room_users.includes(user.id))
            .sort(user => user.isOnline ? -1 : 1);
    }
)

export default selectUsersInModalRoom;
import React, {useCallback} from 'react';
import {connect} from 'react-redux';

import TabPanel from './TabPanel/TabPanel';
import {Button} from '@progress/kendo-react-buttons';

import {setOrderFormProps, toggleSettingsModal} from '../../../redux/actions/wb';
import {ACTION_TYPE} from '../../../helpers/constants';

import './Header.scss';
import BEMHelper from 'react-bem-helper';
const classes = new BEMHelper('Header');

const Header = (props) => {
    const {
        orderCount,
        createInterest,

        toggleSettingsModal
    } = props;

    return <div {...classes()}>
        <div {...classes('tabs')}>
            <TabPanel
                orderCount = {orderCount}
            />
        </div>

        <div {...classes('icons')}>
            <Button
                icon='plus'
                {...classes('button')}
                primary={true}
                onClick = {createInterest}
            >
                NEW INTEREST
            </Button>

            <Button
                icon='cog'
                {...classes('button')}
                look='flat'
                onClick = {() => toggleSettingsModal(true)}
            />
        </div>
    </div>
}

const mapDispatchToProps = (dispatch) => {
    return {
        createInterest: () => dispatch(setOrderFormProps({
            actionType: ACTION_TYPE.CREATE,
            prevInterest: {}
        })),
        toggleSettingsModal: (settingsModalOpen) => dispatch(toggleSettingsModal(settingsModalOpen))
    }
}

export default connect(null, mapDispatchToProps) (Header);
export default function (date) {
    if (date == null) return '';
    // console.log(date)
    // console.log(typeof date)

    // date is a string that comes in e.g. 2020-06-04
    const dt = new Date(date);
    dt.setDate(date.slice(8, 10))
    return dt.toLocaleString('default', { month: 'short' }).toUpperCase() +
        ' ' + dt.getDate();
}
import React from 'react';

/** Components **/
import RoomGroupHeader from '../RoomGroupHeader';
import RoomList from '../RoomList';

/** Style **/
import './RoomGroup.scss';
import {connect} from 'react-redux';

const RoomGroup = (props) => {
    const {
        roomType,
        rooms,
        selectedRoom
    } = props;


    return <div className = 'RoomGroup'>
        <RoomGroupHeader
            roomType = {roomType}
        />

        <RoomList
            rooms = {rooms}
            selectedRoom = {selectedRoom}
        />
    </div>
}

const mapStateToProps = (state) => {
    return {
        selectedRoom: state.aivaReducer.selectedRoom
    }
}

export default connect(mapStateToProps, null)(RoomGroup);
import {
    CREATE_MESSAGE
} from '../types';

const notification_sound = require('../../../assets/chin-up.mp3');
let audio = new Audio(notification_sound);

/**
 * Handler for when user sends a message
 * Plays a notification sound
 */
export default function handleMessageCreated (message) {
    return async (dispatch, getState) => {
        const userId = getState().authReducer.user.id;
        dispatch({
            type: CREATE_MESSAGE,
            message: {...message}
        });

        if (message.createdById !== userId) {
            audio.play();
        }
    }
}
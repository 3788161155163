import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';

import Rodal from 'rodal';
import 'rodal/lib/rodal.css';

// import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import {Input} from '@progress/kendo-react-inputs';
import UnderlyingComboBox from '../../../utilities/UnderlyingComboBox';
import OrgComboBox from '../../../utilities/OrgComboBox';
import StructureComboBox from '../../../utilities/StructureComboBox';
import {Button} from '@progress/kendo-react-buttons';
import UserComboBox from '../../../utilities/UserComboBox';

import {ordersService} from '../../../feathers';
import {createOrderAndBids} from '../../../redux/actions';
import {setOrderFormProps} from '../../../redux/actions/wb';
import {ConvertExpirationToCamelCase, ExtractNLP} from '../../../helpers';

import {
    RODAL_STYLES,
    ACTION_TYPE,
    ALL_CAP_INPUT_FIELDS,
    CREATE_INPUT_NAMES,
    OPTION_INPUT_NAMES, REVCON_ID, REVCON_INPUT_NAMES
} from '../../../helpers/constants';

import './OrderForm.scss'
import BEMHelper from 'react-bem-helper';
import {DropDownList} from '@progress/kendo-react-dropdowns';
const classes = new BEMHelper('OrderForm');
const bem = new BEMHelper('FormTable');

const OrderForm = (props) => {
    const {
        orderFormProps,

        closeOrderForm
    } = props;

    const {
        prevOrder,
        actionType
    } = orderFormProps;

    const [order, setOrder] = useState({});
    const [autoSelect, setAutoSelect] = useState(null);

    const isCreate = actionType === ACTION_TYPE.CREATE;

    useEffect(() => {
        if (actionType != null) {
            setOrder({...prevOrder});
            if (isCreate) setAutoSelect('nlp');
        }
    }, [orderFormProps]);

    const onInputChange = (event) => {
        let {
            name,
            value
        } = event.target;

        const edited = {...order};

        if (name === 'expiration') {
            edited.expiration = ConvertExpirationToCamelCase(value);
        } else if (name === 'delta') {
            // Replace all non numeric characters
            edited.delta = value ? value.replace(/[^\d.-]/g, "") : value;
        }
        else if(name === "ask" || name === "bid"){
            if (!/[a-zA-Z]+/.test(value) && (value.match(/\./g) || []).length<=1){
                if (String(value) === value && value.trim() === "") {
                    value = null;
                }
                edited[name] = value;
            }
        } else {
            if (ALL_CAP_INPUT_FIELDS.includes(name)) {
                if(value!==null) value = value.toUpperCase();
            }

            if (String(value) === value && value.trim() === "") {
                value = null;
                if (name === "size") {
                    edited["asize"] = null;
                    edited["bsize"] = null;
                }
            }
            edited[name] = value;
        }

        setOrder(edited);
    };

    const onOverDropDownChange = (event) => {
        setOrder({...order, over: event.target.value});
    }

    const onComboChange = (value) => {
        let noId = {...value};
        delete noId.id;
        setOrder({...order, ...noId});
    }

    const onNlpChange = ({value}) => {
        const map = ExtractNLP(value);

        const edited = {...order};
        for (let key in map) {
            edited[key] = map[key]
        }
        setOrder(edited);
    }

    const onSubmit = (event) => {
        event.preventDefault();

        if (isCreate) {
            createOrderAndBids(order);
        }
        else {
            let desiredFields = (({structName, structureId, ticker, underlyingId, expiration, strike, ratio, tied, delta, orgCode, organizationId, notes, over}) =>
                ({structName, structureId, ticker, underlyingId, expiration, strike, ratio, tied, delta, orgCode, organizationId, notes, over}))(order);
            ordersService.patch(order.id, desiredFields);
        }
        closeOrderForm();
    }

    const InputRows = (nameList) => {
        return nameList.map((name, index) => {
            return <tr key = {index}>
                <td>{name}</td>
                <td>
                    {
                        name === 'structure'
                        ? <StructureComboBox
                            value = {{name: order.structName, id: order.structureId}}
                            name = 'structure'
                            textField = 'name'
                            remapIdKey = 'structureId'
                            remapFieldKey = 'structName'
                            onChange = {onComboChange}
                        />
                        : name === 'ticker'
                        ? <UnderlyingComboBox
                            name = 'ticker'
                            onChange = {onComboChange}
                            ticker = {order.ticker}
                            underlyingId = {order.underlyingId}
                        />
                        : name === 'over'
                        ? <DropDownList
                            data = {['P', 'C']}
                            value = {order.over}
                            onChange = {onOverDropDownChange}
                        />
                        : <Input
                            type='text'
                            name = {name}
                            value={order[name] || ''}
                            onChange = {onInputChange}
                            autoComplete='off'
                        />
                    }
                </td>
            </tr>
        });
    }

    return <Rodal
            visible={actionType != null}
            onClose={closeOrderForm}
            closeOnEsc={true}
            customStyles={RODAL_STYLES}
        >
        <form
            onSubmit={onSubmit}
        >
            {!isCreate &&
                <div {...bem('title')}>
                    Update Order
                </div>
            }
            <div
                {...classes()}
                ref={(x) => {
                    if(autoSelect!=null && x) {
                        setTimeout(() => {
                            x.querySelector(`[id=${autoSelect}]`).select();
                            setAutoSelect(null);
                        }, 400)
                    }
                }}
            >
                {
                    isCreate
                    && <Input
                        id = 'nlp'
                        type="text"
                        name="nlp"
                        label="ORDER"
                        onChange={onNlpChange}
                        style={{width:"100%"}}
                        autoComplete="off"
                        autoFocus={true}
                    />
                }

                <table {...bem()}>
                    <tbody>
                    {
                        (order.structureId === REVCON_ID || parseInt(order.structureId) === REVCON_ID)
                        ? InputRows(REVCON_INPUT_NAMES)
                        : InputRows(OPTION_INPUT_NAMES)
                    }

                    {isCreate && InputRows(CREATE_INPUT_NAMES)}

                    <tr>
                        <td> Org {isCreate ? '(Bid)' : ''} </td>
                        <td>
                            <OrgComboBox
                                value={{id: order.organizationId, code: order.orgCode}}
                                onChange = {onComboChange}
                                textField={'code'}
                                remapIdKey={'organizationId'}
                                remapFieldKey={'orgCode'}
                            />
                        </td>
                    </tr>

                    {isCreate
                        && <>
                        <tr>
                            <td> Org (Ask) </td>
                            <td>
                                <OrgComboBox
                                    value={{id: order.aOrganizationId, code: order.aOrgCode}}
                                    onChange = {onComboChange}
                                    textField={'code'}
                                    remapIdKey={'aOrganizationId'}
                                    remapFieldKey={'aOrgCode'}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td> Broker </td>
                            <td>
                                <UserComboBox
                                    brokerId = {order.brokerId}
                                    onChange = {onComboChange}
                                />
                            </td>
                        </tr>
                        </>
                    }
                    </tbody>
                </table>

                <Button
                    primary={true}
                    type='submit'
                    {...bem('submit_button')}
                    icon={'check-circle'}
                >
                    {isCreate ? 'CREATE ORDER' : 'UPDATE ORDER'}
                </Button>
            </div>
        </form>
    </Rodal>
}

const mapStateToProps = (state) => {
    return {
        orderFormProps: state.wbReducer.orderFormProps,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeOrderForm: () => dispatch(setOrderFormProps())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderForm);
import React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import {Button} from "@progress/kendo-react-buttons";
import _ from "lodash";
import client from "../../feathers";

import { Calendar, CalendarCell, CalendarWeekCell, CalendarNavigationItem, CalendarHeaderTitle, DateInput, DatePicker, TimePicker, MultiViewCalendar, DateRangePicker, DateTimePicker } from '@progress/kendo-react-dateinputs'
import '@progress/kendo-react-intl'
import '@progress/kendo-react-tooltip'
import '@progress/kendo-react-common'
import '@progress/kendo-react-popup'
import '@progress/kendo-date-math'
import '@progress/kendo-react-dropdowns'

export default class MVPModal extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            userList: [],
            dt: new Date(),

            success: false,
            firstInputSelected: false
        };
    }

    componentDidMount () {
        const users = client.service('users');
        users.find().then((reqPage) => {
            console.log("users")
            console.log(reqPage)
            const usersRaw = reqPage;
            const userList = []

            console.log(usersRaw)
            usersRaw.forEach(obj=>userList.push(obj.email))

            this.setState({
                userList: userList,
            })
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps!=this.props){
            const goals = _.cloneDeep(this.props.goals)
            this.setState({
                dt: new Date(),

                success: false,
                firstInputSelected: false
            })
        }
    }

    handleAutoChange = (event) => {
        this.setState({
            'email': event.target.value,
        });
    }

    handleDateChange = (event) => {
        this.setState({ dt: event.target.value })
    }

    handleKeyPress = (target) => {
        if(target.charCode===13){
            this.updateGoals(target);
        }
    }

    updateMVP = (e) => {
        e.preventDefault()

        const item = {}

        item.email = this.state.email
        item.dt = this.state.dt

        console.log("update date" + item.dt)
        client.service('mvps').find({
            query: {
                dt: this.state.dt
            }
        }).then(res=>{
            if (res.length===0) { //if mvp not set for that date yet
                client.service('mvps').create(item)
            } else { //edit existing mvp
                const id = res[0].id
                client.service('mvps').update(id, item)
            }

        })

        this.props.toggleMVPModal()
    }

    render() {
        return (
            <div className= "mm">
                <Dialog
                    // onClose={this.props.cancel}
                >

                    <div>

                        <fieldset id = "mfs" className = "fullWidth">
                            <div id = "mm-header">
                                {/*{gridDataItem.ticker} {gridDataItem.expiration} {gridDataItem.strike} {gridDataItem.structure}*/}
                                {/*<br/>*/}
                                <span style={{color:"#3f51b5"}}> MVP </span>
                            </div>
                            {/*<div style={{"textAlign":"center","paddingBottom":"5px"}}> Please enter matching counterparty. </div>*/}
                            {/*<span className = "mm-label"> {this.counterType.charAt(0).toUpperCase() + this.counterType.slice(1)} </span>*/}
                            <div ref={
                                (x) => {
                                    if(!this.state.firstInputSelected && x) {
                                        x.querySelector('[placeholder="Email"]').select()
                                        this.setState({firstInputSelected: true})
                                    }
                                }
                            }>

                                <span className = "mm-label"> Username </span>
                                <ComboBox
                                    className={"mm-input padded"}
                                    name = "email"
                                    data = {this.state.userList}

                                    placeholder="Email"
                                    suggest={true}
                                    onChange={this.handleAutoChange}
                                    // value={this.state.goals.ordersDaily.value || ''}
                                    // placeholder="Daily Orders"
                                    // onChange = {this.handleChange}
                                    // onKeyPress={this.handleKeyPress}
                                />
                            </div>


                            <DatePicker
                                    defaultValue = {new Date()}
                                    suggest={true}
                                    onChange={this.handleDateChange}
                            />

                        </fieldset>
                        <DialogActionsBar>
                            <Button className="mt-3" type="button" onClick = {e=> this.props.toggleMVPModal()}>Cancel</Button>

                            <Button className="mt-3" type="submit" primary={true} onClick={ e => {
                                this.updateMVP(e)
                            }}>
                                Set MVP
                            </Button>
                        </DialogActionsBar>
                    </div>


                </Dialog>
            </div>
        );
    }
}
import {HANDLE_EDITOR_CHANGED} from '../types';

export default function handleEditorRemoved (id) {
    return async (dispatch) => {
        id = parseInt(id);

        dispatch({
            type: HANDLE_EDITOR_CHANGED,
            payload: {
                id,
                editor: null
            }
        })
    }
}
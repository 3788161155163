import React from 'react';
import BEMHelper from 'react-bem-helper';

import './NotesCell.scss';
const classes = new BEMHelper('NotesCell');

const NotesCell = ({notes}) => {
    return notes
            ? <div {...classes()}>
                <div {...classes('flag')}> ! </div>
                <div {...classes('text')}> {notes} </div>
            </div>
            : <></>
}

export default NotesCell;
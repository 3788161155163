import {setMenuClassModifier} from '../index';
import patchRoomUser from '../roomUsers/patchRoomUser';

/** Close the menu when user clicks on ChatApp
 *  Reset unread count for active state
 * **/
export default function () {
    return (dispatch, getState) => {
        const {
            aivaReducer,
            roomUsersReducer
        } = getState();

        const {
            menuData,
            selectedRoom
        } = aivaReducer;

        const roomUser = roomUsersReducer.roomIdToRoomUser[selectedRoom];
        if (roomUser && roomUser.unreadCount !== 0) {
            patchRoomUser(roomUser.id, {unreadCount: 0})
        }

        if (menuData.classModifier === 'compact') {
            dispatch(setMenuClassModifier('hidden'));
        }
    }
}
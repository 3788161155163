import {combineReducers} from 'redux';
import produce from "immer"

import {
    SET_USERS,
    CREATE_USER,
    UPDATE_USER,
    CREATE_ROOM_USER,
    REMOVE_ROOM_USER
} from '../actions/types';
import GetByIdWithRelated from '../../helpers/GetByIdWithRelated';

const byId = produce((draft, action) => {
    switch(action.type) {
        case SET_USERS:
            return GetByIdWithRelated(action.users,['rooms']);
        case CREATE_USER:
            action.user.rooms = [] // initialize blank array of related rooms ids
            draft[action.user.id] = action.user;
            return;
        case UPDATE_USER:
            draft[action.user.id] = action.user;
            return;
        // Handle user joining/leaving a room
        case CREATE_ROOM_USER: {
            const {roomUser} = action;
            draft[roomUser.userId].rooms
                .push(roomUser.roomId);
            return;
        }
        case REMOVE_ROOM_USER: {
            const {roomUser} = action;
            const roomIdIndex = draft[roomUser.userId].rooms
                .findIndex(roomId => roomId === roomUser.roomId)
            if (roomIdIndex !== -1)
                draft[roomUser.userId].rooms.splice(roomIdIndex, 1);
            return;
        }
    }
}, {});

const allIds = produce((draft, action) => {
    switch (action.type) {
        case SET_USERS:
            return action.users.map(user => user.id);
        case CREATE_USER:
            draft.push(action.user.id);
            return;
    }
}, []);

const UsersReducer = combineReducers({
    byId: byId,
    allIds: allIds
})

export default UsersReducer;
import React, {useEffect} from 'react';

/** Components **/
import Menu from './Menu';
import Main from './Main';
import RoomModal from './Dialog/RoomModal';

/** Style **/
import './Aiva.scss';

/** Redux **/
import {connect} from 'react-redux';

import CreateRoomModal from './Dialog/CreateRoomModal';

const Aiva = (props) => {
    const {
        modalRoomId,
        createRoomType,
    } = props;

    return <div id = 'aiva'>
            {(modalRoomId && modalRoomId !== -1) &&
                <RoomModal
                    modalRoomId = {modalRoomId}
                />
            }

            {createRoomType !== "n" &&
                <CreateRoomModal
                    createRoomType = {createRoomType}
                />
            }
            <Menu/>
            <Main/>
        </div>
}

function mapStateToProps (state) {
    return {
        modalRoomId: state.aivaReducer.modalRoomId,
        createRoomType: state.aivaReducer.modalCreateRoomType,
    }
}

export default connect(mapStateToProps, null) (Aiva);
import React from 'react';

export function OrganizationDetails ({organization = {}}) {
    const {
        name,
        address
    } = organization;

    return <div className='vp-card-row'>
        {name}
    </div>
}

export function EntityDetails ({entity = {}}) {
    const {
        name
    } = entity;
    return <div className = 'vp-card-row'>
        {name}
    </div>
}
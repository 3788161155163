import React from 'react';

/** Used for dialog container and chat box preview for nlp **/
export const INPUT_MAP = {
    'expiration': 'Expiration',
    'strike': 'Strike',
    'structure': 'Structure',
    'risk_rwd_ratio': 'Ratio',
    'tied': 'Tied',
    'delta': 'Delta',
    'size': 'Size',
    'bid': 'Bid',
    'ask': 'Ask',
    'orgCode': 'Buyer',
    'aOrgCode': 'Seller'
}

export const ALL_CAPS_FIELDS = ["orgCode", "aOrgCode", "ticker", "expiration", "structure"];

/** Input validation and constants for admin user modal and change password modal **/
export const USER_INPUT_MAP = {
    'firstname': {
        label: 'First Name',
        pattern: '[a-zA-Z]+',
        required: true,
    },
    'lastname': {
        label: 'Last Name',
        pattern: '[a-zA-Z]+',
        required: true,
    },
    'email': {
        label: 'Email',
        pattern: '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
        required: true,
    },
    'password': {
        label: 'Password',
        pattern: '^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d@$!%*#?&]{8,}$',
        placeholder: 'Must have at least 1 number and 8 characters.'
    },
    'qsUsername': {
        label: 'Quotestream Username',
        pattern: '(.*?)',
        required: false,
    },
    'qsPassword': {
        label: 'Quotestream Password',
        pattern: '(.*?)',
        required: false,
    },
    'qsWmid': {
        label: 'Quotestream WMID',
        pattern: '(.*?)',
        required: false,
    },
}

/* Aiva Order Detail Components ClassNames For Order Fields **/
export const ORDER_DETAIL_FIELDS = ['ticker','expiration','strike','structure','risk_rwd_ratio','tied','delta']

/** Differentiate between future and option. In database future has structureId of 24 **/
export const FUTURE_ID = 19;
export const REVCON_ID = 14;

// Revcon can be calls over +C or puts over +C
export const REVCON_OVER = Object.freeze({
    "CALLS": 'C',
    'PUTS': 'P'
});

export const INTEREST_TYPE = Object.freeze({
    BID: 'b',
    ASK: 'a'
})

/** Update vs create **/
export const ACTION_TYPE = Object.freeze({
    UPDATE: 'UPDATE',
    CREATE: 'CREATE'
});

/** For OrderForm inputs **/
export const OPTION_INPUT_NAMES = [
    'ticker',
    'expiration',
    'strike',
    'structure',
    'ratio',
    'tied',
    'delta',
    'notes'
];

export const REVCON_INPUT_NAMES = [
    'ticker',
    'expiration',
    'strike',
    'structure',
    'over',
    'notes'
];

export const CREATE_INPUT_NAMES = [
    'size',
    'bid',
    'ask'
];

export const ALL_CAP_INPUT_FIELDS = [
    "orgCode",
    "aOrgCode",
    "ticker",
    "expiration",
];

export const ORDER_MENU_OPTIONS = Object.freeze({
    COPY: 'Copy',
    GET_LIVE_PRICING: 'Get Live Prices',
    FLIP_BUY_SELL: 'Flip Buy/Sell',
    MOVE_TO_WORKING: 'Back to Working',
    MOVE_TO_NOT_WORKING: 'Not Working',
    VOID: 'Void'
});

export const SPREAD_MENU_OPTIONS = Object.freeze({
    TRADE: 'Trade',
    DEACTIVATE: 'Deactivate',
    ACTIVATE: 'Reactivate',
    VOID: 'Void'
});

export const RODAL_STYLES = Object.freeze({
    background: '#111',
    height: 'auto',
    bottom: 'auto',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '350px',
    boxShadow: '0 10px 27px 2px rgba(0,0,0,.75)',
    maxHeight: '80%',
    overflowY: 'auto'
});


/** Tab status everything, working, not working **/
export const TAB_STATUS = Object.freeze({
    EVERYTHING: 'EVERYTHING',
    WORKING: 'WORKING',
    NOT_WORKING: 'NOT_WORKING'
});

export const TAB_ID_FIELDS = Object.freeze({
    organization: 'organizationId',
    underlying: 'underlyingId',
    structure: 'structureId'
});

export const TAB_TEXT_FIELDS = Object.freeze({
    organization: 'orgCode',
    underlying: 'ticker',
    structure: 'structName'
});

export const TAB_DEFAULT_TEMPLATE = Object.freeze({
    days: 1,
    title: '',
    unclosable: false,
    filterByDays: true,
    filterByRange: false,
    status: TAB_STATUS.EVERYTHING
});

export const TAB_FILTER_BY = Object.freeze({
    RANGE: 'filterByRange',
    DAYS: 'filterByDays'
})

export const ORDERS_QUERY_LIMIT = 300;

export const BID_PAIR_MAPPINGS = [
    ['id', 'BidId'],
    ['brokeredBy', 'BrokeredBy'],
    ['brokerId', 'UserId'],
    ['orgCode', 'OrgCode'],
    ['organizationId', 'OrganizationId']
];
import {
    SET_MESSAGES,
    CREATE_MESSAGE, CREATE_REPLY
} from '../actions/types';
import {combineReducers} from 'redux';
import produce from "immer"
import GetByIdWithRelated from '../../helpers/GetByIdWithRelated';

const byId = produce((draft, action) => {
    switch(action.type) {
        case SET_MESSAGES:
            return GetByIdWithRelated(action.messages, ['replies']);
        case CREATE_MESSAGE:
            action.message.replies = [] // initialize blank array of related message ids
            draft[action.message.id] = action.message;
            return;
        // Handle user adding a reply - append to list of replies
        case CREATE_REPLY: {
            const {reply} = action;
            if (draft[reply.messageId] == null) return;

            draft[reply.messageId].replies.push(reply.id);
            return;
        }
    }
}, {});

const allIds = produce((draft, action) => {
    switch (action.type) {
        case SET_MESSAGES:
            return action.messages.map(msg => msg.id);
        case CREATE_MESSAGE:
            draft.push(action.message.id);
            return;
    }
}, []);

const MessagesReducer = combineReducers({
    byId: byId,
    allIds: allIds
})

export default MessagesReducer;
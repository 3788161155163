import React from 'react';

/** Style **/
import './Menu.scss';

/** Redux **/
import {connect} from 'react-redux';
import selectRoomsByType from '../../../redux/selectors/selectRoomsByType';

/** Components **/
import RoomGroup from './RoomGroup';
import ReactTooltip from 'react-tooltip';

const Menu = (props) => {
    const {
        channels,
        chats,
        menuClassModifier
    } = props;

    return <div className={'Menu Menu--' + menuClassModifier}>
        <RoomGroup
            roomType = 'channel'
            rooms = {channels}
        />
        <RoomGroup
            roomType = 'chat'
            rooms = {chats}
        />

        {/*<a data-tip data-for='global'> σ`∀´)σ </a>*/}

        {/*<ReactTooltip*/}
        {/*    id='global'*/}
        {/*    aria-haspopup='true'*/}
        {/*    role='example'*/}
        {/*    getContent={() => {*/}
        {/*        return <div style={{'background': 'blue', 'height': '30px'}}>{count}</div>*/}
        {/*    }}*/}
        {/*/>*/}
    </div>
}

const mapStateToProps = (state) => {
    return {
        channels: selectRoomsByType(state, 'channel'),
        chats: selectRoomsByType(state, 'chat'),
        menuClassModifier: state.aivaReducer.menuData.classModifier
    }
}

export default connect(mapStateToProps, null) (Menu);
import React from 'react';
import {connect} from 'react-redux';
import BEMHelper from 'react-bem-helper';

import { Popup } from '@progress/kendo-react-popup';
import { Menu, MenuItem } from '@progress/kendo-react-layout';
import {setOrderMenuProps} from '../../../redux/actions/wb';
import {subscribeToOrderPricing} from '../../../redux/actions';
import {bidsService, ordersService} from '../../../feathers';
import {
    INTEREST_TYPE,
    ORDER_MENU_OPTIONS as ORDER_OPTIONS,
    SPREAD_MENU_OPTIONS as BID_OPTIONS
} from '../../../helpers/constants';
import {
    GetOrderString
} from '../../../helpers';
import {tradeBid} from '../../../redux/actions/wbBids';
import {voidTrade} from '../../../redux/actions/wbTrades';
import {selectWbMarketByOrderId} from '../../../redux/selectors';

import './OrderMenu.scss';
const classes = new BEMHelper('OrderMenu');

/**
 * Bit of a misnomer. This is the context menu for both
 * bids and orders. Either orderId (along with order object)
 * or bidId (along with inactive/traded fields) should be supplied
 * @param All necessary props are passed via orderMenuProps
 */
const OrderMenu = (props) => {
    const {
        orderMenuProps = {},
        closeOrderMenu,
        subscribeToOrderPricing,
        marketSummary: {bestBid, bestAsk} = {},
        tradeBid,
        voidTrade
    } = props;

    const {
        offset,

        order = {},

        bid = {},

        trade = {}
    } = orderMenuProps;

    const {
        id: orderId,
        complete,
        flipBuySell,
        updatedAt,
    } = order;

    const {
        id: bidId,
        inactive,
        blockId,
        direction,
    } = bid;

    const {
        id: tradeId
    } = trade;

    const onOrderSelect = (event) => {
        closeOrderMenu();
        switch (event.item.text) {
            case ORDER_OPTIONS.COPY:
                navigator.clipboard.writeText(
                    GetOrderString(order, bestBid, bestAsk)
                );
                break;

            case ORDER_OPTIONS.GET_LIVE_PRICING:
                subscribeToOrderPricing(order);
                break;

            case ORDER_OPTIONS.MOVE_TO_WORKING:

            case ORDER_OPTIONS.MOVE_TO_NOT_WORKING:
                ordersService.patch(orderId, {complete: !complete});
                break;

            case ORDER_OPTIONS.FLIP_BUY_SELL:
                ordersService.patch(orderId, {flipBuySell: !flipBuySell});
                break;

            case ORDER_OPTIONS.VOID:
                if (window.confirm('Are you sure you want to void this order?'))
                    ordersService.patch(orderId, {isVoided: true});
                break;

            default:
        }
    }

    const onBidSelect = (event) => {
        switch(event.item.text) {
            case BID_OPTIONS.TRADE:
                tradeBid(bid);
                break;

            case BID_OPTIONS.ACTIVATE:

            case BID_OPTIONS.DEACTIVATE:
                // need to pass the order id so feathers hook can update the order
                bidsService.patch(bidId, {inactive: !inactive, orderId: bid.orderId});
                break;

            case BID_OPTIONS.VOID:
                if (window.confirm(`Are you sure you want to void this ${direction === INTEREST_TYPE.BID ? 'bid' : 'ask'}?`))
                    bidsService.patch(bidId, {isVoided: true});
                break;

            default:
        }
    }

    const onTradeSelect = (event) => {
        switch(event.item.text) {
            case BID_OPTIONS.VOID:
                if(window.confirm('Are you sure you want to void this trade?' +
                    ' This will also void the associated counterparty bid.')) {
                    voidTrade(trade);
                }
            default:
        }
    }

    return <Popup
        show = {orderId!=null || bidId!=null || tradeId!=null}
        offset = {offset}
        appendTo = {document.querySelector(".bb")}
        animate={false}
        className={'Popup'}
    >
        <div {...classes()}>
            {orderId != null
                && <Menu
                    vertical={true}
                    onSelect={onOrderSelect}
                    className={'Popup'}
                >
                    <MenuItem text={ORDER_OPTIONS.COPY} icon='copy'/>
                    {
                        new Date(updatedAt) < new Date().setHours(0,0,0,0)
                        && <MenuItem text={ORDER_OPTIONS.GET_LIVE_PRICING} icon='dollar' />
                    }
                    <MenuItem
                        text={
                            complete
                            ? ORDER_OPTIONS.MOVE_TO_WORKING
                            : ORDER_OPTIONS.MOVE_TO_NOT_WORKING
                        }
                        icon='check'
                    />
                    <MenuItem text={ORDER_OPTIONS.FLIP_BUY_SELL} icon='mirror' />
                    <MenuItem text = {ORDER_OPTIONS.VOID} icon='delete' />
                </Menu>
            }
            {
                bidId != null
                && (blockId != null
                    ? <div {...classes('description')}> Please edit in trade table. </div>
                    : <Menu
                        vertical={true}
                        onSelect={onBidSelect}
                    >
                        {
                            !inactive
                            && <MenuItem text={BID_OPTIONS.TRADE} icon='check'/>
                        }
                        <MenuItem
                            text={
                                inactive
                                    ? BID_OPTIONS.ACTIVATE
                                    : BID_OPTIONS.DEACTIVATE
                            }
                            icon={
                                inactive
                                    ? 'unlock'
                                    : 'lock'
                            }
                        />
                        <MenuItem text={BID_OPTIONS.VOID} icon='delete'/>
                    </Menu>)
            }
            {
                tradeId != null
                && <Menu
                    vertical={true}
                    onSelect={onTradeSelect}
                >
                    <MenuItem text={BID_OPTIONS.VOID} icon='delete'/>
                </Menu>
            }

        </div>
    </Popup>
}

const mapStateToProps = (state, {orderMenuProps = {}}) => {
    return {
        marketSummary: selectWbMarketByOrderId(state, (orderMenuProps.order != null
            ? orderMenuProps.order.id
            : -1))
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        tradeBid: (bid) => dispatch(tradeBid(bid)),
        voidTrade: (trade) => dispatch(voidTrade(trade)),
        subscribeToOrderPricing: (order) => dispatch(subscribeToOrderPricing(order)),
        closeOrderMenu: () => dispatch(setOrderMenuProps()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (OrderMenu);
import {createCachedSelector} from 're-reselect';
import {FUTURE_ID} from '../../helpers/constants';

const _getFutureString = (order) => {
    return "";
}

const _getOptionString = (order) => {
    const {
        ticker,
        expiration,
        strike,
        ratio,
        structName,
        tied,
        delta,
    } = order;

    return ticker + ' '
        + (expiration ? expiration : '?') + ' '
        + (strike ? strike : '?') + ' '
        + (ratio ? ratio + ' ' : '')
        + (structName ? structName : '?') + ' '
        + (tied ? 'vs. ' + tied : '') + ' '
        + (delta ? '(' + delta + ')': '');
}

/**
 * Helper function to generate copyable order string
 */
const getOrderString = (order) => {
    const {
        structureId
    } = order;

    return (structureId === FUTURE_ID
        ? _getFutureString(order)
        : _getOptionString(order));
}

/**
 * Returns the order data to be displayed in blackboard (client org, copy string, ticker, notes)
 */

const getOrderById = (state, orderId) => state.wbOrdersReducer.byId[orderId];
const selectWbOrderSummaryByOrderId = createCachedSelector(
    getOrderById,
    (order) => {
        return {
            ...order,
            orderString: getOrderString(order)
        }
    }
)(
    (_state_, orderId) => orderId ?? -1
)

export default selectWbOrderSummaryByOrderId;
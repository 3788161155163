import React from 'react';

/** Style **/
import './TickerQuote.scss';
import BEMHelper from 'react-bem-helper';
let classes = new BEMHelper('TickerQuote');

const TickerQuote = (props) => {
    const summary = props.summary ? props.summary : {percentChange: 0};

    const {
        last,
        percentChange
    } = summary;

    const pctChange = percentChange
        ? (percentChange*100/100).toFixed(2) + '%'
        : undefined;

    return <div {...classes()}>
        <div {...classes('price')}> {last ? last.toFixed(3) : ''} </div>
        <div {...classes('percent', ((percentChange && percentChange>0)?'green':'red'))}> {pctChange} </div>
    </div>
}


export default TickerQuote;
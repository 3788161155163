import ValidateTrade from '../../../helpers/ValidateTrade';
import {
    SET_STP_TRADE,
    SET_STP_TRADE_VALIDATION
} from '../types';

export default function (trade, selectedStpId) {
    return async dispatch => {
        if (selectedStpId === trade.id) {
            const validation = ValidateTrade(trade);

            dispatch({
                type: SET_STP_TRADE_VALIDATION,
                validation: validation,
            });

            dispatch({
                type: SET_STP_TRADE,
                trade
            })
        }
    }
}
export default function (num) {
    if(num == null) {
        return '';
    }

    num = num.toString().replace(/\,/g, '');

    const containsDecimal = num.includes(".")

    return parseFloat(num).toLocaleString(
        'en-US',
        {
            minimumFractionDigits: containsDecimal ? 2 : 0,
            maximumFractionDigits: containsDecimal ? 5 : 0
        },
    )
}
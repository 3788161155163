import {createCachedSelector} from 're-reselect';
import {selectMessageById} from './index';

/** Select list of reply entities for this message **/
const selectRepliesByMessageId = createCachedSelector(
        selectMessageById,
        state => state.repliesReducer.byId,

        ( message, repliesById) => {
            return message ? message.replies.map(replyId => repliesById[replyId]) : [];
        }
)((_state_, messageId) => messageId);

export default selectRepliesByMessageId;
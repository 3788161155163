import produce from 'immer';
import {combineReducers} from 'redux';

import {
    SET_BIDS,
    CREATE_BID,
    UPDATE_BID,
    REMOVE_BID,
} from "../actions/types";
import InsertId from '../../helpers/InsertId';
import GetByIdWithRelated from '../../helpers/GetByIdWithRelated';


const byId = produce((draft, action) => {
    switch (action.type) {
        case SET_BIDS:
            return GetByIdWithRelated(action.bids, [])
        case CREATE_BID:
            draft[action.bid.id] = action.bid;
            return;
        case UPDATE_BID:
            draft[action.bid.id] = action.bid;
            return;
        case REMOVE_BID:
            delete draft[action.bid.id];
            return;
    }
}, {});

const allIds = produce((draft, action) => {
    switch(action.type) {
        case SET_BIDS:
            return action.bids.map(bid => bid.id);
        case CREATE_BID: {
            draft.splice(InsertId(draft, action.bid.id), 0, action.bid.id);
            return;
        }
        case REMOVE_BID: {
            let index = draft.findIndex(bidId => bidId === action.bid.id);
            if (index !== -1) draft.splice(index, 1);
            return;
        }
    }
}, []);


const BidsReducer = combineReducers({
    byId: byId,
    allIds: allIds
});

export default BidsReducer;
import moment from 'moment';

const today = new Date();
today.setHours(0,0,0,0);

/** Used to render ddd MM/DD for createdAt **/
const GetDayFromDate = (dateString) => {
    const date = moment(dateString);
    if (date < today) {
        return date.format("ddd MM/DD")
    }
    return date.format("hh:mma");
}
/**
 * Used to render HH:MM for updatedAt in Whiteboard
 **/
const GetTimeFromDate = (dateString) => {
    const date = moment(dateString);
    return date.format("hh:mma");
}

/** Rendered in hover tooltip for OrderRow **/
const GetFullDate = (dateString) => {
    const date = moment(dateString);
    return date.format("ddd, MMM D YYYY @ hh:mm")
}

/** Rendered in traded menu **/
const GetTradeDate = (dateString) => {
    const date = moment(dateString);
    return date > today
        ? date.format("hh:mm a")
        : date.format("MM/DD/YY")
}

export {
    GetDayFromDate,
    GetTimeFromDate,
    GetFullDate,
    GetTradeDate
};
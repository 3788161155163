const earliestValidDate = new Date().setFullYear(2019);

const isDateValid = (dt) => {
    if (dt == null) return false;
    console.log(dt)
    if (!(dt instanceof Date)) dt = new Date(dt);
    return dt > earliestValidDate;
}

const expirationFields = ['expiration', 'tradeDate'] // add optionExecutionTime for dateTimePicker!
const metadataFields = ['ticker', 'price', 'size', 'optionFloor', 'optionFloorBroker', 'optionClientCategory', 'optionExecutionTime'];
const legFields = ['strike', 'expiration', 'price', 'size', 'buyerBuys'];
const hedgeFields = ['hedgePrice', 'hedgeSize', 'hedgeSaleType', 'hedgeCrossAgent', 'hedgeExecutionTime'];
const directions = ['buy', 'sell'];
const directionFields = ['User', 'Brokerage', 'OrganizationId', 'EntityId', 'ClientId'];

export default function ValidateTrade (trade) {
    let ret = []
    // check voided
    if (trade.voided === 1) ret.push('Trade is voided.');

    // check expirations
    expirationFields.forEach(field => {
        if (!isDateValid(trade[field])){
            ret.push(field + ' is an invalid date.');
        }
    });

    // check basic info metadata
    metadataFields.forEach(field => {
        if (trade[field] == null) {
            ret.push(field + ' cannot be empty.');
        }
    });

    // check legs
    if(trade.legs!=null){
        trade.legs.forEach((leg, ix) => {
            legFields.forEach(field => {
                if (leg[field] == null) {
                    ret.push(`${field} for ${leg.structure} ${ix+1} cannot be empty.`);
                }
            });
        });
    } else {
        ret.push('Legs cannot be empty')
    }

    // check hedge
    if (trade.isHedged) {
        hedgeFields.forEach(field => {
            if (trade[field] == null) {
                ret.push(field + ' cannot be empty.');
            }
        });

        /** FOR DATETIMEPICKER
        if (!isDateValid(trade.hedgeExecutionTime)){
            ret.push('Hedge Execution Time is an invalid date.');
        }**/
    }

    // check buyers and sellers
    directions.forEach(dir => {
        directionFields.forEach(field => {
            if (trade[dir+field] == null){
                ret.push(field + ' cannot be empty.');
            }
        });
    });

    return {
        status: ret.length === 0 ? true : false,
        errors: ret,
    };
}
import {
    roomLogicService
} from '../../../feathers';

import selectRoomsByType from '../../selectors/selectRoomsByType';
import {setModalCreateRoomType, setSelectedRoom} from '../index';

export default function createRoom (roomName, roomType, description, userGroup, selectedUsers) {
    return async (dispatch, getState) => {
        const userIds = selectedUsers.map(user => user.id);

        if (roomType === 'chat') {
            const myUserId = getState().authReducer.user.id;
            let newUsersRaw = [...userIds];

            if(newUsersRaw!=null && newUsersRaw[0] !== myUserId) { // if the length === 1 that means it's just a chat with myself so we shouldn't duplicate it
                newUsersRaw.push(myUserId);
            }
            const newUsers = newUsersRaw.sort().join(',');

            const chats = selectRoomsByType(getState(), 'chat');

            for (let i = 0; i < chats.length; i++) {
                if([...chats[i].users].sort().join(',') === newUsers) {
                    dispatch(setSelectedRoom(chats[i].id));
                    dispatch(setModalCreateRoomType('n'))
                    return;
                }
            }
        }

        roomLogicService.create({
            roomName,
            roomType,
            description,
            userGroup,
            selectedUsers: userIds
        });
        dispatch(setModalCreateRoomType('n'));
    }
}
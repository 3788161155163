/* eslint-disable */
/** REDUX **/
import store from '../redux/store';

/** Helpers **/
import {Delay} from '../helpers';
/** ACTIONS **/
import {
    batchPriceData,
    batchIVGreeksData,
    batchQuoteData,
    setQsSid
} from '../redux/actions';
import {setWbOrdersAndBids} from '../redux/actions/wbOrders';

const retry = require('async-retry');
const Streamer = require('./qmci-streamer-2.6.0.js');

async function _waitForAuthenticated () {
    return Delay(500).then(() => {
        if (store.getState().authReducer.isLoggedIn!==true || window.location.pathname !== '/') {
            throw Error;
        }
        return true;
    });
}

let priceData = [];
let ivData = [];
let quoteData = [];

setInterval(()=> {
    // batch(()=>{
    //    priceData.map(message => store.dispatch((setPriceData(message))));
    //    ivData.map(message => store.dispatch(setIVGreeks(message)));
    //    quoteData.map(message => store.dispatch(setQuote(message)));
    // });
    store.dispatch(batchPriceData(priceData));
    store.dispatch(batchQuoteData(quoteData));
    store.dispatch(batchIVGreeksData(ivData));

    priceData = [];
    ivData = [];
    quoteData = [];
}, 300);
// See for creating a promise from callback: https://stackoverflow.com/questions/44791052/export-value-from-callback-in-node-js
export async function startSession(){
    return new Promise((resolve, reject) => {
        /** Get Quotestream credentials from auth state **/
        retry(async bail => {
            const res = await _waitForAuthenticated();
        }, {
            retries: 10
        })
            .then(res => {

                const {user} = store.getState().authReducer;

                try {
                    Streamer.login({
                        host: 'https://app.quotemedia.com/auth',
                        credentials: {
                            wmid: user.qsWmid,
                            username: user.qsUsername,
                            password: user.qsPassword
                        }
                    }, function (error, sid) {
                        /** Set Session Id **/
                        store.dispatch(setQsSid(sid));

                        // After obtaining the SID, use it to authenticate
                        // and open a data stream.
                        // Set rejectExcessiveConnection:true to reject new connections when limit is reached. If not specified or value is false,
                        // first open connection will be closed instead.
                        // If needed, there is ability to specify conflation value per connection. When conflation value is null
                        // or not specified, default conflation is used.
                        // Set format value to 'application/qitch' to use this binary protocol.
                        // Please note that although QITCH protocol uses less bandwidth it can cause significant performance degradation.
                        // In case if no format was specified JSON will be used by default.
                        // Can also enable the built-in logging by setting the browser console as the logger.
                        // Must be set before calling open(). NOTE: May cause performance degradation.
                        // Streamer.logger = console;
                        Streamer.open({
                            host: 'https://app.quotemedia.com/cache',
                            cors: true,
                            rejectExcessiveConnection: false,
                            conflation: 150,
                            format: 'application/json',
                            credentials: {sid: sid}
                        }, function (error, stream) {
                            if (!stream) {
                                window.alert('Please refresh the page. Error connecting to quotestream.')
                                return;
                            }

                            /** Set the orders **/
                            store.dispatch(setWbOrdersAndBids());

                            stream
                            // The stream will asynchronously callback with
                            // incoming market data messages.
                                .on("message", function (message) {
                                    switch (Streamer.dataTypes.get(message)) {
                                        case 'LASTSALE':
                                            priceData.push(message);
                                            // store.dispatch(setPriceData(message))
                                            break;
                                        case 'IVGREEKS':
                                            ivData.push(message);
                                            // store.dispatch(setIVGreeks(message))
                                            break;
                                        case 'QUOTE':
                                            quoteData.push(message);
                                            // store.dispatch(setQuote(message))
                                            break;
                                    }
                                })

                                // It's recommended to attach an error handler
                                // to help diagnose unexpected errors.
                                .on("error", function (err) {
                                    console.error('Quotestream error');
                                    console.error(err, "red");
                                    window.location.reload();
                                })
                                .on("stats", function (msg) {
                                    console.log("STATS. Number of l1 symbols available:  " + msg.numberOfAvailableSymbolsL1 +
                                        ", number of l2 symbols available " + msg.numberOfAvailableSymbolsL2 +
                                        ", number of available connections: " + msg.numberOfAvailableConnections, "green");
                                })
                                .on("slow", function (msg) {
                                    console.log("Slow -> TimesExceeded: " + msg.timesExceeded + " MaxExceed: " + msg.maxExceed);
                                })
                                .on("initialDataSent", function (msg) {
                                    console.log("Initial data sent. Timestamp: " + msg.timestamp);
                                })
                                .on("resubscribeMessage", function (msg) {
                                    console.log("Resubscription has been triggered. Timestamp: " + msg.timestamp);
                                })
                                // Due to network hiccups or other unexepected errors,
                                // the stream may be unexpectedly closed.
                                // For robustness, it's recommended to add reconnection logic.
                                .on("close", function (msg) {
                                    console.error('Quotestream close');
                                    console.log("Closed: " + msg);
                                });
                            console.log(stream)
                            resolve(stream);

                        })
                    });
                } catch (error) {
                    window.alert('Your QuoteStream credentials are invalid. Please contact the system administrator. \n' + error.message);
                }
            });
        });
}

export default async function loginQuotestream() {
    return await startSession();
}
import React from 'react';

/** Redux **/
import {connect} from 'react-redux';
import {
    selectLegsSummaryByOrder, selectSummaryByTicker
} from '../../../redux/selectors';

/** Helpers **/
import {FormatFloatTwoDecimals, FormatNumber} from '../../../helpers';

/** Components **/
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';

/** Style **/
import './OrderDown.scss';
import BEMHelper from 'react-bem-helper';
const classes = new BEMHelper('OrderDown');

const BidCell = (props) => {
    const price = props.dataItem[props.field+'Price']
    const size = props.dataItem[props.field+'Size'];

    return (
        <td>
           <div> {FormatNumber(price)} </div>
           <div {...classes('size')}> {FormatNumber(size)} </div>
        </td>
    );
}

const BuyerBuysCell = (props) => {
    const buyText = props.dataItem.buyerBuys === 1 ? 'Buys' : 'Sells';
    return (
        <td>
            {buyText}
        </td>
    )
}

const DeltaCell = (props) => {
    const roundedDelta = FormatFloatTwoDecimals(props.dataItem.delta*100);
    return (
        <td>
            {roundedDelta} Δ
        </td>
    )
}

const OrderDown = ({legsByOrder, underlying}) => {
    return <div {...classes()}>
        <Grid
            data={legsByOrder}
        >
            <Column field="buyerBuys" title = "Buyer" cell = {BuyerBuysCell} />
            <Column field="expiry" title = "Expiry" />
            <Column field="leg" title="Leg" />
            <Column field="ratio" title="Ratio" />
            <Column field="strike" title="Strike" />
            <Column field="bid" title="Bid" cell = {BidCell} />
            <Column field="ask" title="Ask" cell = {BidCell} />
            <Column field = 'delta' title = 'Delta Mid' cell = {DeltaCell}/>
            <Column field = 'midIV' title = 'IV Mid' />
        </Grid>

        <Grid
            data = {[underlying]}
            style={{marginTop: "10px", width: "400px"}}
        >
            <Column field='symbol' title='Ticker' />
            <Column field='bidSize' title='Bid Size' />
            <Column field='bidPrice' title='Bid Price' />
            <Column field='askPrice' title='Ask Price' />
            <Column field='askSize' title='Ask Size' />
        </Grid>
    </div>
}

const mapStateToProps = (state, {dataItem = {}}) => {
    return {
        legsByOrder: selectLegsSummaryByOrder(state, dataItem),
        underlying: selectSummaryByTicker(state, dataItem.ticker)
    }
}

export default connect(mapStateToProps, null) (OrderDown);

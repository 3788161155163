import React, {useCallback} from 'react';
import {connect} from 'react-redux';

import {
    selectTradesByIds,
    selectWbMarketByOrderId,
} from '../../../redux/selectors';
import {
    setOrderFormProps,
    setOrderMenuProps,
    setTradeFormProps,
    toggleDetail,
} from '../../../redux/actions/wb';

import IconsCell from '../IconsCell/IconsCell';
import ClientCell from '../ClientCell/ClientCell';
import OrderCell from '../OrderCell/OrderCell';
import MarketCell from '../MarketCell/MarketCell';
import GaugeCell from '../GaugeCell/GaugeCell';
import NotesCell from '../NotesCell/NotesCell';
import ScreensCell from '../ScreensCell/ScreensCell';
import InterestsTable from '../InterestsTable/InterestsTable';

import {
    INTEREST_TYPE,
    ACTION_TYPE
} from '../../../helpers/constants';

import {GetFullDate} from '../../../helpers';

import './OrderRow.scss';
import BEMHelper from 'react-bem-helper';
import DetailRow from '../DetailRow';
import TradeList from '../TradeList/TradeList';
import selectWbOrderById from '../../../redux/selectors/selectWbOrderById';
const classes = new BEMHelper('OrderRow');

/**
 * Top level row component for order.
 * Responsible for retrieving its own data for that order from redux store.
 * @param orderId of a single order
 */
const OrderRow = (props) => {
    const {
        orderId,
        key,
        order,
        marketSummary,
        isDepthExpanded,
        isDetailExpanded,

        setOrderFormProps,
        setOrderMenuProps,
        setTradeFormProps,
        toggleDetail
    } = props;

    const {
        orgCode,
        ticker,
        notes,
        complete,
        createdAt,
        updatedAt,
        createdBy,
        updatedBy,
        trades,
        isVoided,
        flipBuySell
    } = order;

    const {
        orgString,

        live_bids,
        inactive_bids,
        traded_bids,

        live_asks,
        inactive_asks,
        traded_asks,

        bestBid,
        bestAsk
    } = marketSummary;

    const openOrderForm = useCallback(() => {
        setOrderFormProps({prevOrder: order, actionType: ACTION_TYPE.UPDATE})
    }, [order]);

    const onContextMenu = (event) => {
        event.preventDefault();
        setOrderMenuProps({
            order: order,
            offset: {left: event.clientX, top: event.pageY}
        });
    }

    return <div
        {...classes('', (complete ? 'inactive' : ''))}
        key = {key}
        title={`Created By: ${createdBy}\n${GetFullDate(createdAt)}\n\nUpdated By: ${updatedBy}\n${GetFullDate(updatedAt)}`}
        onContextMenu={onContextMenu}
    >
        <div
            {...classes('plus')}
            onClick = {toggleDetail}
        >
            <span className = {'k-icon k-i-' + (isDetailExpanded ? 'minus' : 'plus')} />
        </div>

        {/*<div {...classes('gauge')}>*/}
        {/*    <GaugeCell/>*/}
        {/*</div>*/}

        <div {...classes('client')} onDoubleClick={openOrderForm}>
            <ClientCell
                orgCode = {orgCode}
                orgString = {orgString}
            />
        </div>

        <div {...classes('order')} onDoubleClick={openOrderForm}>
            <OrderCell
                order={order}
            />
        </div>

        <div {...classes('icons')}>
            <IconsCell
                createdAt = {createdAt}
                updatedAt = {updatedAt}
            />
        </div>

        <div {...classes('bids')}>
            <MarketCell
                direction = {INTEREST_TYPE.BID}
                bestInterest = {bestBid}
                orderId = {orderId}
            />
        </div>

        <div {...classes('screens')}>
            <ScreensCell
                dataItem = {order}
            />
        </div>

        <div {...classes('asks')}>
            <MarketCell
                direction = {INTEREST_TYPE.ASK}
                bestInterest = {bestAsk}
                orderId = {orderId}
            />
        </div>


        <div {...classes('footer')} onDoubleClick={openOrderForm}>
            <NotesCell
                notes = {notes}
            />

            <TradeList
                trades = {trades}
                isDepthExpanded = {isDepthExpanded}
                setTradeFormProps={setTradeFormProps}
                setOrderMenuProps={setOrderMenuProps}
            />
        </div>

        <div {...classes('detail')}>
            {(isDetailExpanded) && <DetailRow
                dataItem = {order}
            />}
        </div>

        {isDepthExpanded && <>
            <div {...classes('bid_depth')}>
                <InterestsTable
                    direction = 'b'
                    liveInterests = {live_bids}
                    inactiveInterests = {inactive_bids}
                    tradedInterests = {traded_bids}
                    setOrderMenuProps = {setOrderMenuProps}
                />
            </div>

            <div {...classes('ask_depth')}>
                <InterestsTable
                    direction = 'a'
                    liveInterests = {live_asks}
                    inactiveInterests = {inactive_asks}
                    tradedInterests = {traded_asks}
                    setOrderMenuProps = {setOrderMenuProps}
                />
            </div>
        </>}
    </div>
}


const mapStateToProps = (state, {orderId = -1}) => {
    return {
        // orderSummary: selectWbOrderSummaryByOrderId(state, orderId),
        order: selectWbOrderById(state, orderId),
        marketSummary: selectWbMarketByOrderId(state, orderId),
    }
}

const mapDispatchToProps = (dispatch, {orderId = -1}) => {
    return {
        toggleDetail: () => dispatch(toggleDetail(orderId)),
        setOrderFormProps: (orderFormProps) => dispatch(setOrderFormProps(orderFormProps)),
        setOrderMenuProps: (orderMenuProps) => dispatch(setOrderMenuProps(orderMenuProps)),
        setTradeFormProps: (tradeFormProps) => dispatch(setTradeFormProps(tradeFormProps))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderRow);
import produce from 'immer';
import {combineReducers} from 'redux';

import {
    SET_WB_FILTERS,
    CREATE_WB_FILTER,
    UPDATE_WB_FILTER,
    REMOVE_WB_FILTER,
} from "../actions/wbFilters";

const byId = produce((draft, action) => {
    switch (action.type) {
        case SET_WB_FILTERS:
            return action.filters;
        case CREATE_WB_FILTER:
            draft[action.filter.id] = action.filter;
            return;
        case UPDATE_WB_FILTER:
            draft[action.filter.id] = action.filter;
            return;
        case REMOVE_WB_FILTER:
            delete draft[action.filter.id];
            return;
    }
}, {});

const allIds = produce((draft, action) => {
    switch(action.type) {
        case SET_WB_FILTERS:
            return action.filters.map(filter => filter.id);
        case CREATE_WB_FILTER: {
            draft.splice(0, action.filter.id);
            return;
        }
        case REMOVE_WB_FILTER: {
            let index = draft.findIndex(filterId => filterId === action.filter.id);
            if (index !== -1) draft.splice(index, 1);
            return;
        }
    }
}, []);


const WbFiltersReducer = combineReducers({
    byId: byId,
    allIds: allIds
});

export default WbFiltersReducer;
import {
    SET_TC_FILTER
} from '../types';

import GetDateFromBDays from '../../../helpers/GetDateFromBDays';

import {tradesService} from '../../../feathers';
import {setTradesState} from '../index';

export default function handleSetTcFilter(event) {
    return async dispatch => {
        if (event.target.name === 'days') {
            const startDate = GetDateFromBDays(event.target.value);
            const endDate = null;

            dispatch({
                type: SET_TC_FILTER,
                name: 'startDate',
                value: startDate,
            });

            dispatch({
                type: SET_TC_FILTER,
                name: 'endDate',
                value: endDate,
            });
        }

        dispatch({
            type: SET_TC_FILTER,
            name: event.target.name,
            value: event.target.value
        });

        dispatch(setTradesState());
    }
}
import React from 'react';

import './admin_style.css';

import client from '../../feathers';
import {Cookies, withCookies} from 'react-cookie';
import {instanceOf} from 'prop-types';

import OrganizationsForm from './OrganizationsForm';
import ClientsForm from './ClientsForm';
import Users from './Users';
import UnderlyingForm from './UnderlyingForm';
import {TabStrip, TabStripTab} from '@progress/kendo-react-layout'

/** Redux **/
import {connect} from 'react-redux';

const organizations = client.service('organizations');

class AdminApp extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            orgData: [],
            entData: [],
            pnData: [],

            selected: 0,
        };
    }

    /** Drawer Methods **/
    handleSelect = (e) => {
        this.setState({selected: e.selected});
    }

    componentDidMount() {
        const {user} = this.props
        this.setState({user})

        organizations.find({
                query: {
                    $sort: {
                        name: 1,
                    }
                }
            }).then((reqPage) => {
            const reqs = reqPage;
            this.setState({orgData: reqs,})
        });
        // entities.find().then((reqPage) => {
        //     const reqs = reqPage;
        //     this.setState({entData: reqs,})
        // });
        // persons.find().then((reqPage) => {
        //     const reqs = reqPage;
        //     this.setState({pnData: reqs,})
        // });

        // Add new messages to the message list
        organizations.on('created', op => this.orgItemCreated(op));

    }

    render() {
        const {privilege} = this.props.user;

        return (
            <div className={"admin-wrapper"}>
                {privilege === 'ADMIN'
                    ? <TabStrip selected={this.state.selected} onSelect={this.handleSelect} tabPosition={"top"}>
                        <TabStripTab title = 'Users'>
                            <Users/>
                        </TabStripTab>
                        <TabStripTab title="Organizations" contentClassName={"org-content"}>
                            <OrganizationsForm
                                orgData={this.state.orgData}
                                // entData = {this.state.entData}
                            ></OrganizationsForm>
                        </TabStripTab>
                        <TabStripTab title="People">
                            <ClientsForm
                                orgData={this.state.orgData}
                            ></ClientsForm>
                        </TabStripTab>
                        <TabStripTab title="Underlying">
                            <UnderlyingForm></UnderlyingForm>
                        </TabStripTab>
                    </TabStrip>
                    : <h1> Your account does not have administrator permissions. </h1>
                }
            </div>

        );
    }


    /* Feathers Database Updated Events */

    orgItemCreated = (op) => {
        organizations.find().then((reqPage) => {
            const reqs = reqPage;

            this.setState({
                orgData: reqs,
            })
        });
    }


}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user
    }
}

export default connect(mapStateToProps, null)(withCookies(AdminApp));
import React from 'react';
import FilterComboBox from '../FilterComboBox';
import {connect} from 'react-redux';

/** Missing props for FilterComboBox:
 * value
 * onChange
 *
 * textField
 * remapIdKey
 * remapFieldKey
 *
 * clearButton
 * disabled
 * **/

const MyComboBox = (props) => {
    const {
        data,
        textField,
        remapIdKey,
        remapFieldKey,
    } = props;

    const handleChange = (event) => {
        /**
         *  dataItem = {id: 21, code: GS}
         *  Remap to {organizationId: 21, orgCode: GS}
         *  **/
        const value = event.target.value != null ? event.target.value: {};

        let dataItem = {
            [remapIdKey]: value.id,
            [remapFieldKey]: (value[textField] != null ? value[textField].toUpperCase() : null)
        }

        props.onChange(dataItem)
    }

    return (
        <FilterComboBox
            {...props}
            data={data}
            onChange={handleChange}
            dataItemKey={'id'}
            textField={textField}
            allowCustom={true}
            popupSettings={{animate: false}}
        />
    );
}

export default MyComboBox;
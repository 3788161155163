import {
    SET_TC_FILTER_BY
} from '../types';
import {setTradesState} from '../index';

export default function handleSetTcFilterBy (event) {
    return async dispatch => {
        dispatch({
            type: SET_TC_FILTER_BY,
            filterBy: event.target.value
        });

        dispatch(setTradesState());
    }
}
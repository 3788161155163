import {
    setSymbolInfo,
    subscribeToSymbols
} from '../index';
import {getEnhancedQuotes} from '../../../api';

export default function subscribeToOrderPricing (order) {
    return async dispatch => {
        dispatch(subscribeToSymbols(order));

        /** Update the ticker metadata **/
        const data = await getEnhancedQuotes(order.ticker);
        dispatch(setSymbolInfo(data));
    }
}
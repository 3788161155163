import {
    SET_STP_TRADE_VALIDATION,
} from '../types';
import store from '../../store';

import ValidateTrade from '../../../helpers/ValidateTrade';
import sendEmails from './sendEmails';

// send emails from stp panel
export default function handleSendEmails (dir, emailList) {
    return async dispatch => {
        const {id, trade} = store.getState().stpReducer;

        const validation = ValidateTrade(trade);
        if (!validation.status || emailList.length === 0) {
            dispatch({
                type: SET_STP_TRADE_VALIDATION,
                validation: validation,
            })

            window.alert('This trade is incomplete.')
            return;
        }

        dispatch(sendEmails(emailList, id, dir));
    }
}
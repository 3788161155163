import React from 'react';

/** Redux **/
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {setUsers} from '../../../redux/actions';

/** Kendo **/
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import CommandCell from './CommandCell';
import StatusCell from './StatusCell';

import usersListener from '../../../feathers/usersListener';

/** Style **/
import BEMHelper from 'react-bem-helper';
import UserModal from './UserModal';
const classes = new BEMHelper('Users');

class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            editID: null,
            dialogType: '',
            dialogDataItem: {}
        }

        this.CommandCell = CommandCell({
            onEdit: this.onEdit,
            onRemove: this.onRemove
        })
    }

    componentDidMount() {
        usersListener();
        this.props.setUsers();
    }

    onEdit = (dataItem) => {
        this.setState({
            dialogType: 'update',
            dialogDataItem: {...dataItem}
        })
    }

    onRemove = (dataItem) => {

    }

    openCreateDialog = () => {
        this.setState({
            dialogType: 'create',
            dialogDataItem: {
                email: '',
                firstname: '',
                lastname: '',
                qsUsername: '',
                qsPassword: '',
                qsWmid: '',
                desk: ''
            }
        });
    }

    closeDialog = () => {
        this.setState({
            dialogType: '',
            dialogDataItem: {},
        });
    }

    render() {
        const {
            allIds = [],
            byId = {}
        } = this.props;

        return (
            <div>
                {
                    this.state.dialogType !== '' && <UserModal
                        dialogType = {this.state.dialogType}
                        dialogDataItem = {this.state.dialogDataItem}
                        closeDialog = {this.closeDialog}
                    />
                }
                <Grid
                    style={{ height: '100%' }}
                    data={allIds.map((id) => byId[id])}
                >
                    <GridToolbar>
                        <button title="Add new" className="k-button k-primary" onClick={this.openCreateDialog} >
                            Create New User
                        </button>
                    </GridToolbar>
                    <Column field="id" title="ID" width="50px" editable={false} />
                    <Column cell = {StatusCell} width="100px" field="isOnline" title = "Status" editable={false} />
                    <Column field="firstname" title="First" />
                    <Column field="lastname" title="Last" />
                    {/*<Column field="username" title="Username" />*/}
                    <Column field="email" title="Email" width = '300px'/>
                    <Column field = 'qsUsername' title = 'QS Username' />
                    <Column field = 'qsPassword' title = 'QS Password' />
                    <Column field = 'qsWmid' title = 'QS WMID' />
                    <Column field="desk" title="Desk" />
                    <Column field= 'privilege' title = "Entitlement" />
                    <Column cell = {this.CommandCell} width = '100px'/>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        byId: state.usersReducer.byId,
        allIds: state.usersReducer.allIds
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
       setUsers
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps) (Users);
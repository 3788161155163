export default function (str, map) {
    let direction = '';
    let size;

    /** Look for OrgCode - Newly added 07/19/2020 **/
    let orgCode_r = /[A-Z]{2,}/;
    let orgCode = orgCode_r.exec(str);
    if (orgCode) map.orgCode = [orgCode[0]];

    if (new RegExp(/bght|bought|buy|bid/).test(str)) {
        direction = "buyer"
    } else if (new RegExp(/off|sld|sold|sell|ask/).test(str)) {
        direction = "seller"
    }
    map["direction"] = [direction]

    let size_r = /(sld|sold|sell|ask|bght|bought|buy|trade|bid|off|rev)[a-z\s]*([\d\.xk,]+)\b/ //added |bid|off|rev and \b for revcon bid/off to rev
    size = size_r.exec(str)

    if (!size){
        size_r = /([\d\.k]+)x\b/ //BIDU April 100 puts vs. 115.65 on an 18^, 2.37 bid 600x (when size ends in "x")
        size = size_r.exec(str)
    }

    let price;
    if (size) {
        map["size"] = [size[size.length - 1]]
    } else {
        //add stuff here to look for a size containing x or k
        // 12/22: if size is by itself (eg 500 @ 11.20 and bid to reverse 3k)
        size_r = /\b(?!\.)([\dxk,]+)[\s]+@/  // 05/30/2020 removed negative lookahead (?!) to ensure that the size is not a decimal eg 3.2 @ 1.5
        size = size_r.exec(str)
        if(size) map["size"] = size[size.length-1]

        // Cleanup 12/22: addressing no direction specified but size or price specified
        // eg NVDA Feb  210 Put vs. 215.5 (40d) 500 @ 11.20
        if(size && !direction){
            direction = "seller"
            map["direction"] = "seller"
        }
    }

    if (new RegExp(/bid|ask|off/).test(str)) {
        let price_r = /([\d\.]+)[\s]+(bid|ask|off)/
        price = price_r.exec(str)
        if (price) {
            if (direction === "buyer")
                map["bid"] = [price[1]]
            else
                map["ask"] = [price[1]]
        }
    } else {
        let price_r = /\b([\d]+\.[\d]+)[\s]*@[\s]*([\d]+\.[\d]+)\b/ // if there is bid/ask separated by @ eg MCK Mar 200 line 1.20 @ 1.30
        price = price_r.exec(str)
        if (price) {
            map["bid"] = [price[1]]
            map["ask"] = [price[2]]
        } else {
            price_r = /(at|for|paid|@)([\s]*[\d\.]+)/
            price = price_r.exec(str)
            if (price) {
                if (direction === "buyer") {
                    map["bid"] = [price[price.length - 1]]
                }
                else
                    map["ask"] = [price[price.length - 1]]
            }
        }
    }

    // Clean up size (remove k)
    if (map.size && map.size[0].includes('k')) {
        const size_with_k = map.size[0];

        const decimal_index = size_with_k.indexOf('.');

        let new_size = parseInt(size_with_k.slice(0, decimal_index!=-1 ? decimal_index : size_with_k.length))*1000;
        if (decimal_index !== -1) {
            let after_decimal = size_with_k.slice(decimal_index + 1, size_with_k.length - 1);
            if (after_decimal != null) {
                console.log(after_decimal)
                console.log(after_decimal.length)
                new_size += Math.floor(parseInt(1000*after_decimal)/(10**after_decimal.length));
            }
        }

        map.size = [new_size]
    }

    return map;
}
import React from 'react';
import {connect} from 'react-redux';
import {selectAllUsersByRoomId} from '../../../../redux/selectors';

/** Components **/
import OnlineDot from '../../Dialog/OnlineDot';
const ChatName = ({roomUsers}) => {
    return <div>
        {
            roomUsers.map((user, ix) => {
                return <div key = {ix}>
                    <OnlineDot
                        isOnline = {user.isOnline}
                    />
                    &nbsp;
                    {user.lastname}
                </div>
            })
        }
    </div>
}

const mapStateToProps = (state, props) => {
    return {
        roomUsers: selectAllUsersByRoomId(state, props.roomId)
    }
}

export default connect(mapStateToProps, null)(ChatName);
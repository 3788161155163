import React, {useEffect} from 'react';

/** Helpers **/
import {ExtractNLP} from '../../../../helpers';

/** Constants **/
import {
    INPUT_MAP,
    ALL_CAPS_FIELDS,
} from '../../../../helpers/constants';

import {
    structureList
} from '../../../../helpers/data';

/** Components **/
import {Input} from '@progress/kendo-react-inputs';
import {AutoComplete, MultiSelect} from '@progress/kendo-react-dropdowns';

/** Redux **/
import {connect} from 'react-redux';

/** Style **/
import './Preview.scss';
import BEMHelper from 'react-bem-helper';
import UnderlyingComboBox from '../../../../utilities/UnderlyingComboBox/UnderlyingComboBox';
const classes = new BEMHelper('Preview');

const onDialogInputChange = (event, itemInEdit) => {
    const edited = {...itemInEdit};

    let target = event.target;
    const name = target.props ? target.props.name : target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    if(name === "ask" || name === "bid"){
        if (!/[a-zA-Z]+/.test(value) && (value.match(/\./g) || []).length<=1) {
            if (String(value) === value && value.trim() == "") {
                value = null;
            }
            edited[name] = value;
        }
    } else {
        if (ALL_CAPS_FIELDS.includes(name)) {
            if(value!==null) value = value.toUpperCase();
        }

        if (String(value) === value && value.trim() == "") {
            value = null;
            if (name === "size") {
                edited["asize"] = null;
                edited["bsize"] = null;
            }
        }
        edited[name] = value;
    }

    return edited;
}

const OrderPreview = (props) => {
    const {
        aivaPortal,
        itemInEdit,
        setItemInEdit,

        chat,
        previewBEM
    } = props;

    useEffect(() => {
        // capture group only what is between 'rfq' and an optional newline
        const RFQ_REGEX = new RegExp("[rR][fF][qQ][: ]*(.*)[\\r\\n|\\r|\\n]?")
        let regex_result = RFQ_REGEX.exec(chat);
        const nlp_string = regex_result != null ? regex_result[1] : ''
        const nlp_map = ExtractNLP(nlp_string);
        setItemInEdit(nlp_map);
    }, [chat])

    const onInputChange = (event) => {
        setItemInEdit(onDialogInputChange(event, itemInEdit));
    }

    const handleTickerChange = ({id: underlyingId, ticker}) => {
        /** Event target comes in like {ticker: 'VIX', id: '5'} **/
        setItemInEdit({
            ...itemInEdit,
            underlyingId,
            ticker
        })
    }
    return <div {...classes('', previewBEM)}>
        <div {...classes('body')}>
            <table>
            <tbody>
                <tr {...classes('tr')}>
                    <td {...classes('td_title')}>
                        Ticker
                    </td>
                    <td>
                        <UnderlyingComboBox
                            // value={this.state.itemInEdit.ticker || ''}
                            onChange = {handleTickerChange}
                            ticker = {itemInEdit.ticker}
                            underlyingId = {itemInEdit.underlyingId}
                            portal = {aivaPortal}
                        />
                    </td>
                </tr>
                {Object.keys(INPUT_MAP).map((key, i) => {
                    return (
                        <tr {...classes('tr')}
                            key={i}>
                            <td {...classes('td_title')}>
                                {INPUT_MAP[key]}
                            </td>
                            <td>
                                {
                                    key === 'structure'
                                        ? <AutoComplete
                                            name="structure"
                                            value={itemInEdit.structure || ''}
                                            data={structureList}
                                            onChange={onInputChange}
                                            suggest={true}
                                            allowCustom={false}
                                            popupSettings={{
                                                "animate": false,
                                                "appendTo": aivaPortal.document.body
                                            }}
                                        />
                                        : <Input
                                            type="text"
                                            name={key}
                                            value={itemInEdit[key] || ''}
                                            onChange={onInputChange}
                                            autoComplete="off"
                                        />
                                }
                            </td>
                        </tr>
                    );
                })}
                {/*<tr>*/}
                {/*    <td> Org (Bid) </td>*/}
                {/*    <td>*/}
                {/*        <AutoComplete*/}
                {/*            style = {{width: "200px"}}*/}
                {/*            name="orgCode"*/}
                {/*            value={itemInEdit.orgCode || ''}*/}
                {/*            data={this.orgCodeList}*/}
                {/*            suggest={true}*/}
                {/*            onChange={onDialogInputChange}*/}
                {/*            onKeyPress={this.handleKeyPress}*/}

                {/*            suggest={true}*/}
                {/*            // clearButton = {false}*/}
                {/*            // suggest={true}*/}
                {/*            // allowCustom={true}*/}
                {/*        />*/}
                {/*    </td>*/}
                {/*</tr>*/}

                {/*<tr>*/}
                {/*    <td> Org (Ask) </td>*/}
                {/*    <td>*/}
                {/*        <AutoComplete*/}
                {/*            style = {{width: "200px"}}*/}
                {/*            name="aOrgCode"*/}
                {/*            placeholder={"Blank if same as buyer"}*/}
                {/*            value={itemInEdit.aOrgCode || ''}*/}
                {/*            data={this.orgCodeList}*/}
                {/*            onChange={this.onDialogInputChange}*/}
                {/*            onKeyPress={this.handleKeyPress}*/}

                {/*            suggest={true}*/}
                {/*            // clearButton = {false}*/}
                {/*            // suggest={true}*/}
                {/*            // allowCustom={true}*/}
                {/*        />*/}
                {/*    </td>*/}
                {/*</tr>*/}

                </tbody>
            </table>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return {
        aivaPortal: state.aivaReducer.aivaPortal
    }
}
export default connect(mapStateToProps, null) (OrderPreview);
import React from 'react';

/** Kendo **/
import { Dialog } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import Button from '@progress/kendo-react-buttons/dist/es/Button';

/** Feathers **/
import {usersService} from '../../../../feathers';

/** Constants **/
import {USER_INPUT_MAP as INPUT_MAP} from '../../../../helpers/constants';

/** Style **/
import './UserModal.scss';
import BEMHelper from 'react-bem-helper';
import {DropDownList} from '@progress/kendo-react-dropdowns';
const classes = new BEMHelper('UserModal')

export default class UserModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataItem: {...props.dialogDataItem},
            newPassword: ''
        }
    }

    handleCreateUser = (event) => {
        event.preventDefault();
        const {dataItem} = this.state;
        usersService.create(dataItem)
            .then(res => {
                window.alert('Successfully created user');
            })
            .catch(err => {
                console.log(err)
                window.alert("Error creating user")
            })
            .finally(() => {
                    this.props.closeDialog();
                })
    }

    handleUpdateUser = (event) => {
        event.preventDefault();
        const {dataItem} = this.state;
        const {id} = dataItem;

        const patchDataItem = {
            email: dataItem.email,
            firstname: dataItem.firstname,
            lastname: dataItem.lastname,
            qsPassword: dataItem.qsPassword,
            qsUsername: dataItem.qsUsername,
            qsWmid: dataItem.qsWmid,
            desk: dataItem.desk,
            privilege: dataItem.privilege,
        }

        usersService.patch(id, patchDataItem)
            .then(res => {
                console.log(res)
                window.alert('User Update Success');
            })
            .catch(err => {
                console.error(err)
                window.alert('Error updating user.');
            })
            .finally(() => {
                this.props.closeDialog();
            })
    }

    handleResetPassword = (event) => {
        event.preventDefault();
        const {newPassword, dataItem} = this.state;
        usersService.patch(dataItem.id, {password: newPassword})
            .then(res => {
                window.alert('Reset Password Success!');
            })
            .catch(err => {
                window.alert('Error resetting password.');
            })
            .finally(() => {
                this.props.closeDialog();
            })
    }

    handleInputChange = (event) => {
        this.setState({
            dataItem: {...this.state.dataItem, [event.target.name]: event.target.value}
        })
    }

    handlePasswordInputChange = (event) => {
        this.setState({
            newPassword: event.target.value
        })
    }

    render() {
        const {dataItem} = this.state;

        const isCreateMode = this.props.dialogType === 'create';

        return (
            <Dialog title={isCreateMode ? 'Create User' : 'Update User'} onClose={this.props.closeDialog}>
                {!isCreateMode && <form
                    onSubmit={this.handleResetPassword}
                    {...classes('form', 'password')}
                >
                    <Input
                        required
                        label = {'New Password'}
                        minLength={8}
                        value = {this.state.newPassword}
                        onChange = {this.handlePasswordInputChange}
                        type={'password'}
                        pattern = {INPUT_MAP.password.pattern}
                        placeholder = {INPUT_MAP.password.placeholder}
                    />
                    <Button className="mt-3" type="submit" primary={true}> Reset Password </Button>
                </form>}

                <form
                    onSubmit={(event) => {isCreateMode ? this.handleCreateUser(event) : this.handleUpdateUser(event)}}
                    {...classes('form')}
                    autocomplete="off"
                >
                    {
                        Object.keys(INPUT_MAP).map(name => {
                            if(name === 'password') {
                                if (!isCreateMode) {
                                    return <></>
                                } else {
                                    return <Input
                                        key = {name}
                                        label = {INPUT_MAP[name].label}
                                        name = {name}
                                        value = {dataItem[name]}
                                        onChange = {this.handleInputChange}
                                        type={'password'}
                                        pattern = {INPUT_MAP.password.pattern}
                                        placeholder = {INPUT_MAP.password.placeholder}
                                        required
                                        minLength={8}
                                    />
                                }
                            }
                            return <Input
                                key = {name}
                                name = {name}
                                value = {dataItem[name]}
                                label = {INPUT_MAP[name].label}
                                onChange = {this.handleInputChange}
                                required = {INPUT_MAP[name].required}
                                pattern = {INPUT_MAP[name].pattern}
                            />
                        })
                    }

                    <DropDownList
                        name = {'desk'}
                        value = {dataItem['desk']}
                        label = {'Desk'}
                        onChange = {this.handleInputChange}
                        required = {true}
                        data = {['OPTIONS', 'BACK OFFICE']}
                    />
                    <DropDownList
                        name = {'privilege'}
                        value = {dataItem['privilege']}
                        label = {'Entitlement'}
                        onChange = {this.handleInputChange}
                        required = {true}
                        data = {['ADMIN', 'USER', 'DISABLED']}
                    />
                    <Button className="mt-3" type="submit" primary={true}> {isCreateMode ? 'Create User' : 'Update User'} </Button>
                </form>
            </Dialog>
        );
    }
}
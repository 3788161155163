import {
    CREATE_WINDOW
} from '../types';

export default function addWindow (window) {
    return async dispatch => {
        dispatch({
            type: CREATE_WINDOW,
            window
        })
    }
}
import { ordersService } from './index';
import store from '../redux/store';

import {
    handleOrderCreated,
    handleOrderUpdated,
} from '../redux/actions';

import {
    handleWbOrderCreated,
    handleWbOrderUpdated
} from '../redux/actions/wbOrders';

export default function () {
    ordersService.on('created', obj => {
        store.dispatch(handleWbOrderCreated(obj));
        store.dispatch(handleOrderCreated(obj));
    });

    ordersService.on('updated', obj => {
        store.dispatch(handleWbOrderUpdated(obj));
        store.dispatch(handleOrderUpdated(obj));
    });

    ordersService.on('patched', obj => {
        store.dispatch(handleWbOrderUpdated(obj));
        store.dispatch(handleOrderUpdated(obj));
    });

    // ordersService.on('removed', obj => {
    //     store.dispatch(handleOrderRemoved(obj));
    // })
}
import {
    HANDLE_LOGOUT,
    SET_AUTH,
    PATCH_AUTH,
    SET_QS_SID
} from '../actions/types';

let defaultState = {
    user: {
        tabs: [],
        tabKey: '0'
    },
    isLoggedIn: false,
    qsSid: null
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case SET_AUTH:
            console.log('User logged in');
            console.log(action.user);
            return {
                ...state,
                user: {
                    ...action.user,
                    tabs: (action.user.tabs!=null ? (JSON.parse(action.user.tabs)) : [])
                },
                isLoggedIn: true
            }
        case PATCH_AUTH:
            return {
                ...state,
                user: {
                    ...action.user,
                    tabs: JSON.parse(action.user.tabs)
                }
            }
        case HANDLE_LOGOUT:
            return {
                ...state,
                user: null,
                isLoggedIn: false,
            }
        case SET_QS_SID:
            return {
                ...state,
                qsSid: action.qsSid
            }
        default:
            return state;
    }
}
import moment from 'moment';
import FormatFloat from './FormatFloat';

/**
 * Decodes a symbol
 * @param symbol the option string to be decoded
 * @returns a hash containing the option expiry mm, dd, yy, strike, etc
 */
export default function DecodeSymbol (symbol) {
    const expirationMoment = moment(symbol.substring(7, 13), "YYMMDD");
    return {
        expirationMoment,
        expiry: expirationMoment.format("DD MMM YY"),
        leg: symbol.substring(13, 14),
        strike: FormatFloat(parseFloat((symbol.substring(14,19) + '.' + symbol.substring(19)))),
    }
}
import {messagesService} from '../../../feathers';

export default function createMessage (message){
    return async (dispatch) => {
        messagesService.create(message)
            .catch(e => {
            console.log('Error creating message')
            console.log(e);
        });
    }
}
import {messagesService} from '../../../feathers'
import {SET_MESSAGES} from '../types';
import {GetQueryByTopDay} from '../../../helpers';

export default function (queryParams = GetQueryByTopDay){
    return async (dispatch) => {
        messagesService
            .find({
                query: {...queryParams}
            })
            .then(res => {
                dispatch({
                    type: SET_MESSAGES,
                    messages: res
                });
            }).catch(error => {
                console.log('Error Setting Messages');
                console.log(error);
            });
    }
}
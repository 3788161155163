import React, {useState, useRef, useEffect} from 'react';

/** Actions **/
import {createOrderAndBids} from '../../../../redux/actions';

/** Components **/
import OrderPreview from '../OrderPreview';
import BidPreview from '../BidPreview';
import ResizeObserver from 'react-resize-observer';

/** Style **/
import './ChatBox.scss';
import BEMHelper from 'react-bem-helper';
import createBidFromNlpMap from '../../../../redux/actions/bids/createBidFromNlpMap';
let classes = new BEMHelper('ChatBox');

/**
 *
 * @param roomId
 * @param createFeathersChat redux prop to create the message/reply
 * @param chatBoxType can be either 'message' or 'reply'
 * @param orderId (optional) only required if chatBoxType is a reply
 */
const ChatBox = ({roomId, createFeathersChat, chatBoxType, orderId, onClick}) => {
    const [chat, setChat] = useState('');
    const [isRfq, setIsRfq] = useState(false);
    const [previewBEM, setPreviewBEM] = useState('default');
    const [itemInEdit, setItemInEdit] = useState({});

    const textAreaRef = useRef({});

    const handleResize = (rect) => {
        if(rect.width < 350) {
            setPreviewBEM('compact')
        } else {
            setPreviewBEM('default')
        }
    }

    const handleType = (event) => {
        const newChat = event.target.value;
        setChat( newChat );

        // if there is rfq, set blurb open
        if (newChat.toLowerCase().includes('rfq')) {
            if (!isRfq) {
                setIsRfq(true);
            }
        } else if (isRfq) {
            setIsRfq(false);
        }
    }

    const handleEnter = (e) => {
        if(e.keyCode === 13 && e.shiftKey === false && (chat.trim())!=='') {
            e.preventDefault();
            sendMessage();
            setChat('');
            setIsRfq(false);
        }
    }

    const sendMessage = () => {
        if (isRfq) {
            if (chatBoxType === 'message') {
                createOrderAndBids(itemInEdit, chat, roomId);
            } else if (chatBoxType === 'reply') {
                createBidFromNlpMap({...itemInEdit, orderId}, chat, roomId);
            }
        } else {
            createFeathersChat({body: chat, roomId});
        }
    }

    return <div
            onClick = {onClick}
            {...classes()}
        >
        {
            (chatBoxType === 'message' && isRfq) && <OrderPreview
                chat = {chat}
                itemInEdit = {itemInEdit}
                setItemInEdit = {setItemInEdit}
                previewBEM = {previewBEM}
            />
        }

        {
            (chatBoxType === 'reply' && isRfq) && <BidPreview
                chat = {chat}
                itemInEdit = {itemInEdit}
                setItemInEdit = {setItemInEdit}
                previewBEM = {previewBEM}
            />
        }

        <textarea
            ref={textAreaRef}
            {...classes('textarea')}
            rows={4}
            placeholder={
                chatBoxType === 'reply'
                    ? 'Example: rfq BAML 34.52 bid 3.5k'
                    : 'Example: rfq AMD Jul 50/55 Strangle live 1k @ 2.29'
            }
            value = {chat}
            onChange = {handleType}
            onKeyDown = {handleEnter}
        />

        <ResizeObserver onResize={handleResize} />
    </div>

}

export default ChatBox;
import React from 'react';
import BEMHelper from 'react-bem-helper';
const classes = BEMHelper('RevconDisplay');

/** Styling is in OrderCell.scss **/
const RevconDisplay = ({order = {}}) => {
    const {
        ticker,
        expiration,
        strike,
        structName,
        over
    } = order;

    return <div {...classes()}>
        <div {...classes('ticker')}>
            {ticker}
        </div>
        <div>
            <span {...classes('primary')}>
                {(expiration ? expiration : '?') + ' '
                + (strike ? strike : '?') + ' '
                + (structName ? structName : '?') + ' '}
            </span>
            <span {...classes('secondary')}>
                +{over}
            </span>
        </div>
    </div>
}

export default RevconDisplay;
import {createCachedSelector} from 're-reselect';
import {DecodeSymbol} from '../../../helpers';
import {LEG_TEMPLATES} from '../../../helpers/data';

/** Generates array of objects containing quotestream data for each leg **/
const selectLegsSummaryByOrder = createCachedSelector(
    (_state_, order) => order,
    state => state.symbolsReducer.orderIdToSymbols,
    state => state.symbolsReducer.symbolToQuote,
    state => state.symbolsReducer.symbolToIVGreeks,
    ( order, orderIdToSymbols, symbolToQuote, symbolToIVGreeks ) => {
        if (order==null) return [];
        const order_id = order.id;
        const flipBuySell = order.flipBuySell;
        const symbols = orderIdToSymbols[order_id];
        if (symbols == null) return [];
        let quotes = [];

        const {
            structName: structure,
            ratio: risk_rwd_ratio
        } = order;

        const TEMPLATE_LEGS = LEG_TEMPLATES[structure] ? LEG_TEMPLATES[structure].legs : [];
        const ratio_list = risk_rwd_ratio ? risk_rwd_ratio.toLowerCase().split('x') : [];
        let strikes_set = new Set();
        let expiries_set = new Set();

        symbols.slice(0, symbols.length - 1).forEach((symbol, ix) => {
            const quote = symbolToQuote[symbol];
            const greeks = symbolToIVGreeks[symbol];

            try {
                const defaultBuyerBuys = TEMPLATE_LEGS[ix<TEMPLATE_LEGS.length ? ix: 0].buyerBuys;
                let legSummary = {
                    ...DecodeSymbol(symbol),
                    buyerBuys: flipBuySell ? (defaultBuyerBuys ? 0 : 1) : defaultBuyerBuys, // sometimes when you change order from call spread to call/put this will throw an error
                    ratio: ratio_list.length > ix
                        ? ratio_list[ix]
                        : (TEMPLATE_LEGS[ix] && TEMPLATE_LEGS[ix].ratio
                           ? TEMPLATE_LEGS[ix].ratio
                           : 1
                        ) // default ratio is 1 if no ratio is specified by user or LEG_TEMPLATES
                }

                expiries_set.add(legSummary.expiry);
                strikes_set.add(legSummary.strike);

                if(greeks && quote) {
                    legSummary = {
                        symbol: symbol,
                        ...legSummary,
                        ...greeks,
                        ...quote
                    };
                }
                quotes.push(legSummary);
            } catch (e) {
                console.error(e)
                console.error(symbols)
                console.error(TEMPLATE_LEGS)
            }
        });

        /** Always default put spread to buy high sell lower strike **/
        /** Added before production release **/
        if (strikes_set.size === 2 && expiries_set.size === 1) {
            if (structure === 'PUT SPREAD') {
                if (parseFloat(quotes[0].strike) > parseFloat(quotes[1].strike)) {
                    quotes[0].buyerBuys = flipBuySell ? 0 : 1;
                    quotes[1].buyerBuys = flipBuySell ? 1 : 0;
                } else {
                    quotes[0].buyerBuys = flipBuySell ? 1 : 0;
                    quotes[1].buyerBuys = flipBuySell ? 0 : 1;
                }
            } else if (structure === 'CALL SPREAD') {
                if (parseFloat(quotes[0].strike) < parseFloat(quotes[1].strike)) {
                    quotes[0].buyerBuys = flipBuySell ? 0 : 1;
                    quotes[1].buyerBuys = flipBuySell ? 1 : 0;
                } else {
                    quotes[0].buyerBuys = flipBuySell ? 1 : 0;
                    quotes[1].buyerBuys = flipBuySell ? 0 : 1;
                }
            }
        }

        return quotes;
    }
)((_state_, order) => order!=null ? order.id : -1);

export default selectLegsSummaryByOrder;
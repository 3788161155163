import React from 'react';

/** Redux **/
import {selectUnreadCountByRoomId} from '../../../../redux/selectors';
import {connect} from 'react-redux';

/** Style **/
import './RoomListNotification.scss';
import BEMHelper from 'react-bem-helper';
const classes = new BEMHelper('RoomListNotification');

const RoomListNotification = ({unreadCount}) => {
    return <>
        {(unreadCount!=null && unreadCount!==0) && <div {...classes()}>{unreadCount}</div>}
    </>
}

const mapStateToProps = (state, props) => {
    return {
        unreadCount: selectUnreadCountByRoomId(state, props.roomId)
    }
}

export default connect (mapStateToProps, null) (RoomListNotification);
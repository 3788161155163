export default function (num, field = '') {
    if(num == null) {
        return '';
    }

    num = num.toString().replace(/\,/g, '');

    const containsDecimal = num.includes(".") || field.includes("strike") || field.includes("rice")  || field.includes("rokerage")

    return parseFloat(num).toLocaleString(
        'en-US',
        {
            minimumFractionDigits: containsDecimal ? 2 : 0,
            maximumFractionDigits: containsDecimal ? 5 : 0
        },
    )
}
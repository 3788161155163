export default function (num) {
    if(num == null) {
        return '';
    }

    num = num.toString().replace(/\,/g, '');

    return parseFloat(num).toLocaleString(
        'en-US',
        {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        },
    )
}
import { editorsService } from './index';
import store from '../redux/store';

import {
    handleEditorCreated,
    handleEditorRemoved
} from '../redux/actions';

export default function () {
    editorsService.on('created', obj => {
        store.dispatch(handleEditorCreated(obj));
    })

    editorsService.on('removed', obj => {
        store.dispatch(handleEditorRemoved(obj.id));
    })
}
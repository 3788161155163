import ValidateTrade from '../../../helpers/ValidateTrade';
import {sendEmails} from '../index';

export default function handleSendAllEmails (trade) {
    return async dispatch => {
        const validation = ValidateTrade(trade);
        if (!validation.status) {
            window.alert('Trade is incomplete.')
        } else {
            dispatch(sendEmails(getEmailList('buy', trade),  trade.id, 'buy' ));
            dispatch(sendEmails(getEmailList('sell', trade), trade.id, 'sell'));
        }
    }

    function getEmailList (dir, trade) {
        const emails = trade[dir + 'Emails'];
        let emailList = [];
        emails.forEach(obj => {
            emailList.push(obj.email);
        });
        return emailList;
    }
}
import React from 'react';

/** Redux **/
import {connect} from 'react-redux';
import {selectNBBO} from '../../../redux/selectors';

/** Style **/
import BEMHelper from 'react-bem-helper';
/** All styling is in market cell **/
const classes = new BEMHelper('ScreensCell');
const best_bem = new BEMHelper('Best');

const ScreensCell = ({nbbo = {}}) => {
    const {
        deltaAdjusted,
        ntlBestBid,
        ntlBestOffer,
        ntlBestBidSize,
        ntlBestOfferSize
    } = nbbo;

    const screen_modifier = deltaAdjusted ? 'screen--gold' : '';

    return <>
        {
            (ntlBestBid && ntlBestOffer) && <div {...classes('', deltaAdjusted ? 'gold' : '')}>
                <div {...best_bem('', 'bid_screen')}>
                    <div {...best_bem('price', screen_modifier)}> {ntlBestBid!=='NaN' && ntlBestBid!=='0' ? ntlBestBid : ''} </div>
                    <div {...best_bem('size', 'screen')}> {ntlBestBidSize!=='NaN' && ntlBestBidSize !== '∞' ? ntlBestBidSize : ''} </div>
                </div>
                <div {...best_bem()}>
                    <div {...best_bem('price', screen_modifier)}> {ntlBestOffer!=='NaN' && ntlBestOffer!=='0' ? ntlBestOffer : ''} </div>
                    <div {...best_bem('size', 'screen')}> {ntlBestOfferSize!=='NaN' && ntlBestOfferSize !== '∞' ? ntlBestOfferSize : ''} </div>
                </div>
            </div>
        }
        </>
}

const mapStateToProps = (state, props) => {
    return {
        nbbo: selectNBBO(state, props.dataItem)
    }
}

export default connect(mapStateToProps, null) (ScreensCell);
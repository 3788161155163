import {
    REMOVE_BID,
} from '../types';

export default function handleBidRemoved (obj) {
    return function (dispatch) {
        dispatch({
            type: REMOVE_BID,
            bid: obj
        });
    }
}
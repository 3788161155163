import {
    roomUsersService
} from '../../../feathers';

/**
 * NOT A REDUX FUNCTION
 * Used by RoomModal to add a list of users to a room
 * @param roomId the id of the Room to add the users to
 * @param userIdList the list of user id's to join the room
 */
export default function handleJoinRoom (roomId, userIdList) {
    if (userIdList != null && roomId != null) {
        userIdList.forEach(userId => {
            roomUsersService.create({
                roomId,
                userId
            });
        });
    }
}
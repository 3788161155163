import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import Button from 'react-bootstrap/Button';

export default function CommandCell({ onEdit, onRemove }) {
    return class extends GridCell {
        render() {
            const { dataItem } = this.props;

            return <td className="k-command-cell">
                    <Button
                        className="k-button k-grid-save-command"
                        onClick={() => onEdit(dataItem)}
                    >
                        Edit
                    </Button>
                </td>
        }
    }
}


import {
    SET_REPLIES,
    CREATE_REPLY,
} from '../actions/types';
import {combineReducers} from 'redux';
import produce from "immer"
import GetByIdWithRelated from '../../helpers/GetByIdWithRelated';

const byId = produce((draft, action) => {
    switch(action.type) {
        case SET_REPLIES: // takes in raw list of reply entities eg [{id: 1}]
            return GetByIdWithRelated(action.replies, []);
        case CREATE_REPLY:
            draft[action.reply.id] = action.reply;
            return;
    }
}, {});

const allIds = produce((draft, action) => {
    switch (action.type) {
        case SET_REPLIES:
            return action.replies.map(reply => reply.id);
        case CREATE_REPLY:
            draft.push(action.reply.id);
            return;
    }
}, []);

const RepliesReducer = combineReducers({
    byId: byId,
    allIds: allIds
})

export default RepliesReducer;
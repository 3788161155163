import {createTicket} from '../index';
import {tradesService} from '../../../feathers';
import _ from 'lodash';

export default function duplicateTrade (t) {
    return async dispatch => {
        const trade = _.cloneDeep(t);

        delete trade.id;

        trade['sellEmails'].forEach(email=>{email.status = 0;});
        trade.sellEmails = JSON.stringify(trade.sellEmails);

        trade['buyEmails'].forEach(email=>{email.status = 0;});
        trade.buyEmails = JSON.stringify(trade.buyEmails);

        trade.legs = JSON.stringify(trade.legs);

        delete trade.createdAt;
        delete trade.createdBy;
        delete trade.updatedBy;
        delete trade.updatedBy;
        delete trade.buyOrganization;
        delete trade.sellOrganization;
        delete trade.buyEntity;
        delete trade.sellEntity;
        delete trade.buyClient;
        delete trade.sellClient;
        delete trade.buyUser;
        delete trade.sellUser;

        tradesService.create(trade).then(obj=> {
            dispatch(createTicket(obj.id));
        });
    }
}
import React from 'react';
import {Button, ButtonGroup, Toolbar, ToolbarItem, ToolbarSeparator} from '@progress/kendo-react-buttons';
import {AutoComplete, ComboBox} from '@progress/kendo-react-dropdowns';
import {Input, Checkbox} from "@progress/kendo-react-inputs";
import { Popup } from '@progress/kendo-react-popup'

import _ from 'lodash';
import client from "../../feathers";

import Hotkeys from 'react-hot-keys';

var Scroll = require('react-scroll');
var scroll = Scroll.animateScroll;

const inputMap = {
    "first_name": "First Name",
    "last_name": "Last Name",
    "code":"Org Code"
}

const entityMap = {
    "email_address": "Email"
}

export default class ClientsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            personsDropDown: [],


            orgItemAdded: true,

            orgItemInEdit: {},
            prevOrgItem: {},
            orgItemChanged: false,

            entItems: {},
            prevEntItems: {},
            entIdsInEdit: new Set(),
            entItemChanged: false,

            newEntItems: [],
            entIndexToSelect: -1,

            utpItems: {},
            prevUtpItems: {},
            utpIdsInEdit: new Set(),
            utpItemChanged: false,
            newUtpItems: [],
        };
    }

    componentDidMount() {
        this.getUnderlyingsDropDown() //gets the dropdown list for all the utps
        client.service('underlyings').on('created',obj=>{
            const underlyingsDropDown = this.state.underlyingsDropDown.slice()
            underlyingsDropDown.push(underlyingsDropDown)
            this.setState(underlyingsDropDown)
        });
        client.service('underlyings').on('updated',obj=>{
            const underlyingsDropDown = this.state.underlyingsDropDown.slice()
            for (let i = 0; i < this.state.underlyingsDropDown.length; i++){
                if (this.state.underlyingsDropDown.id === obj.id ){
                    underlyingsDropDown[i] = obj;
                    break;
                }
            }
            this.setState(underlyingsDropDown)
        });
    }

    /** Combo Box **/


    handleOrgSelected =(org_item) => {
        this.setState({
            entItems: {},
            utpItems: {},
            orgItemAdded: true,
            orgItemInEdit:{id:null},
            orgComboItemInEdit:{},

            selectedPnObj: {},
        }); //clears the current person item (in this case person)
        if(!org_item) {
            this.setState({
                selectedOrgId: undefined,
                personsDropDown: [],
            })
            return
        }

        let org_id = parseInt(org_item["id"])
        this.setState({
            selectedOrgId: org_id
        })

        this.getPnDropDown(org_id);
    }

    getPnDropDown = (org_id) => {
        client.service('clients').find({
            query: {
                organizationId: org_id,
                $sort: {
                    last_name: 1,
                }
            }
        }).then((reqPage) => {
            console.log(reqPage)
            let personsDropDown = []
            reqPage.forEach(obj=>{
                obj["full_name"] = obj["first_name"] + " " + obj["last_name"]
                personsDropDown.push(obj)
            })
            this.setState({personsDropDown})
        })
    }

    handlePnSelected = (pn_obj) => {
        if(!pn_obj) {

            this.setState({selectedPnObj: {}});
            return;
        }

        //get organization for combobox
        this.getOrgItemFromId(pn_obj["organizationId"]);

        this.setState({
            selectedPnObj: pn_obj,
        })


        let orgId = pn_obj["id"]
        // let org_code = pn_obj["pn_code"]
        let orgItemInEdit = _.cloneDeep(pn_obj);

            this.setState({
                orgItemChanged: false,
                orgId,
                // orgCode: org_code,
                orgItemInEdit,
                prevOrgItem: _.cloneDeep(orgItemInEdit),
            })
            // query database for entities, then generate a dictionary where {ent_id: entity}

            // Now find the entities
            this.extractEntities(orgId);



        this.setState({
            orgItemAdded: false,

            entItemChanged: false,


            newEntItems: [],
        })

        // if(!this.state.orgItemAdded){  // if the user clicked the x and no organization is selcted, default to add mode
        //     this.setState({orgItemAdded: true})
        // }
    }

    getOrgItemFromId = (org_id) => {
        if(!org_id){
            this.setState({orgComboItemInEdit:{"id": null, "code": null}});
        }
        client.service('organizations').find({
            query: {
                id: parseInt(org_id)
            }
        }).then((reqPage) => {
            if(reqPage.length>0){
                console.log(reqPage[0]);
                this.setState({orgComboItemInEdit:reqPage[0]})
                // return reqPage[0];
            }
        })
    }


    /** COPY STUFF **/
    extractEntities(orgId) {
        console.log("EXTRACTING ENTITIES")

        let entItemsArr = {}
        let entItems = {}

        /** FOR EMAILS **/
        client.service('emails').find({
            query: {
                clientId: parseInt(orgId)
            }
        }).then((reqPage) => {
            const reqs = reqPage;
            entItemsArr = reqs;

            for (let i = 0; i < entItemsArr.length; i++) {
                entItems[entItemsArr[i]['id']] = entItemsArr[i]
            }

            //need to call setstate separately because feathers find takes a while
            this.setState({
                entItems,
                prevEntItems: _.cloneDeep(entItems),
            })
        });

        let utpItemsArr = {}
        let utpItems = {}

        /** FOR UTPS **/
        client.service('utps').find({
            query: {
                clientId: parseInt(orgId)
            }
        }).then((reqPage) => {
            console.group("Getting UTP data");
            console.log(reqPage);
            console.groupEnd();

            const reqs = reqPage;
            utpItemsArr = reqs;

            for (let i = 0; i < utpItemsArr.length; i++) {
                utpItems[utpItemsArr[i]['id']] = utpItemsArr[i]
            }

            //need to call setstate separately because feathers find takes a while
            this.setState({
                utpItems,
                prevUtpItems: _.cloneDeep(utpItemsArr),
            })
        });
    }

    handleOrgItemChange = (e) => {
        const orgItemInEdit = this.state.orgItemInEdit;

        if (e.target.name === "active") {
            orgItemInEdit['active'] = !orgItemInEdit['active']
        }  else if (e.target.name === "code") { //if the element is a combobox, the e.target.value is the object itself
            let orgItem = e.target.value? e.target.value : {id: null, code:null,}; // if the user clicked the close button on the combobox
            orgItemInEdit["code"] = orgItem["code"];
            orgItemInEdit["organizationId"] = orgItem["id"];

            this.getOrgItemFromId(orgItem["id"]);
        } else {
            orgItemInEdit[e.target.name] = e.target.value;
        }

        this.setState({
            orgItemInEdit: orgItemInEdit,
            orgItemChanged: true,
        })
    }

    toggleFinraCheck = () => {
        const orgItemInEdit = this.state.orgItemInEdit;
        orgItemInEdit['active'] = orgItemInEdit['active']==1 ? 0 : 1;

        this.setState({
            orgItemInEdit,
            orgItemChanged:true
        })
    }

    handleAddOrg = () => {

        this.setState({
            orgItemAdded: true,

            orgItemChanged: false,
            orgItemInEdit: {id: undefined},

            entItemChanged: false,
            entItems: {},
            entIdsInEdit: new Set(),
            newEntItems: [],

            utpItemChanged: false,
            utpItems: {},
            utpIdsInEdit: new Set(),
            newUtpItems: [],

            orgComboItemInEdit: {},
            // orgId: null, //makes sure that the wrongs stps don't pop up for person
        })

    }

    /** Entities form **/
    handleEntItemChange = (ent_id, e) => {
        /** Edit entItems to control form input **/
        const entItems = this.state.entItems;
        entItems[ent_id][e.target.name] = e.target.value

        // add edited entity ID to set
        const entIdsInEdit = this.state.entIdsInEdit;
        entIdsInEdit.add(ent_id);

        console.log(ent_id)

        this.setState({
            entItems:entItems,

            entIdsInEdit:entIdsInEdit,
            entItemChanged: true,
        })
    }

    handleAddEntity = () => {
        const newEntItems = this.state.newEntItems;

        newEntItems.push(
            {
                // "ent_code": null,
                // "ent_name": null,
                // "address": null,
                // "city": null,
                // "state": null,
                // "zip": null,
                "clientId": this.state.orgId,
            }
        );

        this.setState({
            entIndexToSelect: newEntItems.length-1,

            newEntItems,
            entItemChanged: true
        })
    }

    handleNewEntItemChange = (ent_id, e) => {
        /** Edit entItems to control form input **/
        const newEntItems = this.state.newEntItems;
        newEntItems[ent_id][e.target.name] = e.target.value


        this.setState({
            newEntItems:newEntItems,

            entItemChanged: true,
        })
    }

    /**
     * FOR UTP (Underlying To Person)
     * **/

    getUnderlyingsDropDown = () => {
        client.service('underlyings').find({
        }).then((reqPage) => {
            this.setState({underlyingsDropDown: reqPage})
        })
    }

    getTickerFromId = (under_id) => {
        for (let i = 0; i < this.state.underlyingsDropDown.length; i+=1){
            if (under_id == this.state.underlyingsDropDown[i].id){
                return this.state.underlyingsDropDown[i]
            } else {
                console.log(this.state.underlyingsDropDown[i].id)
                console.log(under_id)
                console.log()
            }
        }
        return {}
    }

    handleUtpItemChange = (utp_id, under_item) => { //utp_id refers to the index in the array, not the actual utp id
        console.group("Handling UTP Item change: utp_item")
        console.log(under_item)
        console.groupEnd()
        /** Edit entItems to control form input **/
        const utpItems = this.state.utpItems;
        // key line which sets the underlying id from the underlying item
        if(under_item === null) // if the user clicked the x on the combobox
            utpItems[utp_id]["underlyingId"] = null
        else
            utpItems[utp_id]["underlyingId"] = under_item["id"]

        // add edited entity ID to set
        const utpIdsInEdit = this.state.utpIdsInEdit;
        utpIdsInEdit.add(utp_id);

        this.setState({
            utpItems,
            utpIdsInEdit,

            utpItemChanged: true,
        })
    }

    handleAddUtp = () => {
        const newUtpItems = this.state.newUtpItems;

        newUtpItems.push(
            {
                "clientId": this.state.orgId,
            }
        );

        this.setState({
            newUtpItems,
            utpItemChanged: true
        })
    }

    handleNewUtpItemChange = (utp_id, under_item) => { //utp_id refers to the index in the array, not the actual utp id
        /** Edit entItems to control form input **/
        const newUtpItems = this.state.newUtpItems;

        // key line which sets the underlying id from the underlying item
        if(under_item === null) // if the user clicked the x on the combobox
            newUtpItems[utp_id]["underlyingId"] = null
        else
            newUtpItems[utp_id]["underlyingId"] = under_item["id"]

        this.setState({
            newUtpItems,
            utpItemChanged: true,
        })
    }


    /** Saving changes made to organizations or entities **/
    saveChanges = () => {

        let error = false;


        if(this.state.orgItemAdded){
            client.service('clients').find({
                query:{
                    first_name: this.state.orgItemInEdit.first_name,
                    last_name: this.state.orgItemInEdit.last_name
                }
            }).then(ret => {
                if(ret.length===0 || window.confirm("A person with the same first and last name already exists in the database. Confirm add duplicate?")){
                    client.service('clients').create(this.state.orgItemInEdit).then(response => {

                        this.setState({orgId: response.id, orgItemAdded: false} //need to set the orgId to the person id so that when resetaftersavechagnes is called, the program simulates selecting that person
                            , () => {
                                console.group("Created new person")
                                console.log("New person object: ");
                                console.log(response);
                                console.groupEnd("End created new person")
                                this.resetAfterSaveChanges()

                                // after changes have been saved, refresh the persons dropdown using teh selectedOrgId state variable
                                this.getPnDropDown(this.state.selectedOrgId);

                                // this.handleOrgSelected(this.state.orgItemInEdit)
                            })
                    }).catch(e => {
                        console.group("ERROR CREATING CLIENT")
                        console.log(this.state.orgItemInEdit)
                        console.groupEnd()
                        window.alert(e)
                        // this.cancelChanges()
                        error = true;
                    })
                }
            })


        } else if(this.state.orgItemChanged){
            client.service('clients').update(this.state.orgItemInEdit.id, this.state.orgItemInEdit).then(response=>{
                this.resetAfterSaveChanges()
                this.getPnDropDown(this.state.selectedOrgId);

            }).catch(e => {
                window.alert(e)
                error = true;
            })
        }

        if (this.state.entItemChanged) {
            this.state.newEntItems.forEach(obj=>{
                client.service('emails').create(obj).then(response => {
                }).catch(e => {
                    window.alert(e);
                    error = true;
                })
            })

            this.state.entIdsInEdit.forEach(ent_id=>{
                client.service('emails').update(ent_id, this.state.entItems[ent_id]).then(response => {
                    this.extractEntities(this.state.orgId);
                }).catch(e => {
                    window.alert(e)
                    error = true;
                })
            })
            if (!error){
                this.resetAfterSaveChanges();
            }
        }

        if (this.state.utpItemChanged) {
            console.group("Saving to database: utpIdsInEdit, utpItems")
            console.log(this.state.utpIdsInEdit)
            console.log(this.state.utpItems)

            this.state.newUtpItems.forEach(obj=>{
                client.service('utps').create(obj).then(response => {
                }).catch(e => {
                    window.alert(e);
                    error = true;
                })
            })



            this.state.utpIdsInEdit.forEach(utp_id=>{
                client.service('utps').update(utp_id, this.state.utpItems[utp_id]).then(response => {
                }).catch(e => {
                    window.alert(e)
                    error = true;
                })
            })
            if (!error){
                this.resetUtpAfterSaveChanges();
            }
        }
    }

    resetAfterSaveChanges = () => {
        this.setState({
            newEntItems: [],
            entIdsInEdit: new Set (),

            orgItemAdded: false,
            entItemChanged: false,
            orgItemChanged: false,
        })

        this.extractEntities(this.state.orgId);

        this.setState({
            prevOrgItem: _.cloneDeep(this.state.orgItemInEdit),
            // prevEntItems: _.cloneDeep(this.state.entItems)
        })

    }

    resetUtpAfterSaveChanges = () =>{
        this.setState({
            newUtpItems: [],
            utpIdsInEdit: new Set (),
            utpItemChanged: false,
        })
        this.extractEntities(this.state.orgId);

        this.setState({
            prevOrgItem: _.cloneDeep(this.state.orgItemInEdit),
            // prevEntItems: _.cloneDeep(this.state.entItems)
        })
    }
    cancelChanges = () => {
        this.setState({
            orgItemChanged: false,
            orgItemInEdit: _.cloneDeep(this.state.prevOrgItem),

            entItemChanged: false,
            entItems: _.cloneDeep(this.state.prevEntItems),
            entIdsInEdit: new Set(),

            utpItemChanged: false,
            utpItems: _.cloneDeep(this.state.prevUtpItems),
            utpIdsInEdit: new Set(),

            newEntItems: [],
            newUtpItems: [],
        })
    }

    /** Rendering for person dropdown for duplicate names **/
    pnDropDownRender = (li, itemProps) => {
        const index = itemProps.index;
        const itemChildren = "ID " + index + ": " + li.props.children;

        return React.cloneElement(li, li.props, itemChildren);
    }


    render() {

        return (
            <div>
                <div className={"admin-wrapper"}>
                    <div className={"admin-btn-panel sticky"}>
                        <ComboBox data={this.props.orgData}
                                  placeholder={"Organization"}
                                  allowCustom={false}
                                  suggest={true}
                                  textField={"code"}
                                  dataItemKey={"id"}
                                  onChange={e => {this.handleOrgSelected(e.target.value)}}
                                  popupSettings={{animate:false}}

                        />
                        <ComboBox data = {this.state.personsDropDown}
                                  placeholder={"Full Name"}
                                  allowCustom={false}
                                  suggest={true}
                                  textField={"full_name"}
                                  dataItemKey={"id"}
                                  itemRender={this.pnDropDownRender}
                                  value = {this.state.selectedPnObj}
                                  onChange={e => {this.handlePnSelected(e.target.value)}}
                                  popupSettings={{animate:false}}
                        />
                        {!this.state.orgItemAdded && this.state.orgId &&
                            [
                        <Button icon="add" title="Add" onClick = {this.handleAddEntity}> Add Email</Button>,
                        <Button icon="add" title="Add" onClick = {this.handleAddUtp}> Map Underlying </Button>
                            ]
                        }
                        {(this.state.orgItemChanged || this.state.entItemChanged || this.state.utpItemChanged) ?
                            [
                                <Button icon="cancel" title="Cancel" onClick = {this.cancelChanges}> Cancel </Button>,
                                <Button icon="save" title="Save" onClick = {this.saveChanges}> Save Changes </Button>
                            ]
                            :
                            <Button icon="add" title="Add" onClick = {this.handleAddOrg}> Add Person</Button>
                        }
                    </div>
                </div>

                <div className={"admin-content"}>
                    <form autoComplete={"false"} className={"ac-wrapper"}>
                    <table className = "admin-table">
                        <tbody className = "dialog-table">
                        {Object.keys(inputMap).map((key, i) => {
                            let inputComponent;
                            if(key === "active"){
                                inputComponent = <Checkbox
                                    label={"Yes"}
                                    name = {key}

                                    value = {this.state.orgItemInEdit[key]==1 ? true : false}
                                    onChange={ this.toggleFinraCheck }
                                />
                            } else if (key === "code"){
                                inputComponent = (
                                    <ComboBox
                                        data={this.props.orgData}
                                        placeholder={"Organization"}
                                        allowCustom={false}
                                        suggest={true}
                                        textField={"code"}
                                        dataItemKey={"id"}
                                        name = {key}

                                        value={this.state.orgComboItemInEdit || null}
                                        onChange={ this.handleOrgItemChange }
                                        popupSettings={{animate:false}}

                                    />
                                );
                            } else {
                                inputComponent = (
                                    <Input
                                        type="text"
                                        name={key}

                                        value={this.state.orgItemInEdit[key] || ''}
                                        onChange={ this.handleOrgItemChange }
                                        // onKeyPress={this.handleKeyPress}
                                    />
                                );
                            }
                            return(

                                <tr key={i}>
                                    <td>
                                        {inputMap[key]}
                                    </td>
                                    <td>
                                        {inputComponent}
                                    </td>
                                </tr>
                            );
                        })}

                        </tbody>
                    </table>

            <div className={"ent-wrapper"}>
                {

                <div className = "ent-panel">
                    {Object.keys(this.state.entItems).length!=0 &&

                        <table className = "ent-table">
                            <tbody>
                            <tr> <th> Emails </th> </tr>
                        {Object.keys(this.state.entItems).map((ent_id, index) => (

                                Object.keys(entityMap).map((key, i) => {
                                    let inputComponent;

                                        inputComponent = (
                                            <Input
                                                type="text"

                                                name={key}
                                                value={this.state.entItems[ent_id][key] || ''}
                                                onChange = {e=>this.handleEntItemChange(ent_id, e)}
                                            />
                                        );

                                    return(
                                        <tr key={i}>
                                            <td>
                                                {inputComponent}
                                            </td>
                                        </tr>
                                    );
                                })

                        ))}

                                </tbody>
                            </table>
                    }

                    {
                        this.state.newEntItems.map((obj, index) => (
                            <table className = 'new-ent-table'>
                                <tbody>
                                {Object.keys(entityMap).map((key, i) => {
                                    let inputComponent;
                                    if(i === 0) {
                                        inputComponent = (
                                            <div
                                                ref={
                                                    (x) => {
                                                        if(x && index === this.state.entIndexToSelect) {
                                                            x.querySelector(`[name=${key}]`).select()
                                                            this.setState({entIndexToSelect: -1})
                                                        }
                                                    }}
                                            >

                                                <Input
                                                    type="text"

                                                    name={key}
                                                    label = {"Email"}
                                                    value={this.state.newEntItems[index][key] || ''}
                                                    onChange = {e=>this.handleNewEntItemChange(index, e)}
                                                />
                                            </div>
                                        );
                                    }  else {
                                        inputComponent = (
                                            <Input
                                                type="text"

                                                name={key}
                                                label = {"New Email"}
                                                value={this.state.newEntItems[index][key] || ''}
                                                onChange = {e=>this.handleNewEntItemChange(index, e)}
                                            />
                                        );
                                    }
                                    return(
                                        <tr key={i}>
                                            <td>
                                                {inputComponent}
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        ))
                    }
                </div>
                }

                {
                <div className = "ent-panel">
                {/*UNDERLYING MAPPINGS*/}
                        {Object.keys(this.state.utpItems).length!=0 &&
                            <table className = "ent-table">
                                <tbody>
                                <tr> <th> Underlyings </th> </tr>

                            {Object.keys(this.state.utpItems).map((utp_id, index) => (
                                    <tr>
                                        <td>
                                            <ComboBox
                                                data={this.state.underlyingsDropDown}
                                                value={this.getTickerFromId(this.state.utpItems[utp_id].underlyingId) || ''}
                                                textField="ticker"
                                                dataItemKey="id"
                                                allowCustom={false}
                                                suggest={true}
                                                onChange={e => {this.handleUtpItemChange(utp_id, e.target.value)}}
                                                popupSettings={{animate:false}}
                                            />
                                        </td>
                                    </tr>
                            ))}

                                </tbody>
                            </table>
                        }

                    {this.state.entIndexToSelect!=-1 && scroll.scrollToBottom()}
                    <table className = 'new-ent-table'>
                        <tbody>
                            {
                                this.state.newUtpItems.map((obj, index) => (

                                    <tr key = {index}>

                                        <td>
                                            <ComboBox
                                                data={this.state.underlyingsDropDown}
                                                label = {'New Underlying'}
                                                value={this.getTickerFromId(this.state.newUtpItems[index].underlyingId) || ''}
                                                textField="ticker"
                                                dataItemKey="id"
                                                allowCustom={false}
                                                suggest={true}
                                                onChange={e => {this.handleNewUtpItemChange(index, e.target.value)}}
                                                popupSettings={{animate:false}}
                                            />
                                        </td>
                                    </tr>

                                ))
                            }
                        </tbody>
                    </table>
                </div>
                }
            </div>

            </form>

                </div>
            </div>
        );
    }
}
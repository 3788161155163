import React from 'react';

/** Redux **/
import {connect} from 'react-redux';
import {selectOrderById} from '../../../../redux/selectors';

/** Style **/
import './OrderDetail.scss';
import BEMHelper from 'react-bem-helper';
const classes = new BEMHelper('OrderDetail');

const OrderDetail = (props) => {
    const order = props.order ? props.order : {};


    return <div {...classes()}>
        <span {...classes('ticker')}> {order.ticker} </span>
        <span {...classes('expiration')}> {order.expiration} </span>
        <span {...classes('strike')}> {order.strike} </span>
        <span {...classes('structure')}> {order.structName} </span>
        <span {...classes('risk_rwd_ratio')}> {order.ratio} </span>
        {order.tied &&  <span> vs. <span {...classes('tied')}> {order.tied} </span> </span>}
        {order.delta && <span>(<span {...classes('delta')}>{order.delta}</span>)</span>}
    </div>
}

const mapStateToProps = (state, props) => {
    return {
        order: selectOrderById(state, props.orderId)
    }
}

export default connect(mapStateToProps, null) (OrderDetail);
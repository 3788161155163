import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';

export default function StatusCell(props) {
    const { dataItem } = props;
    return <td>
        {dataItem.isOnline
            ? <span style = {{color:"lightgreen"}}> ONLINE </span>
            : '-'}
    </td>
}


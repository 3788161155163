/** Helpers **/
import {
    GetSymbolList,
    Delay
} from '../../../helpers';
import promiseRetry from 'promise-retry';

/** Actions **/
import {
    mapOrderIdToSymbols,
    removeOrderIdToSymbolMapping,
    removeSymbol
} from '../index';

/** Redux **/
import store from '../../store';
import {selectSymbolsByOrderId} from '../../selectors';

/** Quotestream */
import streamModule from '../../../quotestream/stream-module';
const Streamer = require('../../../quotestream/qmci-streamer-2.6.0');

class NullStreamError extends Error {
    constructor(message) {
        super(message);
        this.name = 'NullStreamError';
    }
}

class InvalidSymbolError extends Error {
    constructor(message){
        super(message);
        this.name = 'InvalidSymbolError';
    }
}

export default function (order) {
    return async dispatch => {
        const orderId = order.id;

        const prev_symbol_list = selectSymbolsByOrderId(store.getState(), orderId);

        let symbol_list = [];
        try {
            symbol_list = await GetSymbolList(order);

            /** Check if the symbols already exist and are the same.
             *  If so, no need to resubscribe and return
             **/
            if (prev_symbol_list != null && symbol_list.length === prev_symbol_list.length) {
                let is_symbols_same = true;

                for (let i = 0; i < symbol_list.length; i++) {
                    if (prev_symbol_list[i] !== symbol_list[i]) {
                        is_symbols_same = false;
                    }
                }

                if(is_symbols_same) {
                    return;
                }
            }

            // This is never reached if the symbols are the same
            if (prev_symbol_list!=null) {
                dispatch(removeOrderIdToSymbolMapping(orderId));
            }
        } catch (error) {
            console.error(error)
            return;
        }

        promiseRetry(function (retry, number) {
            if (number > 10) {
                return;
            }

            if (number > 1) {
                return Delay()
                    .then(() => {
                        return subscribe(orderId, symbol_list)
                            .catch( e => {
                                if (e instanceof NullStreamError) {
                                    retry();
                                } else {
                                    return;
                                }
                            })
                            // .then(() => {
                            //     dispatch(mapOrderIdToSymbols(order.id, symbol_list))
                            // })
                    });
            } else {
                return subscribe(orderId, symbol_list)
                    .catch( e => {
                        if (e instanceof NullStreamError) {
                            retry();
                        } else {
                            return;
                        }
                    })
            }
        })
    }
}

function unsubscribe (stream, symbol_list) {
    stream.unsubscribe(symbol_list, [
        Streamer.dataTypes.PRICEDATA,
        Streamer.dataTypes.QUOTE,
        Streamer.dataTypes.IVGREEKS,
    ], {}, function(error, result) {
        // The unsubscription result will include the unsubscriptions.
        let unsubscribed = result.unsubscribed;
        for (let i = 0; i < unsubscribed.length; ++i) {
            console.log("Unsubscribed: " + unsubscribed[i].symbol + " - " + unsubscribed[i].type);
        }
    });
}

function subscribe (orderId, symbol_list) {
    return new Promise(function(resolve, reject) {
        let stream = streamModule.stream;

        /** Reject if stream has not been loaded yet **/
        if (!stream) {
            console.log("NO STREAM THROWING ERROR")
            throw new NullStreamError();
        }

        /**Quotestream **/
        // Subscribe for symbols and data types.
        // These can be either single strings or arrays of strings.
        // See Object.keys(Streamer.datatTypes) for available types.
        // An optional options object can also be passed in. Current available options include:
        //  - skipHeavyInitialLoad: whether to skip initial heavy loads (e.g., previous trades and intervals), defaults to false.
        //  - conflation: Override default connection conflation, default to null. A matching conflation must be supplied when unsubscribing.
        stream.subscribe(symbol_list, [
            Streamer.dataTypes.LASTSALE,
            Streamer.dataTypes.QUOTE,
            Streamer.dataTypes.IVGREEKS,
        ], { skipHeavyInitialLoad: false }, function(error, result) {
            // The subscription result will include the successful subscriptions
            // as well as the unentitled and rejected subscriptions and invalid symbols.
            let {
                invalidSymbols,
            } = result;

            store.dispatch(mapOrderIdToSymbols(orderId, symbol_list)); /** Used to be in line 166 **/

            /** If we did not successfully subscribe to some symbols, unsubscribe from ALL SYMBOLS in the order **/
            if(invalidSymbols.length > 0) {
                console.error('Invalid symbols')
                invalidSymbols.forEach(symbol => {
                    console.error(symbol);
                })
                unsubscribe(stream, invalidSymbols);

                // Unsubscribe for symbols and data types.
                // These can be either single strings or arrays of strings.
                // An optional options object can also be passed in. Current available options include:
                //  - conflation: Override default connection conflation, default to null. Should match a subscribe conflation.
                // unsubscribe(stream, symbol_list);
            } else {
                /** If no invalid symbols and subscriptions were successful, map the order to symbols list **/
            }
        });
    });
}


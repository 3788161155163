import React, {memo} from 'react';
import {connect} from 'react-redux'

/** Components **/
import Body from '../Body';

/** Style **/
import BEMHelper from 'react-bem-helper';
import './Replies.scss';
import {toggleThread} from '../../../../redux/actions';
import {selectRepliesByMessageId} from '../../../../redux/selectors';
let classes = new BEMHelper('Replies');

const Replies = memo((props) => {
    const {
        replies,

        toggleThread
    } = props;

    console.log('Rendering replies');

    return <div>
        <div {...classes('', 'message_board')}>
            <div
                {...classes('border_preview')}
                onClick={toggleThread}
            >
                <div {...classes('border_preview_line')} />
            </div>
            <ul>
                {replies.map((reply, ix) => {
                    return reply
                    ?   <Body
                            key = {reply.id}
                            chat = {reply}
                        />
                    : <div key = {ix}> Loading Reply </div>
                })}
            </ul>
        </div>
    </div>
});


const mapStateToProps = (state, props) => {
    return {
        replies: selectRepliesByMessageId(state, props.messageId)
    }
}

const mapDispatchToProps = (dispatch, {messageId}) => {
    return {
        toggleThread: () => dispatch(toggleThread(messageId))
    }
}


export default connect(mapStateToProps, mapDispatchToProps) (Replies);
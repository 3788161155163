import {roomUsersService} from '../../../feathers'
import {SET_ROOM_USERS} from '../types';

export default function (){
    return async (dispatch) => {
        roomUsersService
            .find({
                query: {
                    $select: [ 'id', 'roomId', 'userId', 'unreadCount' ]
                }
            })
            .then(res => {
                dispatch({
                    type: SET_ROOM_USERS,
                    roomUsers: res
                });
            }).catch(error => {
            console.error('Error Setting Room Users');
            console.error(error);
        });
    }
}
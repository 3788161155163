import {
    SET_TC_FILTER_BY,
    SET_TC_FILTER
} from '../actions/types';

let defaultState = {
    filterBy: 'Business Days',
    days: 1,
    startDate: new Date().setHours(0, 0, 0, 0),
    endDate: null,
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case SET_TC_FILTER_BY:
            return {
                ...state,
                filterBy: action.filterBy
            }
        case SET_TC_FILTER:
            return {
                ...state,
                [action.name]: action.value
            }
        // case SET_TC_DAYS:
        //     return {
        //         ...state,
        //         days: action.days
        //     }
        // case SET_TC_START_DATE:
        //     return {
        //         ...state,
        //         startDate: action.startDate
        //     }
        // case SET_TC_END_DATE:
        //     return {
        //         ...state,
        //         endDate: action.endDate
        //     }
        default:
            return {
                ...state
            }
    }
}
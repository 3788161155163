import React from 'react';
import BEMHelper from 'react-bem-helper';
const classes = BEMHelper('OptionDisplay');

/** Styling is in OrderCell.scss **/
const OptionDisplay = ({order = {}}) => {
    const {
        ticker,
        expiration,
        strike,
        ratio,
        structName,
        tied,
        delta,
    } = order;

    return <div {...classes()}>
        <div {...classes('ticker')}>
            {ticker}
        </div>
        <div>
            <span {...classes('primary')}>
                {(expiration ? expiration : '?') + ' '
                + (strike ? strike : '?') + ' '
                + (ratio ? ratio + ' ' : '')
                + (structName ? structName : '?') + ' '}
            </span>
            <span {...classes('secondary')}>
                { (tied ? 'vs. ' + tied : '') + ' '
                + (delta ? delta + 'Δ': '')}
            </span>
        </div>
    </div>
}

export default OptionDisplay;
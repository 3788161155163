import {createCachedSelector} from 're-reselect';

const getUserById = (state, userId) => state.usersReducer.byId[userId];

const selectAbbreviatedUsernameById = createCachedSelector(
    getUserById,
    (user) => {
        return user.firstname.slice(0,1) + '.' + user.lastname.slice(0,4)
    }
)(
    (_state_, userId) => (userId != null ? userId : -1)
)

export default selectAbbreviatedUsernameById;
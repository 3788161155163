import React from "react";
import ReactDOM from 'react-dom';

/** Redux **/
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
    createAivaPortal,
    toggleIsAivaPortalOpen,
    setIsWindowCompact,
} from '../../../../redux/actions';

/** Aiva **/
import Aiva from '../../Aiva';

function copyStyles(source, target) {
    Array.from(source.styleSheets).forEach(styleSheet => {
        // For <style> elements
        let rules;
        try {
            rules = styleSheet.cssRules;
        } catch (err) {
            console.error(err);
        }
        if (rules) {
            const newStyleEl = source.createElement("style");

            // Write the text of each rule into the body of the style element
            Array.from(styleSheet.cssRules).forEach(cssRule => {
                try {
                    const { cssText, type } = cssRule;
                    let returnText = cssText;
                    // Check if the cssRule type is CSSImportRule (3) or CSSFontFaceRule (5) to handle local imports on a about:blank page
                    // '/custom.css' turns to 'http://my-site.com/custom.css'
                    if ([3, 5].includes(type)) {
                        returnText = cssText
                            .split("url(")
                            .map(line => {
                                if (line[1] === "/") {
                                    return `${line.slice(0, 1)}${
                                        window.location.origin
                                    }${line.slice(1)}`;
                                }
                                return line;
                            })
                            .join("url(");
                    }
                    newStyleEl.appendChild(source.createTextNode(returnText));
                } catch (e) {
                    console.log(e)
                }
            });

            target.head.appendChild(newStyleEl);
        } else if (styleSheet.href) {
            // for <link> elements loading CSS from a URL
            const newLinkEl = source.createElement("link");

            newLinkEl.rel = "stylesheet";
            newLinkEl.href = styleSheet.href;
            target.head.appendChild(newLinkEl);
        }
    });
}

class AivaPortal extends React.Component {
    constructor(props) {
        super(props);
        this.containerEl = document.createElement("div"); // STEP 1: create an empty div

        this.state = {
            externalWindow: null,
        }
    }

    componentDidMount() {
        // STEP 3: open a new browser window and store a reference to it
        const width = 800;
        const left = window.screen.width/2 - width/2

        const externalWindow = window.open(
            "",
            "_blank",
            `height=900, width=${width},left=${left}, top=200`
        );

        // STEP 4: append the container <div> (that has props.children appended to it) to the body of the new window
        externalWindow.document.body.appendChild(this.containerEl);
        externalWindow.document.title = 'Aiva Chat';
        // call the redux function to append the new window object to the store
        this.props.createAivaPortal(externalWindow);

        copyStyles(document, externalWindow.document);

        /** Event listener for when user selects something to copy to clipboard **/
        externalWindow.document.addEventListener('mouseup', () => {
            // const textToCopy = externalWindow.document.getSelection().toString();
            externalWindow.document.execCommand("copy");
        });

        /** Event Listener for when user selects the window to clear notifications **/
        externalWindow.addEventListener('focus', () => {
           console.log('focus')
        });

        externalWindow.addEventListener('unload', () => {
            this.props.toggleIsAivaPortalOpen();
        });

        externalWindow.addEventListener('resize', () => {
            this.props.setIsWindowCompact(externalWindow.innerWidth);
        })

        this.setState({
            externalWindow: externalWindow
        })
    }

    render() {
        // STEP 2: append props.children to the container <div> that isn't mounted anywhere yet
        return ReactDOM.createPortal(<Aiva window = {this.state.externalWindow} />, this.containerEl);
    }
}

function mapStateToProps (state) {
    return {
        menuData: state.aivaReducer.menuData
    }
}

function mapDispatchToProps (dispatch) {
    return bindActionCreators({
        createAivaPortal,
        toggleIsAivaPortalOpen,
        setIsWindowCompact,
    }, dispatch);
}


export default connect (mapStateToProps, mapDispatchToProps) (AivaPortal);
import {createCachedSelector} from 're-reselect';
import {selectMessageById} from './index';

const getRoomById = (state, roomId) => state.roomsReducer.byId;

const selectRoomByMessageId = createCachedSelector(
    selectMessageById,
    getRoomById,
    (message, roomById) => message ? roomById[message.roomId] : {users:[]}
)(
    (_state_, messageId) => messageId
)

export default selectRoomByMessageId;
import {Button} from '@progress/kendo-react-buttons';
import React from 'react';

/** helpers **/
import FormatDateTime from '../../helpers/FormatDateTime';
import CapitalizeFirst from '../../helpers/CapitalizeFirst';
import Colors from './Styles/Colors';

export default function EmailBlotter({trade, dir, validation, handleSendEmails}) {
    const organization = trade[dir + 'Organization'].name;
    const client = trade[dir + 'Client'];
    const emails = trade[dir + 'Emails'];
    let emailList = [];
    emails.forEach(obj => {
        emailList.push(obj.email);
    });
    const isInvalid = validation.status!==true;
    const validationErrors = validation.errors;

    return <div className={'email-blotter'}>
        <div className = 'eb-header sticky-top' id = {dir+'-eb-header'}>
            <div className = 'eb-header-left'>
                <div className= 'eb-header-dir'> {CapitalizeFirst(dir) + 'er: '} {client.first_name} {client.last_name} </div>
                <div className = 'eb-header-org'> {organization} </div>
            </div>
            <div className = 'eb-header-right'>
                { emailList.length !== 0 && (isInvalid ?
                    <Button
                        className = 'icon-button'
                        iconClass = 'k-icon k-i-question'
                        onClick={() => {window.alert(validationErrors.join('\n'))}}
                    > INFO </Button>:
                    <Button
                        className= 'icon-button'
                        iconClass = 'far fa-paper-plane'
                        onClick = {() => handleSendEmails(
                            dir,
                            emailList
                        )}
                    > SEND ALL </Button>
                )}

            </div>
        </div>
        <div className = 'eb-body'>
            <table>
                <tbody>
                {emails.map((obj, ix) => {
                    let status;
                    if (isInvalid) {
                        status = 'NOTREADY'
                    } else if (obj.status === 2) {
                        status = 'SENT'
                    } else if (obj.status === 4) {
                        status = 'FAIL'
                    } else if (obj.status === 1) {
                        status = 'SENDING'
                    }else {
                        status = 'READY'
                    }

                    const statusStyle = {color: Colors[status]};
                    return (
                      <tr key = {ix}>
                          <td className={'td-email'}> {obj.email} </td>
                          <td className={'td-status'}
                              style = {{...statusStyle}}
                          > {status} </td>
                          <td className={'td-sentAt'}>
                              {FormatDateTime(obj.sentAt)}
                          </td>
                          <td className={'td-icon'}>
                              {obj.status === 1 ?
                                  <div className="k-icon k-i-loading"></div> :
                                  isInvalid ? <div className='k-icon k-i-warning'> </div>:
                                      <Button
                                          icon='email'
                                          look='flat'
                                          onClick={() => handleSendEmails(
                                              dir,
                                              [obj.email]
                                          )}
                                      />

                              }
                          </td>
                      </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    </div>
}
import React from 'react';
import './Styles/TicketStyle.scss'
import client from '../../feathers';
/** Lodash **/
import _ from 'lodash';
/** helpers **/
import FormatNumber from '../../helpers/FormatNumber';
/** Components **/
import {EntityDetails, OrganizationDetails} from './EntryFormDetails';
/** Kendo form components **/
import {Button} from '@progress/kendo-react-buttons';
import {ComboBox} from '@progress/kendo-react-dropdowns';
import {Checkbox, Input} from '@progress/kendo-react-inputs';
import {Notification, NotificationGroup} from '@progress/kendo-react-notification'
import {Zoom} from '@progress/kendo-react-animation'
/** Structures and Template Mapping **/
import {
    hedgeCrossAgentList,
    hedgeSaleTypeList,
    LEG_TEMPLATES,
    optionClientCategoryList,
    optionFloorBrokerList,
    optionFloorList,
    structureList
} from '../../helpers/data';
import FormatDateTime from '../../helpers/FormatDateTime';

/** Kendo **/
// import '@progress/kendo-theme-bootstrap/dist/all.css';

// import '@progress/kendo-react-intl'
// import '@progress/kendo-react-dropdowns'
// import '@progress/kendo-react-buttons'
// import '@progress/kendo-react-inputs'
// import 'react-router-dom'
const tradeNumFields = ['price', 'size',
    'hedgePrice', 'hedgeSize',
    'buyBrokerage', 'sellBrokerage'
]
const legNumFields = ['strike', 'price', 'size']
const jsonFields = ['legs', 'buyEmails', 'sellEmails']

/** Feathers **/
const usersService = client.service('users')
const tradesService = client.service('trades')
const organizationsService = client.service('organizations')
const entitiesService = client.service('entities')
const underlyingsService = client.service('underlyings')
const clientsService = client.service('clients')


/** Components **/
const ViewCards = ({trade, window, handleInputChange, handleNumericInputChange, handleCheckBoxChange, formatNumber, handleRemoveLeg, viewOnly}) => {
    const legs = trade.legs
    let callCount = 0;
    let putCount = 0;
    if(!legs) return <div></div>

    return (
        legs.map((leg, ix) => {
            const path = "legs[" + ix.toString() + "]" // path of the leg relative to the trade object
        return <div className = {"vp-card vp-card-" + leg.structure.toLowerCase()} key = {ix} >
                    <div className = "vp-card-row vp-card-banner">
                        <div className = "vp-card-banner-icon">
                            <span className={"k-icon " + (leg.structure === "CALL" ? "k-i-arrow-chevron-up" : "k-i-arrow-chevron-down")}></span>
                        </div>
                        <div className = "vp-card-banner-label">
                            {leg.structure} {leg.structure === "CALL" ? ++callCount: ++putCount}
                        </div>
                        { !viewOnly &&
                        <div className= "vp-card-banner-close">
                            <span
                                className="k-icon k-i-close"
                                onClick={()=>handleRemoveLeg(ix)}
                            ></span>
                        </div>
                        }
                    </div>

                    <div className = "vp-card-row">
                        <div className = "vp-card-row-left">
                            Strike
                        </div>
                        <div className= "vp-card-row-right">
                            <Input
                                disabled = { viewOnly }
                                name="price"
                                value={_.get(trade, path+".strike") || ''}
                                onChange={ e => handleNumericInputChange(e.target.value, path+".strike")}
                                onBlur = { e => formatNumber(path+".strike") }

                                className = "v-inp"
                            />
                        </div>
                    </div>

                    <div className = "vp-card-row">
                        <div className = "vp-card-row-left">
                            Expiry
                        </div>
                        <div className= "vp-card-row-right">
                            <Input
                                type = 'date'
                                disabled = { viewOnly }
                                name = "expiration"
                                value={_.get(trade, path+".expiration") || ''}
                                onChange={e=>handleInputChange(e.target.value, path+".expiration")}

                                format={"MMM-dd-yyyy"}
                                className={"v-datepicker"}
                                popupSettings={{
                                    "animate": false,
                                    "className": "",
                                    "appendTo": window
                                }}
                            />
                        </div>
                    </div>

                    <div className = "vp-card-row">
                        <div className = "vp-card-row-left">
                            Price
                        </div>
                        <div className= "vp-card-row-right">
                            <Input
                                disabled = { viewOnly }
                                name="price"
                                value={_.get(trade, path+".price") || ''}
                                onChange={ e => handleNumericInputChange(e.target.value, path+".price")}
                                onBlur = { e => formatNumber(path+".price") }

                                className = "v-inp"
                            />
                        </div>
                    </div>

                    <div className = "vp-card-row">
                        <div className = "vp-card-row-left">
                            Size
                        </div>
                        <div className= "vp-card-row-right">
                            <Input
                                disabled = { viewOnly }
                                name="size"
                                value={_.get(trade, path+".size") || ''}
                                onChange={ e => handleNumericInputChange(e.target.value, path+".size")}
                                onBlur = { e => formatNumber(path+".size") }

                                className = "v-inp"
                            />
                        </div>
                    </div>

                    <div className = "vp-card-row">
                        <div className = "vp-card-row-left">
                            Buyer
                        </div>
                        <div className= "vp-card-row-right">
                            <div style={{"display": "flex", "flexFlow": "row wrap", "justifyContent":"space-evenly"}}>
                                <Checkbox
                                    disabled = { viewOnly }
                                    label = "Buys"
                                    value={_.get(trade, path+".buyerBuys") === 1}
                                    onChange={e=>{handleCheckBoxChange(path+".buyerBuys", 'buys')}}
                                />
                                <Checkbox
                                    disabled = { viewOnly }
                                    label = "Sells"
                                    value={_.get(trade, path+".buyerBuys") === 0}
                                    onChange={e=>{handleCheckBoxChange(path+".buyerBuys", 'sells')}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
        }
    )

    )
}

class EntryForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            trade: {},
            viewOnly: false,
            errorMsg: null,
            successMsg: null,

            buyEmailIxToSelect: -1,
            sellEmailIxToSelect: -1,

            metadataExpanded: false,
            buyOrganizationExpanded: false,
            buyEntityExpanded: false,
            sellOrganizationExpanded: false,
            sellEntityExpanded: false,
        };
    }

    componentDidMount() {
        usersService.find({
            query: {
                $select: ['id', 'username', 'desk'],
                $sort: {username: 1}
            }
        }).then(res => {
            const users = res;
            // const userDesks = Array.from(new Set( users.map(obj=> obj.desk ) ))
            this.setState({users})
        })

        underlyingsService.find({
            query:{
                $select: ['id', 'ticker'],
                $sort: {ticker: 1}
            }
        }).then(res => {
            const underlyings = res
            this.setState({underlyings})
        })

        organizationsService.find({
            query: {
                $select: ['id', 'code'],
                $sort: {code: 1}
            }
        }).then(res => {
            const organizations = res
            this.setState({organizations})
        })
    }

    componentDidUpdate(prevProps){
        if(this.props.trade !== undefined && this.props.viewOnly !==undefined && prevProps.trade !== this.props.trade ){
            const trade =_.cloneDeep(this.props.trade);
            const {viewOnly} = this.props
            /** Do Preprocessing on Trade object **/

            // Convert legs string to JSON
            // We now have a hook that does this
            jsonFields.forEach(field => {
                if (trade[field] != null) {
                    if(typeof trade[field] === 'string'){
                        trade[field] = JSON.parse(trade[field])
                    }
                } else {
                    trade[field] = []
                }
            })


            // Call a find on organizations service to populate linked entities and clients comboboxes with the hook objects
            this.getOrganizationsRelated(trade.buyOrganizationId, "buy")
            this.getOrganizationsRelated(trade.sellOrganizationId, "sell")

            this.setState({trade: {}}, () => {
                this.setState({
                    viewOnly,
                    trade,
                }, () => {
                    this.formatNumbers();
                    // this.props.setChanged();
                    // if the legs are empty, generate them from template
                    // trade.legs.length === 0 && this.generateLegs(trade.structure);
                });
            });
        }
        if (!this.isEventListenerMounted && this.props.window != null ) {
            this.isEventListenerMounted = true;
            this.props.window.addEventListener("beforeunload", (ev) =>
            {
                ev.preventDefault();
                // this.handleSaveChanges();
                if(this.props.changed) {
                    return ev.returnValue = 'Are you sure you want to exit? You have unsaved changes.';
                }
            });

            // IF THE USER CHOSE TO LEAVE, CHECK IF THE TICKET HAS NEVER BEEN UPDATED, AND DELETE IT IF SO
            this.props.window.addEventListener("unload", (ev) => {
                const { id } = this.props.trade;
                tradesService.get(id)
                    .then( trade => {
                        if (trade.updatedBy == null) {
                            tradesService.remove(id);
                        }
                    })
            });

            this.props.window.addEventListener('keyup', this.handleKeyup, false);
        }

    }

    /** Shortcut **/
    handleKeyup = (e) => {
        if (e.ctrlKey && e.keyCode === 83) { // ctrl + s
            this.handleSaveChanges();
        }
    }

    /**** Topbar operations ****/
    handleSaveChanges = () => {
        const trade = {...this.state.trade}

        // Clean all numeric inputs ( cast from strings with zeros and commas back into int)
        this.getNumPaths(trade).forEach(path=>{
            let num = _.get(trade, path)

            if (num && typeof num === 'string') {
                _.set(trade, path, parseFloat(num.replace(/,/g,'')))
            }
        });

        // Stringify the legs JSON object
        jsonFields.forEach(field=> {trade[field] = JSON.stringify(trade[field])})

        tradesService.update(trade.id, trade).then(()=>{
            this.setNotification('successMsg', "Successfully saved ticket to database.");
            // reformat numbers after saving to database
            this.formatNumbers();
            this.props.setUnchanged();
        }).catch( e => {
            this.setNotification('errorMsg', "Error saving ticket to database." + e.toString())
        })
    }

    setNotification = (field, msg) => {
        this.setState({
                [field]: msg
            }, () =>
                setTimeout(()=>{
                    this.setState({[field]: null})
                }, 5000)
            )
    }

    /**** Handle click voided checkbox in metadata ****/
    handleVoided = (e) => {
        const {trade} = this.state
        trade.voided = e.value ? 1 : 0;
        this.setState({trade});
        this.props.setChanged();
    }

    /**** Handle Input, Combobox, and date Changes ****/
    handleLinkedComboChange = (e, tradeIdField, textField, tradeTextField, pre=null) => {
        const item = e.target.value ? e.target.value : {id: null, [textField]:null,}; // if the user clicked the close button on the combobox
        const trade = {...this.state.trade}

        trade[tradeIdField] = item.id
        trade[tradeTextField] = item[textField]
        this.setState({trade}, ()=>{
            // if the combobox selected was a buy or sell client, find its related stps
            if(tradeIdField.includes("Client")){
                this.getClientsRelated(e.target.value, pre)
            } else if (tradeIdField.includes("Entity")) {
                this.getEntDetails(pre);
            }
        })

        this.props.setChanged();
    }

    handleInputChange = (value, path, generateLegs = false) => {
        const trade = {...this.state.trade};

        if (value !== null) {
            value = value.toString()
        }

        _.set(trade, path, value)
        this.setState({trade:trade}, ()=>{
            if (generateLegs) {
                this.generateLegs(value)
            }
        })

        this.props.setChanged();
    }

    handleNumericInputChange = (num, path) => {
        const trade = {...this.state.trade}

        if (num === ""){
            _.set(trade, path, undefined)
        } else {
            num = num.toLowerCase();
            if (num.includes('.') && num.includes('ssss'))
            num  = num.replace(/k|K/g, '000').replace(/m|M/g, '000000').replace(/\,/g, '') //get, replace k with K, get rid of commas
            if(!isNaN(num)) {
                _.set(trade, path, num)
            }
        }
        this.setState({trade})

        this.props.setChanged();
    }

    handleCheckBoxChange = (path, direction) => {
        const trade = {...this.state.trade}

        const prevVal = _.get(trade, path)
        if (prevVal === undefined || prevVal === null) {
            _.set(trade, path, direction==="buys" ? 1 : 0)
        } else {
            _.set(trade, path, prevVal === 0 ? 1 : 0)
        }
        this.refreshState({trade});
        this.props.setChanged();
    }

    /**** Handle leg generation and user input ****/
    /** Generate legs when user selects a structure **/
    generateLegs = (structure) => {
        if(!Object.keys(LEG_TEMPLATES).includes(structure)) return;

        const trade = {...this.state.trade}

        /** Loader templates from data resources file */
        let strikes = trade.strike ? trade.strike.split("/") : [] // create array of the trade strikes
        strikes.sort();

        let legs = [] // each output leg dictionary will be appended to this

        LEG_TEMPLATES[structure].legs.forEach(leg => {
            legs.push(this.generateLeg(leg, trade, strikes));
        })
        trade.legs = legs

        /** Handling hedge **/
        this.generateHedge(structure, trade)

        // this.setState({trade}, this.formatNumbers)
        this.refreshState({trade}, undefined, this.formatNumbers)
    }

    generateLeg = (leg, trade, strikes) => {
        let out = {
            "structure": null,
            "strike": null,
            "expiration": null,
            "price": null,
            "size": null,
            "buyerBuys": null,
        }

        out.structure = leg.structure
        out.price = trade.price
        out.buyerBuys = leg.buyerBuys

        /** Sizes **/
        if (leg.ratio === undefined || !trade.size) {
            out.size = trade.size
        } else {
            out.size = parseInt(leg.ratio) * parseInt(trade.size)
        }

        /** Strikes **/
        if (leg.strike === undefined || strikes.length < leg.strike + 1) {
            out.strike = trade.strike
        } else {
            out.strike = strikes[leg.strike]
        }

        /** Expiration **/
        if (leg.expiration === undefined || !trade.expiration) {
            out.expiration = trade.expiration
        } else {
            let expiration = new Date(trade.expiration);
            expiration.setDate(parseInt(trade.expiration.slice(8,10)))
            expiration = new Date(expiration.setMonth(expiration.getMonth() + leg.expiration)).toISOString().slice(0,10);
            out.expiration = expiration
        }
        return out;
    }

    /** Handle user clicking button to add call **/
    handleAddLeg = (structure) => {
        const trade = {...this.state.trade}
        let strikes = trade.strike ? trade.strike.split("/") : [] // create array of the trade strikes
        strikes.sort();

        const out = trade.legs // array of actual trade legs

        // removed faulty logic to check if user deleted a leg and wanted to repopulate it
        let leg = {structure} // leg is NOT the actual out to be appended to legs, but rather the template
        out.push(this.generateLeg(leg, trade, strikes))

        trade.legs = out
        this.setState({trade})

        this.props.setChanged();
    }

    /** Handle user clicking x to remove **/
    handleRemoveLeg = (ix) => {
        console.log('handle remove leg')
        if(this.props.window.confirm("Are you sure you want to delete this leg?")){
            const trade = {...this.state.trade}
            trade.legs.splice(ix, 1)
            this.setState({trade})
        }

        this.props.setChanged();
    }

    /**** HANDLE HEDGE FEATURES ****/
    generateHedge = (structure, trade) => { // currently does not return trade and modifies the reference in place
        if(structure == null) { // if the user has not selected structure combobox (eg call/put spread)
            trade.isHedged = 1;
            return;
        }

        const hedgeTemplate = LEG_TEMPLATES[structure].hedge
        // let tieds = trade.tied ? trade.tied.split("/") : []
        if (hedgeTemplate.default || trade.isHedged) {
            // calculate the hedge size by multiplying delta by trade size
            trade.isHedged = 1;
            let delta = trade.delta
            if (delta) {

                delta.replace("/[ a-zA-Z,()]/", "");
                if (delta.includes(".")) {
                    delta = parseFloat(delta) * 100
                }
                delta = parseInt(delta)
                const size = parseInt(trade.size.replace(",", ""))
                trade.hedgeSize = delta*size;
            }

            if (hedgeTemplate.buyerBuys!==undefined) {
                trade.hedgeSaleType = hedgeTemplate.buyerBuys ? "Buys" : "Sells Short"
            }
        }
    }

    handleAddHedge = () => {
        const trade = {...this.state.trade}
        trade.isHedged = 1;
        this.generateHedge(trade.structure, trade)
        this.setState({trade}, this.formatNumbers)

        this.props.setChanged();
    }

    handleRemoveHedge = () => {
        const trade = _.cloneDeep(this.state.trade)
        trade.isHedged = 0;
        ['hedgePrice', 'hedgeSize', 'hedgeSaleType', 'hedgeCrossAgent'].forEach(field=>{
            trade[field] = undefined;
        })
        trade.hedgeExecutionTime = null;
        this.setState({trade})

        this.props.setChanged();
    }

    /**** HANDLE USER CHANGING EMAIL RECIPIENTS FOR BUYERS AND SELLERS ****/
    handleEmailInputEnter = (key, pre) => {
        if(key === 'Enter') {
            this.handleAddEmail(pre);
        }
    }

    handleAddEmail = (pre) => {
        const trade = {...this.state.trade}

        const path = pre+'Emails'
        const emails = [..._.get(trade, path)]
        emails.push({
            id: null,
            email: null,
            sent: 0,
            def: 0,
        })
        _.set(trade, path, emails)
        this.refreshState({
            trade,
        }, {[pre+'EmailIxToSelect']: emails.length-1})

        this.props.setChanged();
    }

    handleRemoveEmail = (ix, path) => { //ix is the index of the email within the array, not the actual id of the email object
        const trade = {...this.state.trade}
        const emails = [..._.get(trade, path)]

        if(emails[ix].status===2){
            this.setNotification('errorMsg', 'Cannot remove email which has already been sent.')
        } else {
            emails.splice(ix, 1)
            _.set(trade, path, emails)
            this.refreshState({trade});
        }

        this.props.setChanged();
    }

    /** Helper to force a complete state refresh. Solved issue for nested buy/sell stps not updating the tables correctly **/
    refreshState = (params, scrollToBottomParams=undefined, callBack=undefined) => {
        this.setState({trade: {}},()=>{
            this.setState({...params}, ()=> {
                if(scrollToBottomParams!==undefined) {
                    this.setState({...scrollToBottomParams})
                }
                if(callBack!==undefined) {
                    callBack();
                }
            });
        })
    }

    /** Add extra zero to decimal for display in numeric input box **/
    formatNumber = (field) => {
        const trade = {...this.state.trade}
        let num = _.get(trade, field)
        if (!num || String(num).length === 0 ) {
            return
        }

        this.formatNumberHelper(num, trade, field);
        this.setState({trade})
    }

    /** Formats all number fields **/
    formatNumbers = () => {
        const trade = {...this.state.trade}

        const allNumPaths = this.getNumPaths(trade);

        for (let i = 0; i < allNumPaths.length; i++) {
            const field = allNumPaths[i]
            let num = _.get(trade, field)
            if (!num || String(num).length === 0 ) {
                continue;
            }

            this.formatNumberHelper(num, trade, field)
        }
        this.setState({trade})
    }

    /** Returns all the paths of numeric inputs (including in legs) **/
    getNumPaths = (trade) => {
        let allNumPaths = [...tradeNumFields]
        trade.legs.forEach((out, ix) => {
            legNumFields.forEach(field => {
                allNumPaths.push("legs[" + ix + "]." + field)
            })
        })
        return allNumPaths;
    }

    /** Does the actual formatting toa dd commas and periods **/
    formatNumberHelper = (num, trade, field) => {
        _.set(trade, field, FormatNumber(num, field))
    }

    /**** Cascading BUYER/SELLER combobox handling ****/
    /* Feathers get all entities and clients related to the selected organization  */
    getOrganizationsRelated = (organizationId, pre) => {
        if(organizationId === undefined || organizationId === null) {
            this.setState({
                [pre+'Entities']: [],
                [pre+'Clients']: [],
            })
            return
        }
        organizationsService.find({
            query: {
                id: organizationId
            }
        }).then(res=>{
            let {entities, clients} = res!==undefined ? res[0] : {'entities': [], 'clients': []}
            // add the full_name field by concatenating first and last name for combobox use
            const clientsWithFullName = clients.length>0 ?
                clients.map(obj=>({...obj, "full_name": obj.first_name + " " + obj.last_name})) : []
            this.setState({
                [pre+'Entities']: entities,
                [pre+'Clients']: clientsWithFullName
            })
        })
    }
    /* Feathers get all stps related to selected client (either buyer or seller */
    getClientsRelated = (clientObj, pre) => {
        const clientId = clientObj.id
        const trade = {...this.state.trade}
        let ret = []

        if(clientId === undefined || clientId === null) {
            trade[pre+'Emails'] = ret
            this.setState({trade})
            return
        }

        // else find the client id if it is defined
        clientsService.find({
            query: {
                id: clientId
            }
        }).then(res=>{
            const { emails } = res!==undefined ? res[0] : {'emails': []}
            ret = emails.map(obj => ({
                id: obj.id,
                email: obj.email_address,
                sent: 0,
                default: 1, // default
            }))

            /** NEED TO REVISIT THIS FUNCTIONALITY **/
            // don't overwrite any custom nondefault stps the user created
            const prevEmails = _.get(trade, pre+"Emails")
            const customEmails = prevEmails.filter( obj => !obj.default )

            // trade[pre+'Emails'] = [...ret, ...customEmails];
            trade[pre+'Emails'] = [...ret]
            this.refreshState({trade})
        })
    }

    /* for linked combo where the value for the combobox is {id: 1, code: GS } */
    getComboItemFromId = (modelName, tradeIdField) => {
        const model = this.state[modelName]
        if (model && model.length>0) {
            const res = model.find(x => x.id === this.state.trade[tradeIdField]);
            return res!==undefined ? res : {}
        }
    }

    // handleUserDeskComboChange = (e, tradeIdField, textField, tradeTextField) => {
    //     const item = e.target.value ? e.target.value : {id: null, [textField]:null,}; // if the user clicked the close button on the combobox
    //     const trade = {...this.state.trade}
    //     trade[tradeIdField] = item.id
    //     trade[tradeTextField] = item[textField]
    //     this.setState({trade})
    // }

    handleOrgComboChange = (e, pre, textField) => {
        let item = e.target.value ? e.target.value : {id: undefined, [textField]:undefined,}; // if the user clicked the close button on the combobox

        // update the foreignKeyId and the foreignTextField
        const trade = {...this.state.trade}
        trade[pre+"OrganizationId"] = item.id
        trade[pre+"OrganizationCode"] = item[textField]

        // clear the foreign key references
        const linkedFields = ['EntityId','EntityCode','ClientId','ClientCode']
        linkedFields.forEach((field)=>
            trade[pre+field] = null
        )

        this.setState({
            trade,
            [pre+'Entities']: [],
            [pre+'Clients']: [],
        }, () => {this.getOrgDetails(pre)});

        // populate the clients and entities dropdowns
        this.getOrganizationsRelated(item.id, pre)

        this.props.setChanged();
    }

    /** Handle expanding organization and entity to view details **/
    handleOrgExpanded = (pre) => {
        const field = [pre+'OrganizationExpanded'];
        if (!!field) {
            this.getOrgDetails(pre);
        }
        this.setState({[field]: !this.state[field]});
    }

    getOrgDetails = (pre) => {
        const trade = _.cloneDeep(this.state.trade);
        if (trade[pre + 'Organization'] != null && trade[pre + 'OrganizationId'] !== trade[pre + 'Organization'].id) {
            organizationsService.get(trade[pre+'OrganizationId']).then(organization => {
                trade[pre+'Organization'] = {...organization};
                this.setState({
                    trade,
                })
            });
        }

    }

    handleEntExpanded = (pre) => {
        const field = [pre+'EntityExpanded'];
        if (!!field) {
            this.getEntDetails(pre);
        }
        this.setState({[field]: !this.state[field]});
    }

    getEntDetails = (pre) => {
        const trade = _.cloneDeep(this.state.trade);
        console.log(trade[pre+"Entity"])
        if (trade[pre + 'Entity'] != null && trade[pre + 'EntityId'] !== trade[pre + 'Entity'].id) {
            entitiesService.get(trade[pre+'EntityId']).then(organization => {
                trade[pre+'Entity'] = {...organization};
                this.setState({
                    trade,
                })
            });
        }

    }



    BrokeragePanel = (trade, window, pre, viewOnly) => {
        const organization = trade[pre+'Organization'];
        const entity = trade[pre+'Entity'];
        const organizationExpanded = this.state[pre+'OrganizationExpanded'];
        const entityExpanded = this.state[pre+'EntityExpanded'];
        return  <div className = {"brokerage-panel"} id = {pre+"-panel"}>


                    <div className = {"vp-banner"}>
                        <div className = {"vp-banner-label"}> {pre.toUpperCase()} </div>
                    </div>

                {/* BUY ORGANIZATION CARD */}
                <div className = "vp-card">
                    <div className = "vp-card-row vp-card-banner">
                        <div className = "vp-card-banner-icon">
                            <span className={"fa fa-users"}></span>
                        </div>
                        <div className = "vp-card-banner-label">
                            {pre.toUpperCase()} ORGANIZATION
                        </div>
                    </div>

                    <div className = "vp-card-row">
                        <div className = "vp-card-row-left">
                            <span
                                className={"k-icon k-icon-btn k-i-" + (organizationExpanded ? "minus" : "info")}
                                style={{marginRight: '5px'}}
                                onClick={()=>{
                                    this.handleOrgExpanded(pre);
                                }}
                            ></span>
                            Org
                        </div>
                        <div className= "vp-card-row-right">
                            <ComboBox
                                disabled = { viewOnly }
                                name = {pre+"Organization"}
                                value = {
                                    this.getComboItemFromId("organizations", pre+"OrganizationId")
                                }
                                onChange={e=>{this.handleOrgComboChange(e, pre, "code")}}

                                data = {this.state.organizations}
                                textField = "code"
                                dataItemKey = "id"

                                // className = "v-combo"
                                suggest={true}
                                clearButton={true}
                                allowCustom={false}
                                popupSettings={{
                                    "animate": false,
                                    "className": "",
                                    "appendTo": window
                                }}
                            />
                        </div>
                    </div>
                    {!!organizationExpanded && <OrganizationDetails organization = {organization} />}
                    <div className = "vp-card-row">
                        <div className = "vp-card-row-left">
                            <span
                                className={"k-icon k-icon-btn k-i-" + (entityExpanded ? "minus" : "info")}
                                style={{marginRight: '5px'}}
                                onClick={()=>this.handleEntExpanded(pre)}
                            ></span>
                            Entity
                        </div>
                        <div className= "vp-card-row-right">
                            <ComboBox
                                disabled = { viewOnly }
                                name = {pre+'Entity'}
                                value = {
                                    this.getComboItemFromId(pre+"Entities", pre+"EntityId")
                                }
                                onChange={e=>{this.handleLinkedComboChange(e, pre+"EntityId", "code", pre+"EntityCode", pre)}}

                                data = {this.state[pre+'Entities']}
                                textField = "code"
                                dataItemKey = "id"

                                // className = "v-combo"
                                suggest={true}
                                clearButton={true}
                                allowCustom={false}
                                popupSettings={{
                                    "animate": false,
                                    "className": "",
                                    "appendTo": window
                                }}
                            />
                        </div>
                    </div>
                    {!!entityExpanded && <EntityDetails entity = {entity} />}
                    <div className = "vp-card-row">
                        <div className = "vp-card-row-left">
                            Client
                        </div>
                        <div className= "vp-card-row-right">
                            <ComboBox
                                disabled = { viewOnly }
                                name = {pre+"Client"}
                                value = {
                                    this.getComboItemFromId(pre+"Clients", pre+"ClientId")
                                }
                                onChange={ e => {
                                    this.handleLinkedComboChange(e, pre+"ClientId", "full_name", pre+"ClientFullName", pre);
                                }
                                }

                                data = {this.state[pre+'Clients']}
                                textField = "full_name"
                                dataItemKey = "id"

                                // className = "v-combo"
                                suggest={true}
                                clearButton={true}
                                allowCustom={false}
                                popupSettings={{
                                    "animate": false,
                                    "className": "dropdown-popup-small",
                                    "appendTo": window
                                }}
                            />
                        </div>
                    </div>

                </div>

                {/* BUY BROKER CARD */}
                <div className = "vp-card">

                    <div className = "vp-card-row vp-card-banner">
                        <div className = "vp-card-banner-icon">
                            <span className={"fa fa-hand-holding-usd"}></span>
                        </div>
                        <div className = "vp-card-banner-label">
                            {pre.toUpperCase()} BROKER
                        </div>
                    </div>

                    <div className = "vp-card-row">
                        <div className = "vp-card-row-left">
                            Broker
                        </div>
                        <div className= "vp-card-row-right">
                            <ComboBox
                                disabled = { viewOnly }
                                name = {pre+"User"}
                                value = {
                                    this.getComboItemFromId("users", pre+"UserId")
                                }
                                onChange={e=>{this.handleLinkedComboChange(e, pre+"UserId", "username", pre+"Username")}}

                                data = {this.state.users}
                                textField = "username"
                                dataItemKey = "id"

                                // className = "v-combo"
                                suggest={true}
                                clearButton={true}
                                allowCustom={false}
                                popupSettings={{
                                    "animate": false,
                                    "className": "",
                                    "appendTo": window
                                }}
                            />
                        </div>
                    </div>
                    <div className = "vp-card-row">
                        <div className = "vp-card-row-left">
                            Brokerage
                        </div>
                        <div className= "vp-card-row-right">
                            <Input
                                disabled = { viewOnly }
                                name={pre+"Brokerage"}
                                value={trade[pre+"Brokerage"] || ''}
                                onChange={ e => this.handleNumericInputChange(e.target.value, e.target.name)}
                                onBlur = { e => this.formatNumber(e.target.name) }

                                className = "v-inp"
                            />
                        </div>
                    </div>

                </div>

                {/* BUY RECIPIENTS CARD */}
                <div className = {"vp-card email-table-card"}>
                    <div className = "vp-card-row vp-card-banner">
                        <div className = "vp-card-banner-icon">
                            <span className={"fa fa-envelope"}></span>
                        </div>
                        <div className = "vp-card-banner-label">
                            {pre.toUpperCase()} RECIPIENTS
                        </div>
                        { !viewOnly &&
                        <div className= "vp-card-banner-close">
                                <span
                                    className="k-icon k-i-add"
                                    onClick={()=>this.handleAddEmail(pre)}
                                ></span>
                        </div>
                        }
                    </div>

                    {(this.state.buyEmailIxToSelect!==-1 || this.state.sellEmailIxToSelect!==-1) &&
                    this.props.window.scrollTo({
                        top: window.scrollHeight,
                        behavior: 'smooth'
                    })}

                    <table className = "email-table">
                        <tbody>
                        {trade[pre+'Emails'] && _.cloneDeep(trade[pre+'Emails']).map( (obj, ix) => {
                            const path = pre+"Emails["+ix+"]";
                            return <tr key={obj.id}>
                                <td> {ix+1} </td>
                                <td>
                                    <div
                                        ref={
                                            (x) => {
                                                if(x && ix === this.state[pre+'EmailIxToSelect']) {
                                                    x.querySelector(`[name=${pre+"Email"+ix}]`).select()
                                                    this.setState({[pre+'EmailIxToSelect']: -1})
                                                }
                                            }}
                                    >
                                        <Input
                                            disabled = { viewOnly || obj.status === 2}
                                            name={pre+"Email"+ix}
                                            value={_.get(trade, path+".email") || ''}
                                            onChange={ e => this.handleInputChange(e.target.value, path+".email")}
                                            onKeyDown={e => this.handleEmailInputEnter(e.key, pre)}
                                            className = "email-inp"
                                        />
                                    </div>
                                </td>
                                <td>
                                    {!viewOnly && obj.status !==2  &&
                                    <span className={"k-icon k-i-close"}
                                          onClick={() => this.handleRemoveEmail(ix, pre + "Emails")}></span>
                                    }
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                </div>

            </div>

    }


    render() {
        const trade = {...this.state.trade}

        console.log('Trade')
        console.log(trade)

        const { editor } = trade;
        const { viewOnly, metadataExpanded } = this.state
        const window = this.props.window == null ? null : this.props.window.document.body;

        const {canEnterEdit, handleEnterEdit} = this.props;
        // console.log(JSON.stringify(trade))
        // const BuyBrokeragePanel = <BrokeragePanel pre = "buy"/>
        return (
            <div className = "view" id = 'entry-form-wrapper'>
                {this.props.changed &&
                    <div className = "save-btn-wrapper">
                        <Button
                            disabled = {viewOnly}
                            icon={"save"}
                            onClick={this.handleSaveChanges}
                            primary={true}
                        >
                            Save Changes
                        </Button>
                    </div>
                }
                {(viewOnly && canEnterEdit) &&
                    (<div className = "save-btn-wrapper">
                        {editor!=null ?
                            <div className='in-edit-wrapper'> In Edit by {editor} </div> :
                            <Button
                                icon = {'edit'}
                                primary = {true}
                                onClick = {handleEnterEdit}
                            >
                                Enter Edit
                            </Button>
                        }
                    </div>)
                }
                {/* SYSTEM DATA BAR */}
                {/*<div className = {"vp"} id = "vp-sticky">*/}
                {/*    <div className = "vp-card-banner-close">*/}
                {/*        <Button
                disabled = {viewOnly*/}
                {/*            icon={"save"}*/}
                {/*            onClick={this.handleSaveChanges}*/}
                {/*            primary={"true"}*/}
                {/*        >*/}
                {/*            Save Changes*/}
                {/*        </Button>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/* TRADE METADATA PANEL */}
                <div className='vp' id = 'meta-vp'>
                    <div className = 'vp'>
                        <span
                            className={'k-icon k-i-' + (metadataExpanded ? 'close' : 'add')} id = {'meta-expander'}
                            onClick = {()=>this.setState({metadataExpanded: !metadataExpanded})}
                        />
                        <div className = 'meta-div'>
                            <span className = 'meta-title'> Trade ID: </span>
                            <span className= 'meta-value'> {trade.id} </span>
                        </div>
                        <div className = 'tradeDate-wrapper'>
                            <span className = 'meta-title tradeDate-left'> Trade Date: </span>
                            <Input
                                type = 'date'
                                disabled = { viewOnly }
                                name = "tradeDate"
                                value={trade.tradeDate || ''}
                                onChange={e=>{this.handleInputChange(e.target.value, e.target.name)}}

                                format={"MMM-dd-yyyy"}
                                className={"v-datepicker tradeDate-right"}
                            />
                            {/*<DatePicker*/}
                            {/*    disabled = { viewOnly }*/}
                            {/*    name = "tradeDate"*/}
                            {/*    value={new Date(trade.tradeDate)}*/}
                            {/*    onChange={e=>this.handleInputChange(e.target.value, e.target.name)}*/}

                            {/*    format={"MMM-dd-yyyy"}*/}
                            {/*    className={"v-datepicker tradeDate-right"}*/}
                            {/*    popupSettings={{*/}
                            {/*        "animate": false,*/}
                            {/*        "className": "",*/}
                            {/*        "appendTo": window*/}
                            {/*    }}*/}
                            {/*/>*/}
                    </div>

                    {!!metadataExpanded &&
                        <div className = 'vp' id = 'meta-content'>
                            <div className = 'vp'>
                                <div className = 'meta-div'>
                                    <span className = 'meta-title'> Order ID: </span>
                                    <span className= 'meta-value'> {trade.orderId ? trade.orderId : 'n/a'} </span>
                                </div>

                                <div className = 'meta-div'>
                                    <span className = 'meta-title'> Bid ID: </span>
                                    <span className= 'meta-value'> {trade.bidId ? trade.bidId : 'n/a'} </span>
                                </div>

                                <div className = 'meta-div'>
                                    <span className = 'meta-title'> Voided: </span>
                                    <Checkbox
                                        disabled={viewOnly}
                                        onChange={this.handleVoided}
                                        value = {trade.voided === 1}
                                    />
                                </div>
                            </div>
                            <div className = 'vp'>
                                <div className = 'meta-div'>
                                    <span className = 'meta-title'> Created By: </span>
                                    <span className= 'meta-value'> {trade.createdBy} </span>
                                </div>

                                <div className = 'meta-div'>
                                    <span className = 'meta-title'> Created At: </span>
                                    <span className= 'meta-value'> {FormatDateTime(trade.createdAt)} </span>
                                </div>
                            </div>
                            <div className = 'vp'>
                                <div className = 'meta-div'>
                                    <span className = 'meta-title'> Updated By: </span>
                                    <span className= 'meta-value'> {trade.updatedBy} </span>
                                </div>

                                <div className = 'meta-div'>
                                    <span className = 'meta-title'> Last Updated: </span>
                                    <span className= 'meta-value'> {FormatDateTime(trade.updatedAt)} </span>
                                </div>
                            </div>

                        </div>
                    }
                    </div>

                </div>
                {/* BASE INFO PANEL */}
                <div className = "vp vp-no-header" id = "core-vp">
                    <div className = "vp-vertical-wrap v-combo-wrap">
                        <div className = "vp-label">
                            UNDERLYING
                        </div>
                        <ComboBox
                            disabled = { viewOnly }
                            name = "ticker"
                            value = {{"id": trade.underlyingId, "ticker": trade.ticker}}
                            onChange={e=>this.handleLinkedComboChange(e, "underlyingId", "ticker", "ticker")}

                            data = {this.state.underlyings}
                            textField = "ticker"
                            dataItemKey = "id"

                            // className = "v-combo"
                            suggest={true}
                            clearButton={false}
                            allowCustom={false}
                            popupSettings={{
                                "animate": false,
                                "className": "",
                                "appendTo": window
                            }}
                        />
                    </div>

                    <div className = "vp-vertical-wrap v-date-wrap">
                        <div className = "vp-label">
                            EXPIRATION
                        </div>
                        <Input
                            type = "date"
                            disabled = { viewOnly }
                            name = "expiration"
                            value={trade.expiration || ''}
                            onChange={e=>this.handleInputChange(e.target.value, e.target.name)}

                            format={"MMM-dd-yyyy"}
                            className={"v-datepicker"}
                        />
                    </div>

                    <div className = "vp-vertical-wrap v-combo-wrap">
                        <div className = "vp-label">
                            STRUCTURE
                        </div>
                        <ComboBox
                            disabled = { viewOnly }
                            name = "structure"
                            value = {trade.structure}
                            onChange={(e)=>{
                                this.handleInputChange(e.target.value, e.target.name, true);
                                // this.generateLegs(e.target.value);
                            }}

                            data = {structureList}

                            suggest={true}
                            allowCustom={false}
                            clearButton={false}
                            popupSettings={{
                                "animate": false,
                                "className": "",
                                "appendTo": window
                            }}
                        />
                    </div>

                    {/*<div className = "vp-vertical-wrap v-inp-wrap">*/}
                    {/*    <div className = "vp-label">*/}
                    {/*        PRICE*/}
                    {/*    </div>*/}
                    {/*    <Input*/}
                    {/*        disabled = { viewOnly }*/}
                    {/*        name="price"*/}
                    {/*        value={trade.price || ''}*/}
                    {/*        onChange={ e => this.handleNumericInputChange(e.target.value, e.target.name)}*/}
                    {/*        onBlur = { e => this.formatNumber(e.target.name) }*/}

                    {/*        className = "v-inp"*/}
                    {/*    />*/}
                    {/*</div>*/}

                    {/*<div className = "vp-vertical-wrap v-inp-wrap">*/}
                    {/*    <div className = "vp-label">*/}
                    {/*        SIZE*/}
                    {/*    </div>*/}
                    {/*    <Input*/}
                    {/*        disabled = { viewOnly }*/}
                    {/*        name="size"*/}
                    {/*        value={trade.size || ''}*/}
                    {/*        onChange={ e =>this.handleNumericInputChange(e.target.value, e.target.name)}*/}
                    {/*        onBlur = { e => this.formatNumber(e.target.name) }*/}

                    {/*        className = "v-inp"*/}
                    {/*    />*/}
                    {/*</div>*/}
                </div>

                {/* LEGS PANEL */}
                <div className={"vp"} id = "legs-vp">
                    <div className = {"vp-banner-wrapper"}>
                        <div className = {"vp-banner"}>
                            <div className = {"vp-banner-label"}> LEGS </div>
                            <Button
                                disabled = {viewOnly}
                                iconClass="fa fa-chevron-circle-up"
                                onClick={()=>this.handleAddLeg("CALL")}
                                id = {"btn-add-call"}
                            >
                                Add Call
                            </Button>
                            <Button
                                disabled = {viewOnly}
                                iconClass="fa fa-chevron-circle-down"
                                onClick={()=>this.handleAddLeg("PUT")}
                                id = {"btn-add-put"}
                            >
                                Add Put
                            </Button>
                        </div>
                    </div>

                    <ViewCards
                        trade = {this.state.trade}
                        window = {window}
                        handleInputChange = {this.handleInputChange}
                        handleNumericInputChange = {this.handleNumericInputChange}
                        handleCheckBoxChange={this.handleCheckBoxChange}
                        formatNumber={this.formatNumber}
                        handleRemoveLeg={this.handleRemoveLeg}
                        viewOnly = {viewOnly}
                    />
                </div>

                {/* OPTION PANEL */}
                <div className={"vp vp-no-header"} id = "option-vp">
                    <div className = "vp-vertical-wrap v-combo-wrap">
                        <div className = "vp-label">
                            OPTION FLOOR
                        </div>
                        <ComboBox
                            disabled = { viewOnly }
                            name = "optionFloor"
                            value = {trade.optionFloor}
                            onChange={e=>this.handleInputChange(e.target.value, e.target.name)}

                            data = {optionFloorList}

                            // className = "v-combo"
                            suggest={true}
                            clearButton={false}
                            allowCustom={false}
                            popupSettings={{
                                "animate": false,
                                "className": "",
                                "appendTo": window
                            }}
                        />
                    </div>

                    <div className = "vp-vertical-wrap v-combo-wrap">
                        <div className = "vp-label">
                            FLOOR BROKER
                        </div>
                        <ComboBox
                            disabled = { viewOnly }
                            name = "optionFloorBroker"
                            value = {trade.optionFloorBroker}
                            onChange={e=>this.handleInputChange(e.target.value, e.target.name)}

                            data = {optionFloorBrokerList}

                            // className = "v-combo"
                            suggest={true}
                            clearButton={false}
                            allowCustom={false}
                            popupSettings={{
                                "animate": false,
                                "className": "",
                                "appendTo": window
                            }}
                        />
                    </div>

                    <div className = "vp-vertical-wrap v-combo-wrap">
                        <div className = "vp-label">
                            CLIENT CATEGORY
                        </div>
                        <ComboBox
                            disabled = { viewOnly }
                            name = "optionClientCategory"
                            value = {trade.optionClientCategory}
                            onChange={e=>this.handleInputChange(e.target.value, e.target.name)}

                            data = {optionClientCategoryList}

                            // className = "v-combo"
                            suggest={true}
                            clearButton={false}
                            allowCustom={false}
                            popupSettings={{
                                "animate": false,
                                "className": "",
                                "appendTo": window
                            }}
                        />
                    </div>

                    <div className = "vp-vertical-wrap v-datetime-wrap">
                        <div className = "vp-label">
                            OPTION EXECUTION TIME
                        </div>
                        <Input
                            type="time"
                            disabled = { viewOnly }
                            name = "optionExecutionTime"
                            value={trade.optionExecutionTime || ''}
                            step = '1'
                            onChange={e=>this.handleInputChange(e.target.value, e.target.name)}
                            className={"v-datetime-widget"}
                        />

                        {/*<TimePicker*/}
                        {/*    disabled = { viewOnly }*/}
                        {/*    name = "optionExecutionTime"*/}
                        {/*    value={new Date(trade.optionExecutionTime)}*/}
                        {/*    onChange={e=>this.handleInputChange(e.target.value, e.target.name)}*/}

                        {/*    format="hh:mm:ss a"*/}

                        {/*    className={"v-datetime-widget"}*/}
                        {/*    popupSettings={{*/}
                        {/*        "animate": false,*/}
                        {/*        "appendTo": window*/}
                        {/*    }}*/}
                        {/*/>*/}

                    </div>
                </div>

                {/* HEDGE PANEL */}
                <div className={"vp"} id ={"hedge-vp-wrapper"}>
                    <div className={"vp"} id ={"hedge-vp"}
                         style={trade.isHedged?null:{"borderColor": "rgba(100,181,246, .4)"}}>
                    <div className = {"vp-banner-wrapper"}>
                        <div className = {"vp-banner"}>
                            <div className = {"vp-banner-label"} style = {trade.isHedged?null:{"opacity": .4}}> HEDGE </div>
                            {!trade.isHedged &&
                                <Button
                                    disabled = {viewOnly}
                                iconClass="fa fa-dollar-sign"
                                onClick={this.handleAddHedge}
                            >
                                Add Hedge
                            </Button>}
                        </div>
                    </div>

                    {trade.isHedged ?
                        [
                            <div className = "vp-card">
                        <div className = "vp-card-row vp-card-banner">
                            <div className = "vp-card-banner-icon">
                                <span className={"k-icon k-i-dollar\n"}></span>
                            </div>
                            <div className = "vp-card-banner-label">
                                HEDGE
                            </div>
                            <div className= "vp-card-banner-close">
                                { !viewOnly &&
                                <span
                                className="k-icon k-i-close"
                                onClick={this.handleRemoveHedge}
                                ></span>
                                }
                            </div>
                        </div>

                        <div className = "vp-card-row">
                            <div className = "vp-card-row-left">
                                Price
                            </div>
                            <div className= "vp-card-row-right">
                                <Input
                                    disabled = { viewOnly }
                                    name="hedgePrice"
                                    value={trade.hedgePrice || ''}
                                    onChange={ e => this.handleNumericInputChange(e.target.value, e.target.name)}
                                    onBlur = { e => this.formatNumber(e.target.name) }

                                    className = "v-inp"
                                />
                            </div>
                        </div>

                        <div className = "vp-card-row">
                            <div className = "vp-card-row-left">
                                Size
                            </div>
                            <div className= "vp-card-row-right">
                                <Input
                                    disabled = { viewOnly }
                                    name="hedgeSize"
                                    value={trade.hedgeSize || ''}
                                    onChange={ e => this.handleNumericInputChange(e.target.value, e.target.name)}
                                    onBlur = { e => this.formatNumber(e.target.name) }

                                    className = "v-inp"
                                />
                            </div>
                        </div>

                        <div className = "vp-card-row">
                            <div className = "vp-card-row-left">
                                Buyer
                            </div>
                            <div className= "vp-card-row-right">
                                <ComboBox
                                    disabled = { viewOnly }
                                    name = "hedgeSaleType"
                                    value = {trade.hedgeSaleType}
                                    onChange={e=>this.handleInputChange(e.target.value, e.target.name)}

                                    data = {hedgeSaleTypeList}

                                    // className = "v-combo"
                                    suggest={true}
                                    clearButton={false}
                                    allowCustom={false}
                                    popupSettings={{
                                        "animate": false,
                                        "className": "",
                                        "appendTo": window
                                    }}
                                />
                            </div>
                        </div>


                    </div>
                            ,
                            <div className = {"vp-card-bare"} >
                                <div className = "vp-vertical-wrap">
                                    <div className = "vp-label">
                                        HEDGE CROSS AGENT
                                    </div>
                                    <ComboBox
                                        disabled = { viewOnly }
                                        name = "hedgeCrossAgent"
                                        value = {trade.hedgeCrossAgent}
                                        onChange={e=>this.handleInputChange(e.target.value, e.target.name)}

                                        data = {hedgeCrossAgentList}

                                        // className = "v-combo"
                                        suggest={true}
                                        clearButton={false}
                                        allowCustom={false}
                                        popupSettings={{
                                            "animate": false,
                                            "className": "",
                                            "appendTo": window
                                        }}
                                    />
                                </div>
                                <div className = "vp-vertical-wrap">
                                    <div className = "vp-label">
                                        HEDGE EXECUTION TIME
                                    </div>
                                    <Input
                                        type="time"
                                        disabled = { viewOnly }
                                        name = "hedgeExecutionTime"
                                        value={trade.hedgeExecutionTime || ''}
                                        step = '1'
                                        onChange={e=>this.handleInputChange(e.target.value, e.target.name)}
                                        className={"v-datetime-widget"}
                                    />
                                    {/*<TimePicker*/}
                                    {/*    disabled = { viewOnly }*/}
                                    {/*    name = "hedgeExecutionTime"*/}
                                    {/*    value={new Date(trade.hedgeExecutionTime)}*/}
                                    {/*    onChange={e=>this.handleInputChange(e.target.value, e.target.name)}*/}

                                    {/*    format="hh:mm:ss a"*/}

                                    {/*    className={"v-datetime-widget"}*/}
                                    {/*    popupSettings={{*/}
                                    {/*        "animate": false,*/}
                                    {/*        "appendTo": window*/}
                                    {/*    }}*/}
                                    {/*/>*/}
                                    {/*<DateTimePicker*/}
                                    {/*    disabled = { viewOnly }*/}
                                    {/*    name = "hedgeExecutionTime"*/}
                                    {/*    value={new Date(trade.hedgeExecutionTime)}*/}
                                    {/*    onChange={e=>this.handleInputChange(e.target.value, e.target.name)}*/}

                                    {/*    format={"MMM-dd-yyyy @ hh:mm:ss a"}*/}
                                    {/*    className={"v-datetime-widget"}*/}
                                    {/*    popupSettings={{*/}
                                    {/*        "animate": false,*/}
                                    {/*        "className": "",*/}
                                    {/*        "appendTo": window*/}
                                    {/*    }}*/}
                                    {/*/>*/}
                                </div>
                            </div>
                        ] :
                        null
                    }
                    </div>
                </div>
                <div id ="hedge-vp-after"></div>

                {/* BROKERAGE PANELS */}
                <div className={"vp"} id ={"brokerage-vp-wrapper"}>
                    <div className={"vp"} id = {"brokerage-vp"}>
                        {this.BrokeragePanel(trade, window,"buy", viewOnly)}
                        {this.BrokeragePanel(trade, window,"sell", viewOnly)}
                    </div>
                </div>

                <NotificationGroup
                    style={{
                        right: 0,
                        bottom: 0,
                        alignItems: 'flex-start',
                        flexWrap: 'wrap-reverse'
                    }}
                >
                    <Zoom enter={true} exit={true}>
                        {this.state.successMsg && <Notification
                            type={{ style: 'success', icon: true }}
                            closable={true}
                            onClose={() => this.setState({ successMsg: null })}
                        >
                            <span> {this.state.successMsg} </span>
                        </Notification>}
                    </Zoom>
                    <Zoom enter={true} exit={true}>
                        {this.state.errorMsg && <Notification
                            type={{ style: 'error', icon: true }}
                            closable={true}
                            onClose={() => this.setState({ errorMsg: null })}
                        >
                            <span> {this.state.errorMsg }</span>
                        </Notification>}
                    </Zoom>
                </NotificationGroup>
                {/*{Object.keys(trade).map((key, i) => {*/}
                {/*    return <div style={{"flex":"100%"}}> <span> {key} </span> <span> {trade[key]}</span> </div>*/}
                {/*})}*/}

            </div>
        );
    }
}

export default EntryForm;
import {
    organizationsService,
    structuresService,
} from '../../feathers';

/** Types **/
export const SET_STRUCTURES = 'SET_STRUCTURES';
export const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS';

/** Actions **/

export const setStructures = () => {
    return async (dispatch) => {
        try {
            const structures = await structuresService.find();

            dispatch({
                type: SET_STRUCTURES,
                structures
            });

        } catch (e) {
            console.log("Error setting structures");
            console.error(e);
        }
    }
}

export const setOrganizations = () => {
    return async (dispatch) => {
        try {
            const organizations = await organizationsService.find({
                query: {
                    $select: ['id', 'code']
                }
            });

            dispatch({
                type: SET_ORGANIZATIONS,
                organizations
            });

        } catch (e) {
            console.log("Error setting organizations");
            console.error(e);
        }
    }
}
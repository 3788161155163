import {
    SET_AUTH
} from "../types";


export default function setTradesState (user) {
    return {
        type: SET_AUTH,
        user: user,
    }
}
import {
    SET_ROOMS,
    CREATE_ROOM,
    CREATE_ROOM_USER,
    REMOVE_ROOM_USER,
    CREATE_MESSAGE,
    PATCH_ROOM
} from '../actions/types';
import {combineReducers} from 'redux';
import produce from "immer"
import GetByIdWithRelated from '../../helpers/GetByIdWithRelated';

const byId = produce((draft, action) => {
    switch(action.type) {
        case SET_ROOMS: // takes in raw list of room entities eg [{id: 1, messages: [{id: 1}]}]
            return GetByIdWithRelated(action.rooms, ['messages', 'users']);
        case PATCH_ROOM:
            // only update the metadata, make sure not to overwrite the associations
            draft[action.room.id].name = action.room.name;
            draft[action.room.id].description = action.room.description;
            return;
        case CREATE_ROOM:
            // The list of user ids comes prepackaged from the roomLogicListener event
            // action.room.messages = [];
            // action.room.users = []; // create blank list of associated users for a newly created room
            draft[action.room.id] = action.room;
            return;
        // Handle user adding a message - append to list of messages
        case CREATE_MESSAGE: {
            const {message} = action;
            draft[message.roomId].messages.push(message.id);
            return;
        }
        // Handle user joining/leaving a room
        case CREATE_ROOM_USER: {
            const {roomUser} = action;
            if(draft[roomUser.roomId]) {
                if (draft[roomUser.roomId] && draft[roomUser.roomId].users && !draft[roomUser.roomId].users.includes(roomUser.userId)) {
                    draft[roomUser.roomId].users.push(roomUser.userId);
                }
                return;
            }
            return;
        }
        case REMOVE_ROOM_USER: {
            const {roomUser} = action;
            const userIdIndex = draft[roomUser.roomId].users
                .findIndex(userId => userId === roomUser.userId);
            if (userIdIndex !== -1)
                draft[roomUser.roomId].users.splice(userIdIndex, 1);
            return;
        }
    }
}, {});

const allIds = produce((draft, action) => {
    switch (action.type) {
        case SET_ROOMS:
            return action.rooms.map(room => room.id);
        case CREATE_ROOM:
            draft.push(action.room.id);
            return;
    }
}, []);

const RoomsReducer = combineReducers({
    byId: byId,
    allIds: allIds,
})

export default RoomsReducer;
import {
    CREATE_AIVA_PORTAL
} from '../types';

export default function createAivaPortal (portal) {
    return async dispatch => {
        dispatch({
            type: CREATE_AIVA_PORTAL,
            portal
        })
    }
}
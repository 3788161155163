import React, {useEffect} from 'react';

/** Actions **/
import {
    createBidFromNLPMap
} from '../../../../redux/actions';

/** Helpers **/
import {ExtractBidNLP} from '../../../../helpers';

/** Style **/
import BEMHelper from 'react-bem-helper';
import {Input} from '@progress/kendo-react-inputs';
const classes = new BEMHelper('Preview');

const onDialogInputChange = (event, itemInEdit) => {
    const edited = {...itemInEdit};

    let target = event.target;
    const name = target.props ? target.props.name : target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    if(name === "ask" || name === "bid"){
        if (!/[a-zA-Z]+/.test(value) && (value.match(/\./g) || []).length<=1) {
            if (String(value) === value && value.trim() == "") {
                value = null;
            }
            edited[name] = value;
        }
    } else {
        if (['orgCode'].includes(name)) {
            if(value!==null) value = value.toUpperCase();
        }

        if (String(value) === value && value.trim() == "") {
            value = null;
            if (name === "size") {
                edited["asize"] = null;
                edited["bsize"] = null;
            }
        }
        edited[name] = value;
    }

    return edited;
}

const BidPreview = (props) => {
    const {
        itemInEdit,
        setItemInEdit,

        chat,
        previewBEM,
    } = props;

    useEffect(() => {
        // capture group only what is between 'rfq' and an optional newline
        const RFQ_REGEX = new RegExp("[rR][fF][qQ][: ]*(.*)[\\r\\n|\\r|\\n]?")
        let regex_result = RFQ_REGEX.exec(chat);
        const nlp_string = regex_result != null ? regex_result[1] : ''

        // raw nlp map comes in with fields in arrays
        const nlp_map_raw = ExtractBidNLP(nlp_string, {});
        const nlp_map = {
            orgCode: nlp_map_raw.orgCode ? nlp_map_raw.orgCode[0] : undefined,
            direction: nlp_map_raw.direction ? nlp_map_raw.direction[0] : undefined,
            bid: nlp_map_raw.bid ? nlp_map_raw.bid[0] : undefined,
            ask: nlp_map_raw.ask ? nlp_map_raw.ask[0] : undefined,
            size: nlp_map_raw.size ? nlp_map_raw.size[0] : undefined
        }

        console.log(nlp_map)
        setItemInEdit(nlp_map);
    }, [chat])

    const onInputChange = (event) => {
        setItemInEdit(onDialogInputChange(event, itemInEdit));
    }

    const isSeller = itemInEdit.direction === 'seller';

    return <div {...classes('', [previewBEM, 'reply_box'],)}>
        <div {...classes('body')}>
            <table>
                <tbody>
                    <tr {...classes('tr')}>
                        <td {...classes('td_title')}>
                            {isSeller ? 'Seller' : 'Buyer'}
                        </td>
                        <td>
                            <Input
                                type="text"
                                name={'orgCode'}
                                value={itemInEdit['orgCode'] || ''}
                                onChange={onInputChange}
                                autoComplete="off"
                            />
                        </td>
                    </tr>

                    <tr {...classes('tr')}>
                        <td {...classes('td_title')}>
                            {isSeller ? 'Ask' : 'Bid'}
                        </td>
                        <td>
                            <Input
                                type="text"
                                name={isSeller ? 'ask' : 'bid'}
                                value={itemInEdit[isSeller ? 'ask' : 'bid'] || ''}
                                onChange={onInputChange}
                                autoComplete="off"
                            />
                        </td>
                    </tr>

                    <tr {...classes('tr')}>
                        <td {...classes('td_title')}>
                            Size
                        </td>
                        <td>
                            <Input
                                type="text"
                                name={'size'}
                                value={itemInEdit['size'] || ''}
                                onChange={onInputChange}
                                autoComplete="off"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
}

export default BidPreview;
import store from '../redux/store';

/** Gets industry information and name for string of tickers **/
export default async function getEnhancedQuotes(symbolsString) {
    const {
        qsSid,
        user: {qsWmid}
    } = store.getState().authReducer;

    const response = await fetch(`https://app.quotemedia.com/data/getEnhancedQuotes.json?symbols=${symbolsString}&webmasterId=${qsWmid}&sid=${qsSid}`);
    const data = await response.json();
    return data.results.quote;
}
import {bidsService} from '../../../feathers'
import {SET_BIDS} from '../types';
import moment from 'moment';

const defaultQuery = {
    createdAt: {
        $gt: moment().startOf('day')
    }
}

export default function (query = defaultQuery){
    return async (dispatch) => {
        bidsService.find({
            query: query
        }).then(res => {
            dispatch({
                type: SET_BIDS,
                bids: res
            });
        }).catch(error => {
            console.log('Error Setting Bids');
            console.log(error);
        });
    }
}
import {createCachedSelector} from 're-reselect';

const getUserById = (state, userId) => state.usersReducer.byId[userId];

const selectUserById = createCachedSelector(
    getUserById,
    (user) => user
)(
    (_state_, userId) => userId
)

export default selectUserById;
import React from 'react';
import client from "../../feathers";

/** Kendo **/
import '@progress/kendo-react-intl'
import '@progress/kendo-react-dropdowns'
import '@progress/kendo-react-buttons'
import '@progress/kendo-react-inputs'
import 'react-router-dom'
import {Zoom} from "@progress/kendo-react-animation";
import {Notification, NotificationGroup} from "@progress/kendo-react-notification";
import {DropDownList} from '@progress/kendo-react-dropdowns';

/** Styling **/
import styled from 'styled-components'
import EntryForm from "./EntryForm";

/** helpers **/
import FormatDateTime from "../../helpers/FormatDateTime";

/** Feathers **/
const versionsService = client.service('versions');

/** Style **/




class VersionView extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            dropDownVersion: undefined, // the object of the selected dropdownlist item
            version: undefined, // the actual full object of the selected version
            versions: []
        }

    }

    setVersionState = (dropDownVersion) => {
        versionsService.get(dropDownVersion.id).then(version => {
           this.setState({
               dropDownVersion,
               version
           });
        });
    }

    componentDidMount() {
        console.log(this.props.tradeId)
        versionsService.find(({
            query: {
                tradeId: this.props.tradeId,
                $select: [ 'id', 'tradeId', 'createdBy', 'createdAt' ],
                $sort: {
                    createdAt: -1
                }
            }
        })).then( versions => {
            console.log(this.props.tradeId)
            console.log(versions)
            if (versions.length > 0 ) this.setVersionState(versions[0]);
            this.formatVersions(versions);
        }).catch( err => {
            alert('Error retrieving version history: ' + err);
        })
    }

    handleVersionChange = (e)=> {
        this.setVersionState(e.target.value);
    }

    formatVersions = (versions) => {
        versions.forEach( (v, ix) => {
            v.des = `${FormatDateTime(v.createdAt)} - ${v.createdBy}`;
        });

        this.setState({versions})
    }

    itemRender = (li, itemProps) => {
        const index = itemProps.index;
        const itemChildren = [
            <span style={{ color: '#4da6ff' }}>{index+1}.</span>,
            <span>&nbsp;{li.props.children}</span>
        ];

        return React.cloneElement(li, li.props, itemChildren);
    }

    render() {
        const {
            dropDownVersion,
            versions,
            version
        } = this.state;

        const {window} = this.props;
        return(
            <div>
                <DropDownList
                    data={versions}
                    textField="des"
                    dataItemKey="id"
                    value={dropDownVersion}
                    onChange={this.handleVersionChange}
                    className={'sticky-top'}
                    itemRender={this.itemRender}

                    popupSettings={
                        {
                            'appendTo': window
                        }
                    }
                />

                <EntryForm
                    viewOnly = {true}
                    canEnterEdit = {false}
                    trade = {version}
                />
            </div>
        )
    }
}

export default VersionView;
import {
    SET_QS_SID
} from '../types';

/** Sets the session ID variable for rest api requests **/
export default function (qsSid) {
    return {
        type: SET_QS_SID,
        qsSid
    }
}
import React from 'react';

/** Style **/
import './Main.scss'

/** Redux **/
import {connect} from 'react-redux';

/** Components **/
import ChatApp from './ChatApp';
import ThreadApp from './ThreadApp';

const Main = (props) => {
    const {
        selectedRoomId,
        selectedThreadId
    } = props;

    return <div className={'Main'}>
            <ChatApp
                roomId = {selectedRoomId}
            />
        {
            selectedThreadId != -1 && <ThreadApp
                roomId = {selectedRoomId}
                messageId = {selectedThreadId}
            />
        }
    </div>
}

const mapStateToProps = (state) => {
    return {
        selectedRoomId: state.aivaReducer.selectedRoom,
        selectedThreadId: state.aivaReducer.selectedThread
    }
}

export default connect(mapStateToProps, null)(Main);
import {createCachedSelector} from 're-reselect';
import {INTEREST_TYPE} from '../../helpers/constants';

/** Splits list of interests into three categories **/
const _categorizeInterests = (interests, live_ints, inactive_ints, traded_ints) => {
    interests.forEach(interest => {
        const {
            inactive,
            blockId,
            isVoided
        } = interest;

        if (!isVoided) {
            if (inactive) inactive_ints.push(interest);
            else if (blockId != null) traded_ints.push(interest);
            else live_ints.push(interest);
        }
    });
}

/** Get best price and size in object for market **/
const _getBestInterest = (live_interests = []) => {
    if (live_interests == null || live_interests.length === 0) return {price: null, size: null};

    const price = live_interests[0].price;
    let size = 0;
    for (let i = 0; i < live_interests.length; i++) {
        if (live_interests[i].price === price) size += live_interests[i].size;
        else break;
    }

    return {price, size};
}

/**
 * Returns a dictionary of interest objects
 * The objects are in sorted by createdAt order
 * Live, Inactive, Traded
 * {
 *     bids: []
 *     asks: []
 *     directionString
 * }
 */

const getOrderByOrderId = (state, orderId) => state.wbOrdersReducer.byId[orderId] ?? {}
const getBidsById = (state, _) => state.wbBidsReducer.byId;

const selectWbMarketByOrderId = createCachedSelector(
    getOrderByOrderId,
    getBidsById,
    (order, interestMap) => {
        const interestIds = order.bids;
        if (interestIds == null) {
            return {
                bids: [],
                asks: []
            }
        }

        let [all_bids, live_bids, inactive_bids, traded_bids] = [[], [], [], []];
        let [all_asks, live_asks, inactive_asks, traded_asks] = [[], [], [], []];

        /** Sort the bids by price and creation date **/
        const orderOrgCode = order.orgCode ?? '?';

        /** Also find the BUYER or SELLER org. bestBid and bestAsk are for the potential TWO WAY **/
        let clientBids = [], clientAsks = [];

        interestIds.forEach(interestIds => {
            const interest = interestMap[interestIds];
            if (interest) {
                if (interest.direction === INTEREST_TYPE.BID) {
                    all_bids.push(interest);
                    if (interest.orgCode === orderOrgCode) clientBids.push(interest.price);
                } else {
                    all_asks.push(interest);
                    if (interest.orgCode === orderOrgCode) clientAsks.push(interest.price);
                }
            }
        });

        all_bids.sort((a, b) => {
           return b.price - a.price || new Date(a.createdAt) - new Date(b.createdAt);
        });
        all_asks.sort((a, b) => {
            return a.price - b.price || new Date(a.createdAt) - new Date(b.createdAt);
        });

        let orgString = '';
        if (clientBids.length || clientAsks.length) {
            if (clientBids.length && clientAsks.length) {
                orgString = '2-WAY ' + Math.max(...clientBids) + '/' + Math.min(...clientAsks);
            } else {
                orgString = (clientBids.length
                    ? 'BUYER ' + Math.max(...clientBids)
                    : 'SELLER ' + Math.min(...clientAsks)
                )
            }
        }

        /** Split interests into categories (modify the three arrays in place) **/
        _categorizeInterests(all_bids, live_bids, inactive_bids, traded_bids);
        _categorizeInterests(all_asks, live_asks, inactive_asks, traded_asks);

        /** Get best price and best size for market **/
        const bestBid = _getBestInterest(live_bids);
        const bestAsk = _getBestInterest(live_asks);

        return {
            live_bids,
            inactive_bids,
            traded_bids,

            live_asks,
            inactive_asks,
            traded_asks,

            bestBid,
            bestAsk,
            orgString
        }
    }
)(
    (_state_, orderId) => orderId ?? -1
)

export default selectWbMarketByOrderId;
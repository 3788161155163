import {combineReducers} from 'redux';

import {
    SET_WB_ORDERS_LOADING,
    TOGGLE_DEPTH,
    SET_SPREAD_FORM_PROPS,
    SET_ORDER_FORM_PROPS,
    SET_ORDER_MENU_PROPS,
    TOGGLE_DETAIL,
    SET_TAB_FORM_PROPS,
    SET_TRADE_FORM_PROPS, TOGGLE_SETTINGS_MODAL
} from '../actions/wb';

const DEFAULT_WB_STATE = {
    wbOrdersLoading: true,
    settingsModalOpen: false,
}

const wbState = (state = DEFAULT_WB_STATE, action) => {
    switch(action.type) {
        case SET_WB_ORDERS_LOADING:
            return {
                ...state,
                wbOrdersLoading: action.wbOrdersLoading
            }
        case TOGGLE_SETTINGS_MODAL:
            return {
                ...state,
                settingsModalOpen: action.settingsModalOpen
            }
        default:
            return state;
    }
}

/** Dictionary of {
 *      orderId: is the market/depth expanded
 * }
 */

const openDepths = (state = {}, action) => {
    switch (action.type) {
        case TOGGLE_DEPTH: {
            let copy = {...state};

            if (copy[action.orderId])
                delete copy[action.orderId];
            else
                copy[action.orderId] = true;

            return copy;
        }
        default:
            return state;
    }
}

const openDetails = (state = {}, action) => {
    switch(action.type) {
        case TOGGLE_DETAIL: {
            let copy = {...state};

            if(copy[action.orderId])
                delete copy[action.orderId];
            else
                copy[action.orderId] = true;

            return copy;
        }
        default:
            return state;
    }
}

/**
 * Everything needed for SpreadForm
 */
const DEFAULT_SPREAD_FORM_PROPS = {
    orderId: null,
    bidId: null,
    offset: {left: 0, top: 0},
    direction: null,
}

const spreadFormProps = (state = DEFAULT_SPREAD_FORM_PROPS, action) => {
    switch(action.type) {
        case SET_SPREAD_FORM_PROPS: {
            return action.spreadFormProps;
        }
        default:
            return state;
    }
}

const ORDER_MENU_PROPS = {
    order: undefined,
    bid: undefined,
    trade: undefined,
    offset: {left: 0, top: 0}
}

const orderMenuProps = (state = ORDER_MENU_PROPS, action) => {
    switch(action.type) {
        case SET_ORDER_MENU_PROPS:
            return action.orderMenuProps;
        default:
            return state;
    }
}

const DEFAULT_ORDER_FORM_PROPS = {
    prevOrder: undefined,
    actionType: undefined
}
const orderFormProps = (state = DEFAULT_ORDER_FORM_PROPS, action) => {
    switch(action.type) {
        case SET_ORDER_FORM_PROPS: {
            return action.orderFormProps;
        }
        default:
            return state;
    }
}

const DEFAULT_TAB_FORM_PROPS = {
    prevTab: undefined,
    actionType: undefined
}
const tabFormProps = (state = DEFAULT_TAB_FORM_PROPS, action) => {
    switch(action.type) {
        case SET_TAB_FORM_PROPS: {
            return action.tabFormProps;
        }
        default:
            return state;
    }
}

const DEFAULT_TRADE_FORM_PROPS = {
    prevTrade: undefined,
    offset: {left: 0, top: 0}
}
const tradeFormProps = (state = DEFAULT_TRADE_FORM_PROPS, action) => {
    switch(action.type) {
        case SET_TRADE_FORM_PROPS: {
            return action.tradeFormProps;
        }
        default:
            return state;
    }
}

const WbReducer = combineReducers({
    wbState,
    openDepths,
    openDetails,
    spreadFormProps,
    orderFormProps,
    orderMenuProps,
    tabFormProps,
    tradeFormProps
});

export default WbReducer;
import {
    SET_STP_TRADE_ID
} from '../types';

import { tradesService } from '../../../feathers';
import handleValidateTrade from './handleSetStpTrade';

export default function handleShowStp (id) {
    return async dispatch => {
        dispatch({
            type: SET_STP_TRADE_ID,
            id
        })

        tradesService.get(id).then(trade => {
            dispatch(handleValidateTrade(trade, id));
        })
    }
}
import produce from 'immer';
import {combineReducers} from 'redux';

import {
    SET_WB_TRADES,
    CREATE_WB_TRADE,
    UPDATE_WB_TRADE,
} from "../actions/wbTrades";
import GetByIdWithRelated from '../../helpers/GetByIdWithRelated';

const byId = produce((draft, action) => {
    switch (action.type) {
        case SET_WB_TRADES:
            return GetByIdWithRelated(action.trades);
        case CREATE_WB_TRADE:
        case UPDATE_WB_TRADE:
            draft[action.trade.id] = action.trade;
            return;
        // case REMOVE_WB_TRADE:
        //     delete draft[action.trade.id];
        //     return;
    }
}, {});

const WbTradesReducer = combineReducers({
    byId: byId,
});

export default WbTradesReducer;
import React from 'react';

/** Components **/
import ChatName from '../ChatName';

/** Redux **/
import {connect} from 'react-redux';
import {setSelectedRoom} from '../../../../redux/actions';

/** Style **/
import BEMHelper from 'react-bem-helper';
import './RoomList.scss';
import RoomListNotification from '../RoomListNotification';
const classes = new BEMHelper('RoomList');

const RoomList = (props) => {
    const {
        rooms,
        selectedRoom,

        setSelectedRoom
    } = props;


    return <div {...classes()}>
        {rooms.map(room => {
            return <div
                key = {room.id}
                {...classes('li', (selectedRoom === room.id ? 'selected' : 'default'))}
                onClick = {() => setSelectedRoom(room.id)}
            >
                <div {...classes('text')}>{room.type === 'channel' ? `# ${room.name}` : <ChatName roomId = {room.id} />} </div>
                <RoomListNotification roomId = {room.id} />
            </div>
        })}
    </div>
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSelectedRoom: roomId => dispatch(setSelectedRoom(roomId))
    }
}

export default connect (null, mapDispatchToProps) (RoomList);
import React from 'react';

/** Redux **/
import {connect} from 'react-redux';

import {
    setBids,
    toggleIsAivaPortalOpen,
    setRooms,
    setMessages,
    setReplies,
    setUsers,
    setRoomUsers,
} from '../../../redux/actions';

import {setWbOrdersAndBids} from '../../../redux/actions/wbOrders';

import {
    setStructures,
    setOrganizations
} from '../../../redux/actions/static';

import {bindActionCreators} from 'redux';

/** Components **/
import BlackBoard from '../../BlackBoard/BlackBoard';

/** Style **/
import AivaPortal from '../../Aiva/Dialog/Portal';

/** Feathers **/
import ordersListener from '../../../feathers/ordersListener';
import bidsListener from '../../../feathers/bidsListener';

/** Aiva **/
import messagesListener from '../../../feathers/messagesListener';
import repliesListener from '../../../feathers/repliesListener';
import usersListener from '../../../feathers/usersListener';
import roomLogicListener from '../../../feathers/roomLogicListener';
import roomsUsersListener from '../../../feathers/roomsUsersListener';
import tradesListener from '../../../feathers/tradesListener';

class Home extends React.Component {
    constructor(props){
        super(props);
    }

    componentDidMount () {
        ordersListener();
        bidsListener();
        tradesListener();
        // this.props.setWbOrdersAndBids();
        // this.props.setBids();

        this.props.setStructures();
        this.props.setOrganizations();

        /** Aiva **/
        // messagesListener();
        // repliesListener();
        usersListener();
        // roomLogicListener();
        // roomsUsersListener();
        // this.props.setRooms();
        // this.props.setMessages();
        // this.props.setReplies();
        this.props.setUsers();
        // this.props.setRoomUsers();

        // this.props.toggleIsAivaPortalOpen();

        // window.addEventListener('unload', e => {
        //     try {
        //         this.props.aivaPortal.close()
        //     } catch (e) {
        //         window.alert(e)
        //     }
        // });
    }

    render () {
        const {
            isAivaPortalOpen,
        } = this.props;

        return <>
            {/*{*/}
            {/*    isAivaPortalOpen && <AivaPortal />*/}
            {/*}*/}
            {/*<GridApplication />*/}
            <BlackBoard />
        </>
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        toggleIsAivaPortalOpen,
        setBids,
        setWbOrdersAndBids,

        setRooms,
        setMessages,
        setReplies,
        setUsers,
        setRoomUsers,
        setStructures,
        setOrganizations
    }, dispatch);
}

const mapStateToProps = (state) => {
    return {
        aivaPortal: state.aivaReducer.aivaPortal,
        isAivaPortalOpen: state.aivaReducer.isAivaPortalOpen
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (Home);
import React from 'react';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import {connect} from 'react-redux';

// const allData = [
//     { text: "Small", id: 1 },
//     { text: "Medium", id: 2 },
//     { text: "Large", id: 3 }
// ];

class UnderlyingComboBox extends React.Component {
    state = {
        data: this.props.allData,
        loading: false
    };

    filterChange = (event) => {
        this.setState({
            loading: true
        });
        const data = this.filterData(event.filter);
        this.setState({
            data,
            loading: false
        });
    }

    inputChange = (event) => {
        const rawValue = event.target.value ? event.target.value : {ticker: ''};
        const value = {
            id: rawValue.id,
            underlyingId: rawValue.id,
            ticker: rawValue.ticker.toUpperCase(),
        }
        this.props.onChange(value);
    }

    filterData(filter) {
        if(filter.value === '') return [];
        const data = this.props.allData.slice();
        return filterBy(data, {
            field: 'ticker',
            ignoreCase: true,
            operator: 'startswith',
            value: filter.value
        });
    }

    render() {
        const value = {
            id: this.props.underlyingId,
            ticker: this.props.ticker
        };

        let popupSettings = {
            animate: false
        };
        if (this.props.portal) {
            popupSettings.appendTo = this.props.portal.document.body
        }

        return (
            <ComboBox
                onChange={this.inputChange}
                style = {this.props.style}
                name={this.props.name}
                value={value}
                allowCustom={true}
                suggest={true}
                data={this.state.data}
                textField="ticker"
                filterable={true}
                onFilterChange={this.filterChange}
                loading={this.state.loading}
                popupSettings={popupSettings}
            />
        );
    }
}

function mapStateToProps (state) {
    return {
        allData: Object.values(state.underlyingsReducer.underlyingsById)
    }
}

export default connect(mapStateToProps, null) (UnderlyingComboBox);
import {
    UPDATE_ORDER,
} from '../types';
import {
    subscribeToOrderPricing,
} from '../index';

export default function handleOrderUpdated (obj) {
    return async function (dispatch) {
        dispatch({
            type: UPDATE_ORDER,
            order: obj
        });

        dispatch((subscribeToOrderPricing(obj)));
    }
}
import React from 'react';

/** Redux **/
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
    handleSendEmails
} from '../../redux/actions';

/** Kendo **/
import { Window } from '@progress/kendo-react-dialogs';

/** Components **/
import EmailBlotter from './EmailBlotter';

/** helpers **/
import FormatTradeName from '../../helpers/FormatTradeName';

class WindowTitle extends React.Component {
    render() {
        return (
            <div>
                <span className="fas fa-mail-bulk"></span> {FormatTradeName(this.props.trade)}
            </div>
        );
    }
}


const defaultWidth = 600;
const defaultHeight = 400;
const stagePadding = 20;

class StpWindow extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            width: defaultWidth,
            height: defaultHeight,
            windowStage: 'DEFAULT',
        }
    }

    componentDidMount() {
        this.setState({
            left: window.innerWidth - this.state.width - stagePadding,
            top: window.innerHeight - this.state.height - stagePadding,
        });
    }

    handleMove = (event) => {
        this.setState({
            left: event.left,
            top: event.top
        });
    }
    handleResize = (event) => {
        this.setState({
            left: event.left,
            top: event.top,
            width: event.width,
            height: event.height
        });
    }

    handleStageChange = (event) => {
        console.log(event)
        const stage = event.state;

        if (stage === 'MINIMIZED') {
            this.setState({
                left: window.innerWidth - this.state.width - stagePadding,
                top: window.innerHeight - 30 - stagePadding,
            })
        } else if (stage === 'DEFAULT') {
            this.setState({
                height: defaultHeight,
                left: window.innerWidth - this.state.width - stagePadding,
                top: window.innerHeight - defaultHeight - stagePadding,
            })
        }

        this.setState({
            windowStage: event.state
        })
    }

    render() {
        const {isStpOpen, handleCloseStpWindow, handleSendEmails} = this.props;
        const {trade, validation} = this.props.stpReducer;
        return(
            isStpOpen &&
            <Window
                title = {<WindowTitle trade = {trade} />}
                left={this.state.left}
                top={this.state.top}
                width={this.state.width}
                height={this.state.height}
                stage={this.state.windowStage}
                onStageChange={this.handleStageChange}
                onMove={this.handleMove}
                onClose={handleCloseStpWindow}
                onResize={this.handleResize}
                shouldUpdateOnDrag={true}
                style = {{position: 'fixed'}}
            >
                <div id = 'eb-wrapper'>
                    <EmailBlotter
                        dir = {'buy'}
                        trade = {trade}
                        validation = {validation}
                        handleSendEmails={handleSendEmails}
                    />
                    <EmailBlotter
                        dir = {'sell'}
                        trade = {trade}
                        validation = {validation}
                        handleSendEmails={handleSendEmails}
                    />
                </div>
            </Window>
        )
    }

}

function mapStateToProps (state) {
    return {
        stpReducer: state.stpReducer
    }
}

function mapDispatchToProps (dispatch) {
    return bindActionCreators({handleSendEmails}, dispatch);
}
export default connect (mapStateToProps, mapDispatchToProps) (StpWindow);
import {createCachedSelector} from 're-reselect';

/** Select List of Body Entities for this Chatroom **/
const selectedMessagesByRoomId = createCachedSelector(
    (state, roomId) => state.roomsReducer.byId[roomId]
        ? state.roomsReducer.byId[roomId].messages
        : [],
    (state, _) => state.messagesReducer.byId,

    ( roomMessagesAllIds, messagesById ) => {
        return roomMessagesAllIds.map(messageId => messagesById[messageId]);
    })(
    (_state_, roomId) => roomId
);

export default selectedMessagesByRoomId;
import React from 'react';
import FilterComboBox from '../FilterComboBox';
import {connect} from 'react-redux';
import {selectAllUsers} from '../../redux/selectors';

/** Missing props for UserComboBox:
 * brokerId
 * onChange
 *
 * disabled
 * **/

const itemRender = (li, {dataItem = {}}) => {
    const itemChildren = <span> {dataItem.firstname + ' ' + dataItem.lastname} </span>
    return React.cloneElement(li, li.props, itemChildren);
}

const UserComboBox = (props) => {
    const {
        users,
        usersById,
        brokerId,
    } = props;

    const handleChange = (event) => {
        if (event.target.value == null) return;
        const {value} = event.target;
        let dataItem = {
            brokerId: value.id,
            brokeredBy: value.username
        }

        props.onChange(dataItem);
    }

    const value = {
        id: brokerId,
        first_last: usersById[brokerId]
            ? usersById[brokerId].firstname + ' ' + usersById[brokerId].lastname
            : ''
    }

    return (
        <FilterComboBox
            {...props}
            name = "brokerId"
            value = {value}
            data={users}
            dataItemKey={"id"}
            textField = {"first_last"}
            onChange = {handleChange}
            itemRender = {itemRender}
            allowCustom={false}
            clearButton = {false}
            popupSettings={{
                animate: false
            }}
            placeholder = "Myself"
            suggest={true}
        />
    );
}

const mapState = (state) => {
    return {
        users: selectAllUsers(state),
        usersById: state.usersReducer.byId
    }
}
export default connect(mapState) (UserComboBox);
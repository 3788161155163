import React, {useState} from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';

/** Components **/
import UsersMulti from '../UsersMulti';
import {Button} from '@progress/kendo-react-buttons';

/** Redux **/
import {connect} from 'react-redux';
import {selectUsersInModalRoom} from '../../../../redux/selectors';
import {
    handleJoinRoom,
    handleLeaveRoom,
    setRoomModal,
    patchRoom
} from '../../../../redux/actions';

/** Style **/
import './RoomModal.scss';
import BEMHelper from 'react-bem-helper';
import {Input} from '@progress/kendo-react-inputs';
const classes = new BEMHelper('RoomModal');
const liClasses = new BEMHelper('UsersUl');

const RoomModal = (props) => {
    const {
        aivaPortal,
        modalRoomId,
        roomUsers,
        closeRoomModal,
        roomData = {name: '', description: ''}
    } = props;

    /** Metadata **/
    const [roomName, setRoomName] = useState(roomData.name);
    const [description, setDescription] = useState(roomData.description);

    /** Value is the list of selected users for the multiselect **/
    const [value, setValue] = useState([]);

    const onChange = (e) => {
        setValue(e.target.value)
    }

    const onSave = () => {
        patchRoom(modalRoomId, {name: roomName, description: description});

        /** Save room users **/
        if (value.length > 0) {
            handleJoinRoom(modalRoomId, value.map(user => user.id));
            setValue([]);
        }
    }

    return <Dialog>
        <div {...classes()}>
            <Button
                icon = 'close'
                {...classes('close_button')}
                onClick = {closeRoomModal}
            />

            <div {...classes('input_label')}> Room Name </div>
            <Input
                value = {roomName}
                onChange = {e => {setRoomName(e.target.value)}}
            />

            <div {...classes('input_label')}> Description </div>
            <Input
                value = {description}
                onChange = {e => {setDescription(e.target.value)}}
            />

            <div {...classes('heading')}> Add Users </div>
            <UsersMulti
                value = {value}
                onChange = {onChange}
            />

            <div {...classes('button_wrap')}>
                <Button
                    {...classes('button')}
                    onClick = {onSave}
                > Save </Button>
            </div>

            <div {...classes('heading')}> Users </div>

                <div {...liClasses()}>
                    {roomUsers.map(user => {
                        const online_BEM = user.isOnline ? 'online' : 'offline';
                        const dot = <div className = {'Body__dot Body__dot--' + online_BEM}/>
                        return <div
                            {...liClasses('li')}
                            key = {user.id}
                        >
                            {dot} &nbsp;&nbsp; {user.firstname} {user.lastname}
                            <div
                                {...liClasses('close')}
                                onClick = {()=>{
                                    if(aivaPortal.confirm('Are you sure you want to remove ' + user.username + '?')) {
                                        handleLeaveRoom(modalRoomId, user.id);
                                    }
                                }}
                            > x </div>
                        </div>
                    })}
                </div>

        </div>

    </Dialog>
}

const mapStateToProps = (state, props) => {
    return {
        aivaPortal: state.aivaReducer.aivaPortal,
        roomUsers: selectUsersInModalRoom(state),
        roomData: state.roomsReducer.byId[props.modalRoomId]
    };
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        closeRoomModal: () => dispatch(setRoomModal(-1)),
    }
}

export default connect (mapStateToProps, mapDispatchToProps) (RoomModal);
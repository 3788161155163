import TradesReducer from './TradesReducer';
import AuthReducer from "./AuthReducer";
import StpReducer from './StpReducer';
import TcFilterReducer from './TcFilterReducer';
import TicketReducer from './TicketReducer';

/** Whiteboard **/
import WbReducer from './WbReducer';
import WbOrdersReducer from './WbOrdersReducer';
import WbFiltersReducer from './WbFiltersReducer';
import WbBidsReducer from './WbBidsReducer';
import WbTradesReducer from './WbTradesReducer';
import StaticReducer from './StaticReducer';

import OrdersReducer from './OrdersReducer';
import BidsReducer from './BidsReducer';
import UnderlyingsReducer from './UnderlyingsReducer';

/** Aiva **/
import MessagesReducer from './MessagesReducer';
import RepliesReducer from './RepliesReducer';
import UsersReducer from './UsersReducer';
import RoomsReducer from './RoomsReducer';
import AivaReducer from './AivaReducer';
import RoomUsersReducer from './RoomUsersReducer';

/** Quotestream **/
import SymbolsReducer from './SymbolsReducer';

export default {
    underlyingsReducer: UnderlyingsReducer,

    // tradesReducer: TradesReducer,
    authReducer: AuthReducer,
    // stpReducer: StpReducer,
    // tcFilterReducer: TcFilterReducer,
    // ticketReducer: TicketReducer,

    wbReducer: WbReducer,
    wbOrdersReducer: WbOrdersReducer,
    wbFiltersReducer: WbFiltersReducer,
    wbBidsReducer: WbBidsReducer,
    wbTradesReducer: WbTradesReducer,
    staticReducer: StaticReducer,

    // ordersReducer: OrdersReducer,
    // bidsReducer: BidsReducer,
    symbolsReducer: SymbolsReducer,

    aivaReducer: AivaReducer,
    usersReducer: UsersReducer,
    // repliesReducer: RepliesReducer,
    // roomsReducer: RoomsReducer,
    // roomUsersReducer: RoomUsersReducer,
    // messagesReducer: MessagesReducer,
};
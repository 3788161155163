import React from 'react';

/** Redux **/
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import {
    setTradesState,
    handleSendAllEmails,
    duplicateTrade,
    toggleVoidTrade,
    removeTrade,
    handleShowStp,
    createTicket,
} from '../../redux/actions'
// import * as actions from '../../redux/actions';

/** Styles **/
import './Styles/BlotterStyle.scss';

/** Feathers **/
import tradesListener from '../../feathers/tradesListener';
import editorsListener from '../../feathers/editorsListener';

/** Components **/
import {
    IdCell,
    StrikeCell,
    MonthCell,
    DateTimeCell,
    ClientCell
} from './BlotterCells';
import DateFilterStrip from './DateFilterStrip';
import TicketWindows from './TicketWindows';

/** Tabs **/
import {Tabs, Tab} from 'react-draggable-tab';
import StpWindow from './StpWindow';

/** Kendo **/
import {Grid, GridColumn as Column} from '@progress/kendo-react-grid';
import { Menu, MenuItem } from '@progress/kendo-react-layout';
import { Popup } from '@progress/kendo-react-popup';

/** Filtering **/
import {ColumnMenuCheckboxFilter} from './BlotterFilters';
import { process } from '@progress/kendo-data-query';


const tabsClassNames = {
    tabWrapper: 'myWrapper',
    tabBar: 'myTabBar',
    tabBarAfter: 'myTabBarAfter',
    tab:      'myTab',
    tabTitle: 'myTabTitle',
    tabCloseIcon: 'tabCloseIcon',
    tabBefore: 'myTabBefore',
    tabAfter: 'myTabAfter'
};

const tabsStyles = {
    tabWrapper: {marginTop: '10px'},
    tabBar: {},
    tab:{},
    tabTitle: {},
    tabCloseIcon: {},
    tabBefore: {},
    tabAfter: {}
};

class Blotter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: this.props.user,
            trades: [],
            isStpOpen: false,
            contextMenuTrade: null,
            contextMenuLeft: null,
            contextMenuTop: null,

            dataState: {}, // filter

            badgeCount: 0,
            menuPosition: {},
            showMenu: false,
            dialogOpen: false,
            tabs:[
                (<Tab key={'tab0'} title={'Tabs'} unclosable={true} {...this.makeListeners('tab0')}>
                    <></>
                </Tab>),
                (<Tab key={'tab1'} title={'Don\'t'} {...this.makeListeners('tab1')}>
                    <></>
                </Tab>),
                (<Tab key={'tab2'} title={'Work Yet'}  {...this.makeListeners('tab2')}>
                    <></>
                </Tab>),
            ],
        };
    }

    componentDidMount() {
        tradesListener();
        editorsListener();

        document.title = 'Relixr Trade Capture'
        document.body.style.overflowY = 'hidden'
        document.body.style.overflowX = 'hidden'

        document.getElementById('bap').addEventListener('click', this.handleCloseContextMenu);

        this.props.setTradesState();
        // this.props.createTrade({});
    }

    componentDidUpdate(prevProps) {
        // if (prevProps.trades !== this.props.trades) {
        //     this.setState({
        //         trades: [...this.props.trades]
        //     });
        // }
    }

    /** Tab Methods **/
    makeListeners(key){
        return {
            onClick: (e) => { console.log('onClick', key, e);}, // never called
            onContextMenu: (e) => { console.log('onContextMenu', key, e); this.handleTabContextMenu(key, e)},
            onDoubleClick: (e) => { console.log('onDoubleClick', key, e); this.handleTabDoubleClick(key, e)},
        }
    }

    handleTabSelect(e, key, currentTabs) {
        console.log('handleTabSelect key:', key);
        this.setState({selectedTab: key, tabs: currentTabs});
    }

    handleTabClose(e, key, currentTabs) {
        console.log('tabClosed key:', key);
        this.setState({tabs: currentTabs});
    }

    handleTabPositionChange(e, key, currentTabs) {
        console.log('tabPositionChanged key:', key);
        this.setState({tabs: currentTabs});
    }

    handleTabAddButtonClick(e, currentTabs) {
        // key must be unique
        const key = 'newTab_' + Date.now();
        let newTab = (<Tab key={key} title='untitled'>
            <></>
        </Tab>);
        let newTabs = currentTabs.concat([newTab]);

        this.setState({
            tabs: newTabs,
            selectedTab: key
        });
    }

    /** Grid Methods **/
    rowRender = (trElement, props) => {
        const trProps = {
            ...trElement.props,
            onClick: (e) => {
                this.setState({
                    isStpOpen: true,
                });
                this.props.handleShowStp(props.dataItem.id);
            },
            onContextMenu: (e) => {
                e.preventDefault();
                this.handleContextMenu(e, props.dataItem);
            },
            onDoubleClick: (e) => {
                this.props.createTicket(props.dataItem.id);
            },
        };

        // mark complete orders as red
        const voided = (props.dataItem.voided===1);
        // const style = {style: voided ? Colors.gridCellVoided : {}};
        const style = {style: {
            textDecoration: voided ? 'line-through' : 'none',
        } };
        return React.cloneElement(trElement, { ...trProps , ...style}, trElement.props.children);
    }

    /** CONTEXT MENU METHODS **/

    handleContextMenu = (e, trade) => {
        this.setState({
            contextMenuLeft: e.clientX, contextMenuTop: e.clientY,
            contextMenuTrade: trade
        })
    }

    handleCloseContextMenu = () => {
        this.setState({
            contextMenuLeft: null, contextMenuTop: null,
            contextMenuTrade: null,
        });
    }

    handleContextMenuSelect = (e, trade) => {
        this.handleCloseContextMenu();
        switch (e.item.text) {
            case 'Send All Confirms':
                this.props.handleSendAllEmails(trade);
                break;
            case 'Duplicate':
                this.props.duplicateTrade(trade);
                break;
            case 'Void':
                this.props.toggleVoidTrade(trade);
                break;
            case 'Delete':
                this.props.removeTrade(trade.id);
                break;
            default:
                break;
        }
    }

    handleCloseStpWindow = () => {
        this.setState({
            isStpOpen: false
        });
    }

    /** Grid Filter **/
    handleDataStateChange = (event) => {
        this.setState({dataState: event.data})
    }
    CheckboxFilter = (props) => <ColumnMenuCheckboxFilter {...props} data = {this.props.tradesState.trades} />


    render() {
        // const { getServicesStatus, reduxState: { trades: tradesState } } = this.props;

        // let trades  = tradesState.store.records

        const {trades} = this.props.tradesState;

        const {
            removeTrade
        } = this.props;

        const {
            dataState,

            isStpOpen,
            contextMenuTrade,
            contextMenuLeft,
            contextMenuTop,
        } = this.state;

        return (
                <div id = 'bap'>
                    {/*<Tabs*/}
                    {/*    tabsClassNames={tabsClassNames}*/}
                    {/*    tabsStyles={tabsStyles}*/}
                    {/*    selectedTab={this.state.selectedTab ? this.state.selectedTab : 'tab2'}*/}
                    {/*    onTabSelect={this.handleTabSelect.bind(this)}*/}
                    {/*    onTabClose={this.handleTabClose.bind(this)}*/}
                    {/*    onTabAddButtonClick={this.handleTabAddButtonClick.bind(this)}*/}
                    {/*    onTabPositionChange={this.handleTabPositionChange.bind(this)}*/}
                    {/*    keepSelectedTab={true}*/}
                    {/*    tabs={this.state.tabs}*/}
                    {/*/>*/}
                    <DateFilterStrip/>

                    {/*<button onClick = {this.handleCreateNewTrade}>CLik </button>*/}
                    <TicketWindows />

                    <Grid
                        data = {process(trades, dataState)}
                        rowRender = {this.rowRender}

                        {...dataState}
                        onDataStateChange={this.handleDataStateChange}

                        sortable
                    >
                        <Column field = 'id' title = 'ID' cell = {IdCell} columnMenu={this.CheckboxFilter}/>
                        <Column field = 'createdAt' title = 'Created At' cell = {DateTimeCell} />
                        <Column field='editor' title = 'In Edit' columnMenu={this.CheckboxFilter} />
                        <Column field="ticker" title="Ticker" className='bold' columnMenu={this.CheckboxFilter}/>
                        <Column field="expiration" title="Exp" cell = {MonthCell} className='bold' columnMenu={this.CheckboxFilter} />
                        <Column field="strike" title="Strike" cell = {StrikeCell} className='bold' />
                        <Column field="structure" title="Struct" className='bold' columnMenu={this.CheckboxFilter}/>
                        <Column field='buy' title = 'Buyer' cell = {ClientCell} />
                        <Column field='sell' title = 'Seller' cell = {ClientCell} />

                    </Grid>

                    <StpWindow isStpOpen = {isStpOpen} handleCloseStpWindow = {this.handleCloseStpWindow} />

                    <Popup
                        show={contextMenuTrade!=null}
                        offset={{left: contextMenuLeft, top: contextMenuTop}}
                        animate={false}
                    >
                        <Menu
                            vertical={true}
                            style={{ display: 'inline-block' }}
                            onSelect = {(e) => this.handleContextMenuSelect (e, contextMenuTrade)}
                        >
                            <MenuItem
                                text="Send All Confirms"
                                icon = 'email'
                            />
                            <MenuItem text="Duplicate" icon = 'copy' />
                            <MenuItem text='Void' icon = 'cancel' />
                            <MenuItem
                                text="Delete"
                                icon = 'trash'
                            />
                        </Menu>
                    </Popup>
                </div>

        );
    }
}

function mapStateToProps (state) {
    return {
        tradesState: state.tradesReducer
    }
}

function mapDispatchToProps (dispatch) {
    // return bindActionCreators(actions, dispatch);
    return bindActionCreators({
        setTradesState,
        handleSendAllEmails,
        duplicateTrade,
        toggleVoidTrade,
        removeTrade,
        handleShowStp,
        createTicket,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps) (Blotter);
import {createCachedSelector} from 're-reselect';
import {selectLegsSummaryByOrder, selectSummaryByTicker} from '../index';
import {FormatNumber, FormatFloatTwoDecimals as Round} from '../../../helpers';
import {REVCON_ID, REVCON_OVER} from '../../../helpers/constants';

const BLANK_NBBO = {
    deltaAdjusted: false,
    ntlBestBid: null,
    ntlBestBidSize: null,
    ntlBestOffer: null,
    ntlBestOfferSize: null,
};

const _formatNbbo = (nbbo) => {
    return {
        ...nbbo,
        ntlBestBid: Round(nbbo.ntlBestBid),
        ntlBestBidSize: FormatNumber(nbbo.ntlBestBidSize),
        ntlBestOffer: Round(nbbo.ntlBestOffer),
        ntlBestOfferSize: FormatNumber(nbbo.ntlBestOfferSize),
    }
}

const selectNBBO = createCachedSelector(
    (state, order) => selectLegsSummaryByOrder(state, order),
    (state, order) => selectSummaryByTicker(state, order.ticker),
        (_, order) => order,
    (legsSummary = [], underlying = {}, order) => {
        const {
            delta: customDelta = '0',
            tied = 0,
            structureId,
            over,
        } = order;

        let nbbo = {
            deltaAdjusted: (customDelta!=null && customDelta!=='' && tied!=null && tied!=='' && tied!==0),
            ntlBestBid: 0,
            ntlBestOffer: 0,
            ntlBestBidSize: Infinity,
            ntlBestOfferSize: Infinity,
            ntlDelta: 0
        }

        if (structureId && parseInt(structureId) === REVCON_ID) {
            if (legsSummary.length === 0 || legsSummary.some(({bidPrice}) => bidPrice == null)) {
                return BLANK_NBBO;
            }

            const [call_leg, put_leg] = legsSummary;
            const strike = parseInt(call_leg.strike);
            legsSummary.forEach(leg_item=>{
                const {
                    bidSize,
                    askSize,
                } = leg_item;

                nbbo.ntlBestBidSize = Math.min(nbbo.ntlBestBidSize, bidSize);
                nbbo.ntlBestOfferSize = Math.min(nbbo.ntlBestOfferSize, askSize);
            });

            if(over === REVCON_OVER.PUTS) {
                nbbo.ntlBestBid = underlying.bidPrice + put_leg.bidPrice - call_leg.askPrice - strike;
                nbbo.ntlBestOffer = underlying.askPrice + put_leg.askPrice - call_leg.bidPrice - strike;
            } else {
                nbbo.ntlBestBid = call_leg.bidPrice + strike - put_leg.askPrice - underlying.askPrice;
                nbbo.ntlBestOffer = call_leg.askPrice + strike - put_leg.bidPrice - underlying.bidPrice;
            }
        } else {
            for (let i = 0; i < legsSummary.length; i++) {
                const legSummary = legsSummary[i];

                const {
                    buyerBuys,
                    ratio,
                    bidPrice,
                    askPrice,
                    bidSize,
                    askSize,
                    delta
                } = legSummary;

                nbbo.ntlBestOfferSize = Math.min(nbbo.ntlBestOfferSize, askSize);
                nbbo.ntlBestBidSize = Math.min(nbbo.ntlBestBidSize, bidSize);

                if(bidPrice==null) {
                    // if the bid price is null, the symbol is prob invalid and the nbbo can't be calculated
                    // so return nothing
                    return BLANK_NBBO;
                }

                if (buyerBuys === 1) {
                    nbbo.ntlBestBid += bidPrice * ratio;
                    nbbo.ntlBestOffer += askPrice * ratio;
                    nbbo.ntlDelta += delta * ratio;
                } else {
                    nbbo.ntlBestBid -= askPrice * ratio;
                    nbbo.ntlBestOffer -= bidPrice * ratio;
                    nbbo.ntlDelta -= delta * ratio;
                }
            }

            if (nbbo.deltaAdjusted) {
                const underlyingLastPrice = underlying.last;
                let parsedDelta = parseFloat(customDelta.replace(/d|D/))/100;

                const deltaAdjustment = parsedDelta * (parseFloat(tied.replace(/[a-zA-Z]/)) - underlyingLastPrice);

                nbbo.ntlBestBid += deltaAdjustment;
                nbbo.ntlBestOffer += deltaAdjustment;
            }
        }
        return _formatNbbo(nbbo);
    }
)((_state_, order) => (order!=null && order.id) ? `${order.id}:${order.delta}` : '-1');

export default selectNBBO;
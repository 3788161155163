import produce from 'immer';
import {combineReducers} from 'redux';

import {
    SET_ORDERS,
    CREATE_ORDER,
    UPDATE_ORDER,
    REMOVE_ORDER,
} from "../actions/types";
import InsertId from '../../helpers/InsertId';
import GetByIdWithRelated from '../../helpers/GetByIdWithRelated';

const byId = produce((draft, action) => {
    switch (action.type) {
        case SET_ORDERS:
            return GetByIdWithRelated(action.orders, ['bids'])
        case CREATE_ORDER:
            draft[action.order.id] = action.order;
            return;
        case UPDATE_ORDER:
            draft[action.order.id] = action.order;
            return;
        case REMOVE_ORDER:
            delete draft[action.order.id];
            return;
    }
}, {});

const allIds = produce((draft, action) => {
    switch(action.type) {
        case SET_ORDERS:
            return action.orders.map(order => order.id);
        case CREATE_ORDER: {
            draft.splice(InsertId(draft, action.order.id), 0, action.order.id);
            return;
        }
        case REMOVE_ORDER: {
            let index = draft.findIndex(orderId => orderId === action.order.id);
            if (index !== -1) draft.splice(index, 1);
            return;
        }
    }
}, []);


const OrdersReducer = combineReducers({
    byId: byId,
    allIds: allIds
});

export default OrdersReducer;
import {
    CREATE_ORDER,
} from '../types';

import {
    setSymbolInfo,
    subscribeToSymbols
} from '../index';
import {getEnhancedQuotes} from '../../../api';

export default function handleOrderCreated (obj) {
    return async function (dispatch) {
        dispatch({
            type: CREATE_ORDER,
            order: obj
        });

        /** Get associated Quotestream data for the new order **/
        dispatch(subscribeToSymbols(obj));

        /** Get associated metadata **/
        const data = await getEnhancedQuotes(obj.ticker);
        dispatch(setSymbolInfo(data));
    }
}
/**
 * Processes feathers find () response
 * @param array feathers find () list of entities each with a list of related ids (identified by relatedKey)
 * @param relatedKey e.g. 'messages' for the rooms.find()
 * @return byId for reducer state
 */
const GetByIdWithRelated = (array = [], relatedKeys = []) => {
    const byId = {};

    array.forEach(entity => {
        relatedKeys.forEach(relatedKey => {
            entity[relatedKey] = entity[relatedKey].map(relatedEntity => {
                return relatedEntity.id
            });
        });

        byId[entity.id] = entity;
    });
    return byId;
}

/** Need this function to squeeze some extra efficiency to sort the trades.
 * Currently sequelize doesn't send over associated trades in sorted order.
 */
const GetOrdersById = (orders = []) => {
    const byId = {};

    orders.forEach(entity => {
        entity.bids = entity.bids.map(bid =>  bid.id);
        entity.trades.sort((a,b) => b.blockId - a.blockId);
        entity.trades = entity.trades.map(trade => trade.id);

        byId[entity.id] = entity;
    });
    return byId;
}

export default GetByIdWithRelated;
export {GetOrdersById};
export const SET_WB_FILTERS = 'SET_WB_FILTERS';
export const CREATE_WB_FILTER = 'ADD_WB_FILTER';
export const UPDATE_WB_FILTER = 'UPDATE_WB_FILTER';
export const REMOVE_WB_FILTER = 'REMOVE_WB_FILTER';

export const handleWbFilterCreated = () => {

}

export const handleWbFilterUpdated = () => {

}

export const handleWbFilterRemoved = () => {

}
import React from 'react';

/** Redux **/
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {createReply, handleAivaClick} from '../../../../redux/actions';
import {selectMessageById} from '../../../../redux/selectors';

/** Components **/
import Thread from '../Thread';
import ThreadHeader from '../ThreadHeader';

/** Style **/
import BEMHelper from 'react-bem-helper';
import ChatBox from '../ChatBox';
const classes = new BEMHelper('ChatApp');

const ThreadApp = (props) => {
    const {
        messageId,
        roomId,
        createReply,
        handleAivaClick
    } = props;

    const message = props.message ? props.message : {};

    const createReplyCallback = (reply) => {
        createReply(messageId, reply)
    }

    // Reuse .ChatApp height 100% property
    return <div {...classes()}>
        <ThreadHeader
            message = {message}
            messageId = {messageId} />
        <div onClick = {handleAivaClick}>
            <Thread
                message = {message}
                messageId = {messageId}
            />
            <ChatBox
                orderId = {message.orderId}
                chatBoxType={'reply'}
                roomId={roomId}
                createFeathersChat={createReplyCallback}
            />
        </div>
    </div>
}

const mapStateToProps = (state, props) => {
    return {
        message: selectMessageById(state, props.messageId),
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return bindActionCreators(
        {
            createReply,
            handleAivaClick
        }, dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps) (ThreadApp);
import {bidsService} from '../../../feathers';

/**
 * utilized by Reply chatbox to generate bid/ask
 * @param map the nlp map containing direction, ask/bid, size, orgCode
 */
export default function (map, body, roomId) {
    let dataItem = {...map};
    if (map.direction === 'buyer') {
        dataItem = {...dataItem, ask: null}
    } else if (map.direction === 'seller') {
        dataItem = {...dataItem, bid: null}
    }

    bidsService.create(dataItem, {
        query: {
            body: body,
            roomId: roomId
        }
    })
}
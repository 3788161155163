import io from 'socket.io-client';
import feathers from '@feathersjs/client';
import host from './host';

// Configure Client
const socket = io(host);
socket.on('disconnect', (e) => {
    window.location.reload();
});

const client = feathers()
    .configure(feathers.socketio(socket))
    .configure(feathers.authentication({
        storage: window.localStorage
    }));

export default client;

client.service('confirms').timeout = 15000;
export const confirmsService = client.service('confirms');
export const tradesService = client.service('trades');
export const editorsService = client.service('editors');

/** whiteboard **/
export const structuresService = client.service('structures');
export const organizationsService = client.service('organizations');
export const logicMainService = client.service('logic-main');
export const ordersService = client.service('orders');
export const bidsService = client.service('bids');
export const underlyingsService = client.service('underlyings');

/** aiva **/
export const usersService = client.service('users');
export const roomsService = client.service('rooms');
export const roomUsersService = client.service('room-users');
export const roomLogicService = client.service('room-logic');
export const messagesService = client.service('messages');
export const repliesService = client.service('replies');

// Configure REST Feathers client
const feathersjs = require('@feathersjs/feathers');
const rest = require('@feathersjs/rest-client');
const app = feathersjs();
// Connect to the same as the browser URL (only in the browser)
const restClient = rest(host);
// Configure an AJAX library (see below) with that client
// app.configure(restClient.fetch(window.fetch));
const axios = require('axios');
app.configure(restClient.axios(axios));
app.configure(feathers.authentication({
    storage: window.localStorage
}))

export const restConfirmsService = app.service('confirms');
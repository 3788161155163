import React from 'react';

import MetadataCell from '../MetadataCell/MetadataCell';
import OptionDisplay from './OptionDisplay/OptionDisplay';

import {FUTURE_ID, REVCON_ID} from '../../../helpers/constants';

import BEMHelper from 'react-bem-helper';
import './OrderCell.scss';
import RevconDisplay from './RevconDisplay/RevconDisplay';
const classes = new BEMHelper('OrderCell');

const OrderCell = ({order}) => {
    return <div {...classes()}>
        <div {...classes('order_string')}>
            {
                (order.structureId && parseInt(order.structureId) === REVCON_ID)
                ? <RevconDisplay
                    order = {order}
                />
                : <OptionDisplay
                    order = {order}
                />
            }
        </div>

        <MetadataCell
            ticker = {order.ticker}
        />
    </div>
}

export default OrderCell;
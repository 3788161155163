import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {setSpreadFormProps} from '../../../redux/actions/wb';
import {bidsService} from '../../../feathers';

import { Popup } from '@progress/kendo-react-popup';
import {Button} from '@progress/kendo-react-buttons';
import {Input} from '@progress/kendo-react-inputs';
import OrgComboBox from '../../../utilities/OrgComboBox';
import UserComboBox from '../../../utilities/UserComboBox';

import {INTEREST_TYPE} from '../../../helpers/constants';

import './SpreadForm.scss';
import BEMHelper from 'react-bem-helper';
const classes = new BEMHelper('SpreadForm');

/**
 * Handles updating and creating new bid
 * @param props
 * {
 *     orderId: not null if creating new bid
 *     direction: not null if creating new bid
 *     bidId: not null if updating new bid
 *
 *     prevInterest: the original bid/ask object before updating
 * }
 */
const SpreadForm = (props) => {
    const {
        bidsById,
        spreadFormProps,
        defaultBroker,
        setSpreadFormProps,
        prevInterest
    } = props;

    const {
        orderId,
        bidId,
        direction,
        offset,
    } = spreadFormProps;

    const [autoSelect, setAutoSelect] = useState(true);
    const [interest, setInterest] = useState({});

    /** When a new spreadform is opened, set the interest accordingly **/
    useEffect(() => {
        setAutoSelect(true);
        if (bidId) { // update existing bid
            const prevInterest = bidsById[bidId];
            let desiredFields = (({price, size, orgCode, organizationId, brokerId, brokeredBy, orderId, blockId}) =>
                ({price, size, orgCode, organizationId, brokerId, brokeredBy, orderId, blockId}))(prevInterest);

            const brokerId = desiredFields.brokerId;
            if (brokerId == null) {
                desiredFields.brokerId = defaultBroker.id;
                desiredFields.brokeredBy = defaultBroker.username;
            }
            setInterest(desiredFields);
        } else { // create new interest
            setInterest({
                brokerId: defaultBroker.id,
                brokeredBy: defaultBroker.username
            });
        }
    }, [offset]);

    /** Input Change handlers **/
    const onChange = (event) => {
        const {
            name,
            value
        } = event.target;

        if(value.match(/^-?\d*\.?\d*$/)) {
            setInterest({...interest, [name]: value});
        }
    }

    const onComboChange = (value) => {
        setInterest({...interest, ...value});
    }
    const onSubmit = (event) => {
        event.preventDefault();
        const newInterest = {...interest, price: parseFloat(interest.price), size: parseFloat(interest.size)}
        if (bidId) bidsService.patch(bidId, {...interest});
        else bidsService.create({...newInterest, orderId, direction});
        setSpreadFormProps();
    }

    const preventClickPropagation = (event) => {
        event.stopPropagation();
    }

    const {
        price = '',
        size = '',
        isVoided = false,
        orgCode = null,
        organizationId = null,
        brokerId,
        blockId
    } = interest;

    return <Popup
                show = {(orderId != null || bidId != null)}
                offset={offset}
                appendTo={document.querySelector(".bb")}
                animate={false}
                className={'Popup'}
            >
                    {blockId != null
                        ? <span> Please edit in trade table. </span>
                        : <form
                        autoComplete="off"
                        onSubmit={onSubmit}
                        onClick = {preventClickPropagation}
                    >
                        <div
                            {...classes()}
                            ref={(x) => {
                                if(autoSelect && x) {
                                    x.querySelector(`[name=price]`).select();
                                    setAutoSelect(false);
                                }
                            }}
                        >
                            <div {...classes('row')}>
                                <div {...classes('label')}> Price </div>
                                <div {...classes('field')}>
                                    <Input
                                        name = 'price'
                                        value={price}
                                        onChange = {onChange}
                                        required={false}
                                        disabled={isVoided}
                                        autoComplete='off'
                                    />
                                </div>
                            </div>

                            <div {...classes('row')}>
                                <div {...classes('label')}> Size </div>
                                <div {...classes('field')}>
                                    <Input
                                        name = 'size'
                                        value={size}
                                        onChange = {onChange}
                                        required={false}
                                        disabled={isVoided}
                                        autoComplete='off'
                                    />
                                </div>
                            </div>

                            <div {...classes('row')}>
                                <div {...classes('label')}> Client </div>
                                <div {...classes('field')}>
                                    <OrgComboBox
                                        value={{id: organizationId, code: orgCode}}
                                        onChange = {onComboChange}
                                        textField={'code'}
                                        remapIdKey={'organizationId'}
                                        remapFieldKey={'orgCode'}
                                    />
                                </div>
                            </div>

                            <div {...classes('row')}>
                                <div {...classes('label')}> Broker </div>
                                <div {...classes('field')}>
                                    <UserComboBox
                                        brokerId = {brokerId}
                                        onChange = {onComboChange}
                                    />
                                </div>
                            </div>

                            <Button
                                type = 'submit'
                                primary={true}
                                icon={'check'}
                                {...classes('submit_button')}
                            >
                                {((bidId
                                    ? 'UPDATE'
                                    : 'CREATE')
                                + (direction === INTEREST_TYPE.BID
                                    ? ' BID'
                                    : ' ASK'))}
                            </Button>
                        </div>
                    </form>
                    }
            </Popup>

}

const mapStateToProps = (state, {bidId = null}) => {
    return {
        spreadFormProps: state.wbReducer.spreadFormProps,
        bidsById: state.wbBidsReducer.byId,
        defaultBroker: state.authReducer.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSpreadFormProps: (props) => dispatch(setSpreadFormProps(props))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (SpreadForm);
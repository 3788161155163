import React, { Component } from 'react';
import client from '../../feathers';
import { Input } from '@progress/kendo-react-inputs';
import logo from '../../images/logo.png';


export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    updateField(name, ev) {
        this.setState({ [name]: ev.target.value });
    }

    login() {
        const { email, password } = this.state;

        return client.authenticate({
            strategy: 'local',
            email, password
        })
            .catch( error => {
                this.setState({
                    error
                })
                console.log(error)
            });
    }

    render() {
        {/*<div className="row">*/}
        {/*    <div className="col-12 col-6-tablet push-3-tablet text-center heading">*/}
        {/*        <h1 className="font-100">Log in or signup</h1>*/}
        {/*        <p>{this.state.error && this.state.error.message}</p>*/}
        {/*    </div>*/}
        {/*</div>*/}
        // <main className="login container">
        return <main>
            <div id = "login-background"></div>
            <div id="login-wrapper">
                <div id="login-card">
                        <div className="card-block">
                            <img id = "login-logo" src = {logo} />
                            {/*<p style={{"padding":"15px"}}> <b>Disclaimer</b>: This is the DEMO version of order management system RELIXR. All orders/trades displayed are strictly fictitious. Created by Eric Jiang (edj9@duke.edu).</p>*/}
                            <div id = "login-banner"> Login </div>
                            <form className="" onSubmit = {(e) => {e.preventDefault()}}>
                                    <div className="mb-3">
                                        <Input
                                            name="username"
                                            style={{ width: "100%" }}
                                            label="Email Address"
                                            pattern={"[A-Za-z@\.0-9]+"}
                                            minLength={2}
                                            required={true}

                                            onChange={ev => this.updateField('email', ev)}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <Input
                                            name="password"
                                            type="password"
                                            style={{ width: '100%' }}
                                            label="Password"
                                            required={true}
                                            minLength={6}
                                            maxLength={18}

                                            onChange={ev => this.updateField('password', ev)}
                                        />
                                    </div>
                                <p id = "error-message">{this.state.error != null && this.state.error.message}</p>
                                <input type="submit" onClick={() => this.login()} id="login-button" className="k-button k-primary" value="Login" />
                            </form>
                        </div>
                    </div>
            </div>
            {/*<div className="row">*/}
            {/*    <div className="col-12 col-6-tablet push-3-tablet col-4-desktop push-4-desktop">*/}
            {/*        <form className="form">*/}
            {/*            <fieldset>*/}
            {/*                <input className="block" type="email" name="email" placeholder="email" onChange={ev => this.updateField('email', ev)} />*/}
            {/*            </fieldset>*/}

            {/*            <fieldset>*/}
            {/*                <input className="block" type="password" name="password" placeholder="password" onChange={ev => this.updateField('password', ev)} />*/}
            {/*            </fieldset>*/}

            {/*            <button type="button" className="button button-primary block signup" onClick={() => this.login()}>*/}
            {/*                Log in*/}
            {/*            </button>*/}

            {/*            <button type="button" className="button button-primary block signup" onClick={() => this.signup()}>*/}
            {/*                Signup*/}
            {/*            </button>*/}
            {/*        </form>*/}
            {/*    </div>*/}
            {/*</div>*/}

        </main>;
    }
}
import React, {useCallback, useState} from 'react';
import {GetTradeDate} from '../../../helpers';

import './TradeList.scss';
import BEMHelper from 'react-bem-helper';
import {useDispatch} from 'react-redux';
const classes = BEMHelper('TradeList');

const TradeList = (props) => {
    const {
        trades = [],
        isDepthExpanded,
        setTradeFormProps,
        setOrderMenuProps
    } = props;

    const [expanded, setExpanded] = useState(false);
    const tradesLength = trades.length;

    const toggleExpanded = () => {
        setExpanded(!expanded);
    }

    const stopPropagation = (event) => {
        event.stopPropagation();
    }

    const openTradeForm = useCallback((event, prevTrade) => {
        event.stopPropagation();
        setTradeFormProps({prevTrade, offset: {left: event.clientX, top: event.pageY}})
    }, []);

    const openOrderMenu = useCallback((event, trade) => {
        event.stopPropagation();
        event.preventDefault();
        setOrderMenuProps({trade, offset: {left: event.clientX, top: event.pageY}})
    }, []);

    /** Apply top border to each block **/
    let prev_block_id = -1;
    let is_block_start = true, modifier = 'secondary';
    let is_first = true, is_first_consumed = false;

    return <div {...classes()}>
        {trades.map((trade, index) => {
            const {
                id,
                price,
                size,
                createdAt,
                buyOrgCode,
                sellOrgCode,
                blockId,
                voided,
                createdBy
            } = trade;

            if (!voided) {
                if (prev_block_id != blockId) {
                    modifier = modifier === 'primary' ? 'secondary' : 'primary';
                    is_block_start = true;
                } else {
                    is_block_start = false;
                }
                prev_block_id = blockId;
            }

            if (!is_first_consumed) {
                if (!voided) {
                    is_first_consumed = true;
                }
            } else {
                is_first = false;
            }
            return (
                (
                    ((is_first) || (isDepthExpanded || expanded))
                    && !voided
                )
                && <div
                    {...classes('row', modifier)}
                    key = {id}
                    title = {`Created By: ${createdBy}`}
                    onDoubleClick = {(event) => openTradeForm(event, trade)}
                    onContextMenu={(event) => openOrderMenu(event, trade)}
                >
                    <div {...classes('date')}>
                        {is_block_start && GetTradeDate(createdAt)}
                    </div>

                    <div {...classes('price')}>
                        <div> {size}x </div> @ {price}
                    </div>

                    <div {...classes('client', 'left')}>
                        {buyOrgCode}
                    </div>
                    <div {...classes('slash')}>/</div>
                    <div {...classes('client', 'right')}>
                        {sellOrgCode}
                    </div>

                    {
                        is_first
                        && !isDepthExpanded
                        && tradesLength > 1
                        && <span
                            {...classes(
                                'icon',
                                '',
                                'k-icon k-i-arrow-chevron-' + (expanded ? 'up': 'down')
                            )}
                            onClick = {toggleExpanded}
                            onDoubleClick = {stopPropagation}
                        >

                        </span>
                    }
                </div>
            );
        })}
    </div>
}

export default TradeList;
import React from 'react';
import {MultiSelect} from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import {useSelector} from 'react-redux';
import {
    TAB_ID_FIELDS,
    TAB_TEXT_FIELDS
} from '../../helpers/constants';


const pageSize = 20;
/**
 * The FilterMultiSelect is used for the Blackboard Tab Form
 * It needs to handle both user created and static values
 * specified by textListKey and idListKey corresponding to the tab object fields.
 */
class FilterMultiSelect extends React.Component {
    state = {
        data: Object.values((this.props.byId ?? {})).slice(0, pageSize),
        skip: 0,
        total: 10000
    }
    filteredData = Object.values(this.props.byId);

    onFilterChange = (event) => {
        const filter = event.filter;
        this.filteredData = filterBy(Object.values(this.props.byId), filter);

        const data = this.filteredData.slice(0, pageSize);

        this.setState({
            data: data,
            skip: 0,
            total: this.filteredData.length
        });
    }

    pageChange = (event) => {
        const skip = event.page.skip;
        const take = event.page.take;
        const newSubsetData = this.filteredData.slice(skip, skip + take);

        this.setState({
            data: newSubsetData,
            skip: skip
        });
    }

    handleChange = event => {
        const values = [...event.target.value];

        const {
            data
        } = this.state;

        const {
            textField,
            idListKey,
            textListKey,

            tab,
            setTab
        } = this.props;

        let newIdList = [];
        let newTextList = [];

        values.forEach(value => {
            let id = value.id;
            const text = value[textField].toUpperCase();
            let duplicateItem = undefined;

            // If this is a custom item, check if the item is already in the static data
            if (id === undefined || typeof id === 'string') {
                duplicateItem = data.find(item => item[textField] === text);

                if (duplicateItem === undefined) {
                    newTextList.push(text);
                    return;
                }
            }
            // If this is a static item or if a duplicate was found, add the item's id to the id list
            newIdList.push((id ? id : duplicateItem.id));
        });

        console.log(newIdList)
        console.log(newTextList)
        setTab({
            ...tab,
            [idListKey]: newIdList,
            [textListKey]: newTextList
        });
    };

    render() {
        const {
            data,
            total,
            skip
        } = this.state;

        const {
            byId,
            textField,
            idListKey,
            textListKey,
            tab
        } = this.props;

        /** Generate values to pass to multiselect
         *  List of [{id: xx, [textField]: xxx}]
         *  where textField is emp
         * **/
        const idList = tab[idListKey] ?? [];
        const textList = tab[textListKey] ?? [];
        let values = [];
        idList.forEach(id => {
            values.push({
                id,
                [textField]: byId[id][textField]
            });
        });
        textList.forEach(string => {
            values.push({
                id: string,
                [textField]: string,
            });
        });

        return <MultiSelect
            data={data}
            value={values}
            onChange={this.handleChange}
            dataItemKey='id'
            textField = {textField}
            allowCustom={true}
            suggest={true}
            virtual={{
                total: total,
                pageSize: pageSize,
                skip: skip
            }}
            onPageChange={this.pageChange}
            filterable={true}
            onFilterChange={this.onFilterChange}
        />
    }
}

const OrgMultiSelect = (props) => {
    const organizationsById = useSelector(state => state.staticReducer.organizationsById);

    return <FilterMultiSelect
        {...props}
        textField = 'code'
        byId = {organizationsById}
        idListKey = {TAB_ID_FIELDS.organization}
        textListKey = {TAB_TEXT_FIELDS.organization}
    />
}

const StructureMultiSelect = (props) => {
    const structuresById = useSelector(state => state.staticReducer.structuresById);

    return <FilterMultiSelect
        {...props}
        textField = 'name'
        byId = {structuresById}
        idListKey = {TAB_ID_FIELDS.structure}
        textListKey = {TAB_TEXT_FIELDS.structure}
    />
}

const UnderlyingMultiSelect = (props) => {
    const underlyingsById = useSelector(state => state.underlyingsReducer.underlyingsById);

    return <FilterMultiSelect
        {...props}
        textField = 'ticker'
        byId = {underlyingsById}
        idListKey = {TAB_ID_FIELDS.underlying}
        textListKey = {TAB_TEXT_FIELDS.underlying}
    />
}

export {
    OrgMultiSelect,
    StructureMultiSelect,
    UnderlyingMultiSelect
}
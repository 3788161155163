import {createCachedSelector} from 're-reselect';

const getSymbols = (state, orderId) => state.symbolsReducer.orderIdToSymbols[orderId];

const selectSymbolsByOrderId = createCachedSelector(
    getSymbols,
    (symbols) => symbols
)(
    (_state_, orderId) => orderId
)

export default selectSymbolsByOrderId;
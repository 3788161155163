/** Login **/
export const SET_AUTH = 'SET_AUTH';
export const PATCH_AUTH = 'PATCH_AUTH';
export const HANDLE_LOGOUT = 'HANDLE_LOGOUT';
export const SET_QS_SID = 'SET_QS_SID';

/** Trades **/
export const SET_TRADES = 'SET_TRADES';
export const SET_TRADES_LOADING = 'SET_TRADES_LOADING';
export const ADD_TRADE = 'ADD_TRADE';
export const REMOVE_TRADE = 'REMOVE_TRADE';
export const UPDATE_TRADE = 'UPDATE_TRADE';

export const HANDLE_EDITOR_CHANGED = 'HANDLE_EDITOR_CHANGED';

/** Emails **/
export const SET_STP_TRADE_VALIDATION = 'SET_STP_TRADE_VALIDATION';
export const SET_STP_TRADE_ID = 'SET_STP_TRADE_ID';
export const SET_STP_TRADE = 'SET_STP_TRADE';
export const REMOVE_STP_TRADE = 'REMOVE_STP_TRADE';

/** Blotter Date Filters **/
export const SET_TC_FILTER_BY = 'SET_TC_FILTER_BY';
export const SET_TC_FILTER = 'SET_TC_FILTER';
//
// export const SET_TC_DAYS = 'SET_DAYS';
// export const SET_TC_START_DATE = 'SET_START_DATE';
// export const SET_TC_END_DATE = 'SET_END_DATE';

/** Ticket Windows **/
export const CREATE_TICKET = 'CREATE_TICKET';
export const REMOVE_TICKET = 'REMOVE_TICKET';
export const CREATE_WINDOW = 'CREATE_WINDOW';

/** WHITEBOARD **/
/** Orders **/
export const SET_ORDERS = 'SET_ORDERS';
export const CREATE_ORDER = 'ADD_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const REMOVE_ORDER = 'REMOVE_ORDER';

/** Bids **/
export const SET_BIDS = 'SET_BIDS';
export const CREATE_BID = 'ADD_BID';
export const UPDATE_BID = 'UPDATE_BID';
export const REMOVE_BID = 'REMOVE_BID';

/**** QUOTE_STREAM ****/
export const MAP_ORDER_ID_TO_SYMBOLS = 'MAP_ORDER_ID_TO_SYMBOLS';
export const SET_PRICE_DATA = 'SET_PRICE_DATA';
export const SET_QUOTE = 'SET_QUOTE';
export const SET_IV_GREEKS = 'SET_IV_GREEKS';

export const REMOVE_ORDER_ID_TO_SYMBOLS = 'REMOVE_ORDER_ID_TO_SYMBOLS';
export const REMOVE_SYMBOL = 'REMOVE_SYMBOL';

export const BATCH_PRICE_DATA = 'BATCH_PRICE_DATA';
export const BATCH_QUOTE_DATA = 'BATCH_QUOTE_DATA';
export const BATCH_IV_GREEKS_DATA = 'BATCH_IV_GREEKS_DATA';

export const SET_SYMBOL_INFO = 'SET_SYMBOL_INFO';

/******** AIVA ***********/
/** Aiva State **/
export const CREATE_AIVA_PORTAL = 'CREATE_AIVA_PORTAL';
export const TOGGLE_IS_AIVA_PORTAL_OPEN = 'TOGGLE_IS_AIVA_PORTAL_OPEN';
export const SET_MODAL_ROOM_ID = 'SET_MODAL_ROOM_ID';
export const SET_MODAL_CREATE_ROOM_TYPE = 'SET_MODAL_CREATE_ROOM_TYPE';
export const TOGGLE_THREAD = 'TOGGLE_THREAD';
export const SET_SELECTED_THREAD = 'SET_SELECTED_THREAD';
export const SET_SELECTED_ROOM = 'SET_SELECTED_ROOM';

/** Aiva Menu **/
export const SET_IS_WINDOW_COMPACT = 'SET_IS_WINDOW_COMPACT';
export const SET_MENU_CLASS_MODIFIER = 'SET_MENU_CLASS_MODIFIER';

/** Users **/
export const SET_USERS = 'SET_USERS';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';

/** Rooms **/
export const SET_ROOMS = 'SET_ROOMS';
export const CREATE_ROOM = 'CREATE_ROOM';
export const UPDATE_ROOM = 'UPDATE_ROOM';
export const PATCH_ROOM = 'PATCH_ROOM';

/** Room Users **/
export const SET_ROOM_USERS = 'SET_ROOM_USER';
export const PATCH_ROOM_USER = 'PATCH_ROOM_USER';
export const CREATE_ROOM_USER = 'JOIN_ROOM';
export const REMOVE_ROOM_USER = 'LEAVE_ROOM';

/** Messages **/
export const SET_MESSAGES = 'SET_MESSAGES';
export const CREATE_MESSAGE = 'CREATE_MESSAGE';

/** Replies **/
export const SET_REPLIES = 'SET_REPLIES';
export const CREATE_REPLY = 'CREATE_REPLY';

/** Underlyings **/
export const SET_INDICES = 'SET_INDICES';
export const SET_UNDERLYINGS = 'SET_UNDERLYINGS';
export const SET_TICKERS_TO_IDS = 'SET_TICKERS_TO_IDS';
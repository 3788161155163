import React, {memo, useEffect, useRef} from 'react';
import {connect} from 'react-redux';

/** Hooks **/
import usePrevious from '../../../../hooks/usePrevious';

/** Components **/
import Body from '../Body/Body';
import {selectRepliesByMessageId} from '../../../../redux/selectors';

/** Style **/
import './Thread.scss';
import BEMHelper from 'react-bem-helper';
const dividerClasses = new BEMHelper('Divider');

const Thread = memo((props) => {
    const {
        message,
        replies
    } = props;

    /** Scroll to bottom **/
    const repliesEndRef = useRef(null);
    const prevReplies = usePrevious(replies);
    const scrollToBottom = () => {
        if ((prevReplies!=null && prevReplies.length > 0 && prevReplies[0] === undefined) || (prevReplies && prevReplies.length !== replies.length)) {
            repliesEndRef.current.scrollIntoView({ behavior: "auto" })
        }
    }
    useEffect(scrollToBottom, [replies])

    // See .ChatBoard style for auto calc height of thread body
    return <div className = 'Thread'>
        <Body
            chat = {message}
            className = {'Body--thread'}
        />

        {
            replies && replies.length!==0 &&
            <div {...dividerClasses('')}>
                <div {...dividerClasses('text')}> {replies.length} {replies.length > 1 ? 'Replies' : 'Reply'} </div>
                <div {...dividerClasses('hr')} />
            </div>
        }

        {replies.map((reply, ix) => {
            return reply
                ?   <Body
                        key = {reply.id}
                        chat = {reply}
                        className = {'Body--thread'}
                     />
                : <div key = {ix}> Loading Reply </div>
        })}

        <div className = 'dummyDiv'
             ref={repliesEndRef}
        />
    </div>
});

const mapStateToProps = (state, props) => {
    return {
        replies: selectRepliesByMessageId(state, props.messageId)
    };
}

export default connect(mapStateToProps, null) (Thread);
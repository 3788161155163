import {HANDLE_EDITOR_CHANGED} from '../types';

export default function handleEditorCreated (obj) {
    return async (dispatch) => {
        obj.id = parseInt(obj.id)
        dispatch({
            type: HANDLE_EDITOR_CHANGED,
            payload: obj
        })
    }
}
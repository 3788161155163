import {createCachedSelector} from 're-reselect';

const getOrderById = (state, orderId) => state.ordersReducer.byId[orderId];

const selectOrderById = createCachedSelector(
    getOrderById,
    (order) => order
)(
    (_state_, orderId) => orderId
)

export default selectOrderById;
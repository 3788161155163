import React from 'react';
import './Styles/TicketStyle.scss'
import client, {feathersServices} from "../../feathers";

import NewWindow from 'react-new-window'

/** Tabs **/
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import { TabStrip, TabStripTab, PanelBar, PanelBarItem, PanelBarUtils, Menu, MenuItem, MenuItemModel, MenuItemLink, MenuItemArrow, Splitter, Drawer, DrawerNavigation, DrawerContent, Card, CardHeader, CardTitle, CardBody, CardActions, CardImage, Avatar } from '@progress/kendo-react-layout'
import 'react-tabs/style/react-tabs.css';

/** Kendo **/
import '@progress/kendo-react-intl'
import '@progress/kendo-react-dropdowns'
import '@progress/kendo-react-buttons'
import '@progress/kendo-react-inputs'
import 'react-router-dom'

/** Feathers **/

import {
    withRouter,
} from "react-router-dom";

/** Components **/
import ConfirmView from "./ConfirmView";
import VersionView from './VersionView'
import EntryView from "./EntryView";
import {connect} from "react-redux";

class Ticket extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            tabIndex: 1,
            changed: false,
        }
    }

    handleTabSelected = (tabIndex) => {
        if (!this.state.changed ||
            (this.state.changed && this.props.window.confirm('You have unsaved changes. Discard and switch tabs?'))) {
            this.setState({ tabIndex });
            this.setUnchanged();
        }
    }

    setChanged = () => {
        this.setState({changed: true})
    }

    setUnchanged = () => {
        this.setState({changed: false})
    }

    componentDidMount() {
        // really really hacky and i hate myself for doing this
        // initially the default tab is 1 and this helps resolve the issue of inputs not firing onChange event
        this.setState({
            tabIndex: 0
        })
    }

    render() {
        const {tabIndex} = this.state;
        // const tradeId = parseInt(this.props.match.params.tradeId);
        const {tradeId} = this.props;
        const windowBody = this.props.window==null ? null : this.props.window.document.body;

        const heightStyle = {height: tabIndex === 1 ? '100%' : 'auto'} // needed for pdf to take up full 100%

        return (
            <div className = "ticket" style = {heightStyle}>
                <Tabs
                    selectedIndex={this.state.tabIndex}
                    onSelect={tabIndex => this.handleTabSelected(tabIndex)}
                    style ={heightStyle}
                >
                    <TabList className={"tab-wrapper sticky-top"}>
                        <Tab> Entry </Tab>
                        <Tab> Confirms </Tab>
                        <Tab> Versions </Tab>
                    </TabList>
                    <TabPanel>
                        <EntryView tradeId = {tradeId}
                                   window = {this.props.window}
                                   setChanged = {this.setChanged}
                                   setUnchanged = {this.setUnchanged}
                                   changed = {this.state.changed}
                        />
                    </TabPanel>
                    <TabPanel>
                        <ConfirmView tradeId = {tradeId} />
                    </TabPanel>
                    <TabPanel>
                        <VersionView tradeId = {tradeId} window = {windowBody} />
                    </TabPanel>
                </Tabs>
                {/*<TabStrip selected={this.state.activeTabIndex} onSelect={this.handleSelectTab}>*/}
                {/*    <TabStripTab title={"Entry"} contentClassName={"tab-content-wrapper"}>*/}
                {/*        <Entry trade = {this.props.trade} />*/}
                {/*    </TabStripTab>*/}
                {/*</TabStrip>*/}
            </div>
        );
    }
}

export default withRouter(Ticket);
import React from 'react';
import {Button, ButtonGroup, Toolbar, ToolbarItem, ToolbarSeparator} from '@progress/kendo-react-buttons';
import {AutoComplete, ComboBox} from '@progress/kendo-react-dropdowns';
import {Input, Checkbox} from "@progress/kendo-react-inputs";
import { Popup } from '@progress/kendo-react-popup'

import _ from 'lodash';
import client from "../../feathers";

import Hotkeys from 'react-hot-keys';

var Scroll = require('react-scroll');
var scroll = Scroll.animateScroll;


const inputMap = {
    "code": "Org Code",
    "name": "Name",
    "mpid": "MPID",
    "large_trader_id": "Large Trader ID",
    "finra_member": "FINRA Member",
    "address": "Address",
    "city": "City",
    "state": "State",
    "zip": "Zip",
    "country": "Country",

    // "inactive": "Inactive"
}

const entityMap = {
    "code": "Entity Code",
    "name": "Entity Name",
    "address": "Address",
    "city": "City",
    "state": "State",
    "zip": "Zip",
    "country": "Country",
}

const states = ["AK","AL","AR","AZ","CA","CO","CT","DC","DE","FL","GA","GU","HI","IA","ID", "IL","IN","KS","KY","LA","MA","MD","ME","MH","MI","MN","MO","MS","MT","NC","ND","NE","NH","NJ","NM","NV","NY", "OH","OK","OR","PA","PR","PW","RI","SC","SD","TN","TX","UT","VA","VI","VT","WA","WI","WV","WY"]

export default class OrganizationsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orgItemAdded: true,

            orgItemInEdit: {},
            prevOrgItem: {},
            orgItemChanged: false,

            entItems: {},
            prevEntItems: {},
            entIdsInEdit: new Set(),
            entItemChanged: false,

            newEntItems: [],
            entIndexToSelect: -1,
        };
    }

    getOrgList = (orgData) => {
        const ret = [];
        orgData.forEach(obj => {
           ret.push(obj["code"]);
        });
        return ret;
    }

    handleOrgSelected = (org_code) => {
        console.log(org_code)

        if((!this.state.orgItemChanged && !this.state.entItemChanged) || window.confirm("You have unsaved changes. Discard and continue?")) {
            let orgId = undefined;
            let orgItemInEdit = {}

            client.service('organizations').find({
                query: {
                    code: org_code
                }
            }).then((reqPage) => {
                console.log(reqPage)
                if(reqPage.length>0) {
                    orgId = reqPage[0]["id"]
                    orgItemInEdit = _.cloneDeep(reqPage[0]);
                }
                    this.setState({
                        orgItemChanged: false,
                        orgId,
                        code: org_code,
                        orgItemInEdit,
                        prevOrgItem: _.cloneDeep(orgItemInEdit),
                    })

                    // query database for entities, then generate a dictionary where {ent_id: entity}

                    // Now find the entities
                    this.extractEntities(orgId);

            })


            this.setState({
                orgItemAdded: false,

                entItemChanged: false,


                newEntItems: [],
            })

            if(org_code===null && !this.state.orgItemAdded){  // if the user clicked the x and no organization is selcted, default to add mode
                this.setState({orgItemAdded: true})
            }
        }
    }

    extractEntities(orgId) {
        let entItemsArr = {}
        let entItems = {}

        client.service('entities').find({
            query: {
                organizationId: parseInt(orgId)
            }
        }).then((reqPage) => {
            const reqs = reqPage;
            entItemsArr = reqs;

            for (let i = 0; i < entItemsArr.length; i++) {
                entItems[entItemsArr[i]['id']] = entItemsArr[i]
            }

            //need to call setstate separately because feathers find takes a while
            this.setState({
                entItems,
                prevEntItems: _.cloneDeep(entItems),
            })
        });
    }

    handleOrgItemChange = (e) => {

        const orgItemInEdit = this.state.orgItemInEdit;

        if (e.target.name === "finra_member") {
            orgItemInEdit['finra_member'] = !orgItemInEdit['finra_member']
        } else if (e.target.value == ""){
            orgItemInEdit[e.target.name] = null
        } else {
            orgItemInEdit[e.target.name] = e.target.value;
        }

        this.setState({
            orgItemInEdit: orgItemInEdit,
            orgItemChanged: true,
        })
    }

    toggleFinraCheck = () => {
            const orgItemInEdit = this.state.orgItemInEdit;
            orgItemInEdit['finra_member'] = orgItemInEdit['finra_member']==1 ? 0 : 1;

            this.setState({
                orgItemInEdit,
                orgItemChanged:true
            })
    }

    handleAddOrg = () => {

        this.setState({
            orgItemAdded: true,

            orgItemChanged: false,
            orgItemInEdit: {},

            entItemChanged: false,
            entItems: {},
            entIdsInEdit: new Set(),
            newEntItems: [],

        })

    }

    /** Entities form **/
    handleEntItemChange = (ent_id, e) => {
        /** Edit entItems to control form input **/
        const entItems = this.state.entItems;
        entItems[ent_id][e.target.name] = e.target.value

        // add edited entity ID to set
        const entIdsInEdit = this.state.entIdsInEdit;
        entIdsInEdit.add(ent_id);

        console.log(ent_id)

        this.setState({
            entItems:entItems,

            entIdsInEdit:entIdsInEdit,
            entItemChanged: true,
        })
    }

    handleAddEntity = () => {
        const newEntItems = this.state.newEntItems;

        newEntItems.push(
            {
                // "ent_code": null,
                // "ent_name": null,
                // "address": null,
                // "city": null,
                // "state": null,
                // "zip": null,
                "organizationId": this.state.orgId,
            }
        );

        this.setState({
            entIndexToSelect: newEntItems.length-1,

            newEntItems,
            entItemChanged: true
        })
    }

    handleNewEntItemChange = (ent_id, e) => {
        /** Edit entItems to control form input **/
        const newEntItems = this.state.newEntItems;
        newEntItems[ent_id][e.target.name] = e.target.value


        this.setState({
            newEntItems:newEntItems,

            entItemChanged: true,
        })
    }

    /** Saving changes made to organizations or entities **/
    saveChanges = () => {

        if(this.state.orgItemAdded){
            client.service('organizations').create(this.state.orgItemInEdit).then(response=>{
                this.resetAfterSaveChanges()
                this.setState({orgItemAdded:false}, ()=>{
                    this.handleOrgSelected(this.state.orgItemInEdit.code)
                })
            }).catch(e => {
                window.alert(e)
            })

        } else if(this.state.orgItemChanged){
            client.service('organizations').update(this.state.orgItemInEdit.id, this.state.orgItemInEdit).then(response=>{
                this.resetAfterSaveChanges()
            }).catch(e => {
                window.alert(e)
            })
        }

        if (this.state.entItemChanged) {
            this.state.newEntItems.forEach(obj=>{
                client.service('entities').create(obj).then(response => {
                    this.resetAfterSaveChanges()
                }).catch(e => {
                    window.alert(e)
                    // this.cancelChanges()
                })
            })

            this.state.entIdsInEdit.forEach(ent_id=>{
                client.service('entities').update(ent_id, this.state.entItems[ent_id]).then(response => {
                    this.resetAfterSaveChanges()
                }).catch(e => {
                    window.alert(e)
                    // this.cancelChanges()
                })
            })
        }

    }

    resetAfterSaveChanges = () => {
        this.setState({
            newEntItems: [],
            entIdsInEdit: new Set (),
            orgItemAdded: false,
            entItemChanged: false,
            orgItemChanged: false,
        })

        this.extractEntities(this.state.orgId);

        this.setState({
            prevOrgItem: _.cloneDeep(this.state.orgItemInEdit),
            prevEntItems: _.cloneDeep(this.state.entItems)
        })

    }

    cancelChanges = () => {
        this.setState({
            orgItemChanged: false,
            orgItemInEdit: _.cloneDeep(this.state.prevOrgItem),

            entItemChanged: false,
            entItems: _.cloneDeep(this.state.prevEntItems),
            entIdsInEdit: new Set(),

            newEntItems: [],
        })
    }

    onKeyDown(keyName, e, handle) {
        console.log("test:onKeyDown", keyName, e, handle)
        this.setState({
            output: `onKeyDown ${keyName}`,
        });
    }
    onKeyUp(keyName, e, handle) {
        console.log("test:onKeyUp", e, handle)
        this.setState({
            output: `onKeyUp ${keyName}`,
        });
    }

    render() {

        return (
            <Hotkeys
                keyName="shift+a,alt+s"
                onKeyDown={this.onKeyDown.bind(this)}
                onKeyUp={this.onKeyUp.bind(this)}
            >
            <div className={"admin-wrapper"}>
            <div className={"admin-btn-panel sticky"}>
                <ComboBox data={this.getOrgList(this.props.orgData)}
                          allowCustom={false}
                          suggest={true}
                          onChange={e => {this.handleOrgSelected(e.target.value)}}
                          popupSettings={{animate:false}}
                    // filter = {this.state.orgItemAdded? "New Organization" : this.state.orgCode}
                />
                {!this.state.orgItemAdded && this.state.orgId && <Button icon="add" title="Add" onClick = {this.handleAddEntity}> Add Entity</Button>}

                {(this.state.orgItemChanged || this.state.entItemChanged) ?
                    [
                        <Button icon="cancel" title="Cancel" onClick = {this.cancelChanges}> Cancel </Button>,
                        <Button icon="save" title="Save" onClick = {this.saveChanges}> Save Changes </Button>
                    ]
                        :
                    <Button icon="add" title="Add" onClick = {this.handleAddOrg}> Add Organization</Button>
                }
            </div>
            {/*<form onSubmit={this.handleSubmit}>*/}

                    {/*<div style={{ marginBottom: '10px' }}*/}
                    {/*     ref={*/}
                    {/*         (x) => {*/}
                    {/*             if(!this.state.firstInputSelected && x) {*/}
                    {/*                 x.querySelector(`[name=${'nlp'}]`).select()*/}
                    {/*                 this.setState({firstInputSelected: true})*/}
                    {/*             }*/}
                    {/*         }*/}
                    {/*     }*/}
                    {/*>*/}
            <div className={"admin-content"}>
                <form autoComplete="off">

                <table className = "admin-table">
                    <tbody className = "dialog-table">
                        {Object.keys(inputMap).map((key, i) => {
                            let inputComponent;
                            if(key === "finra_member"){
                                inputComponent = <Checkbox
                                    label={"Yes"}
                                    name = "finra_member"

                                    value = {this.state.orgItemInEdit["finra_member"]==1 ? true : false}
                                    onChange={ this.toggleFinraCheck }
                                />
                            } else if (key === "state"){
                                inputComponent = (
                                    <ComboBox
                                        data = {states}
                                        allowCustom={true}
                                        suggest={true}

                                        name = "state"
                                        value={this.state.orgItemInEdit[key] || ''}
                                        onChange={ this.handleOrgItemChange }
                                        popupSettings={{animate:false}}
                                    />
                                );
                            } else {
                                inputComponent = (
                                    <Input
                                        type="text"
                                        name={key}

                                        value={this.state.orgItemInEdit[key] || ''}
                                        onChange={ this.handleOrgItemChange }
                                        // onKeyPress={this.handleKeyPress}
                                    />
                                );
                            }
                            return(

                                <tr key={i}>
                                    <td>
                                        {inputMap[key]}
                                    </td>
                                    <td>
                                        {inputComponent}
                                    </td>
                                </tr>
                            );
                        })}

                    </tbody>
                </table>

                {
                    Object.keys(this.state.entItems).map((ent_id, index) => (
                        <table className = "admin-table">
                                <tbody className = "dialog-table">
                                {Object.keys(entityMap).map((key, i) => {
                                    let inputComponent;
                                    if (key === "state") {
                                        inputComponent = (
                                            <ComboBox
                                                data = {states}
                                                allowCustom={true}
                                                suggest={true}

                                                name = "state"
                                                value={this.state.entItems[ent_id][key] || ''}
                                                onChange = {e=>this.handleEntItemChange(ent_id, e)}
                                                popupSettings={{animate:false}}

                                            />
                                        );
                                    } else {
                                        inputComponent = (
                                            <Input
                                                type="text"

                                                name={key}
                                                value={this.state.entItems[ent_id][key] || ''}
                                                onChange = {e=>this.handleEntItemChange(ent_id, e)}
                                            />
                                        );
                                    }
                                    return(
                                        <tr key={i}>
                                            <td>
                                                {entityMap[key]}
                                            </td>
                                            <td>
                                                {inputComponent}
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        ))
                }


                {
                    this.state.newEntItems.map((obj, index) => (
                        <table className = "admin-table">
                            <tbody className = "dialog-table">
                            {Object.keys(entityMap).map((key, i) => {
                                let inputComponent;
                                if(i === 0) {
                                    inputComponent = (
                                        <div
                                             ref={
                                                 (x) => {
                                                     if(x && index === this.state.entIndexToSelect) {
                                                         x.querySelector(`[name=${key}]`).select()
                                                         this.setState({entIndexToSelect: -1})
                                                     }
                                                 }}
                                        >

                                            <Input
                                                type="text"

                                                name={key}
                                                value={this.state.newEntItems[index][key] || ''}
                                                onChange = {e=>this.handleNewEntItemChange(index, e)}
                                            />
                                        </div>
                                    );
                                } else if (key === "state") {
                                    inputComponent = (
                                        <ComboBox
                                            data = {states}
                                            allowCustom={true}
                                            suggest={true}

                                            name = "state"
                                            value={this.state.newEntItems[index][key] || ''}
                                            onChange = {e=>this.handleNewEntItemChange(index, e)}
                                            popupSettings={{animate:false}}

                                        />
                                    );
                                } else {
                                    inputComponent = (
                                        <Input
                                            type="text"

                                            name={key}
                                            value={this.state.newEntItems[index][key] || ''}
                                            onChange = {e=>this.handleNewEntItemChange(index, e)}
                                        />
                                    );
                                }
                                return(
                                    <tr key={i}>
                                        <td>
                                            {entityMap[key]}
                                        </td>
                                        <td>
                                            {inputComponent}
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    ))
                }
                {this.state.entIndexToSelect!=-1 && scroll.scrollToBottom()}
                </form>
                </div>
            </div>
            </Hotkeys>
        );
    }
}
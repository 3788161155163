import React from 'react';
import "./dash_style.css"
import logo from '../../images/logo.png';
import { Button } from '@progress/kendo-react-buttons';
import SettingsModal from "../Main/SettingsModal";
import {Link} from "react-router-dom";
import {Tooltip} from "@progress/kendo-react-tooltip";
import {Cookies, withCookies} from "react-cookie";
import {instanceOf} from "prop-types";
import client from "../../feathers";
import { Line, Circle } from 'rc-progress';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { GoTag, GoChecklist } from 'react-icons/go';
import {FiClock, FiCheck} from 'react-icons/fi'

import ReactDOM from 'react-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faCrown, faStar, faTrophy, faCircle} from '@fortawesome/free-solid-svg-icons'
import TextLoop from "react-text-loop";

import 'hammerjs';
import {
    Chart,
    ChartArea,
    ChartTooltip,
    ChartTitle,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartCategoryAxisItem
} from '@progress/kendo-react-charts';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Switch} from '@progress/kendo-react-inputs'

import {Timeline, TimelineEvent, TimelineBlip} from 'react-event-timeline'
import MatchModal from "../Whiteboard/MatchModal";
import GoalsModal from "./GoalsModal";
import MVPModal from "./MVPModal";

import {adminList} from "../../helpers/data";

/** Redux **/
import {connect} from 'react-redux';

library.add(faCoffee, faCrown, faStar, faTrophy, faCircle);


function calcPct(num, denom){
    if(denom === 0) return 0
    return (Math.floor((num / denom) * 100))
}

const Header = ({openSettingsModal, openGoalsModal, toggleMVPModal, user}) => {
    return <header className="main-header">
        <div className="header-container">
            <Link to="/">
                <h1 className="mh-logo" style={{"paddingLeft":"10px"}}>
                    <img src={logo} width="90" height="10" alt="BayCrest.logo" />
                </h1>
            </Link>
            <nav className="main-nav">
                <ul className="main-nav-list">
                    <Link to="/">
                        <li>
                            <Button look="bare" icon="home"></Button>
                        </li>
                    </Link>
                    <li>
                        <Button className = {'DashBoard__button'} look="bare" icon="search" onClick={e => openGoalsModal()}></Button>
                    </li>

                    {user!=undefined && adminList.includes(user.email) &&
                        <li>
                            <Button className = {'DashBoard__button'} look="bare" icon="user" onClick={e => toggleMVPModal()}></Button>
                        </li>
                    }
                    <li>
                        <Button className = {'DashBoard__button'} look="bare" icon="gear" onClick={e => openSettingsModal()}></Button>
                    </li>
                </ul>
            </nav>
        </div>
    </header>;
};

// Calculate conversions percentage
function calcConvPct(tradeData, orderData){
    let set = new Set();
    if(tradeData==undefined) tradeData = [];
    if(orderData == undefined) orderData = [];
    tradeData.forEach(obj => {
        set.add(obj.orderId)
    })

    return [set.size + "/" + orderData.length, calcPct(set.size, orderData.length)]
}

const ConversionsPanel = ({className, id, allDataLoaded, tradeTD, tradeWD, tradeMD, orderTD, orderWD, orderMD}) => {
    let [todayFrac, todayPct] = calcConvPct(tradeTD, orderTD);
    let [weekFrac, weekPct] = calcConvPct(tradeWD, orderWD);
    let [monthFrac, monthPct] = calcConvPct(tradeMD, orderMD);
    return <div id = {id} className = {className} >
        <div className = "line-container">
            <div className = "conv-info-left head-info">
                <p className = "head-title"> Conv.% </p>
                <p className = "head-subtitle">

                    {allDataLoaded //make sure the data is loaded
                    &&
                        <TextLoop interval={8000} className={"grey"}>
                            <span> Today: {todayFrac} </span>
                            <span> Week: {weekFrac} </span>
                            <span> Month: {monthFrac} </span>
                        </TextLoop>
                    }
                </p>
            </div>

            <div className = "conv-info-right">
                <div className = "circle-box">
                    <div className = "circle-container">
                        <CircularProgressbar
                            value={todayPct}
                            text={`${todayPct}%`}
                            styles={buildStyles({
                                // Rotation of path and trail, in number of turns (0-1)
                                // rotation: 0.25,

                                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                strokeLinecap: 'butt',

                                // Text size
                                textSize: '32px',

                                // How long animation takes to go from one percentage to another, in seconds
                                pathTransitionDuration: 0.5,

                                // Can specify path transition in more detail, or remove it entirely
                                // pathTransition: 'none',

                                // Colors
                                pathColor: `#ff9900`,
                                textColor: "black",

                                trailColor: '#d6d6d6',

                            })}
                        />
                        <p className = "bar-subtitle conv-subtitle"> Today </p>
                    </div>
                </div>
                <div className = "circle-box">
                    <div className = "circle-container">
                        <CircularProgressbar
                            value={weekPct}
                            text={`${weekPct}%`}
                            styles={buildStyles({
                                // Rotation of path and trail, in number of turns (0-1)
                                // rotation: 0.25,

                                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                strokeLinecap: 'butt',

                                // Text size
                                textSize: '32px',

                                // How long animation takes to go from one percentage to another, in seconds
                                pathTransitionDuration: 0.5,

                                // Can specify path transition in more detail, or remove it entirely
                                // pathTransition: 'none',

                                // Colors
                                pathColor: "#ff9900",
                                textColor: ' black',
                                trailColor: '#d6d6d6',
                                backgroundColor: '#3e98c7',
                            })}
                        />
                        <p className = "bar-subtitle conv-subtitle"> Week </p>
                    </div>
                </div>
                <div className = "circle-box">
                    <div className = "circle-container">
                        <CircularProgressbar
                            value={monthPct}
                            text={`${monthPct}%`}
                            styles={buildStyles({
                                // Rotation of path and trail, in number of turns (0-1)
                                // rotation: 0.25,

                                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                strokeLinecap: 'butt',

                                // Text size
                                textSize: '32px',

                                // How long animation takes to go from one percentage to another, in seconds
                                pathTransitionDuration: 0.5,

                                // Can specify path transition in more detail, or remove it entirely
                                // pathTransition: 'none',

                                // Colors
                                pathColor: "#ff9900",
                                textColor: 'black',
                                trailColor: '#d6d6d6',
                                backgroundColor: '#3e98c7',
                            })}
                        />
                        <p className = "bar-subtitle conv-subtitle"> Month </p>
                    </div>
                </div>
            </div>

        </div>
    </div>;
}


const TodayPanel = ({className, id, todayData, yestData, weekData, dailyGoal, type}) => {
    if (yestData == undefined) yestData = [];
    // calculate percentage of daily goal met
    const countToday = todayData.length;
    const todayDailyPct = calcPct(countToday, dailyGoal)

    // calculate whether we are up or down compared to yesterday
    let today = new Date();

    let dateType = type==="Orders" ? "createdAt" : "tradedAt"
    yestData = yestData.filter(obj => {
        let dt = new Date(obj[dateType])
        let objTime = new Date()
        objTime.setHours(dt.getHours())
        objTime.setMinutes(dt.getMinutes())
        return objTime < today
    })

    let countYest = yestData.length;
    let compDiff = countToday - countYest;
    let compStr = compDiff<0? "▼" : "▲"
    let hours = today.getHours(), minutes = (today.getMinutes() < 10 ? '0' : '') + today.getMinutes()
    compStr += Math.abs(compDiff)

    let strokeColor = type==="Orders" ? "#00aaff" : "#ff9900"

    return <div id = {id} className = {className} >
        <div className = "line-container head-container">
            <div className = "flex-4 head-info">
                <p className = "head-title"> {type} </p>
                <p className = "head-subtitle">
                    {dailyGoal &&
                    <TextLoop interval={15000} className = "grey">
                        <span> {Math.max(0, dailyGoal-countToday)} to reach daily goal of {dailyGoal} </span>
                        <span><span className={compDiff<0? "head-neg" : "head-pos"}>{compStr}</span>  from yesterday at {hours}:{minutes} </span>
                    </TextLoop>
                    }
                </p>
            </div>
            <div className = "flex-1 head-stat" > {countToday} </div>
        </div>
        <Line percent={todayDailyPct} strokeWidth="3" trailWidth = "3" strokeColor={strokeColor} />
        <div className = "line-container">
            <div className = "flex-4"> Daily Goal: {dailyGoal} </div>
            <div className = "flex-1"> {todayDailyPct}% </div>
        </div>
    </div>;
}

const DetailPanel = ({className, id, yestData, weekData, monthData, weeklyGoal, monthlyGoal, type}) => {
    const yestCount = yestData.length;
    let monthCount = monthData.length;

    let weekPct = calcPct(weekData.length, weeklyGoal)
    let monthPct = calcPct(monthCount, monthlyGoal)

    let strokeColor = type==="Order" ? "#00aaff" : "#ff9900"

    return <div className={className} id = {id}>
        <div className = "dtl-bar dtl-topbar">
            {type==="Order" ? <GoTag style = {{color: strokeColor}}/> : <FiCheck style = {{color: strokeColor}}/>}
            <div className = "dtl-heading"> {type} Stats </div>
            {/*<div className = "dtl-button"> lol </div>*/}
        </div>

        <div className = "dtl-container with-padding">
            <div className = "dtl-line">
                <div className = "half">
                    <div className = "dtl-label"> Yesterday </div>
                    <div className = "dtl-stat"> {yestCount} </div>
                </div>
                <div className = "half">
                    <div className = "dtl-label"> Month </div>
                    <div className = "dtl-stat"> {monthCount} </div>
                </div>
            </div>
        </div>

        <div className = "dtl-bar dtl-footer">
                <div className = "half">
                    <div className = "line-container">
                        <div className = "bar-pct">
                            {weekPct}%
                        </div>
                        <div className = "bar-right">
                            <Line percent={weekPct} strokeWidth="10" trailWidth = "10" strokeColor={strokeColor} />
                        </div>
                    </div>
                    <div className = "bar-subtitle">
                        Weekly Goal: {weeklyGoal}
                    </div>
                </div>
                <div className = "half">
                    <div className = "line-container">
                        <div className = "bar-pct">
                            {monthPct}%
                        </div>
                        <div className = "bar-right">
                            <Line percent={monthPct} strokeWidth="10" trailWidth = "10" strokeColor={strokeColor} />
                        </div>
                    </div>
                    <div className = "bar-subtitle">
                        Monthly Goal {monthlyGoal}
                    </div>
                </div>
        </div>
    </div>
}

// Return yesterday and today leaders (MVP, etc.)
function getMVP(data){
    if(data==undefined || data.length===0) return []

    let arr = data.map(obj => obj.tradedBy)
    let counts = arr.reduce((a, c) => {
        a[c] = (a[c] || 0) + 1;
        return a;
    }, {});
    let maxCount = Math.max(...Object.values(counts));
    let persons = Object.keys(counts).filter(k => counts[k] === maxCount);

    return persons
}

function getFirstPerson(data, field){
    if(data==undefined || data.length===0) return []

    //order the data from oldest to most recent
    let atField = field+"At"
    let byField = field+"By"
    data.sort(function(a,b){
        return new Date(a[atField]) - new Date(b[atField])
    })

    let mvp = data[0][byField] ? data[0][byField] : '@';
    return mvp.slice(1,mvp.indexOf("@")).toUpperCase()
}

const PersonPanel = ({className, id, todayData, yestData, pnType, ...props}) => {
    let color = "#f0f0f0"

    let pnToday = ""
    let pnYest = ""
    let iconName = ""
    let cname = ""

    if(pnType === 'MVP'){
        color="orange"
        cname = "mvp-back"

        if(props.todayMVP){
            pnToday = props.todayMVP.slice(1,props.todayMVP.indexOf("@")).toUpperCase()
        }
        if(props.yestMVP){
            pnYest = props.yestMVP.slice(1,props.yestMVP.indexOf("@")).toUpperCase()
        }

        iconName = "crown"
    } else if (pnType === "First Order"){
        color="#00aaff"
        cname = "first-order-back"

        pnToday = getFirstPerson(todayData, "created")
        pnYest = getFirstPerson(yestData, "created")
        iconName = "star"
    } else if (pnType === "First Trade") {
        color="#ff9900"
        cname = "first-trade-back"

        pnToday = getFirstPerson(todayData, "traded")
        pnYest = getFirstPerson(yestData, "traded")
        iconName = "trophy"
    }

    return <div className = {className} id = {id}>
        <div className = "pn-container">
            <div className = {"pn-left " + cname}>
                {/*<div className = "pn-icon">*/}
                {/*    <div className = "icon-wrapper">*/}
                {/*        <div className = "icon-holder">*/}
                {/*            <FontAwesomeIcon icon={iconName} color = {color}/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className = "icon-wrapper">
                    <div className = "icon-holder">
                        <FontAwesomeIcon icon={iconName} color = {color}
                                         // mask={['fas', 'circle']}
                                         // transform="grow-7 left-1.5 up-2.2"
                        />
                    </div>
                </div>
            </div>
            <div className = "pn-box">

                <div className = "pn-name current">{pnToday}</div>
                <div className = "pn-day"> Today's {pnType} </div>
            </div>
            <div className = "pn-box grey">
                <div className = "pn-name previous">{pnYest}</div>
                <div className = "pn-day"> Yest {pnType} </div>
            </div>
        </div>

    </div>
}


// tooltip render for hover over chart
const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const SharedTooltip = (props) => {
    const { categoryText, category, points } = props;
    let dateObj = new Date(categoryText)
    return (
        <div>
            <div> {days[dateObj.getDay()]}, {categoryText} </div>
            <div>{category}</div>
            {points.map((point) => (<div>{point.series.name} : {point.value}</div>))}
        </div>
    );
};

const sharedTooltipRender = (context) => (<SharedTooltip {...context} />);
const baseUnits = [ 'Days', 'Weeks', 'Months' ];

class DashBoard extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        const { cookies } = props;

        this.state = {
            gridData: [],
            baData: [],
            goals: {
                ordersDaily: {},
                ordersWeekly: {},
                ordersMonthly: {},
                tradesDaily:{},
                tradesWeekly:{},
                tradesMonthly:{}
                },
            yestMVP: "",
            todayMVP: "",

            baseUnit: 'Weeks',
            showToolTip: 0,

            showSettingsModal: false,
            showGoalsModal: false,
            showMVPModal: false,


            user: this.props.user,
        };
    }

    componentDidMount() {
        // Set body overflow to hidden;
        document.body.style.overflowY = "auto"

        const {user} = this.props
        this.setState({user: user})

        const op_req = client.service('orders');
        op_req.find({
            query: {
                $select: ['id', 'createdAt', 'createdBy']
            }
        }).then((reqPage) => {
            const opReqData = reqPage;

            this.setState({
                gridData: opReqData,
            })
        });

        const ba_history = client.service('bids');
        ba_history.find({
            query: {
                $select: ['id', 'createdAt', 'traded', 'createdBy']
            }
        }).then((reqPage) => {
            const reqs = reqPage;
            this.setState({
                baData: reqs,

                allDataLoaded: true,
            })
        });

        // get goals
        const go = client.service('goals');
        go.find().then((reqPage) => {
            const reqs = reqPage;

            let goals = {}
            reqs.forEach(obj=>{
                goals[obj.goalName] = {id: obj.id, value: obj.value};
            })

            this.setState({
                goals: goals,
            })

        });

        // getting today's mvp and yesterday's mvp
        const mvp_history = client.service('mvps')
        mvp_history.find({
            query: {
                dt: new Date().toISOString().split('T')[0]
            }
        }).then((reqPage) => {
            const data = reqPage;
            if(data.length>0) this.setState({"todayMVP": data[0].email})
            // this.setState({"today": data[0]})
        });

        mvp_history.find({
            query: {
                dt: this.getLastBizDay().toISOString().split('T')[0]
            }
        }).then((reqPage) => {
            const data = reqPage;
            if(data.length>0) this.setState({"yestMVP": data[0].email})
            // this.setState({"yesterday": data[0]})
            // console.log("yesterday data string" + data[0].dt)
            // console.log("ylast biz day string" + this.getLastBizDay().toISOString().split('T')[0])
            // console.log("new date to string"  + new Date().toISOString().split('T')[0])
        });


        // Add new messages to the message list
        op_req.on('created', op => this.gridItemCreated(op));
        op_req.on('updated', op => this.gridItemUpdated(op));
        op_req.on("removed", op=>this.gridItemRemoved(op));

        ba_history.on('created', op => this.baItemCreated(op));
        ba_history.on('updated', op => this.baItemUpdated(op));
        ba_history.on("removed", op=>this.baItemRemoved(op));

        // go.on('created', op => this.baItemCreated(op));
        go.on('updated', op => this.goItemUpdated(op));

        mvp_history.on('created', op => this.mvpItemCreated(op));
        mvp_history.on('updated', op => this.mvpItemCreated(op));
        // mvp_history.on("removed", op=>this.mvpItemRemoved(op));
    }

    openSettingsModal = () => {
        this.setState({showSettingsModal:true})
    }
    closeSettingsModal = () => {
        this.setState({showSettingsModal:false})
    }

    openGoalsModal = () => {
        this.setState({showGoalsModal:true})
    }
    closeGoalsModal = () => {
        this.setState({showGoalsModal:false})
    }

    toggleMVPModal = () => {
        this.setState({showMVPModal:!this.state.showMVPModal})
    }

    // // return target goal value for parameter goalname
    // getGoal = (goalName) => {
    //     const goals = this.state.goals;
    //     for (let i = 0; i < goals.length; i++) {
    //         if(goals[i].goalName === goalName){
    //             return goals[i].value
    //         }
    //     }
    // }

    // Return monthly, weekly, yesterday, daily filtered grid data or ba Data
    //helper function to get start and end of week
     getWeekDates =() => {

        let now = new Date();
        let dayOfWeek = now.getDay(); //0-6
        let numDay = now.getDate();

        let start = new Date(now); //copy
        start.setDate(numDay - dayOfWeek);
        start.setHours(0, 0, 0, 0);


        let end = new Date(now); //copy
        end.setDate(numDay + (7 - dayOfWeek));
        end.setHours(0, 0, 0, 0);

        return [start, end];
    }

    getLastBizDay = () => {
        let endDate = ""
        let startDate = new Date();
        let noOfDaysToAdd = 1, count = 0;
        while (count < noOfDaysToAdd) {
            endDate = new Date(startDate.setDate(startDate.getDate() - 1));
            if (endDate.getDay() != 0 && endDate.getDay() != 6) {
                //Date.getDay() gives weekday starting from 0(Sunday) to 6(Saturday)
                count++;
            }
        }

        return endDate
    }

    getfilteredData =(fullData) => {
        // Filter for month
        let currentMonth = new Date().getMonth()
        let currentYear = new Date().getFullYear()
        let monthData = fullData.filter(obj => {
            let objDate = new Date(obj.createdAt)
            return (objDate.getMonth()===currentMonth) && (objDate.getFullYear() === currentYear);
        });

        // Filter for week data
        let [weekStart, weekEnd] = this.getWeekDates();
        console.log(weekStart.toLocaleString(), weekEnd.toLocaleString());
        let weekData = monthData.filter(obj => {
            let createdAt = new Date(obj.createdAt)
            return createdAt > weekStart && createdAt < weekEnd
        })

        // Filter for yesterday
        let lastBizDay = this.getLastBizDay().toDateString();
        let yestData = weekData.filter(obj => {
            return new Date(obj.createdAt).toDateString() === lastBizDay
        })

        let todayDate = new Date()
        todayDate.setHours(0,0,0,0)
        // Filter for today
        let todayData = weekData.filter(obj => {
            return new Date(obj.createdAt) > todayDate
        })

        return [monthData, weekData, yestData, todayData]
    }

    // get total trades, total orders, etc to be graphed
    // @params data (either bahistory or griddata), field is "orders, trades, market"
    getChartData = (data, field) => {
        if(data==undefined) return []

        let orderMap = {}
        let orderData = []
        data.forEach(obj => {
            let date = obj.createdAt;
            orderMap[date] = orderMap[date] || 0;
            orderMap[date]++;
        })
        for (let key in orderMap) {
            // check if the property/key is defined in the object itself, not in parent
            if (orderMap.hasOwnProperty(key)) {
                let obj = {date: new Date(key)}
                obj[field] = orderMap[key]
                orderData.push(obj)
            }
        }

        return orderData
    }

    handleBaseUnitChange = ( event ) => {
        this.setState({ baseUnit: event.target.value });
    }

    // for timeline
    //helper method to get string of order
    getOrderMsg = (dataItem) => {
        let clipBoardText = '';
        // let structures = {
        //     "Call" : "Call",
        //     "Put": "Put",
        //     "Call spread": "CS",
        //     "Put spread": "PS",
        //     "Rev con": "RC"
        // }
        Object.keys(dataItem).forEach((key)=> {
            if(['ticker','expiration','strike','structure','risk_rwd_ratio','tied','delta'].includes(key) && dataItem[key]){
                let value = dataItem[key].toLowerCase()
                value = value
                    .split(' ')
                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                    .join(' ');

                switch(key){
                    case "ticker":
                        console.log("ticker")
                        clipBoardText+= value.toUpperCase();
                        break;
                    case "tied":
                        clipBoardText += " vs. " + value;
                        break;
                    case "delta":
                        clipBoardText += " (" + value + ")";
                        break;
                    default:
                        clipBoardText += " " + value;
                        break;
                }
            }
        });
        return clipBoardText;
    }

    //helper method to get string of market
    getMarketMsg = (dataItem) => {
        let orgCode = dataItem.orgCode
        let type = dataItem.bid? "bid" : "ask"
        let forAt = dataItem.bid? "for" : "@"
        let price = dataItem[type]
        let size = dataItem.size

        return orgCode + ": " + size + " " + type + " " + forAt +" " +price ;
    }
    formatPrice = (price) => {
        return parseFloat(price).toFixed(Math.max(2, (price.toString().split('.')[1] || []).length))
    }
    //get trade msg
    getTradeMsg = (dataItem) => {
        let type, forAt, bidOrgCode, askOrgCode

        if(dataItem.bid) {
            type = "bid"
            forAt = "for"
            bidOrgCode = dataItem.orgCode
            askOrgCode = dataItem.initOrgCode
        } else {
            type = "ask"
            bidOrgCode = dataItem.initOrgCode
            askOrgCode = dataItem.orgCode
        }

        let price = this.formatPrice(dataItem[type])
        let size = dataItem.size
        return "Trade: "+ bidOrgCode+"/"+askOrgCode + " " + price+"x"+size
    }
    formatDate = (createdAt) => {
        let d = new Date(createdAt)
        // let offset = new Date().getTimezoneOffset();
        // let d = new Date(utc_date.setMinutes(utc_date.getMinutes() + offset))

        let month = '' + (d.getMonth() + 1)
        let day = '' + d.getDate()
        let year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;


        let hours = d.getHours();
        let minutes = d.getMinutes();
        let ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        let strTime = hours + ':' + minutes + ' ' + ampm + " on " + [month, day, year.toString().slice(2)].join("/");
        return strTime
    }
    // @ params
    getSortedTodayEvents(orderData, baData, tradeData){
        let ret = {}, orderTemp = {}, marketTemp = {}, tradeTemp = {}

        // create order map
        orderData.forEach(obj=>{
            let orderMsg = this.getOrderMsg(obj)
            orderTemp[new Date(obj.createdAt)] = {
                email: obj.createdBy,
                action: "create",
                type: "order",
                orderMsg: orderMsg,
                // dtlMsg: orderMsg,
            }
        })

        // create market map
        baData.forEach(obj=>{
            const index = this.state.gridData.findIndex(gridItem => gridItem.id === obj.orderId)
            let gridItem = this.state.gridData[index]

            marketTemp[new Date(obj.createdAt)] = {
                email: obj.createdBy,
                action: "create",
                type: "market",
                orderMsg: index? "" : this.getOrderMsg(gridItem),
                dtlMsg: this.getMarketMsg(obj),
            }
        })

        // create trade map
        // first create map with each trade_id group as key
        let visited = []
        tradeData.filter(obj=>obj.initOrgCode).forEach(obj=>{
            let tradeMsg = this.getTradeMsg(obj)

            if(visited.includes(obj.trade_id)){
                tradeTemp[obj.trade_id]["dtlMsg"].push(tradeMsg)
            } else {
                tradeTemp[obj.trade_id] = {
                    date: new Date(obj.tradedAt),
                    email: obj.tradedBy,
                    action: "complete",
                    type: "trade",
                    orderMsg: obj!=undefined? "" : this.getOrderMsg(obj),
                    dtlMsg: [tradeMsg],
                }
                visited.push(obj.trade_id)
            }
        })
        // then create map with dates as key
        let tradeFinal = {}
        for (let key in tradeTemp){
            let obj = tradeTemp[key]
            let date = obj.date
            delete obj.date
            tradeFinal[date] = obj
        }

        let out = [];

        [orderTemp, marketTemp, tradeFinal].forEach(map=>{
            if(Object.entries(map).length !== 0 && map.constructor === Object) { //if object is not empty
                Object.keys(map).forEach(key=>out.push({date:key, ...map[key]}))
            }
        })

        out.sort(function(a,b){
            return new Date(b.date) - new Date(a.date)
        })

        //
        // ret = {...orderTemp, ...marketTemp, ...tradeFinal}
        // console.log("RETETERE")
        // console.log(ret)
        // let out = []
        // Object.keys(ret).sort(function(a, b) {
        //     return new Date(b) -new Date(a);
        // }).forEach(function(key) {
        //     out.push({date: key, ...ret[key]});
        // })

        return out
    }


    render() {
        let [orderMD, orderWD, orderYD, orderTD]  = this.getfilteredData(this.state.gridData)
        let [baMD, baWD, baYD, baTD] = this.getfilteredData(this.state.baData)
        let [tradeMD, tradeWD, tradeYD, tradeTD]  = this.getfilteredData(
            this.state.baData.filter(obj=>{
                return obj.traded
            })
        )

        // for chart
        const { baseUnit } = this.state;

        // for timeline, get sorted map of today's date events
        const todayEvents = this.getSortedTodayEvents(orderTD, baTD, tradeTD)
        console.log("Today events")
        console.log(todayEvents)
        return (
            <div id = "body">
                <Header openSettingsModal={e=>this.openSettingsModal()} openGoalsModal={e=>this.openGoalsModal()} toggleMVPModal={e=>this.toggleMVPModal()} user={this.state.user}/>

                <div className={"container"} id = "stat-container">
                    <TodayPanel id = "orders-today-panel" className = "panel with-padding"
                                todayData = {orderTD}
                                yestData = {orderYD}
                                weekData = {orderWD}
                                dailyGoal = {this.state.goals.ordersDaily.value}
                                type = "Orders"
                    />
                    <TodayPanel id = "trades-today-panel" className = "panel with-padding"
                                todayData = {tradeTD}
                                yestData = {tradeYD}
                                weekData = {tradeWD}
                                dailyGoal = {this.state.goals.tradesDaily.value}
                                type = "Trades"
                    />

                    <ConversionsPanel id = "conversions-panel" className = "panel with-padding"
                                      allDataLoaded={this.state.allDataLoaded}

                                      tradeTD = {tradeTD}
                                      tradeWD = {tradeWD}
                                      tradeMD = {tradeMD}

                                      orderTD = {orderTD}
                                      orderWD = {orderWD}
                                      orderMD = {orderMD}
                    />

                    <DetailPanel id = "orders-history-panel" className = "panel"
                                 yestData = {orderYD}
                                 weekData = {orderWD}
                                 monthData = {orderMD}
                                 weeklyGoal = {this.state.goals.ordersWeekly.value}
                                 monthlyGoal = {this.state.goals.ordersMonthly.value}
                                 type="Order"
                    />
                    <DetailPanel id = "trades-history-panel" className = "panel"
                                 yestData = {tradeYD}
                                 weekData = {tradeWD}
                                 monthData = {tradeMD}
                                 weeklyGoal = {this.state.goals.tradesWeekly.value}
                                 monthlyGoal = {this.state.goals.tradesMonthly.value}
                                 type="Trade"
                    />

                    <PersonPanel id = "mvp-panel" className = "panel person-panel"
                                 todayData = {tradeTD}
                                 yestData = {tradeYD}
                                 pnType = "MVP"

                                 todayMVP = {this.state.todayMVP}
                                 yestMVP = {this.state.yestMVP}
                    />
                    <PersonPanel id = "first-order-panel" className = "panel person-panel"
                                 todayData = {orderTD}
                                 yestData = {orderYD}
                                 pnType = "First Order"/>
                    <PersonPanel id = "first-trade-panel" className = "panel person-panel"
                                 todayData = {tradeTD}
                                 yestData = {tradeYD}
                                 pnType = "First Trade"/>

                    <div id = "chart-panel" className = "panel">
                        <div id = "chart-header">
                            <div className={"chart-header-left"}>
                                <div className = "chart-title-small grey"> PERFORMANCE </div>
                                <div className = "chart-title-big"> Total Orders & Trades </div>
                            </div>
                            <div className = {"chart-header-right"}>

                                <div className = "chart-btn">

                                    <Switch
                                        onChange={(event) => { this.setState({showToolTip: !this.state.showToolTip })}}
                                        defaultChecked = {true}
                                    />
                                </div>
                                <div className = "chart-btn chart-dropdown">
                                    <DropDownList
                                        style={{ marginLeft: 5 }}
                                        value={baseUnit}
                                        onChange={this.handleBaseUnitChange}
                                        data={baseUnits}
                                    />
                                </div>
                            </div>
                        </div>
                        <Chart pannable={{ lock: 'y' }} zoomable={{ mousewheel: { lock: 'y' } }}>
                            <ChartArea background="#00111a" margin={0} height = {"300"}/>
                            <ChartAxisDefaults line={{ color: '#525f7f' }} labels={{ color: "white" }} />

                            <ChartTooltip shared={this.state.showToolTip}  />
                            <ChartLegend position="bottom" orientation="horizontal" background={"#98a2db"} />

                            <ChartSeries>
                                <ChartSeriesItem
                                    name = "Orders"
                                    type="column"
                                    field="orders"
                                    categoryField="date"
                                    aggregate="sum"
                                    color = "#00aaff"
                                    data={this.getChartData(this.state.gridData, "orders")}
                                />
                                <ChartSeriesItem
                                    name = "Market"
                                    type="column"
                                    field="market"
                                    categoryField="date"
                                    aggregate="sum"
                                    color = {"#cccccc"}
                                    data={this.getChartData(this.state.baData, "market")}
                                />
                                <ChartSeriesItem
                                    name =  "Trades"
                                    type="column"
                                    field="trades"
                                    categoryField="date"
                                    aggregate="sum"
                                    color = "#ff9900"
                                    data={this.getChartData(this.state.baData.filter(obj=> obj.traded), "trades")}
                                />
                            </ChartSeries>
                            <ChartCategoryAxis>
                                <ChartCategoryAxisItem baseUnit={this.state.baseUnit.toLowerCase()} weekStartDay={1} maxDivisions={12}/>
                            </ChartCategoryAxis>

                        </Chart>

                    </div>

                    <div id = "timeline-panel" className = "panel">
                        <div className = "dtl-bar dtl-topbar-dark">
                            <FiClock />
                            <div className = "dtl-heading"> Timeline </div>
                            {/*<div className = "dtl-button"> btn </div>*/}
                        </div>

                        <div className = "dtl-container" id="timeline-container" style={{"overflowY":"auto"}}>
                            <Timeline>

                                {todayEvents.map((obj, index) => {
                                    // let orderString = ""
                                    // if (obj.orderMsg) {
                                    //     if(obj.type==="trade") orderString = " " + obj.orderMsg
                                    //     else orderString = " for " + obj.orderMsg
                                    // }
                                    let bubbleColor = "#00aaff"
                                    if(obj.type==="market") bubbleColor = " #cccccc"
                                    else if(obj.type==="trade") bubbleColor = "#ff9900"
                                    return <div>
                                        <TimelineEvent
                                            title={([obj.email, obj.action + "d", obj.type].join(" "))}
                                            subtitle={this.formatDate(obj.date)}

                                            icon={<i className=""></i>}
                                            bubbleStyle = {{border:"2px solid white",
                                            "background":bubbleColor}}


                                            titleStyle = {{color:"white"}}
                                            subtitleStyle= {{color:"#d9d9d9"}}
                                            contentStyle = {{borderRadius:"5px"}}
                                        >
                                            <p> {obj.orderMsg} </p>
                                            {Array.isArray(obj.dtlMsg) ?
                                                obj.dtlMsg.map(line => <p> {line} </p>)
                                                :
                                                obj.dtlMsg}
                                        </TimelineEvent>
                                    </div>
                                })
                                }
                            </Timeline>
                        </div>
                    </div>
                </div>


                {/* Settings modal */}
                {this.state.showSettingsModal && <SettingsModal user = {this.state.user} closeSettingsModal = {this.closeSettingsModal} />}
                {/* Goals modal */}
                {this.state.showGoalsModal && <GoalsModal
                                                closeGoalsModal = {this.closeGoalsModal}
                                                goals = {this.state.goals}/>
                }
                {this.state.showMVPModal && <MVPModal
                                            toggleMVPModal = {this.toggleMVPModal}/>
                }


            </div>
        );
    }

    /* Feathers Database Updated Events */
    setGridData = (gridData) => {
        this.setState({
            gridData,
        })
    }

    setBaData = (baData) => {
        this.setState({
            baData,
        })
    }

    gridItemCreated = (op) => {
        console.log("grid item created");

        const gridData = this.state.gridData.slice();
        gridData.push(op);

        this.setGridData(gridData)
    };

    gridItemUpdated = (op) => {
        const gridData = this.state.gridData.slice();
        const index = gridData.findIndex(element => element.id === op.id)
        gridData[index] = op

        this.setGridData(gridData)
    }

    gridItemRemoved = (op) => {
        const gridData = this.state.gridData.slice();
        const index = gridData.findIndex(element => element.id === op.id)
        if (index !== undefined) gridData.splice(index, 1);

        this.setGridData(gridData)
    }

    baItemCreated = (op) => {
        const baData = this.state.baData.slice();
        baData.push(op);

        this.setBaData(baData)
    }

    baItemUpdated = (op) => {
        const baData = this.state.baData.slice();
        const index = baData.findIndex(element => element.id === op.id)
        baData[index] = op

        this.setBaData(baData)
    }

    baItemRemoved = (op) => {
        const baData = this.state.baData.slice();
        const index = baData.findIndex(element => element.id === op.id)
        if (index !== undefined) baData.splice(index, 1);

        this.setBaData(baData)
    }

    goItemUpdated = (op) => {
        const goals = this.state.goals
        goals[op.goalName] = {id: op.id, value: op.value}

        this.setState({goals})
    }

    mvpItemCreated = (op) => { //handles both created and updated events
        console.log(new Date(op.dt))
        console.log(new Date().toDateString())
        if(op.dt === new Date().toISOString().split('T')[0]){
            this.setState({
                todayMVP: op.email
            })
        } else if (op.dt === this.getLastBizDay().toISOString().split('T')[0]){
            this.setState({
                yestMVP: op.email
            })
        }
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user
    }
}

export default connect(mapStateToProps, null) (withCookies(DashBoard));
import moment from 'moment';
import {
    TAB_STATUS,
    TAB_ID_FIELDS,
    TAB_TEXT_FIELDS,
    TAB_FILTER_BY,
    ORDERS_QUERY_LIMIT
} from './constants';
import GetDateFromBDays from './GetDateFromBDays';

/**
 * For wbOrders.js. Returns the query params for orders.find()
 * @param tab The active tab object from the database
 */
const GetWbOrdersQuery = (tab = {}) => {
    console.log('get tab', tab);

    const {
        days = 1,
        range = {},
        status,
    } = tab;

    let query = {
        $sort: {
            updatedAt: -1
        },
        isVoided: false,
        $limit: ORDERS_QUERY_LIMIT
    };

    /** Handle filter by biz days or date range **/
    if (tab[TAB_FILTER_BY.RANGE]) {
        let {
            start,
            end
        } = range;

        let endDate = new Date(end);
        endDate.setDate(endDate.getDate() + 1);
        query.updatedAt = {
            $gte: start,
            $lte: endDate
        }
    } else {
        query.updatedAt = {
            $gt: GetDateFromBDays(days)
        }
    }

    if(status && status !== TAB_STATUS.EVERYTHING) {
        query.complete = (status === TAB_STATUS.NOT_WORKING);
    }

    /** Generate $or: [
     *              {ticker: {$in: []}},
     *              {underlyingId: {$in: []}}
     *              ]
     * **/
    let orList = [];
    [
        ...Object.values(TAB_ID_FIELDS),
        ...Object.values(TAB_TEXT_FIELDS)
    ].forEach(field => {
        let itemList = tab[field];
        if (Array.isArray(itemList) && itemList.length) {
            orList.push({
               [field]: {
                   $in: tab[field]
               }
            });
        }
    });
    if (orList.length) {
        query.$or = orList;
    }

    console.log('query', query);
    return query;
}

export default GetWbOrdersQuery;
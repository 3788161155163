import React from 'react';

/** Kendo **/
import '@progress/kendo-react-intl'
import '@progress/kendo-react-dropdowns'
import '@progress/kendo-react-buttons'
import '@progress/kendo-react-inputs'
import 'react-router-dom'
import {Zoom} from "@progress/kendo-react-animation";
import {Notification, NotificationGroup} from "@progress/kendo-react-notification";
import {Button} from '@progress/kendo-react-buttons';

/** Styling **/
import styled from 'styled-components'

/** helpers **/
import ValidateTrade from '../../helpers/ValidateTrade';

/** Feathers **/

import {restConfirmsService, tradesService} from '../../feathers';
// const feathers = require('@feathersjs/feathers');
// const rest = require('@feathersjs/rest-client');
// const auth = require('@feathersjs/authentication-client');
//
// const app = feathers();
//
// // Connect to the same as the browser URL (only in the browser)
// const restClient = rest('http://localhost:3030');
//
// // Configure an AJAX library (see below) with that client
// app.configure(restClient.fetch(window.fetch));
// app.configure(f.authentication({
//     storage: window.localStorage
// }))

/** Save styles if we want to have the arrows popup on user mouseenter **/
// const ClickPanelOverlay = styled.div`
//     opacity: 0;
//     z-index: 3000;
//     position: fixed;
//     width: 100%;
//     right: 17px;
//     height: 100%;
//     top: 30px;  // IMPORTANT TO INCLUDE SO OVERLAY DOES NOT BLOCK PANEL BUTTONS
//
//
//     -webkit-transition: opacity .2s ease-in-out;
//     -moz-transition: opacity .2s ease-in-out;
//     -o-transition: opacity .2s ease-in-out;
//     -ms-transition: opacity .2s ease-in-out;
//     transition: opacity .2s ease-in-out;
//
//     &:hover {
//      opacity: 1;
//     }
// `
// const ClickPanel = styled.div`
//     position: absolute;
//     width: 40%;
//     height: 100%;
//     top: 0;
//
//     background-image: ${props => (props.activeside === 'buy' ? 'linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.3))' : 'linear-gradient(to right, rgba(0,0,0,0.3), rgba(0,0,0,0))')};
//
//     right: ${props => (props.activeside === 'buy' ? 0 : 'auto')};
//     left: ${props => ( props.activeside === 'sell' ? 0 : 'auto')};
//
//     &:hover {
//         cursor: pointer;
//     }
// `
// const ChevronButtonWrapper = styled.div`
//     position: absolute;
//     top 50%;
//
//     right: ${props => (props.activeside === 'buy' ? '25%' : 'auto')};
//     left: ${props => ( props.activeside === 'sell' ? '25%' : 'auto')};
//
//     & .k-icon {
//         font-size: 30px;
//     }
// `
/** Save style if we want to have arrow popup on mouseenter half of screen **/
// const ClickPanel = styled.div`
//     position: fixed;
//     width: 40%;
//     height: 100%;
//     z-index: 3000;
//     top: 0;
//     opacity: 0;
//
//     background-image: ${props => (props.activeside === 'buy' ? 'linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.3))' : 'linear-gradient(to right, rgba(0,0,0,0.3), rgba(0,0,0,0))')};
//
//     right: ${props => (props.activeside === 'buy' ? 0 : 'auto')};
//     left: ${props => ( props.activeside === 'sell' ? 0 : 'auto')};
//
//     -webkit-transition: opacity .2s ease-in-out;
//     -moz-transition: opacity .2s ease-in-out;
//     -o-transition: opacity .2s ease-in-out;
//     -ms-transition: opacity .2s ease-in-out;
//     transition: opacity .2s ease-in-out;
//     &:hover {
//      opacity: 1;
//      cursor: pointer;
//   }
// `
// const ChevronButtonWrapper = styled.div`
//     position: absolute;
//     top 50%;
//     right: ${props => (props.activeside === 'buy' ? '25%' : 'auto')};
//     left: ${props => ( props.activeside === 'sell' ? '25%' : 'auto')};
// `

class ConfirmView extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            buyUrl: null,
            sellUrl: null,
            activeside: 'buy',
        }
    }

    componentDidMount() {
        tradesService.get(this.props.tradeId).then(trade => {
           const validation = ValidateTrade(trade);

            if (!validation.status) {
                this.setState({
                    validateMsg: [...validation.errors]
                });
            }
        });

        tradesService.on('updated', trade => {
           if (this.props.tradeId === trade.id) {
               this.handleGenerateConfirm('buy');
               this.handleGenerateConfirm('sell');
           }
        });

        this.handleGenerateConfirm('buy');
        this.handleGenerateConfirm('sell')
    }

    handleGenerateConfirm = (dir) => {
        restConfirmsService.get(this.props.tradeId, {
            headers: {
                'Content-Type': 'application/pdf',
                'Accept': 'application/pdf',
                'Response-Type': 'application/pdf'
            },
            query: {
                dir
            }
        }).then(res => {
            this.handleString(res, dir);
            // this.handleBlob(res);
        }).catch(e => {
            this.setNotification('errorMsg', "Error generating PDF. " + e.toString())
        })
    }

    handleString = (res, dir) => {
        try{
            const byteCharacters = atob(res);

            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {type: 'application/pdf'});
            const url = URL.createObjectURL(blob)

            this.setState({
                [dir+'Url']: url,
            })
        } catch {
            this.setNotification('errorMsg', "Error generating PDF.")
        }
    }

    // Event handler to switch actively displayed PDF on user arrow click
    handleSwitchPdf = () => {
        this.setState({
            activeside: this.state.activeside === 'buy' ? 'sell' : 'buy'
        })
    }

    handleBlob = (res) => {
        const byteNumbers = new Array(res.length);
        for (let i = 0; i < res.length; i++) {
            byteNumbers[i] = res.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: 'application/pdf'});
        const url = URL.createObjectURL(blob)
        console.log(url)
        this.setState({buyPdf: res, url})
    }

    setNotification = (field, msg) => {
        this.setState({
                [field]: msg
            });
    }

    render() {
        const {activeside} = this.state;
        const url = this.state[activeside + 'Url'] == null ? null : this.state[activeside + 'Url'] + '#view=Fit';
        return(
            <div className={"pdf-wrapper"}>

                <div className='SwitchWrapper'>
                    <div className = 'Switch'
                        style = {{background: (activeside === 'buy') ? '#4dc3ff' : 'black'}}
                        onClick = {()=>{this.setState({'activeside': 'buy'})}}
                        dir = 'buy'
                        activeside = {activeside}
                    >
                        <div> BUY </div>
                    </div>
                    <div className= 'Switch'
                        style = {{background: (activeside === 'sell') ? '#fe615a' : 'black'}}
                        onClick = {()=>{this.setState({'activeside': 'sell'})}}
                        dir = 'sell'
                        activeside = {activeside}
                    >
                        <div> SELL </div>
                    </div>
                </div>

                {url != null &&
                    <object className={"pdf-object"}
                            data={url}
                            type="application/pdf"
                    >
                    </object>
                }
                    {/*<ClickPanel activeside = {activeside} onClick = {this.handleSwitchPdf}>*/}
                    {/*    <ChevronButtonWrapper activeside = {activeside}>*/}
                    {/*        <span className={"k-icon k-i-arrow-chevron-" + (activeside === 'buy' ? 'right' : "left")}></span>*/}
                    {/*    </ChevronButtonWrapper>*/}
                    {/*</ClickPanel>*/}
                <NotificationGroup
                    style={{
                        right: 0,
                        bottom: 0,
                        alignItems: 'flex-start',
                        flexWrap: 'wrap-reverse'
                    }}
                >

                    <Zoom enter={true} exit={true}>
                        {this.state.errorMsg && <Notification
                            type={{ style: 'error', icon: true }}
                            closable={true}
                            onClose={() => this.setState({ errorMsg: null })}
                        >
                            <span> {this.state.errorMsg }</span>
                        </Notification>}
                    </Zoom>

                    <Zoom enter={true} exit={true}>
                        {this.state.validateMsg && <Notification
                            type={{ style: 'warning', icon: true }}
                            closable={true}
                            onClose={() => this.setState({ validateMsg: null })}
                        >
                            {this.state.validateMsg.map((msg, id) => {
                                return <div key = {'msg-'+id}> {msg} </div>
                            })}
                        </Notification>}
                    </Zoom>
                </NotificationGroup>
            </div>
        )
    }
}

export default ConfirmView;
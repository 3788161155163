/** Not redux function **/
import {roomUsersService} from '../../../feathers';

export default function patchRoomUser (roomUserId, params) {
    roomUsersService.patch(roomUserId, params)
        .then(res => {
            console.log(res)
        })
        .catch(e => {
            console.log(e)
        });
}
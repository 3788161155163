import {editorsService} from '../../../feathers';

export default function createEditor (tradeId) {
    return async (dispatch, getState) => {
        const {username} = getState().authReducer.user
        console.log(`creating editor ${username} for Trade ${tradeId}`)
        editorsService.create({
            id: tradeId,
            editor: username,
        })
    }
}
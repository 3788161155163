import React from 'react';
import BEMHelper from 'react-bem-helper';

import { ArcGauge } from '@progress/kendo-react-gauges';
import { ProgressBar } from '@progress/kendo-react-progressbars';

const classes = new BEMHelper('GaugeCell');
const arcStyle = {
    height: "80px",
    width: "30%",
}

const barStyle = {
    height: 5,
    width: "100%"
}

const arcCenterRenderer = (value, color) => {
    return <span
        style={{ color: color }}
    >
        {value}%
    </span>;
}

const GaugeCell = (props) => {
    return <div {...classes()}>
        {/*<ArcGauge*/}
        {/*    style = {arcStyle}*/}
        {/*    arcOptions = {{value: 30}}*/}
        {/*    arcCenterRender={arcCenterRenderer}*/}
        {/*/>*/}
        {/*<ArcGauge*/}
        {/*    style = {arcStyle}*/}
        {/*    arcOptions = {{value: 30}}*/}
        {/*    arcCenterRender={arcCenterRenderer}*/}
        {/*/>*/}
        {/*<ArcGauge*/}
        {/*    style = {arcStyle}*/}
        {/*    arcOptions = {{value: 30}}*/}
        {/*    arcCenterRender={arcCenterRenderer}*/}
        {/*/>*/}

        <ProgressBar value={Math.random()* 100} style={barStyle} labelVisible={false}/>
        <ProgressBar value={Math.random()* 100} style={barStyle} labelVisible={false}/>
        <ProgressBar value={Math.random()* 100} style={barStyle} labelVisible={false}/>

    </div>
}

export default GaugeCell;
import {
    SET_IS_WINDOW_COMPACT,
    SET_MENU_CLASS_MODIFIER
} from '../types';

export default function (innerWidth) {
    return (dispatch, getState) => {
        const {menuData} = getState().aivaReducer;
        const isWindowCompact = innerWidth < 650;

        if (menuData.classModifier === 'normal' && isWindowCompact) {
            dispatch({
                type: SET_MENU_CLASS_MODIFIER,
                classModifier: 'hidden'
            })
        } else if (menuData.classModifier !== 'normal' && !isWindowCompact) {
            dispatch({
                type: SET_MENU_CLASS_MODIFIER,
                classModifier: 'normal'
            })
        }

        dispatch({
            type: SET_IS_WINDOW_COMPACT,
            isWindowCompact
        });
    }
}
import React, {memo} from 'react';
import {connect} from 'react-redux';
import {selectUserById} from '../../../../redux/selectors';

/** Components **/
import OrderDetail from '../OrderDetail';
import BidDetail from '../BidDetail';

/** Helpers **/
import moment from 'moment';

/** Style **/
import './Body.scss';
import BEMHelper from 'react-bem-helper';
const classes = new BEMHelper('Body');

const Body = memo((props) => {
    const {
        chat,
        user,
        className,
        handleClickReply
    } = props;

    const {
        firstname,
        lastname,
        isOnline
    } = user ? user : {};

    const {
        body,
        createdAt: createdAtRaw
    } = chat ? chat: {};

    const createdAt = createdAtRaw
        ? moment(createdAtRaw).format('h:mm:ss A')
        : '';

    return <div {...classes({ extra: [className] })}>
        <div {...classes('metadata')}>
            <div {...classes('dot', (isOnline===undefined ? '' : isOnline ? 'online' : 'offline'))} />

            <div {...classes('username')}>
                {firstname} {lastname}
            </div>

            <div {...classes('createdAt')}>
                {createdAt}
            </div>

            {handleClickReply !==undefined && <div onClick = {handleClickReply} {...classes('reply_btn')}>
                Reply
            </div>
            }
        </div>

        {
            chat && (chat.bidId !=null
                ? <BidDetail bidId = {chat.bidId}></BidDetail>
                : chat.orderId != null
                ? <OrderDetail orderId = {chat.orderId}/>
                : <></>)
        }

        <div {...classes('body')}>
            {body}
        </div>

    </div>
});

const mapStateToProps = (state, props) => {
    return {
        user: selectUserById(state, props.chat ? props.chat.createdById : -1)
    }
}

export default connect(mapStateToProps, null) (Body);
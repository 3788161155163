import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import styled from 'styled-components';

/** Kendo **/
import { DropDownList } from '@progress/kendo-react-dropdowns';
import {NumericTextBox} from '@progress/kendo-react-inputs';

/** Redux actions **/
import {
    handleSetTcFilterBy,
    handleSetTcFilter,
    createTrade,
    createTicket
} from '../../redux/actions';
import {DatePicker} from '@progress/kendo-react-dateinputs';
import {Button} from '@progress/kendo-react-buttons';

/** Styled **/
const StripWrapper = styled.div`
    font-size: 13px;
    display: flex;
    flex-flow: row wrap;
    // width: 100%;
    background: #1a1a1a;
`

const StripGroup = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    
    width: 200px;
    padding: 10px 0 10px 20px;
    
    .date-strip-left {
        flex: 1 0 auto;
        padding-right: 10px;
    }
`

class DateFilterStrip extends React.Component {
    constructor (props) {
        super(props);

        this.state={

        }
    }

    // handle user clicking new trade button
    handleCreateTrade = () => {
        this.props.createTrade({}).then(trade => {
            this.props.createTicket(trade.id);
        });
    }
    render () {
        const {
            filterBy,
            days,
            startDate,
            endDate,
        } = this.props.tcFilterReducer;

        const {
            handleSetTcFilterBy,
            handleSetTcFilter,
        } = this.props;

        return(
            <div>
                <StripWrapper id = 'date-strip'>
                    <StripGroup>
                        <div className='date-strip-left'>
                          Filter By:
                        </div>
                        <DropDownList
                            data = {['Business Days', 'Date Range']}
                            value = {filterBy}
                            onChange={handleSetTcFilterBy}
                        />
                    </StripGroup>

                    {
                        filterBy === 'Business Days' ?
                            <StripGroup>
                                <div className='date-strip-left'>
                                    Days:
                                </div>
                                <NumericTextBox
                                    name = 'days'
                                    value = {days}
                                    onChange = {handleSetTcFilter}
                                />
                            </StripGroup> :
                            <StripWrapper>
                                <StripGroup>
                                    <div className='date-strip-left'>
                                        Start:
                                    </div>
                                    <DatePicker
                                        name = 'startDate'
                                        value = {new Date(startDate)}
                                        onChange = {handleSetTcFilter}
                                    />
                                </StripGroup>
                                <StripGroup>
                                    <div className='date-strip-left'>
                                        End:
                                    </div>
                                    <DatePicker
                                        name = 'endDate'
                                        value = {new Date(endDate)}
                                        onChange = {handleSetTcFilter}
                                    />
                                </StripGroup>
                            </StripWrapper>
                    }

                    <StripGroup style = {{marginLeft: 'auto', width: 'auto'}}>
                        <div className='date-strip-left'>
                            <Button
                                icon = 'add'
                                onClick = {this.handleCreateTrade}
                            > New Trade </Button>
                        </div>
                    </StripGroup>
                </StripWrapper>
            </div>
        );
    }
}

function mapStateToProps (state) {
    return {
        tcFilterReducer: state.tcFilterReducer
    }
}
function mapDispatchToProps (dispatch) {
    return bindActionCreators({
        handleSetTcFilterBy,
        handleSetTcFilter,
        createTrade,
        createTicket
    }, dispatch);
}

export default connect (mapStateToProps, mapDispatchToProps) (DateFilterStrip);
import {
    CREATE_BID,
} from '../types';

export default function handleBidCreated (obj) {
    return function (dispatch) {
        dispatch({
            type: CREATE_BID,
            bid: obj
        });
    }
}
import React from 'react';

/** Redux **/
import {selectRoomByMessageId} from '../../../../redux/selectors';
import {connect} from 'react-redux';
import {setSelectedThread} from '../../../../redux/actions';

/** Style **/
import BEMHelper from 'react-bem-helper';
import Button from '@progress/kendo-react-buttons/dist/es/Button';
const classes = new BEMHelper('ChatHeader');

const ThreadHeader = (props) => {
    const {closeThread} = props;
    const room = props.room ? props.room : {users:[]}
    const orderId = props.message ? props.message.orderId : '';

    return <div {...classes()}>
        <div>
            <div {...classes('title')}>
                Thread {orderId && '  - Interest ' + orderId}
            </div>
            <div {...classes('subtitle')}>
                {room.type === 'channel'
                    ? '# ' + room.name
                    : 'Chat with ' + room.users.length + ' others'
                }
            </div>
        </div>
        <Button
            icon = 'close'
            {...classes('button')}
            onClick = {closeThread}
        />
    </div>
}

const mapStateToProps = (state, {messageId}) => {
    return {
        room: selectRoomByMessageId(state, messageId)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeThread: () => dispatch(setSelectedThread(-1))
    }
}
export default connect (mapStateToProps, mapDispatchToProps) (ThreadHeader);
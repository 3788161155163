import React from 'react';
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import {Input} from '@progress/kendo-react-inputs';
import {AutoComplete} from '@progress/kendo-react-dropdowns';
import {Button} from '@progress/kendo-react-buttons';
import _ from 'lodash';
import client from '../../feathers';

export default class MatchModal extends React.Component {
    constructor(props){
        super(props)

        const counterItem = _.cloneDeep(props.matchModalProps.dataItem)
        counterItem.orgCode = ''; //have to clonedeep or else any changes to counterItem reflect on baitem
        this.state = {
            counterItem: counterItem,
            success: false,
            firstInputSelected: false
        };


        this.orgCodeList = ['AKUNA', 'BAML', 'BARC', 'BELV', 'BLUEFIN', 'BMO', 'BNP', 'BUDO', 'CENT', 'CIBC', 'CITADEL', 'CITI', 'COMM', 'CONS', 'CSFB', 'CSFB.EXO', 'CTC', 'CTDL', 'DB', 'DE', 'DLB', 'EDF', 'FLOOR', 'FUND', 'GS', 'HAP', 'HSBC', 'IMC', 'IMPLIED', 'ING', 'ITAU', 'JANE', 'JANE ST', 'JEFFERIES', 'JPM', 'JUMP', 'LAURION', 'MACHINE', 'MAKO', 'MALA', 'MALACHITE', 'MARA', 'MARAT', 'MARATHON', 'MIZUHO', 'ML', 'MS', 'MSCO', 'NBC', 'NBC.NY', 'NOK', 'NOM', 'NOMO', 'NTX', 'NATIXIS', 'OPT', 'PAR', 'PARPLUS', 'PLX', 'RBC', 'RONIN', 'SHAOLS', 'SHOLES', 'SIG', 'SIMPLEX', 'SOC', 'SOCGEN', 'SUSQ', 'TD', 'UBS', 'URSA', 'VERM', 'VISION', 'VOYAGER', 'WELLS', 'WFC', 'WOLV']
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.matchModalProps!=this.props.matchModalProps){
            const counterItem = _.cloneDeep(this.props.matchModalProps.dataItem)
            counterItem.orgCode = ''; //have to clonedeep or else any changes to counterItem reflect on baitem
            this.setState({
                counterItem: counterItem,
                success: false,
                firstInputSelected: false
            })
        }
    }

    handleChange = (event) => {
        const counterItem = this.state.counterItem

        if (String(event.target.name)==="client"){
            counterItem.orgCode = event.target.value.toUpperCase()
        } else if (/^$/.test(event.target.value) || /^\d+$/.test(event.target.value)){
            counterItem[event.target.name] = event.target.value
        }

        this.setState({
            counterItem: counterItem
        });
        console.log(this.state.counterItem)
    }

    addAdditionalFields = (baItem, gridDataItem, dataItem) => {
        const fields = ['ticker', 'expiration', 'strike', 'structure', 'risk_rwd_ratio', 'tied', 'delta']
        fields.forEach((field) => {
            baItem[field] = gridDataItem[field]
        })
        // baItem.orderId = gridDataItem.id; taken out 03/10 since reqid populated on order creation
        baItem.trade_id = dataItem.id;

        return baItem
    }

    updateDataItem = (dataItem, gridDataItem) => {
        dataItem = {...dataItem, initOrgCode: null}
        this.addAdditionalFields(dataItem, gridDataItem, dataItem)
        dataItem.traded = !dataItem.traded;
        client.service('bids').update(dataItem.id, dataItem)
        this.props.closeMatchModal();
    }

    handleTraded = (e) => {
        e.preventDefault();

        const counterItem = this.state.counterItem
        counterItem[this.spreadType] = null;
        counterItem[this.counterType] = this.price;
        delete counterItem['createdAt'];
        delete counterItem['createdBy'];
        delete counterItem['id']

        const dataItem = this.props.matchModalProps.dataItem
        const gridDataItem = this.props.matchModalProps.gridDataItem;



        let spreadType = this.spreadType;
        let childType = this.counterType;

        let price = this.price;
        let childData = [counterItem]

        let totalTradeSize = parseFloat(dataItem.size);
        let tempTradeSize = 0;

        for (let i = 0; i < childData.length; i++) {
            let childItem = childData[i];
            let childSize = parseFloat(childItem.size);
            tempTradeSize += childSize;

            // CASE 1: if there's not enough to fill the requested trade size
            if (i === childData.length - 1 && totalTradeSize > tempTradeSize) {
                childItem.traded = 1;
                childItem.initOrgCode = dataItem.orgCode;
                this.addAdditionalFields(childItem, gridDataItem, dataItem)
                client.service('bids').create(childItem)


                // update data item with remaining partial live size
                let partialLiveSize = totalTradeSize - tempTradeSize;
                let newChildItem = _.cloneDeep(dataItem)
                newChildItem.size = partialLiveSize;
                delete newChildItem.id;
                client.service('bids').create(newChildItem)

                dataItem.size = tempTradeSize;
                dataItem.traded  = 1;
                this.addAdditionalFields(dataItem, gridDataItem, dataItem)
                client.service('bids').update(dataItem.id, dataItem)

                break;
            }

            // behavior as normal; the totalsize is greater than the first child element
            if (tempTradeSize <= totalTradeSize) {
                childItem.traded = 1;
                childItem.initOrgCode = dataItem.orgCode;
                this.addAdditionalFields(childItem, gridDataItem, dataItem)
                console.log(childItem)
                client.service('bids').create(childItem)

                this.updateDataItem(dataItem, gridDataItem)
            } else {
                // edit the live child item to match the partial size remaining
                let partialLiveSize = tempTradeSize - totalTradeSize;

                if (partialLiveSize === childSize) {
                    break;
                }

                let newChildItem = _.cloneDeep(childItem)
                newChildItem.size = partialLiveSize
                delete newChildItem.id
                client.service('bids').create(newChildItem)


                let partialTradedSize = childSize - partialLiveSize;
                childItem.size = partialTradedSize;
                childItem.traded = 1;
                childItem.initOrgCode = dataItem.orgCode;
                this.addAdditionalFields(childItem, gridDataItem, dataItem)
                client.service('bids').create(childItem)


                this.updateDataItem(dataItem, gridDataItem)
                break;
            }
        }

    }


    render() {
        const mmProps = this.props.matchModalProps;
        const dataItem = mmProps.dataItem
        const gridDataItem = mmProps.gridDataItem
        this.spreadType = dataItem.bid!=null ? "bid" : "ask"
        this.counterType = dataItem.bid!=null ? "ask" : "bid"

        this.price = dataItem[this.spreadType]



        return (
            <div className = "mm">
            <Dialog
                // onClose={this.props.cancel}
            >

                <form
                    method = "post"
                    autocomplete="off"
                    onSubmit={(e) => {
                    this.handleTraded(e)
                    this.props.closeMatchModal()
                    // this.props.hideSpreadForm()
                }
                }>

                    <fieldset id = "mfs">
                        <div id = "mm-header">
                            {/*{gridDataItem.ticker} {gridDataItem.expiration} {gridDataItem.strike} {gridDataItem.structure}*/}
                            {/*<br/>*/}
                            <span style={{color:"#3f51b5"}}>{dataItem.orgCode} </span> &nbsp;
                            {this.spreadType.charAt(0).toUpperCase() + this.spreadType.slice(1)} @ {this.price}
                        </div>
                        <div style={{"textAlign":"center","paddingBottom":"5px"}}> Please enter matching counterparty. </div>

                        <div className={'mm_input_wrap'}>
                            <span className = "mm-label"> {this.counterType.charAt(0).toUpperCase() + this.counterType.slice(1)} </span>
                            <Input className = {"mm-input"} name = {this.counterType} value={this.price} disabled={true}/>
                        </div>

                        <div ref={
                            (x) => {
                                if(!this.state.firstInputSelected && x) {
                                    x.querySelector('[name="size"]').select()
                                    this.setState({firstInputSelected: true})
                                }
                            }
                        }
                         className={'mm_input_wrap'}
                        >

                                <span className = "mm-label"> Size </span><Input className={"mm-input"} name = "size" value={this.state.counterItem.size || ''} placeholder="New Size" onChange = {this.handleChange} required={true}/>
                        </div>

                        <div className={'mm_input_wrap'}>
                            <span className="mm-label"> Client </span>
                                <AutoComplete
                                    name = "client"
                                    data={this.orgCodeList}
                                    value={this.state.counterItem.orgCode || ''}
                                    placeholder={"New Client"}
                                    onChange = {this.handleChange}
                                    suggest={true}
                                    required={true}
                                />
                        </div>
                        {/*<label className="k-form-field">*/}
                        {/*    <span>Client</span>*/}
                        {/*    <input name = "client" value={this.state.counterItem.client || ''} className="k-textbox" placeholder="New Client" onChange = {this.handleChange} required={true}/>*/}
                        {/*</label>*/}
                    </fieldset>
                    <DialogActionsBar>
                    <Button className="mt-3" type="button" onClick = {e=>this.props.closeMatchModal()}>Cancel</Button>

                    <Button className="mt-3" type="submit" primary={true}>Trade</Button>
                    </DialogActionsBar>
                </form>

                {/*<DialogActionsBar>*/}
                {/*    <button*/}
                {/*        className="k-button"*/}
                {/*        onClick={this.props.closeMatchModal}*/}
                {/*    >*/}
                {/*        Cancel*/}
                {/*    </button>*/}
                {/*    <button*/}
                {/*        className="k-button k-primary"*/}
                {/*        onClick={this.trade}*/}
                {/*    >*/}
                {/*        Save*/}
                {/*    </button>*/}
                {/*</DialogActionsBar>*/}
            </Dialog>
            </div>
        );
    }
}
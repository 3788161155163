import React from 'react';

/** Redux **/
import {connect} from 'react-redux';
import {setModalCreateRoomType} from '../../../../redux/actions';
/** Helpers **/
import CapitalizeFirst from '../../../../helpers/CapitalizeFirst';

/** Style **/
import BEMHelper from 'react-bem-helper';
import './RoomGroupHeader.scss';
const classes = new BEMHelper('RoomGroupHeader');

const RoomGroupHeader = (props) => {
    const {
        roomType,
        setModalCreateRoomType
    } = props;

    return <div {...classes()}>
        <div>
            <span className={"k-icon " + (roomType === 'chat' ? 'k-i-comment' : 'k-i-myspace-box')}></span>
            {CapitalizeFirst(roomType) + 's'}
        </div>
        <div
            {...classes('button')}
            onClick = {setModalCreateRoomType}
        > <div> + </div> </div>
    </div>
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        setModalCreateRoomType: () => {dispatch(setModalCreateRoomType(props.roomType))}
    }
}

export default connect(null, mapDispatchToProps) (RoomGroupHeader);
import React, {useState} from 'react';

/** Redux **/
import {connect} from 'react-redux';
import {selectAllUsers} from '../../../../redux/selectors';
import {createRoom, setModalCreateRoomType} from '../../../../redux/actions';

/** Kendo **/
import {Dialog} from '@progress/kendo-react-dialogs';
import {Button} from '@progress/kendo-react-buttons';
import {Input, Switch} from '@progress/kendo-react-inputs';
import UsersMulti from '../UsersMulti/UsersMulti';

/** Helpers **/
import CapitalizeFirst from '../../../../helpers/CapitalizeFirst';

/** Style **/
import BEMHelper from 'react-bem-helper';
import {DropDownList} from '@progress/kendo-react-dropdowns';
// Reuse RoomModal classes
const classes = new BEMHelper('RoomModal');

const CreateRoomModal = (props) => {
    const {
        createRoomType,
        users,
        aivaPortal,
        createRoom,
        closeCreateRoomModal
    } = props;

    /** Value is the list of selected users for the multiselect **/
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [roomName, setRoomName] = useState('');
    const [description, setDescription] = useState('');
    const [userGroup, setUserGroup] = useState('All');

    return <Dialog>
        <div {...classes()}>
            <Button
                icon = 'close'
                {...classes('close_button')}
                onClick = {closeCreateRoomModal}
            />

            <div {...classes('heading')}> Create New {CapitalizeFirst(createRoomType)} </div>
            {createRoomType === 'channel' &&
                <div>
                    <div {...classes('input_label')}> Room Name </div>
                    <Input
                        value = {roomName}
                        onChange = {e => {setRoomName(e.target.value)}}
                    />

                    <div {...classes('input_label')}> Description </div>
                    <Input
                        value = {description}
                        onChange = {e => {setDescription(e.target.value)}}
                    />

                    <div {...classes('input_label')}> Which Users? </div>
                    <DropDownList
                        value = {userGroup}
                        data = {['All', 'Selected']}
                        onChange = {e => {setUserGroup(e.target.value)}}
                        popupSettings={{
                            "animate": false,
                            "appendTo": aivaPortal.document ? aivaPortal.document.body : undefined
                        }}
                    />
                </div>
            }

            {(createRoomType === 'chat' || userGroup !== 'All') &&
                <div>
                    <div {...classes('input_label')}> Users </div>
                    <UsersMulti
                        value = {selectedUsers}
                        onChange = {e => setSelectedUsers(e.target.value)}
                    />
                </div>
            }

            <div {...classes('button_wrap')}>
                <Button
                    {...classes('button')}
                    onClick={() => {createRoom(roomName, createRoomType, description, userGroup, selectedUsers)}}
                >
                    Create {createRoomType}
                </Button>
            </div>

        </div>

    </Dialog>
}

const mapStateToProps = (state) => {
    return {
        aivaPortal: state.aivaReducer.aivaPortal,
        users: selectAllUsers(state),
    };
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        createRoom: (roomName, roomType, description, userGroup, selectedUsers) => dispatch(createRoom(roomName, roomType, description, userGroup, selectedUsers)),
        closeCreateRoomModal: () => dispatch(setModalCreateRoomType('n')),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (CreateRoomModal);
import {repliesService} from '../../../feathers';

export default function createReply (messageId, reply){
    console.log(messageId)
    console.log(reply)
    return async (dispatch) => {
        const replyWithMessageId = {...reply, messageId}

        console.log('were oisadjf')

        console.log(replyWithMessageId)
        repliesService.create(replyWithMessageId).catch(e => {
            console.log('Error creating message')
            console.log(e);
        });
    }
}
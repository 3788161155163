export const TOGGLE_DEPTH = 'TOGGLE_DEPTH';
export const toggleDepth = (orderId) => {
    return {
        type: TOGGLE_DEPTH,
        orderId
    }
}

export const TOGGLE_DETAIL = 'TOGGLE_DETAIL';
export const toggleDetail = (orderId) => {
    return {
        type: TOGGLE_DETAIL,
        orderId
    }
}

export const SET_SPREAD_FORM_PROPS = 'SET_SPREAD_FORM_PROPS';
export const setSpreadFormProps = (spreadFormProps = {orderId: null, bidId: null}) => {
    const {offset} = spreadFormProps;
    if (offset != null) {
        offset.left -= 210;
    }

    return {
        type: SET_SPREAD_FORM_PROPS,
        spreadFormProps
    }
}

export const SET_WB_ORDERS_LOADING = 'SET_WB_ORDERS_LOADING';
export const setWbOrdersLoading = (wbOrdersLoading) => {
    return {
        type: SET_WB_ORDERS_LOADING,
        wbOrdersLoading
    }
}

export const SET_ORDER_MENU_PROPS = 'SET_ORDER_MENU_PROPS';
export const setOrderMenuProps = (orderMenuProps = {orderId: null, bidId: null}) => {
    return {
        type: SET_ORDER_MENU_PROPS,
        orderMenuProps
    }
}

export const SET_ORDER_FORM_PROPS = 'SET_ORDER_FORM_PROPS';
export const setOrderFormProps = (orderFormProps = {prevOrder: null, actionType: null}) => {
    return {
        type: SET_ORDER_FORM_PROPS,
        orderFormProps
    }
}

export const SET_TAB_FORM_PROPS = 'SET_TAB_FORM_PROPS';
export const setTabFormProps = (tabFormProps = {prevTab: null, actionType: null}) => {
    return {
        type: SET_TAB_FORM_PROPS,
        tabFormProps
    }
}

export const SET_TRADE_FORM_PROPS = 'SET_TRADE_FORM_PROPS';
export const setTradeFormProps = (tradeFormProps = {prevTrade: {}, offset: {left: 0, top: 0}}) => {
    return {
        type: SET_TRADE_FORM_PROPS,
        tradeFormProps
    }
}

export const TOGGLE_SETTINGS_MODAL = 'TOGGLE_SETTINGS_MODAL';
export const toggleSettingsModal = (settingsModalOpen = false) => {
    return {
        type: TOGGLE_SETTINGS_MODAL,
        settingsModalOpen
    }
}
import {
    roomUsersService
} from '../../../feathers';

/**
 * NOT A REDUX FUNCTION
 * Used by RoomModal to remove a single user from a room
 * @param roomId the id of the Room to remove the users from
 * @param userId the id of the user to remove
 */
export default async function handleLeaveRoom (roomId, userId) {
    if (userId != null && roomId != null) {
        const res = await roomUsersService.find({
            query: {
                userId,
                roomId
            }
        });

        console.log(res)
        if (res && res.length > 0) {
            roomUsersService.remove(res[0].id);
        }
    }
}
import React from 'react';

/** Kendo **/
import '@progress/kendo-react-intl'
import '@progress/kendo-react-dropdowns'
import '@progress/kendo-react-buttons'
import '@progress/kendo-react-inputs'
import 'react-router-dom'

/** Components **/
import EntryForm from "./EntryForm";

/** Redux/Feathers **/
import {editorsService, tradesService} from '../../feathers';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
    createEditor,
    removeEditor
} from '../../redux/actions'

/** helpers **/
import FormatTradeName from '../../helpers/FormatTradeName';

class EntryView extends React.Component {
    constructor(props) {
        super(props);
        this.isEventListenerMounted = false;
        this.state={
            trade: undefined,
            viewOnly: true,
        }
    }

    componentDidMount() {
        this.initTrade(this.props.tradeId)

        // if the user is viewing changes, update the view to match the new edit
        editorsService.on('removed', obj => {
            if (obj.id === this.props.tradeId) { //obj eg {id: 194}
                const trade = {...this.state.trade};
                trade.editor = null;
                this.setState({trade});
            }
        })

        tradesService.on('updated', trade => {
            if (this.state.viewOnly && trade.id === this.props.tradeId) {
                this.setState({
                    trade
                });
            }
            this.props.window.document.title = FormatTradeName(trade);
        })

        tradesService.on('removed', trade => {
           this.handleExitEdit();
           this.props.window.close();
        });

        tradesService.on('patched', patchedTrade => {
           if (patchedTrade.id === this.props.tradeId) {
               this.setState({trade: {...patchedTrade}})
               // const prevTrade = {...this.state.trade};
               // prevTrade.buyEmails = patchedTrade.buyEmails;
               // prevTrade.sellEmails = patchedTrade.sellEmails;
               // this.setState({
               //     trade: prevTrade
               // })
           }
        });
    }

    componentWillUnmount() {
        this.handleExitEdit();
    }

    componentDidUpdate(prevProps, prevState, snapshot) { // mount an event listener to remove editor when the user closes the window
        if (!this.isEventListenerMounted && this.props.window != null ) {
            this.isEventListenerMounted = true;
            this.props.window.addEventListener("unload", (ev) =>
            {
                this.handleExitEdit();
            });
        }
    }

    handleExitEdit = () => {
        if(this.state.viewOnly === false) {
            this.props.removeEditor(this.props.tradeId);
        }
    }

    initTrade = (tradeId) => {
        tradesService.get(tradeId).then(trade=>{
            // determines if viewonly should be set to false
            if(trade.editor === null) {
                this.props.createEditor(tradeId)
                this.setState({
                    trade,
                    viewOnly: false,
                });

                this.props.window.document.title = FormatTradeName(trade);
            } else {
                this.setState({
                    trade,
                    viewOnly: true,
                })
            }
        });
    }

    handleEnterEdit = () => {
        this.initTrade(this.state.trade.id);
    }

    render() {
        return(
            <EntryForm
                trade = {this.state.trade}
                window = {this.props.window}
                canEnterEdit = {true}
                handleEnterEdit = {this.handleEnterEdit}
                viewOnly = {this.state.viewOnly}
                setChanged = {this.props.setChanged}
                setUnchanged = {this.props.setUnchanged}
                changed = {this.props.changed}
            />
        )
    }
}

function mapStateToProps (state) {
    return {
        auth: state.authReducer
    }
}

function mapDispatchToProps (dispatch) {
    return bindActionCreators({
        createEditor,
        removeEditor
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps) (EntryView);
import React from 'react';
import {connect} from 'react-redux';

import {setSelectedThread, toggleThread} from '../../../../redux/actions';

/** Style **/
import './MessageActionPanel.scss';
import BEMHelper from 'react-bem-helper';
const classes = new BEMHelper('MessageActionPanel');

const MessageActionPanel = (props) => {
    const {
        setSelectedThread,
        repliesCount,
        toggleThread,
        isThreadOpen
    } = props;

    return <div {...classes()}
                onClick = {setSelectedThread}
            >
                <div
                    className = {'k-icon k-i-arrow-chevron-' + (isThreadOpen ? 'up' : 'down')}
                    onClick = {toggleThread}
                />
                <div {...classes('count')}>
                    {repliesCount} {repliesCount === 1 ? 'reply' : 'replies'}
                </div>
        </div>
}

const mapDispatchToProps = (dispatch, {messageId}) => {
    return {
        toggleThread: () => dispatch(toggleThread(messageId)),
        setSelectedThread: () => dispatch(setSelectedThread(messageId))
    }
}

export default connect (null, mapDispatchToProps) (MessageActionPanel);
import {
    SET_ROOMS
} from "../types";

import { roomsService } from '../../../feathers';
import GetQueryByTopDay from '../../../helpers/GetQueryByTopDay';

/**
 * Calls Find on Rooms and sets Rooms reducer state
 * IMPORTANT:
 *  - must send query parameter messagesQuery to pick associated messages from a particular date
 *  - PARAMETER mesagesQuery defaults to top day
 * @returns {Function}
 */
const setRooms = (messagesQuery = GetQueryByTopDay) => {
    return async (dispatch) => {
        roomsService.find({
            query: {
                messagesQuery: messagesQuery
            }
        }).then(res => {
            dispatch({
                type: SET_ROOMS,
                rooms: res
            });
        }).catch(error => {
            console.log('Error retrieving rooms:');
            console.log(error);
        });
    }
}

export default setRooms;
import React, {memo} from 'react';

/** Redux **/
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {setSelectedThread} from '../../../../redux/actions';

/** Style **/
import './Message.scss';

/** Components **/
import Body from '../Body';
import MessageActionPanel from '../MessageActionPanel';

const Message = (props) => {
    const {
        message,
        isThreadOpen,
        handleClickReply
    } = props;

    const messageId = message.id;
    const repliesCount = message.replies.length;

    return <div className = 'Message'>
        <Body
            chat = {message}
            handleClickReply = {handleClickReply}
        />
        {
            repliesCount != 0 &&
            <MessageActionPanel
                messageId = {messageId}
                repliesCount = {repliesCount}
                isThreadOpen = {isThreadOpen}
            />
        }
    </div>
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        handleClickReply: () => dispatch(setSelectedThread(props.message.id))
    }
}

export default connect(null, mapDispatchToProps)(Message);
import React from 'react';

/** Components */
import Button from '@progress/kendo-react-buttons/dist/es/Button';

/** Redux **/
import {selectAllUsersByRoomId, selectRoomById} from '../../../../redux/selectors';
import {connect} from 'react-redux';
import {setMenuClassModifier, setRoomModal} from '../../../../redux/actions';

/** Style **/
import './ChatHeader.scss';
import BEMHelper from 'react-bem-helper';
const classes = new BEMHelper('ChatHeader');

const ChatHeader = (props) => {
    const room = props.room ? props.room : {users: [], type: 'chat'}

    const {
        setRoomModal,
        menuData = {},
        showMenu
    } = props;

    const roomUsers = props.roomUsers ? props.roomUsers : [];
    const roomUsersText = (roomUsers.slice(0,4)).map(user => user.username).join(', ') + (roomUsers.length > 4 ? ` + ${roomUsers.length - 4} others`: '');

    /** Menu visibility toggle **/
    const {
        isWindowCompact,
        classModifier
    } = menuData;

    return <div {...classes()}>
        {
            (isWindowCompact && classModifier === 'hidden') && <><Button icon = 'hamburger' onClick = {showMenu} /> &nbsp;&nbsp; </>
        }
        <div>
            <div {...classes('title')}>
                {room.type === 'channel' ? '#' : ''} {room.name}
            </div>
            <div {...classes('subtitle')}>
                <div {...classes('people_icon')}>
                    <span className = 'k-icon k-i-user'/>
                    {room.users.length}
                </div>
                <div {...classes('description')}>
                    &nbsp;| {(room.type === 'channel' && room.description!=='') ? room.description : roomUsersText}
                </div>
            </div>
        </div>
        <Button
            icon = 'edit'
            {...classes('button')}
            onClick = {setRoomModal}
        />
    </div>
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        setRoomModal: () => dispatch(setRoomModal(props.roomId)),
        showMenu: () => dispatch(setMenuClassModifier('compact'))
    }
}

const mapStateToProps = (state, props) => {
    return {
        room: selectRoomById(state, props.roomId ? props.roomId : -1),
        roomUsers: selectAllUsersByRoomId(state, props.roomId ? props.roomId : -1),
        menuData: state.aivaReducer.menuData
    }
}

export default connect (mapStateToProps, mapDispatchToProps) (ChatHeader);
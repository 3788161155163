import {createCachedSelector} from 're-reselect';

const myUserId = (state) => state.authReducer.user.id;
const room = (state, roomId) => state.roomsReducer.byId[roomId];
const usersById = (state) => state.usersReducer.byId;

const selectAllUsersByRoomId = createCachedSelector(
    myUserId,
    room,
    usersById,
    (myUserId, room, usersById) => {
        let users = [];
        if (room == null) return users;
        room.users.forEach(userId => {
            if (usersById[userId] && userId !== myUserId) {
                users.push(usersById[userId])
            }
        });

        if(room.users.length === 1) {
            users = [usersById[room.users[0]]]
        }
        return users;
    }
)((_state_, roomId) => roomId)

export default selectAllUsersByRoomId;
import produce from 'immer';
import {combineReducers} from 'redux';

import {
    SET_STRUCTURES,
    SET_ORGANIZATIONS
} from "../actions/static";

import GetByIdWithRelated from '../../helpers/GetByIdWithRelated';

const structuresById = produce((draft, action) => {
    switch (action.type) {
        case SET_STRUCTURES:
            return GetByIdWithRelated(action.structures);
        // case CREATE_STRUCTURE:
        //     draft[action.structure.id] = action.structure;
        //     return;
        // case UPDATE_STRUCTURE:
        //     draft[action.structure.id] = action.structure;
        //     return;
        // case REMOVE_STRUCTURE:
        //     delete draft[action.structure.id];
        //     return;
    }
}, {});

const organizationsById = produce((draft, action) => {
    if (action.type === SET_ORGANIZATIONS) {
        return GetByIdWithRelated(action.organizations);
    }
}, {});


const StaticReducer = combineReducers({
    structuresById,
    organizationsById
});

export default StaticReducer;
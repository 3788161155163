import {tradesService, confirmsService} from '../../../feathers';

export default function sendEmails (emailList, id, dir) {
    return async dispatch => {
        // set the email objects which are being set to status 1 (loading)
        // update statuses to sending (1)
        await patchEmailStatuses(emailList, id, dir, 1)

        // send the email confirm
        confirmsService.create({id, dir, emailList}).then(res=>{
            window.alert(`${dir} ${id}: ${res}`);
        }).catch(e => {
            window.alert(e);
            patchEmailStatuses(emailList, id, dir, 4, true)
        });
    }
}

async function patchEmailStatuses (emailList, id, dir, statusCode) {
    tradesService.get(id).then(trade => {
        const emails = trade[dir+'Emails'];

        emails.forEach(obj => {
            if (emailList.includes(obj.email)) {
                obj.status = statusCode;
            }
        })

        // update the trade with its status 1 so people can see spinners
        tradesService.patch(id, {[dir+'Emails']: JSON.stringify(emails)});
    });
}
import {bidsService, tradesService} from '../../feathers';
import store from '../store';

export const SET_WB_TRADES = 'SET_WB_TRADES';
export const CREATE_WB_TRADE = 'ADD_WB_TRADE';
export const UPDATE_WB_TRADE = 'UPDATE_WB_TRADE';
export const REMOVE_WB_TRADE = 'REMOVE_WB_TRADE';

/**
 * Unlike setWbOrders, settrades does not call the api.
 * this method is called by wbOrders
 * @param trades a list of trade objects from the order associations
 */
export const setWbTrades = (trades) => {
    return {
        type: SET_WB_TRADES,
        trades
    }
}

export const createWbTrade = (trade) => {
    return {
        type: CREATE_WB_TRADE,
        trade
    }
}

export const updateWbTrade = (trade) => {
    return {
        type: UPDATE_WB_TRADE,
        trade
    }
}

export const voidTrade = (trade) => {
    return async (dispatch, getState) => {
        const {
            id: tradeId,
            buyBidId,
            sellBidId,
            blockId,
            size: tradeSize
        } = trade;

        // const initId = blockId === buyBidId ? buyBidId : sellBidId;
        // const counterId = blockId === buyBidId ? sellBidId : buyBidId;
        const bidsById = getState().wbBidsReducer.byId;
        [buyBidId, sellBidId].forEach(bidId => {
           const bid = bidsById[bidId];
           if (bid.size === tradeSize) {
               bidsService.patch(bidId, {isVoided: true});
           } else {
               bidsService.patch(bidId, {size: bid.size - tradeSize});
           }
        });

        tradesService.patch(tradeId, {voided: true}).then(res=>console.log(res));
    }
}

/** Actually updates an existing trade with new data.
 * Adjusts the corresponding sell and buy bids
 * Notice this uses store directly rather than being a real action
 * @param prevTrade the trade object prior to changes
 * @param newTrade the desired new trade object
 */
export const editTrade = (prevTrade, newTrade) => {
    const {
        id,
        size: prevSize,
        price: prevPrice,
        buyOrgCode: prevBuyOrgCode,
        sellOrgCode: prevSellOrgCode,

        buyBidId,
        sellBidId,
        blockId
    } = prevTrade;

    const {
        size: newSize,
        price: newPrice,
        buyOrgCode: newBuyOrgCode,
        sellOrgCode: newSellOrgCode,
    } = newTrade;

    /** Update sizes only **/
    const bidsById = store.getState().wbBidsReducer.byId;
    const sizeChange = newSize - prevSize;
    [buyBidId, sellBidId].forEach(bidId => {
        const {size} = bidsById[bidId];
        bidsService.patch(bidId, {size: size + sizeChange});
    });
    tradesService.patch(id, {size: newSize});
}
import React from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons'
import client from "../../feathers";
import {connect} from 'react-redux';
import {USER_INPUT_MAP as INPUT_MAP} from '../../helpers/constants';
import {toggleSettingsModal} from '../../redux/actions/wb';

class SettingsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.user.email,
            newEmail:"",
            newPassword: "",
        };

    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    updateMsg = (msg) => {
        this.setState({msg})
        setTimeout(() => { this.setState({ msg: undefined}); }, 3000);
    }

    changePassword =(e) => {
        e.preventDefault();
        const {password, confirmPassword} = this.state
        console.log(this.state)
        if(!password || password.length < 6) {
            this.updateMsg("Password must be at least 6 characters.")
        }
        else if(password!=confirmPassword) {
            this.updateMsg("Passwords don't match.")
        } else {
            const user = this.props.user
            user.password = password
            client.service('users').patch(user.id, {password, password_changed: true},)
            .then(() => {
                this.updateMsg("Password changed.")
                setTimeout(() => {this.props.closeSettingsModal() }, 300);
                window.location.reload(false);
            })
            .catch((e) => {console.log(e);this.updateMsg("Database error.")}
            );
        }
        // const user = this.props.user
        // console.log(user)
        // user.password = "hello"
        // client.service('users').update(user.id, user)
        //
        // this.setState({ success: true });
        // setTimeout(() => { this.setState({ success: false }); }, 3000);
    }


    render() {
        return (
            <div id = "settings-modal">
                <Dialog>
                    <div style = {{margin: "30px", width: "400px"}}>
                        <h4>Password Reset</h4>
                        <br/>
                        <form
                            onSubmit={this.changePassword}
                        >
                        {this.state.msg && (
                            <div
                                className="alert alert-success"
                                style={{ position: 'absolute', top:"15px", right: "15px", width: "50%", zIndex:"1000"}}
                            >
                                {this.state.msg}
                            </div>)}
                                <div className="mb-3">
                                    <Input
                                        name="password"
                                        type="password"
                                        style={{ width: '100%' }}
                                        label="Password"
                                        onChange = {this.handleChange}
                                        autoFocus={true}
                                        pattern = {INPUT_MAP.password.pattern}
                                        placeholder = {INPUT_MAP.password.placeholder}
                                        required
                                        minLength={8}
                                        onInvalid={()=>{window.alert('Password must have at least 1 digit and 8 characters')}}
                                    />
                                </div>
                                <div className="mb-3">
                                    <Input
                                        name="confirmPassword"
                                        type="password"
                                        style={{ width: '100%' }}
                                        label="Confirm Password"
                                        onChange = {this.handleChange}
                                    />
                                </div>

                            <div>
                                <Button className = "center-btn" icon = "save" type="submit" primary = {true} > Change Password </Button>
                            </div>
                        </form>

                        {this.props.closeSettingsModal &&
                            <div id = "btn-wrapper"
                                 style = {{marginTop: '20px'}}
                            >
                                <Button
                                    style = {{width: '45%'}}
                                    className = "sec-btn" icon="close" look = "bare" primary={false} onClick={this.props.closeSettingsModal}>Close</Button>

                                <Button
                                    style = {{width: '45%', marginLeft: '10%'}}
                                    className = "sec-btn" icon="logout" look = "bare" id="logout-button" onClick={() => {this.props.closeSettingsModal(); client.logout()}}> Logout </Button>
                            </div>
                        }
                    </div>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeSettingsModal: () => dispatch(toggleSettingsModal())
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (SettingsModal);
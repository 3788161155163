import {createCachedSelector} from 're-reselect';

const getBidById = (state, bidId) => state.bidsReducer.byId[bidId];

const selectBidById = createCachedSelector(
    getBidById,
    (bid) => bid
)(
    (_state_, bidId) => bidId
)

export default selectBidById;
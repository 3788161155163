import React from 'react';
import DateToMonth from "../../helpers/FormatExpiration";
import FormatDate from "../../helpers/FormatDate";
import FormatNumber from '../../helpers/FormatNumber';
import Colors from './Styles/Colors';

export class IdCell extends React.Component {
    render() {
        return <td {...this.props} style = {{textAlign:'right'}}>
            {this.props.dataItem.id}
        </td>
    }
}
export class StrikeCell extends React.Component {
    render () {
        const legs = this.props.dataItem.legs == null ? [] : this.props.dataItem.legs;
        console.log(this.props)
        return (
            <td {...this.props}>
                {
                    legs.map((leg, ix)=>{
                      return <div>
                          {FormatNumber(leg.strike, 'strike')}
                            &nbsp;
                            <span style = {{color: Colors[leg.structure]}}> {leg.structure} </span>

                      </div>

                    //       <div className = 'strike-flex'>
                    //         <div>
                    //         {FormatNumber(leg.strike, 'strike')}
                    // </div>
                    // <div>
                    // <span style = {{color: Colors[leg.structure]}}> {leg.structure} </span>
                    // </div>
                    // </div>
                    })
                }
            </td>
        )
    }
}

export class MonthCell extends React.Component {
    render(){
        return (
            <td {...this.props}>
                {DateToMonth(this.props.dataItem[this.props.field])}
            </td>
        );
    }
}

export class DateTimeCell extends React.Component {
    render () {
        return (
            <td {...this.props}>
                {FormatDate(this.props.dataItem[this.props.field])}
            </td>
        )
    }
}

export class ClientCell extends React.Component {
    render () {
        const dir = this.props.field; // either buy or sell
        let  { dataItem }  = this.props;
        let orgCode, clientName;

        const org = dataItem[dir+'Organization'];
        if (dataItem!==undefined && org!=null && org.code!=null ){
            orgCode = org.code;
        }

        const client = dataItem[dir+'Client'];
        if (dataItem!==undefined && client != null && client.first_name!=null) {
            clientName = client.first_name + ' ' + client.last_name;
        }

        const style = {color: dir === 'buy' ? Colors.gridCellBuy : Colors.gridCellSell};

        return (
            <td {...this.props}>
                <span style = {{...style}}> {clientName} </span>
                <span> {orgCode!==undefined && `(${orgCode})`} </span>
            </td>
        )
    }
}
import React from 'react';

import {GetDayFromDate, GetTimeFromDate} from '../../../helpers';

import BEMHelper from 'react-bem-helper';
import './IconsCell.scss';
const classes = new BEMHelper('IconsCell');

const IconsCell = (props) => {
    const {
        createdAt,
        updatedAt
    } = props;

    return <div {...classes()}>
        <div {...classes('date', 'update')}>
            {GetTimeFromDate(updatedAt)}
        </div>

        <div {...classes('date', 'create')}>
            {GetDayFromDate(createdAt)}
        </div>
    </div>
}

export default IconsCell;
import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {setSpreadFormProps} from '../../../redux/actions/wb';

import BEMHelper from 'react-bem-helper';
import './InterestsTable.scss';
import {GetTimeFromDate} from '../../../helpers';
const classes = new BEMHelper('Depth');

const InterestsTable = (props) => {
    const {
        direction,
        liveInterests,
        inactiveInterests,
        tradedInterests,
        setOrderMenuProps
    } = props;

    const dispatch = useDispatch();

    const onDoubleClick = useCallback((event, bidId) => {
        event.preventDefault();

        dispatch(setSpreadFormProps({
           orderId: null,
           direction,
           bidId,
           offset: {left: event.clientX, top: event.pageY},
        }));
    }, [dispatch]);

    const InterestCells = (interests, bem_modifier) => {
        return (interests.map(interest => {
            const {
                id,
                price,
                size,
                orgCode,
                brokeredBy,
                createdAt,
                createdBy,
                updatedAt,
                updatedBy,
                inactive,
                blockId
            } = interest;

            return <div
                {...classes('row', bem_modifier)}
                key = {id}
                onDoubleClick={(event) => onDoubleClick(event, id)}
                onContextMenu={(event) => onContextMenu(event, interest)}
                title = {`Created By: ${createdBy}\nCreated At: ${GetTimeFromDate(createdAt)}\n\nUpdated By: ${updatedBy}\nUpdated At: ${GetTimeFromDate(updatedAt)}`}
            >
                <div {...classes('panel', 'left')}>
                    <div>{price}</div>
                    <div>{orgCode ?? '...'}</div>
                </div>
                <div {...classes('panel')}>
                    <div>{size ?? '...'}</div>
                    <div>{brokeredBy}</div>
                </div>
            </div>
        }));
    }

    const onContextMenu = useCallback((event, bid) => {
        event.preventDefault();
        event.stopPropagation();
        setOrderMenuProps({
            bid,
            offset: {left: event.clientX, top: event.pageY}
        });
    }, []);

    return (liveInterests.length + inactiveInterests.length + tradedInterests.length > 0
        ? <div {...classes()}>
            {InterestCells(liveInterests, '')}
            {InterestCells(inactiveInterests, 'inactive')}
            {InterestCells(tradedInterests, 'traded')}
        </div>
        : <></>)
}

export default InterestsTable;
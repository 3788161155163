import React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { AutoComplete } from '@progress/kendo-react-dropdowns';
import {Button} from "@progress/kendo-react-buttons";
import _ from "lodash";
import client from "../../feathers";

export default class GoalsModal extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            goals: this.props.goals,

            changes: {},
            success: false,
            firstInputSelected: false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps!=this.props){
            const goals = _.cloneDeep(this.props.goals)
            this.setState({
                goals: goals,

                changes: {},
                success: false,
                firstInputSelected: false
            })
        }
    }

    handleChange = (event) => {
        const goalName = event.target.name
        const value = event.target.value

        if(/^$/.test(value) || /^\d+$/.test(value)) { //check if empty string or value
            const goals = this.state.goals
            goals[goalName].value = value

            const changes = this.state.changes
            changes[goalName] = goals[goalName]

            this.setState({
                goals: goals,
                changes: changes,
            })
        }
    }

    handleKeyPress = (target) => {
        if(target.charCode===13){
            this.updateGoals(target);
        }
    }

    updateGoals = (e) => {
        e.preventDefault()

        const changes = this.state.changes

        Object.keys(changes).forEach(key=>{
            if(changes[key].value!=""){
                client.service('goals').update(changes[key].id, {goalName: key, value: changes[key].value})
            }
        })

        this.props.closeGoalsModal()
    }

    render() {
        return (
            <div className = "mm">
                <Dialog
                    // onClose={this.props.cancel}
                >

                    <div>

                        <fieldset id = "mfs">
                            <div id = "mm-header">
                                {/*{gridDataItem.ticker} {gridDataItem.expiration} {gridDataItem.strike} {gridDataItem.structure}*/}
                                {/*<br/>*/}
                                <span style={{color:"#3f51b5"}}> Update Goals </span>
                            </div>
                            {/*<div style={{"textAlign":"center","paddingBottom":"5px"}}> Please enter matching counterparty. </div>*/}
                            {/*<span className = "mm-label"> {this.counterType.charAt(0).toUpperCase() + this.counterType.slice(1)} </span>*/}
                            <div ref={
                                (x) => {
                                    if(!this.state.firstInputSelected && x) {
                                        x.querySelector('[name="ordersDaily"]').select()
                                        this.setState({firstInputSelected: true})
                                    }
                                }
                            }>

                                <span className = "mm-label"> Daily Orders </span><Input className={"mm-input padded"} name = "ordersDaily" value={this.state.goals.ordersDaily.value || ''} placeholder="Daily Orders" onChange = {this.handleChange} onKeyPress={this.handleKeyPress}/>
                            </div>

                            <span className="mm-label"> Weekly Orders </span><Input className={"mm-input padded"} name = "ordersWeekly" value={this.state.goals.ordersWeekly.value || ''} placeholder="Weekly Orders" onChange = {this.handleChange} onKeyPress={this.handleKeyPress}/>

                            <span className="mm-label"> Monthly Orders </span><Input className={"mm-input padded"} name = "ordersMonthly" value={this.state.goals.ordersMonthly.value || ''} placeholder="Monthly Orders" onChange = {this.handleChange} onKeyPress={this.handleKeyPress}/>



                            {/*trades*/}
                            <span className = "mm-label"> Daily Trades </span><Input className={"mm-input padded"} name = "tradesDaily" value={this.state.goals.tradesDaily.value || ''} placeholder="Daily Trades" onChange = {this.handleChange} onKeyPress={this.handleKeyPress}/>
                            <span className="mm-label"> Weekly Trades </span><Input className={"mm-input padded"} name = "tradesWeekly" value={this.state.goals.tradesWeekly.value || ''} placeholder="Weekly Trades" onChange = {this.handleChange} onKeyPress={this.handleKeyPress}/>
                            <span className="mm-label"> Monthly Trades </span><Input className={"mm-input padded"} name = "tradesMonthly" value={this.state.goals.tradesMonthly.value || ''} placeholder="Monthly Trades" onChange = {this.handleChange} onKeyPress={this.handleKeyPress}/>

                        </fieldset>
                        <DialogActionsBar>
                            <Button className="mt-3" type="button" onClick = {e=> this.props.closeGoalsModal()}>Cancel</Button>

                            <Button className="mt-3" type="submit" primary={true} onClick={ e => {
                                this.updateGoals(e)
                            }}>
                                Update
                            </Button>
                        </DialogActionsBar>
                    </div>

                    {/*<DialogActionsBar>*/}
                    {/*    <button*/}
                    {/*        className="k-button"*/}
                    {/*        onClick={this.props.closeMatchModal}*/}
                    {/*    >*/}
                    {/*        Cancel*/}
                    {/*    </button>*/}
                    {/*    <button*/}
                    {/*        className="k-button k-primary"*/}
                    {/*        onClick={this.trade}*/}
                    {/*    >*/}
                    {/*        Save*/}
                    {/*    </button>*/}
                    {/*</DialogActionsBar>*/}
                </Dialog>
            </div>
        );
    }
}
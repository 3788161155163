import {
    CREATE_ROOM,
    CREATE_ROOM_USER, PATCH_ROOM_USER,
    REMOVE_ROOM_USER,
    SET_ROOM_USERS, SET_ROOMS,
} from '../actions/types';
import {combineReducers} from 'redux';
import produce from "immer"

function _mapRoomIdToRoomUser (roomUsers) {
    let res = {};

    roomUsers.forEach(roomUser => {
        res[roomUser.roomId] = roomUser;
    });

    return res;
}

const roomIdToRoomUser = produce((draft, action) => {
    switch(action.type) {
        case SET_ROOM_USERS:
            return _mapRoomIdToRoomUser(action.roomUsers);
        case PATCH_ROOM_USER:
        case CREATE_ROOM_USER:
            draft[action.roomUser.roomId] = action.roomUser;
            return;
        // case CREATE_ROOM:
        //     // The list of user ids comes prepackaged from the roomLogicListener event
        //     // action.room.messages = [];
        //     // action.room.users = []; // create blank list of associated users for a newly created room
        //     draft[action.room.id] = action.room;
        //     return;
        // // Handle user adding a message - append to list of messages
        // case CREATE_MESSAGE: {
        //     const {message} = action;
        //     draft[message.roomId].messages.push(message.id);
        //     return;
        // }
        // // Handle user joining/leaving a room
        // case JOIN_ROOM:
        //     if(draft[action.roomId]) {
        //         if (draft[action.roomId] && draft[action.roomId].users && !draft[action.roomId].users.includes(action.userId)) {
        //             draft[action.roomId].users.push(action.userId);
        //         }
        //         return;
        //     }
        //     return;
        // case LEAVE_ROOM: {
        //     const userIdIndex = draft[action.roomId].users
        //         .findIndex(userId => userId === action.userId);
        //     if (userIdIndex !== -1)
        //         draft[action.roomId].users.splice(userIdIndex, 1);
        //     return;
        // }
        case REMOVE_ROOM_USER:
            delete draft[action.roomUser.id];
            return;
    }
}, {});

// const allIds = produce((draft, action) => {
//     switch (action.type) {
//         case SET_ROOM_USERS:
//             return action.roomUsers.map(roomUser => roomUser.id);
//         case CREATE_ROOM_USER:
//             draft.push(action.roomUser.id);
//             return;
//         case REMOVE_ROOM_USER: {
//             const {roomUser} = action;
//             const roomUserId = roomUser.id;
//             const roomUserIndex = draft.findIndex(id => id === roomUserId);
//             if (roomUserIndex !== -1)
//                 draft.splice(roomUser, 1);
//             return;
//         }
//     }
// }, []);

const RoomUsersReducer = combineReducers({
    roomIdToRoomUser,
})

export default RoomUsersReducer;
import {SET_SELECTED_ROOM} from '../types';
import patchRoomUser from '../roomUsers/patchRoomUser';
import {handleAivaClick} from '../index';

export default function setSelectedRoom (roomId = -1) {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_SELECTED_ROOM,
            roomId: roomId
        });

        const roomUser = getState().roomUsersReducer.roomIdToRoomUser[roomId];
        if (roomUser && roomUser.unreadCount !== 0) {
            patchRoomUser(roomUser.id, {unreadCount: 0})
        }

        /** Close Menu if Click **/
        dispatch(handleAivaClick());
    }
}
import React, {useState, useEffect} from 'react';
import BEMHelper from 'react-bem-helper';
import {Popup} from '@progress/kendo-react-popup';
import {Input} from '@progress/kendo-react-inputs';
import {Button} from '@progress/kendo-react-buttons';
import './TradeForm.scss';
import {editTrade} from '../../../redux/actions/wbTrades';
import OrgComboBox from '../../../utilities/OrgComboBox';
import {useDispatch} from 'react-redux';
import {setTradeFormProps} from '../../../redux/actions/wb';
const classes = new BEMHelper('TradeForm');

const TradeForm = ({prevTrade, offset}) => {
    const [trade, setTrade] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        if (prevTrade != null) setTrade({...prevTrade});
    },[prevTrade]);

    const onInputChange = (event) => {
        let {
            name,
            value
        } = event.target;

        // if (value == null) setTrade({...trade, [name]: ''});
        if (value.match(/^\d*\.?\d*$/)) {
            setTrade({...trade, [name]: value});
        }
    }

    const onComboChange = (value) => {
        setTrade({...trade, ...value});
    }

    const onSubmit = (event) => {
        event.preventDefault();

        editTrade(prevTrade, trade);

        dispatch(setTradeFormProps());
    }

    const preventClickPropagation = (event) => {
        event.stopPropagation();
    }

    const {
        id: tradeId,
        size,
        price,
        buyOrgCode,
        buyOrganizationId,
        sellOrgCode,
        sellOrganizationId
    } = trade;

    return <Popup
        show = {tradeId != null}
        offset = {offset}
        appendTo={document.querySelector('.bb')}
        animate={false}
        className = {'Popup'}
    >
        <form
            autoComplete='off'
            onSubmit = {onSubmit}
            onClick = {preventClickPropagation}
        >
        <div {...classes()}>
            <div {...classes('cell', 'input')}>
                <Input
                    value = {size}
                    name = 'size'
                    label='Size'
                    onChange = {onInputChange}
                    required={true}
                />
            </div>
            <div {...classes('times')}>x @</div>
            <div {...classes('cell', 'input')}>
                <Input
                    value = {price}
                    name = 'price'
                    label='Price'
                    onChange = {onInputChange}
                    required={true}
                    disabled={true}
                />
            </div>
            <div {...classes('cell', 'combo')}>
                <OrgComboBox
                    value={{id: buyOrganizationId, code: buyOrgCode}}
                    onChange = {onComboChange}
                    textField={'code'}
                    remapIdKey={'buyOrganizationId'}
                    remapFieldKey={'buyOrgCode'}
                    label = {'Buyer'}
                    required={true}
                    disabled={true}
                />
            </div>
            <div {...classes('cell', 'combo')}>
                <OrgComboBox
                    value={{id: sellOrganizationId, code: sellOrgCode}}
                    onChange = {onComboChange}
                    textField={'code'}
                    remapIdKey={'sellOrganizationId'}
                    remapFieldKey={'sellOrgCode'}
                    label = {'Seller'}
                    required={true}
                    disabled={true}
                />
            </div>

            <Button
                icon={'check'}
                type='submit'
                primary={true}
                {...classes('button')}
            />
        </div>
        </form>
    </Popup>
}

export default TradeForm;
import React from 'react';
import {connect} from 'react-redux';
import MyComboBox from '../MyComboBox';

const StructureComboBox = (props) => {
    return <MyComboBox
        {...props}
        data = {props.structures}
    />
}

const mapState = (state) => {
    return {
        structures: Object.values(state.staticReducer.structuresById)
    }
}

export default connect(mapState)(StructureComboBox);
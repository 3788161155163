import moment from 'moment';

/**
 * Gets the specified third day of week
 * @param year
 * @param month
 * @param day (Either 5 for friday or 3 for wednesday)
 */
export default function GetThirdDay(year, month, day){
    // Convert date to moment (month 0-11)
    let myMonth = moment();

    myMonth.year(year).month(month).date(1)

    // Get first Friday of the first week of the month
    let firstFriday = myMonth.day(day);
    let nWeeks = 2;
    // Check if first Friday is in the given month
    if( firstFriday.month() != month ){
        nWeeks++;
    }

    // Return 3rd Friday of the month formatted (custom format)
    const ret = firstFriday.add(nWeeks, 'weeks');
    return ret.format("DD");
}
import moment from 'moment';


/** Helpers **/
import {GetThirdDay} from './index';
import {LEG_TEMPLATES} from './data';

/** Store **/
import store from '../redux/store';

const MONTH_MAP = {
    'JAN' : '01',
    'FEB' : '02',
    'MAR' : '03',
    'APR' : '04',
    'MAY' : '05',
    'JUN' : '06',
    'JUL' : '07',
    'AUG' : '08',
    'SEP' : '09',
    'OCT' : '10',
    'NOV' : '11',
    'DEC' : '12'
}

const SYM_PADDING = {
    day: 2,
    strike_whole: 5,
    strike_decimal: 3
}

const _getMonthAndDay = (ticker, expiry, createdAt, createdAtYYYY, expirationOrder = 0) => {
    // Expiration can come in form DDMMMYY e.g. 24JAN21
    let DD_MM_YY_r = /([\d]*)([A-Za-z]+)([\d]*)/;
    let DD_MM_YY = DD_MM_YY_r.exec(expiry);

    if (DD_MM_YY == null) return '';

    const month = DD_MM_YY[2].toUpperCase();
    let month_sym = MONTH_MAP[month];

    let addOneYear = false;
    if (expirationOrder !== 0) {
        month_sym = parseInt(month_sym) + expirationOrder;
        if (month_sym > 12) {
            month_sym %= 12;
            addOneYear = true;
        }
        month_sym = month_sym.toString().padStart(2, '0');
    }

    let year_sym  = DD_MM_YY[3];
    if(year_sym === '') {
        const year_YYYY = moment(`${createdAtYYYY}-${month_sym}-01`).add(1, 'M').isBefore(createdAt, 'day')
            ? parseInt(createdAtYYYY) + 1
            : createdAtYYYY;
        year_sym = String(year_YYYY ).substring(2)
    } else if (addOneYear) {
        year_sym = (parseInt(year_sym) + 1).toString();
    }

    let day_sym = DD_MM_YY[1];
    if (day_sym === '') {
        // Get either the third friday or wednesday if vix
        day_sym = GetThirdDay('20'+year_sym, parseInt(month_sym)-1, (ticker==='VIX' ? 3 : 5));
    } else {
        day_sym = day_sym.padStart(SYM_PADDING.day, '0');
    }

    return [year_sym, month_sym, day_sym];
}

const _getStrikeWholeAndDecimal = (strike) => {
    const [whole, decimal] = strike.split(".");

    let whole_sym = whole!=null ? whole.padStart(SYM_PADDING.strike_whole, '0') : '0'.repeat(SYM_PADDING.strike_whole);
    let decimal_sym = decimal!=null ? decimal.padEnd(SYM_PADDING.strike_decimal, '0') : '0'.repeat(SYM_PADDING.strike_decimal);

    return [whole_sym, decimal_sym];
}

export default async function GetSymbolList (order) {
    let symbol_list = [];

    if(order.ticker == null || order.ticker === '') {
        return [];
    }
    let ticker = order.ticker;

    const base_symbol = '@' + ticker.padEnd(6);

    let expiry_list = order.expiration.split("/");
    let strike_list = order.strike.split("/");

    let structure = order.structName;
    if (expiry_list.length ===1 && strike_list.length === 1) {
        if (structure === 'CALL SPREAD') {
            structure = 'CAL CS';
        } else if (structure === 'PUT SPREAD') {
            structure = 'CAL PS';
        }
    }

    if (!LEG_TEMPLATES[structure]) {
        throw new Error('Symbol does not exist');
    }
    let legs = LEG_TEMPLATES[structure].legs;

    /** Year **/
    let createdAt = moment(order.createdAt);
    let createdAtYYYY = createdAt.year();

    legs.forEach((leg_obj, ix) => {
        let leg_sym = leg_obj.structure === 'CALL' ? 'C' : 'P';

        /** Expiration (Month, Day, Year) **/
        let expiry = expiry_list[0];
        let expirationOrder = 0;
        if (expiry_list.length > 1) {
            expiry = expiry_list[ix];
        } else if (ix > 0 && leg_obj.expiration!=null) {
            expirationOrder = leg_obj.expiration;
        }
        const [year_sym, month_sym, day_sym] = _getMonthAndDay(ticker, expiry, createdAt, createdAtYYYY, expirationOrder);

        /** Strike **/
        let strike = strike_list[0];
        if (strike_list.length > 1) {
            strike = strike_list[ix];
        }
        const [strike_whole_sym, strike_decimal_sym] = _getStrikeWholeAndDecimal(strike);

        /** Generate symbol **/
        let symbol = base_symbol + year_sym + month_sym + day_sym + leg_sym + strike_whole_sym + strike_decimal_sym;
        symbol_list.push(symbol);
    });

    /** Generate the correct ticker (Add ^VIX if index not stock)**/
    if (ticker in store.getState().underlyingsReducer.indicesByTicker) {
        ticker = '^' + order.ticker
    }

    /** Add the base ticker at the end **/
    symbol_list.push(ticker);

    return symbol_list;

    // same expiry, multiple strikes
        // NVDA Jul 360/400 Strangle
        // NOV 14/16 CS
        // DEC 185/210 RISK REV
        // NOV 310/320 STRANGLE
        // VIX NOV 12.5/13/13.5 PUT FLY
        // jan 155/160 PS

    // different expiry, same strike
        // JAN/FEB 14 PS
        // NOV/DEC 35 CS

    // diff expiry, diff strikes
        // SPY Dec 295 Jun21 290 Put Spread

    // Same strike, same expiry
        // JAN 200 REV CON
        // PYPL Jun21 210 calls

}
import {
    CREATE_AIVA_PORTAL,
    SET_MODAL_ROOM_ID,
    SET_MODAL_CREATE_ROOM_TYPE,
    SET_SELECTED_ROOM,
    SET_SELECTED_THREAD,
    TOGGLE_IS_AIVA_PORTAL_OPEN,
    TOGGLE_THREAD,

    SET_IS_WINDOW_COMPACT,
    SET_MENU_CLASS_MODIFIER
} from '../actions/types';

import {combineReducers} from 'redux';
import produce from "immer"

/** The New Window Portal for Aiva **/
const aivaPortal = (state = {}, action) => {
    switch (action.type) {
        case CREATE_AIVA_PORTAL:
            return action.portal;
        case TOGGLE_IS_AIVA_PORTAL_OPEN:
            return {};
        default:
            return state;
    }
}

const isAivaPortalOpen = (state = false, action) => {
    switch (action.type) {
        case TOGGLE_IS_AIVA_PORTAL_OPEN:
            return !state
        default:
            return state
    }
}

/** The room id for the current open modal **/
const modalRoomId = (state = -1, action) => {
    switch (action.type) {
        case SET_MODAL_ROOM_ID:
            return action.roomId;
        default:
            return state;
    }
}

const modalCreateRoomType = (state = 'n', action) => {
    if (action.type === SET_MODAL_CREATE_ROOM_TYPE) {
        return action.createRoomType;
    } else {
        return state;
    }
}

const openThreadsById = produce((draft, action) => {
    switch (action.type) {
        case TOGGLE_THREAD:
            draft[action.messageId] = !draft[action.messageId];
            return;
    }
}, {});

const selectedThread = (state = -1, action) => {
    switch(action.type) {
        case SET_SELECTED_THREAD:
            return action.messageId;
        default:
            return state;
    }
}

const selectedRoom = (state = 1, action) => {
    switch (action.type) {
        case SET_SELECTED_ROOM:
            return action.roomId;
        default:
            return state;
    }
}

/** visibility and size of the menu **/
const menuData = (state = {classModifier: 'normal', isWindowCompact: false}, action) => {
    switch (action.type) {
        case SET_IS_WINDOW_COMPACT:
            return {...state, isWindowCompact: action.isWindowCompact}
        case SET_MENU_CLASS_MODIFIER:
            return {...state, classModifier: action.classModifier}
        default:
            return state;
    }
}

const AivaReducer = combineReducers({
    aivaPortal: aivaPortal,
    isAivaPortalOpen: isAivaPortalOpen,
    modalRoomId: modalRoomId,
    modalCreateRoomType: modalCreateRoomType,
    openThreadsById: openThreadsById,
    selectedThread: selectedThread,
    selectedRoom: selectedRoom,
    menuData: menuData
})

export default AivaReducer;
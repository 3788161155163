import {
    logicMainService
} from '../../../feathers';

/**
 *
 * @param dataItem (required) the dictionary generated by NLP containing order/bid information
 * @param body (optional) the message body to be created for the Aiva chat order. Defaults to 'Created in whiteboard'.
 * @param roomId (REQUIRED) the id of the room. this is attached to the query parameter
 */
export default function (dataItem, body, roomId) {
    console.log(dataItem)

    logicMainService.create(dataItem, {
        query: {
            body: body,
            roomId: roomId
        }
    });
}
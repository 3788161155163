import React from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';

/** Components **/
import WindowPortal from './WindowPortal';

class TicketWindows extends React.Component {
    constructor (props) {
        super(props);
    }

    componentDidMount() {
        window.addEventListener('unload', e => {
            const { windows } = this.props.ticketReducer;
            windows.forEach(window => {
               try {
                   window.close()
               } catch {

               }
            });
        })
    }

    render () {
        const { tickets } = this.props.ticketReducer;
        return (
            <div>
                {
                    tickets.map((id, ix) => {
                        return <WindowPortal
                            id = {id}
                            key = {id+ix}
                        />
                    })
                }
            </div>
        );
    }
}

function mapStateToProps (state) {
    return {
        ticketReducer: state.ticketReducer
    }
}

function mapDispatchToProps (dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect (mapStateToProps, mapDispatchToProps) (TicketWindows);